exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2cFU4-Yx1Fo4vtaZoGZ-bR {\n    text-align: center;\n    margin-bottom: 20px;\n    padding-top: 10px;\n}\n\n._23uUnKyNGnd9SwQ3_lAJAm {\n    font-size: 28px;\n    color: #02499d;\n    font-weight: 600;\n}\n._100AAFs9p7rcHLRJ43gFub{\n    font-size: 17px;\n    color: #333;\n}\n", ""]);

// exports
exports.locals = {
	"deadline_container": "_2cFU4-Yx1Fo4vtaZoGZ-bR",
	"deadline": "_23uUnKyNGnd9SwQ3_lAJAm",
	"label": "_100AAFs9p7rcHLRJ43gFub"
};
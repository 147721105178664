import React from 'react';

import EntryPickPage from 'app/containers/entry/entryPickPage_container.jsx';
import EntryResultPage from 'app/views/entry/entryMain/entryResultPage.jsx';
import EntryHeader from 'app/containers/entry/entryHeader_container.jsx';

export default class EntryMain extends React.Component {
    _renderMain(){
        var hashUrlb = this.props.entry.entryData.urlaData.hashUrlb;
        var deadLine = this.props.deadLine;
        switch (this.props.entry.pageController.tabValue) {
            case 'pick':
                return <EntryPickPage deadLine={deadLine} {...this.props}/>
            case 'result':
                return <EntryResultPage deadLine={deadLine} hashUrlb={hashUrlb} {...this.props}/>
            default:
                return <EntryPickPage deadLine={deadLine} {...this.props}/>
        }
    }
    render() {
        var {hashUrla,deadLine} = this.props;
        return (
            <div>
                <EntryHeader hashUrla={hashUrla} deadLine={deadLine}/>
                {this._renderMain()}
            </div>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-aIZbdVNrF9aU6EokyHI-{\n    height: 450px;\n}\n._2TSPGUPRxquUcfeWY5xiv_{\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n._3A31v2GQdIjWgElZHVYYt6{\n    font-size: 17px;\n    font-weight: 700;\n    text-align: center;\n    margin-bottom: 0;\n}\n._3A31v2GQdIjWgElZHVYYt6 span{\n    color: #02499d;\n}\n._3p_Kih0bZxpSVOafqUnsVH{\n    font-weight: 200;\n    color: #999;\n    text-align: center;\n    margin-bottom: 40px;\n}\n._2207zTNh54f6MHJ2AGXZu3{\n    text-align: left;\n    margin-bottom: 3px;\n}\n._2BMT2T5Jc3ucEBd1D_qXtP{\n    height: 152px;\n}\n._1puNdyX4ajC5btO82OXXf1 span{\n    font-weight: bold;\n    color:#02499d;\n}\n\n._1vmli2IoTp4wWKghUl6SPy{\n}\n\n._3FK338W7DEEaAXdnh-e_m8{\n    width: 65px;\n    height: 65px;\n    border-radius: 50px;\n}\n\n._3RtkMe2c0BM0nnZrugkKla{\n    font-weight: bold;\n    color: #333;\n    margin-bottom: 3px;\n}\n._3kW1Afl8fBUHGXgL4qaQWC{\n    color: #999;\n    margin-bottom: 3px;\n}\n\n._2VDRprcU-0zrJLeCcLZGUG{\n    padding:10px 5px;\n    margin-bottom: 20px;\n}\n._1yqX3lPZz-GI17cr3lxQTS {\n\tposition: relative;\n\tbackground: #eee;\n\tborder-radius: .4em;\n    margin-top: 12px;\n}\n\n._1yqX3lPZz-GI17cr3lxQTS:after {\n    content: '';\n\tposition: absolute;\n\ttop: 0;\n\tleft: 50%;\n\twidth: 0;\n\theight: 0;\n\tborder: 8px solid transparent;\n\tborder-bottom-color: #eee;\n\tborder-top: 0;\n\tmargin-left: -8px;\n\tmargin-top: -8px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1-aIZbdVNrF9aU6EokyHI-",
	"page": "_2TSPGUPRxquUcfeWY5xiv_",
	"introText": "_3A31v2GQdIjWgElZHVYYt6",
	"introSmallText": "_3p_Kih0bZxpSVOafqUnsVH",
	"textLeft": "_2207zTNh54f6MHJ2AGXZu3",
	"image": "_2BMT2T5Jc3ucEBd1D_qXtP",
	"text": "_1puNdyX4ajC5btO82OXXf1",
	"inviteMessageBox": "_1vmli2IoTp4wWKghUl6SPy",
	"avatar": "_3FK338W7DEEaAXdnh-e_m8",
	"eventTitle": "_3RtkMe2c0BM0nnZrugkKla",
	"eventDescription": "_3kW1Afl8fBUHGXgL4qaQWC",
	"bubbleBox": "_2VDRprcU-0zrJLeCcLZGUG",
	"speech_bubble": "_1yqX3lPZz-GI17cr3lxQTS"
};
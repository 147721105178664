import {
    combineReducers
} from 'redux';

import {
    SWITCH_STICKER_BOX_DISPLAY,
	TEXT_COMMENT_UPDATE,
    RESET_TEXT_COMMENT
} from 'app/actions/comment/commentBox_action.js';

function commentBox(state={
	isStickerBoxDisplay:false,
	textComment:''
},action) {
    switch (action.type) {
        case SWITCH_STICKER_BOX_DISPLAY:
            var newState = Object.assign({},state);
            newState.isStickerBoxDisplay = (newState.isStickerBoxDisplay)?false:true;
            return newState;
		case TEXT_COMMENT_UPDATE:
            var newState = Object.assign({},state);
            newState.textComment = action.data;
            return newState;
        case RESET_TEXT_COMMENT:
            var newState = Object.assign({},state);
            newState.textComment = '';
            newState.isStickerBoxDisplay = false;
            return newState;
        default:
            return state;
    }
}


export default commentBox;

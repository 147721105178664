import {
    combineReducers
} from 'redux';

function index (state = {
    testIndex:'test',
    screenWidth: null,
},action){
    switch (action.type) {
        case 'RESIZE_SCREEN':
            var newState = Object.assign({},state);
            newState.screenWidth = action.data;
            return newState;
        default:
            return state;
    }
}

export default index;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import ControlPanel from 'app/components/controlPanel/controlPanel.jsx';

import * as userActions from 'app/actions/user/user_action.js';
import * as languageActions from 'app/actions/api/language_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 controlPanelActions : bindActionCreators(controlPanelActions,dispatch),
     userActions:bindActionCreators(userActions, dispatch),
     languageActions:bindActionCreators(languageActions, dispatch)
  };
}

function mapStateToProps(state) {
	return {
        user:state.user,
        language:state.language,
		controlPanelData:state.controlPanel,
        feedback:state.feedback
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ControlPanel);

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/textbox_style.css';

export default class TextBox extends React.Component {
	value(text){
		if(text || text==''){
			this.refs.myInput.value = text;
		}else {
			return this.refs.myInput.value;
		}
	}
	render() {
        var {className} = this.props;
		className =  className+' '+styles.my_input;
		return (
                <input	ref='myInput'
                        {...this.props}
						className={className}/>
		);
	}
}

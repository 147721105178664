import {
    ADD_URLB_LIST,
    SET_URLB_LIST_IS_LOADING
} from 'app/actions/keoList/urlbList_action.js';

function ulrbList(state={
    list:[],
    isLoadMore:false,
    isLoading:false
},action) {
    switch (action.type) {
        case ADD_URLB_LIST:
            var newState = Object.assign({},state);
            var list = Object.assign([],state.list);
            if(action.latestDate==''){
                list = action.data;
            }else {
                list = list.concat(action.data);
            }
            newState.list = list;
            newState.isLoadMore = action.isLoadMore;
            return newState;
        case SET_URLB_LIST_IS_LOADING:
            var newState = Object.assign({},state);
            newState.isLoading = action.data;
            return newState;
        default:
            return state;
    }
}

export default ulrbList;

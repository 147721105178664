export const GENERATE_RESULT_FETCH_DATA = 'GENERATE_RESULT_FETCH_DATA';
export const GENERATE_SWITCH_EDIT_MODE = 'GENERATE_SWITCH_EDIT_MODE';
export const GENERATE_EDIT_URLA_DATA = 'GENERATE_EDIT_URLA_DATA';
export const GENERATE_SWITCH_VOTE_PANEL = 'GENERATE_SWITCH_VOTE_PANEL';

import { API_GENERATE_RESULT_FETCH, API_GENERATE_EDIT_URLA } from 'app/js/api.js';


import { fetchResultFromServer } from 'app/actions/api/result_action.js';

export function generateResultFetchFromServer(hashUrlb) {
    return function(dispatch) {
        fetchResultFromServer(hashUrlb, function(content) {
            if (content) {
                dispatch(generateResultFetchData(content));
            }
        });
    }
}

export function generateResultFetchData(data) {
    return {
        type: GENERATE_RESULT_FETCH_DATA,
        data: data
    };
}



export function generateDeleteCommentFromServer(urlaId, urlbId, index) {
    var submitData = {
        urlaId: urlaId,
        urlbId: urlbId
    };

    return function(dispatch) {
        dispatch(generateDeleteCommentItemData(index));
        return fetch(API_GENERATE_DELETE_COMMENT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(generateResultFetchData(responseJson.content));
            }
        });
    }
}

export function switchEditMode(data) {
    return {
        type: GENERATE_SWITCH_EDIT_MODE,
        data: data
    };
}

export function editUrlaDataToServer(hashUrla, title, owner, description) {
    var submitData = {
        hashUrla: hashUrla,
        title: title,
        owner: owner,
        description: description,
    };
    return function(dispatch) {
        return fetch(API_GENERATE_EDIT_URLA, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(switchEditMode(0));
                dispatch(editUrlaData(responseJson.content));
            }
        });
    }
}

export function editUrlaData(data) {
    return {
        type: GENERATE_EDIT_URLA_DATA,
        data: data
    };
}

export function switchVoteEditPanel() {
    return {
        type: GENERATE_SWITCH_VOTE_PANEL
    };
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/userList_style.css';
import {GNullBoard} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class UserList extends React.Component {
	_renderList(){
		var nodes = this.props.data.map(function(item,index){
			return <UserUnit data={item} index={index}/>;
		});
		if(nodes.length>0){
			return nodes;
		}else {
			return <GNullBoard><FormattedMessage id="userList.noVotesMessage" /></GNullBoard>;
		}
	}
    render () {
        return (
            <div>
				<div style={{marginTop:10}}>
					{this._renderList()}
				</div>
            </div>
        );
    }
}

export class UserUnit extends React.Component {
    render () {
        var user = this.props.data.user;
		var avatarUrl = (user)?user.profile.avatarUrl:'/images/control/nullAvatar.jpg';
        var displayName = (user)?user.displayName:this.props.data.urlb.name;
        return (
        	<div className={styles.like_item_board}>
				<img src={avatarUrl} className={styles.like_item_avatar}/>
				{displayName}
			</div>
        );
    }
}

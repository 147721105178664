
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/gNullBoard_style.css';


export default class NullBoard extends React.Component{
    render(){
        var myStyle = (this.props.style)?this.props.style:{};
        return(
            <div className={styles.list_post_null_board} style={myStyle} >
                <center>
                    {this.props.children}
                </center>
            </div>
        );
    }
}

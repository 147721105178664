import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GButton, KeywordList, GNullBoard, AlertBox } from 'app/components/gTools/gTools.jsx';
import VoteInfo from 'app/components/vote/voteInfo.jsx';
import SelectedDayList from 'app/components/entry/selectedDayList.jsx';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generatePage4_style.css';
import mainStyles from './styles/generateMain_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
moment.locale('vi');
moment.updateLocale('vi', { months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });

export default class GeneratePage4 extends React.Component {
    _onClickSubmit() {
        console.log('this.props.generate.urlaData', this.props.generate.urlaData);
        this.props.generatePageActions.submitUrlaDataToServer(
            this.props.gFormInputData.generateSettingForm.data.title,
            this.props.gFormInputData.generateSettingForm.data.owner,
            this.props.gFormInputData.generateSettingForm.data.description,
            this.props.generate.urlaData.deadLine,
            this.props.entryDatePicker,
            this.props.votePanelData.votes
        );
    }
    _onClickBack() {
        this.props.generatePageActions.setGenerateMainTabValue('time');
        window.scrollTo(0, 0);
    }
    _renderDayList() {
        var selectedDays = this.props.entryDatePicker.selectedDays;
        if (selectedDays.length > 0) {
            return <SelectedDayList
                deadLine
                disable
                voteTimeMode
                data={selectedDays} />;
        } else {
            return (
                <AlertBox alignItems='center' color='gray'>
                    <p><FormattedMessage id="generatePage4.dateNotSpecifiedMessage" /></p>
                    <p><FormattedMessage id="generatePage4.participantsFreeTimeMessage" /></p>
                </AlertBox>
            );
        }
    }
    _renderSubmitButton() {
        if (!this.props.generate.generateMain.isLoading) {
            return (
                <GButton
                    default='primary'
                    style={{ width: '100%', height: 47, fontSize: 10 }}
                    onClick={this._onClickSubmit.bind(this)}>
                    <i className="fa fa-check" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage4.createEventMessage" />
                </GButton>
            );
        } else {
            return (
                <GButton
                    default='block'
                    style={{ width: '100%', height: 47, fontSize: 10 }}>
                    <i className="fa fa-check" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage4.createEventMessage" />
                </GButton>
            );
        }
    }
    render() {
        var generateSettingForm = this.props.gFormInputData.generateSettingForm;
        var owner, title, description = '';
        if (generateSettingForm) {
            owner = generateSettingForm.data.owner;
            title = generateSettingForm.data.title;
            description = generateSettingForm.data.description;
        }
        var votes = this.props.votePanelData.votes;
        var thumbnail = getThumbnail(votes);
        var radioButtonChecked = this.props.generate.urlaData.deadLine;
        return (
            <div className={styles.container}>
                <div className={styles.headerContainer} >
                    <div className={styles.blur} style={{ backgroundImage: `url(${thumbnail})` }}></div>
                    <div className={styles.headerInfoBoard}>
                        <div className={styles.headerTop}>
                            <div className={styles.ownerBoard}>
                                <p className={styles.owner}>{owner}</p>
                            </div>
                            <div className={styles.titleBoard}>
                                <p className={styles.title}>{title}</p>
                            </div>
                        </div>
                        <div className={styles.headerFooter}>
                            <p className={styles.description}>{description}</p>
                        </div>
                    </div>
                </div>
                <p className={styles.category}>
                    <i className='fa fa-map-marker' style={{ fontSize: 17, marginRight: 3 }} aria-hidden="true"></i>
                    <FormattedMessage id="generatePage4.expectedLocationMessage" />
                </p>
                <ListInfo data={votes} />
                <p className={styles.category} style={{ marginTop: 15 }}>
                    <i className='fa fa-calendar' style={{ fontSize: 17, marginRight: 3 }} aria-hidden="true"></i>
                    <FormattedMessage id="generatePage4.expectedTimeMessage" />
                </p>
                {this._renderDayList()}
                <p className={styles.category}>
                    <i className='fa fa-clock-o' style={{ fontSize: 17, marginRight: 3 }} aria-hidden="true"></i>
                    Thời hạn pick kèo
                </p>
                <p className={styles.value}>{convertLimitTimeToLabel(radioButtonChecked)}</p>
                <AlertBox color='blue'>
                    <p>
                        (!) <FormattedMessage id="generatePage4.confirmationMessage" />
                    </p>
                </AlertBox>
                <div className={mainStyles.submitBox}>
                    <div style={{ display: 'flex' }}>
                        <GButton
                            default='gray'
                            style={{ width: '100%', height: 47, fontSize: 10, marginRight: 10 }}
                            onClick={this._onClickBack.bind(this)}>
                            <i className="fa fa-arrow-left" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage4.goBackMessage" />
                        </GButton>
                        <div style={{ flex: 1 }}></div>
                        {this._renderSubmitButton()}
                    </div>
                </div>
            </div>
        );
    }
}

class ListInfo extends React.Component {
    _renderMain() {
        var data = this.props.data;
        if (data.length > 0 && data[0].title != '') {
            var nodes = this.props.data.map(function (item, index) {
                if (item.content || item.location) {
                    return <VoteInfo
                        color='blue'
                        disableMap={1} data={item} />
                } else {
                    return <p className={styles.value}>{item.title}</p>;
                }
            });
            return nodes;
        } else {
            return <GNullBoard style={{ paddingTop: 25, paddingBottom: 25 }}><FormattedMessage id="generatePage4.noLocationSelectedMessage" />            </GNullBoard>;
        }
    }
    render() {
        return (
            <div style={{ marginBottom: 10 }}>
                {this._renderMain()}
            </div>
        );
    }
}

function convertLimitTimeToLabel(value) {
    switch (value) {
        case 'unlimited':
            return 'Vô hạn';
        case '12':
            return 'Sau 12 giờ';
        case '24':
            return 'Sau 24 giờ';
        case '48':
            return 'Sau 48 giờ';
        default:

    }
}

function getThumbnail(votes) {
    for (var i = 0; i < votes.length; i++) {
        if (votes[i].content) {
            return votes[i].content.thumbnail;
        }
        if (votes[i].location) {
            return votes[i].location.thumbnail.mobilePicturePath;
        }
    }
    return '';
}
// <p className={styles.category}>
//     <i className='fa fa-clock-o' style={{fontSize:17,marginRight:3}} aria-hidden="true"></i>
//     Thời hạn pick kèo
// </p>
// <p className={styles.value}>{convertLimitTimeToLabel(radioButtonChecked)}</p>

import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles/tagInput_style.css';

export default class TagInput extends React.Component {
    _renderTagList() {
        var tagList = this.props.tagData.map((item,index)=> {
            return (
                <span id={index}>{item}</span>
            )
        })
        return(
            {tagList}
        )
    }
    render() {
        return (
            <div>
                <input className={styles.input}
                       {...this.props}/>
                <div>
                {this._renderTagList}
                </div>
            </div>
        )
    }
}

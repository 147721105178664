import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generateDatePicker_style.css';
import {KeywordList} from 'app/components/gTools/gTools.jsx';

import DayPicker, { DateUtils } from 'react-day-picker';
import dayPickerStyles from 'app/styles/picker_style.css';
//set moment lib qua parse tieng viet
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import 'moment/locale/ja';
import 'moment/locale/en-ie';

moment.locale('vi');
moment.updateLocale('vi', {months : 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export default class GenerateDatePicker extends React.Component {
    componentWillUnmount(){
        this.props.generateDatePickerActions.resetDay();
    }
    _onDayClick(day){
        var today = new Date();
        today.setDate(today.getDate() - 1);
        if (today>day) {
            return;
        }
        const selectedIndex = this.props.generateDatePicker.selectedDays.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
        );
        if(selectedIndex!=-1){
            this.props.generateDatePickerActions.spliceDay(selectedIndex);
        }else {
            this.props.generateDatePickerActions.selectDay(day);
        }
    }
    render(){
        var start = new Date();
        switch (this.props.language.lang) {
            case 'vn':
                var locale = 'vi';
                break;
            case 'en':
                var locale = 'en-ie';
                break;
            case 'jp':
                var locale = 'ja';
                break;
            default:
                var locale = 'vi';
        }
        return(
            <div className={styles.picker_day_board}>
                <DayPicker
                    locale={locale}
                    localeUtils={MomentLocaleUtils}
                    classNames={dayPickerStyles}
                    selectedDays={this.props.generateDatePicker.selectedDays}
                    disabledDays={[{ before: start }]}
                    onDayClick={this._onDayClick.bind(this)}/>
            </div>
        );
    }
}

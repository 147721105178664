export const SELECT_DAY = 'SELECT_DAY';
export const DESELECT_DAY = 'DESELECT_DAY';
export const SET_START_TIME_AT = 'SET_START_TIME_AT';
export const SET_END_TIME_AT = 'SET_END_TIME_AT';
export const PREVENT_EVENT = 'PREVENT_EVENT';
export const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN';
export const DELETE_TIME_AT = 'DELETE_TIME_AT';
export const SET_SELECTED_DAY_IN_PAGE_CONTROLLER = 'SET_SELECTED_DAY_IN_PAGE_CONTROLLER';
export const SET_MONTHS = 'SET_MONTHS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const RESET_DATA = 'RESET_DATA';
export const SET_FETCHED_DATA = 'SET_FETCHED_DATA';
export const SET_IS_END_AT = 'SET_IS_END_AT';

export function selectDay(data) {
    return {
        type: SELECT_DAY,
        data: data
    }
}

export function deselectDay(index) {
    return {
        type: DESELECT_DAY,
        index: index
    }
}

export function setStartTime(data,index) {
    return {
        type: SET_START_TIME_AT,
        data:data,
        index: index,
    }
}

export function setEndTime(data,index) {
    return {
        type: SET_END_TIME_AT,
        data: data,
        index: index,
    }
}

export function preventEvent (index) {
    return {
        type: PREVENT_EVENT,
        index: index,
    }
}

export function toggleDropdown(data,index) {
    return {
        type: TOGGLE_DROPDOWN,
        data: data,
        index: index,
    }
}

export function deleteTimeRange(index,index2) {
    return {
        type: DELETE_TIME_AT,
        index: index,
        index2: index2,
    }
}


export function update(data,isStart,index) {
    return function(dispatch) {
        if (isStart) {
            dispatch(setStartTime(data,index));
        }
        else {
            dispatch(setEndTime(data,index));
        }
    }
}

export function setIsEnd(data,index) {
    return {
        type: SET_IS_END_AT,
        data: data,
        index: index
    }
}

export function setSelectedDayInPageController(data) {
    return {
        type: SET_SELECTED_DAY_IN_PAGE_CONTROLLER,
        data: data,
    }
}

export function setMonths(scheduledDays) {
    if (scheduledDays.length) {
        var startMonth = scheduledDays[0];
        var endMonth = scheduledDays[0];
        scheduledDays.forEach((item) => {
            if (item < startMonth) {
                startMonth = item;
            }
            if (item > endMonth) {
                endMonth = item;
            }
        })
    }
    return {
        type: SET_MONTHS,
        initialMonth: startMonth,
        startMonth: startMonth,
        endMonth: endMonth,
    }
}

export function toggleModal(index,value) {
    return {
        type: TOGGLE_MODAL,
        index: index,
        data: value,
    }
}

export function setFetchedDayData(data) {
    return {
        type: SET_FETCHED_DATA,
        data: data
    }
}

export function resetData() {
    return {
        type: RESET_DATA,
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/GFormInput_style.css';

export default class GFormInput extends React.Component {
    componentWillUnmount(){
        var {name,form} = this.props;
        this.props.inputActions.clearError(name,form);
    }
    componentDidMount(){
        this.props.inputActions.initInput(
                        this.props.name,
                        this.props.form,
                        '',
                        this.props.checks
                    );
    }
    _renderError(){
        var errorImg ={};
        var bubbleContent = {};
        var {form} = this.props;
        switch (this.props.default) {
            case 'small':
                bubbleContent = styles.bubble_content_s;
                break;
            case 'medium':
                bubbleContent = styles.bubble_content_m;
                break;
            case 'large':
                bubbleContent = styles.bubble_content_l;
                break;
            default: break;
        }
        if (this.props.inputData[form] && this.props.inputData[form].errors && this.props.inputData[form].errors[this.props.name]) {
            if (this.props.inputData[form].errors[this.props.name].length) {
                return (
                    <div className={styles.error_container}>
                        <div className={styles.bubble}>
                            <div className={bubbleContent}>
                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
                                
                                <FormattedMessage id={this.props.inputData[form].errors[this.props.name]} />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }

    updateData(event) {
        if (this.props.inputActions){
            this.props.inputActions.updateData(this.props.name,event.target.value,this.props.form);
            this.validateData();
            //this.props.inputActions.finalCheck(false,this.props.form);
        }
    }
    finalCheck() {
        if (this.props.finalCheck) {
            this.validateData();
            this.props.inputActions.finalCheck(false,this.props.form);
        }
    }
    validateData(){
        var {form} = this.props;
        if (this.props.inputActions) {
            if (this.props.inputData[form] && this.props.inputData[form].data[this.props.name]!=null) {
                this.props.inputActions.validateData(
                                            this.props.name,
                                            this.props.inputData[form].checks,
                                            this.props.inputData[form].data,
                                            this.props.form,
                                            undefined,
                                            this.props.lang);
            }
            else {
                // this.props.inputActions.validateData(
                //                             this.props.name,
                //                             this.props.inputData[form].checks,
                //                             '',
                //                             this.props.form,
                //                             undefined,
                //                             this.props.lang);
            }
        }
    }

    clearError(){
        var {form} = this.props;
        if (this.props.inputActions) {
            if(this.props.inputData[form]&& this.props.inputData[form].errors && this.props.inputData[form].errors[this.props.name]) {
                this.props.inputActions.clearError(this.props.name,this.props.form);
            }
        }
    }
    _renderInputOrTextArea() {
        var {form} = this.props;
        var inputStyle = '';
        var className = (this.props.inputClassName)?this.props.inputClassName:'';
        switch (this.props.default) {
            case 'small':
                inputStyle = styles.input_s;
                break;
            case 'medium':
                inputStyle = styles.input_m;
                break;
            case 'large':
                inputStyle = styles.input_l;
                break;
            default: inputStyle = styles.input_m;
        }
        var isValid = ''

        if (this.props.inputData[form]&&this.props.inputData[form].errors) {
            if (this.props.inputData[form].errors[this.props.name]) {
                if (this.props.inputData[form].errors[this.props.name].length) {
                    isValid = '#be4b49';
                }
                else isValid = '#5bb75b'
            }
        }
        var type = (this.props.format)? (this.props.format) : 'input';
        var value = (this.props.inputData[form])?this.props.inputData[form].data[this.props.name]:'';
        switch (type) {
            case 'textarea':
                return (
                <textarea className={styles.textarea+' '+className}
                       onChange={this.updateData.bind(this)}
                       onBlur={this.validateData.bind(this)}
                       onFocus={this.clearError.bind(this)}
                       style={{borderColor : isValid}}
                       value={value}
                       {...this.props}/>)
            case 'input':
                return (
                    <input className={inputStyle+' '+className}
                           onChange={this.updateData.bind(this)}
                           onBlur={this.validateData.bind(this)}
                           onFocus={this.clearError.bind(this)}
                           value={value}
                           style={{borderColor : isValid}}
                           {...this.props}/>)
        };
    }
    render() {
        return(
            <div className={styles.container} style={this.props.containerStyle}>
                {this._renderInputOrTextArea()}
                {this._renderError()}
                {this.finalCheck()}
            </div>
        )
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GModal, GButton, CheckBox } from 'app/components/gTools/gTools.jsx';
import styles from './styles/selectedDayList_style.css';
import TimeRangeList from 'app/components/time/timeRangeList.jsx';
import TimePicker from 'app/components/time/timePicker.jsx';
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
moment.updateLocale('vi', { months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });
import { FormattedMessage, injectIntl } from 'react-intl';

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
export default class SelectedDayList extends React.Component {
    render() {
        var nodes = [];
        var selectedDays = this.props.data;
        var { deadLine, pickMode, disable, voteTimeMode } = this.props;
        var length = selectedDays.length;
        for (var i = 0; i < selectedDays.length; i++) {
            //if(selectedDays[i].picked ||selectedDays[i].time){
            nodes.push(<DateItem
                deadLine={deadLine}
                pickMode={pickMode}
                disable={disable}
                voteTimeMode={voteTimeMode}
                data={selectedDays[i]}
                key={i}
                index={i}
                length={length}
                entryDatePickerActions={this.props.entryDatePickerActions}
                pickDate={this.props.pickDate} />);
            //}
        }
        return (
            <div className={styles.container}>
                {nodes}
            </div>
        );
    }
}


class DateItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visiblePickTimeModal: false
        }
    }
    _onPressCancel() {
        this.setState({
            visiblePickTimeModal: false
        });
    }
    _onPressSetTimeRange() {
        var time = this.refs.timePicker.getTimeRanges();
        this.props.entryDatePickerActions.setTimeRange(time, this.props.index);
        this._onPressCancel();
    }
    _renderPickTimeModal() {
        var time = (this.props.data.time) ? this.props.data.time : [];
        return (
            <GModal
                visible={this.state.visiblePickTimeModal}
                closeModal={this._onPressCancel.bind(this)}>
                <p className={styles.pickTimeModalText}>
                    {moment(this.props.data.day).format('dddd') + ' ' + moment(this.props.data.day).format('DD/MM/YYYY')}
                </p>
                <p className={styles.pickTimeModalDescription}>
                    <FormattedMessage id="selectedDayList.selectTimeRangeMessage" />
                </p>
                <TimePicker ref='timePicker' time={time} />
                <GButton
                    default='primary'
                    style={{ marginBottom: 10, width: '100%', marginTop: 10 }}
                    onClick={this._onPressSetTimeRange.bind(this)}><FormattedMessage id="selectedDayList.confirmMessage" /></GButton>
                <GButton
                    default='gray'
                    style={{ marginBottom: 10, width: '100%' }}
                    onClick={this._onPressCancel.bind(this)}><FormattedMessage id="selectedDayList.skipMessage" /></GButton>
            </GModal>
        );
    }
    _renderTimeRange() {
        var time = (this.props.data.genTime) ? this.props.data.genTime : this.props.data.time;
        var { disable, pickMode, deadLine, voteTimeMode } = this.props;
        //time = this.props.data.time;
        var timeRangeList = <TimeRangeList
            selectedDayIndex={this.props.index}
            data={time}
            voteTimeMode={voteTimeMode}
            entryDatePickerActions={this.props.entryDatePickerActions}
            disable={disable}
            picked={this.props.data.picked}
            pickMode={pickMode} />;
        var pencil = (deadLine || this.props.data.genTime) ?
            null :
            <div style={{ alignSelf: 'flex-start' }}>
                <i className='fa fa-pencil' style={{ color: '#70b4ff' }} aria-hidden="true"></i>
            </div>;
        return (
            <div className={styles.timeRangeBoard}>
                <div>
                    {timeRangeList}
                </div>
                <div style={{ flex: 1 }}></div>
                {pencil}
            </div>
        )
    }
    _onPressShowPickTimeModal() {
        if (this.props.deadLine || this.props.data.genTime) return;
        this.setState({
            visiblePickTimeModal: true
        });
    }
    _onCheckBoxChanged() {
        this.props.pickDate(this.props.data.day);
    }
    _renderDate() {
        var dateString = moment(this.props.data.day).format('dddd').capitalize() + ' ' + moment(this.props.data.day).format('DD/MM');
        var day = this.props.data.day;
        // if(this.props.pickMode=='list'){
        //     return (
        //         <CheckBox
        //             disable={false}
        //             className={styles.checkbox_style}
        //             name ="entryDatePicker"
        //             checked={this.props.data.picked}
        //             value={this.props.data.day} title={dateString}
        //             onChange={this._onCheckBoxChanged.bind(this)}/>
        //     );
        // }else {
        return (
            <div style={{ display: 'flex' }} className={styles.dateText}>
                {dateString}
            </div>
        );
        // }
    }
    render() {
        var rightHover = (this.props.data.genTime) ? '' : styles.rightHover
        var borderBottom = (this.props.length - 1 == this.props.index) ? '' : styles.bottomBorder;
        return (
            <div className={styles.dateItem + ' ' + borderBottom}>
                {this._renderPickTimeModal()}
                <div className={styles.dateItemLeft}>
                    {this._renderDate()}
                </div>
                <div className={styles.dateItemRight + ' ' + rightHover} onClick={this._onPressShowPickTimeModal.bind(this)}>
                    {this._renderTimeRange()}
                </div>
            </div>
        );
    }
}

import React from 'react';
import {browserHistory, Link} from 'react-router';
import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import {Dropdown,GNullBoard} from 'app/components/gTools/gTools.jsx';
import moment from 'moment';
import 'moment/locale/vi';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/notificationBar_style.css';

export default class Notification extends React.Component {
    _onOpen(){
        this.props.notificationActions.fetchNotificationFromServer('');
    }
    _renderItem(){
        var isLoading = this.props.notification.isLoading;
        if(isLoading){
            return(
                <center style={{marginTop:10}}>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'24',color:'#02499d'}} aria-hidden="true"></i>
                </center>
            );
        }else{
            var listData = this.props.notification.list;
            var notificationActions = this.props.notificationActions;
            listData = listData.map(function(item,index){
                return <NotifyItem 
                            data={item}
                            notificationActions={notificationActions}
                            key={index}
                            index={item}/>;
            });
            if(listData.length == 0){
                return (
                    <GNullBoard style={{marginTop:10}}><FormattedMessage id="notificationBar.noNotificationMessage" /></GNullBoard>
                );
            }else{
                return listData;
            }
        }
    }
    render() {
        var notifyCount = this.props.notification.count;
        var iconStyle = this.props.iconStyle;
        var notifyBadge=null;
        if(notifyCount>0){
            notifyBadge = <span className={styles.badgeCount}>{notifyCount}</span>;
        }
        var dropdownTrigger = <span className={styles.headerIcon+' '+ iconStyle}>
            <i className={"fa fa-bell "} aria-hidden="true"></i>
            {notifyBadge}
        </span>;
        return(
            <Dropdown dropdownTrigger={dropdownTrigger} onOpen={this._onOpen.bind(this)}>
                <DropdownContent className={DropdownStyles.drop_down+' '+DropdownStyles.drop_right+' '+styles.container}>
                    <div>
                        <div className={styles.headerLable}>
                            <span><FormattedMessage id="notificationBar.notificationMessage" /></span>
                        </div>
                        <div className={styles.listItem}>
                            {this._renderItem()}
                        </div>
                        <div className={styles.footerBar}></div>
                    </div>
                </DropdownContent>
            </Dropdown>
        );
    }
}

class NotifyItem extends React.Component {
    _onClick(){
        var notifyId = this.props.data.id;
        this.props.notificationActions.checkNotificationFromServer(notifyId);
    }
    render() {
        var {deliverySetting,createdAt,isread} = this.props.data;
        var {thumbnail,message,url} = deliverySetting;
        var timeStamp = moment(new Date(createdAt)).fromNow();
        var isreadStyle = (isread==2)?"":styles.isread;
        return(
            <Link to={url} onClick={this._onClick.bind(this)}>
                <div className={styles.itemBoard+' '+isreadStyle}>
                    <div className={styles.itemRight}>
                        <img className={styles.thumbnail} src={thumbnail}/>
                    </div>
                    <div className={styles.itemLeft}>
                        <div className={styles.notifyContent} dangerouslySetInnerHTML={{
                            __html: message
                        }}></div>
                        <span className={styles.timeLabel}>{timeStamp}</span>
                    </div>
                </div>
            </Link>
        );
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, IndexRoute, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';

import store from 'app/store.js'; //redux store

import Index from 'app/containers/index/index_container.jsx';
import Main from 'app/containers/main/main_container.jsx';
import Entry from 'app/containers/entry/entry_container.jsx';
import Generate from 'app/containers/generate/generate_container.jsx';
import NotFoundPage from 'app/views/error/notFoundPage.jsx';
import { checkUserLoginFromServer } from 'app/actions/user/user_action.js';
import Feedback from 'app/containers/feedback/feedback_container.jsx';
import UserHome from 'app/containers/user/userHome_container.jsx';
import Explorer from 'app/containers/explorer/explorer_container.jsx';
import Policy   from 'app/views/policy/policy.jsx';
//kiem tra user da login chua
store.dispatch(checkUserLoginFromServer());

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={browserHistory}>
                    <Route path="/" component={Main}>
                        <IndexRoute component={Index} />
                        <Route path="index" component={Index} />
                        <Route path="welcome" component={Index} />
                        <Route path="home" component={UserHome} />
                        <Route path="entry/:urlb" component={Entry} />
                        <Route path="generate" component={Generate} />
                        <Route path="generate/:urla" component={Entry} />
                        <Route path="xungquanh" component={Explorer} />
                        <Route path="feedback" component={Feedback} />
                        <Route path="policy" component={Policy} />
                        <Route path="*" component={NotFoundPage} />
                    </Route>
                </Router>
            </Provider>
        );
    }
}

ReactDOM.render(
    <App />, document.getElementById('app-body'));

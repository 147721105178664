exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2GQTR5QQX5CymUd864q1CN{\n    overflow: hidden;\n    position:relative;\n    display: flex;\n    flex-direction:column;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);\n    -moz-box-sizing: border-box;\n    box-sizing: border-box;\n    margin-bottom: 10px;\n}\n\n.HMvIeiy3P0EdWqPH1eCX1{\n    background-size:cover;\n    -webkit-filter: blur(5px);\n    -moz-filter: blur(5px);\n    -o-filter: blur(5px);\n    -ms-filter: blur(5px);\n    filter: blur(5px);\n    position:absolute;\n    width:100%;\n    height:100%;\n    background-position: 50%;\n    background-color: #295b84;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n._2CQHTC8tg4oPVDCkKFFetq {\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n.H3LZuQe_NEBuNCZqkiTwq{\n    z-index: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px 0;\n}\n._32HhHwpEbQJFbiWELA39Td{\n    width: 66px;\n    height: 66px;\n    border-radius: 33px;\n}\n._1xYczOmZF7YWoFjF5bxwHK{\n    margin-top: 5px;\n    font-size: 17px;\n    text-align: center;\n    font-weight: 600;\n    color: #fff;\n    line-height: 1.4em;\n    text-shadow: 2px 1px 2px black;\n}\n._2xA2t6dFQ2nCl7brOjeG9h{\n    font-size: 14px;\n    text-shadow: 1px 1px 1px black;\n    margin-bottom: 0;\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_2GQTR5QQX5CymUd864q1CN",
	"blur": "HMvIeiy3P0EdWqPH1eCX1",
	"trianglify": "_2CQHTC8tg4oPVDCkKFFetq",
	"headerTop": "H3LZuQe_NEBuNCZqkiTwq",
	"avatar": "_32HhHwpEbQJFbiWELA39Td",
	"displayName": "_1xYczOmZF7YWoFjF5bxwHK",
	"info": "_2xA2t6dFQ2nCl7brOjeG9h"
};
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2ufqHddWiIKPokmvg3ciDS{\n    display: flex;\n    flex-direction: column;\n    flex:1;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_2ufqHddWiIKPokmvg3ciDS"
};

import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/commentBox_style.css';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';
import StickerBox from 'app/components/comment/stickerBox.jsx';
import StickerView from 'app/components/comment/stickerView.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

class CommentBox extends React.Component{
    constructor(props){
        super(props);
        this.displayName='commentBox';
    }
    _onChangeText(event){
        if(!this.props.deadLine){
            this.props.commentBoxActions.updateText(event.target.value);
        }
    }
    _renderStickerBox(){
        if(this.props.commentBox.isStickerBoxDisplay){
            return(
                <StickerBox
                    parentId={this.displayName}
                    collectionBox={this.props.collectionBox}
                    collectionActions={this.props.collectionActions}/>
            );
        }else {
            return null;
        }
    }
    _onClickShowStickerBox(){
        if(!this.props.deadLine){
            this.props.commentBoxActions.switchStickerBoxDisplay();
        }
    }
    _onClickSubmitComment(){
        if(this.props.user.isLogin){
            var stickerData = this.props.collectionBox.stickerData.selectedData['commentBox'];
            if(this.props.commentBox.textComment.trim().length<=0 && !stickerData) return;
            var textComment =  this.props.commentBox.textComment;
            var hashUrlb = this.props.hashUrlb;
            var message = {
                text:textComment.trim(),
                stickerId:(!stickerData)?null:stickerData.id
            };
            this.props.commentBoxActions.submitCommentToServer(hashUrlb,message);
        }else {
            this.props.controlPanelActions.switchIsShowLoginModal(true);
        }

    }
    render(){
        var tabSelectedStyle = (this.props.commentBox.isStickerBoxDisplay)?styles.tab_selected:'';
        var selectedStickerData = this.props.collectionBox.stickerData.selectedData[this.displayName];
        const {intl} = this.props;
        return(
            <div className={styles.comment_box_board}>
                <textarea
                    placeholder= {intl.formatMessage({ id: 'commentBox.commentPlaceholder' })}
                    value={this.props.commentBox.textComment}
                    className={styles.input_style}
                    onChange={this._onChangeText.bind(this)}/>
                {this._renderStickerBox()}
                <StickerView
                    parentId={this.displayName}
                    data={selectedStickerData}
                    collectionActions={this.props.collectionActions}/>
                <div className={styles.control_bar}>
                    <span className={styles.control_button+' '+tabSelectedStyle} onClick={this._onClickShowStickerBox.bind(this)}>
                        <i className="fa fa-smile-o" aria-hidden="true" style={{fontSize:'24',marginRight:'4'}}></i>
                        <FormattedMessage id="commentBox.labelMessage" />
                    </span>
                    <div style={{flex:1}}></div>
                    <span className={styles.control_button+' '} onClick={this._onClickSubmitComment.bind(this)}>
                        <i className="fa fa-paper-plane" aria-hidden="true" style={{fontSize:'19',marginRight:'4'}}></i>
                        <FormattedMessage id="commentBox.postMessage" />
                    </span>
                </div>
            </div>
        );
    }
}

const CommentBoxInjectIntl = injectIntl(CommentBox);

export default CommentBoxInjectIntl;
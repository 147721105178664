exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2u12C6nP01uwkGi78e99se{\n    cursor: pointer;\n    color: #999;\n}\n._2u12C6nP01uwkGi78e99se:hover{\n    color:#f79424;\n}\n", ""]);

// exports
exports.locals = {
	"button": "_2u12C6nP01uwkGi78e99se"
};
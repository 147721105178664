//Vietnamese

var Vn = {
    index: {
        indexTitle: 'pickboz.com',
        mainDescription: 'Ứng dụng xếp lịch đi chơi, ăn uống, hẹn hò các kiểu...',
        generateButton: 'CREATE',
        generateIconCaption: 'Lập kèo',
        entryIconCaption: 'Pick kèo',
        resultIconCaption: 'Kết quả kèo',

        generateParagraphTitle: 'Lập kèo nhanh',
        generateParagraphContent: 'Nhập thông tin cần thiết, chọn lịch dự kiến. Gửi link pick kèo cho mọi người. Lưu lại link quản lý kèo.',

        entryParagraphTitle: 'Pick kèo trong 1 nốt nhạc',
        entryParagraphContent: 'Nhận link pick kèo, chọn thời gian rảnh. Tham gia ngay với hội bạn, đồng nghiệp cùng cơ quan, lớp cũ...',

        resultParagraphTitle: 'Kết quả kèo trực quan',
        resultParagraphContent1: 'Xem kết quả kèo bằng chính link tạo kèo.',
        resultParagraphContent2: 'Quyết định thời điểm gặp gỡ nhanh nhất với bảng tổng kết kèo trực quan.',

        finalParagraph1: 'Không login phức tạp. Chỉ với một đường link.',
        finalParagraph2: 'Tất cả các nền tảng.',
        finalParagraphButtonCaption: 'Tạo kèo ngay bây giờ:',

        footerCopyright: '© GDV |',
        footerHomepage: 'GDV Homepage',

    },
    entry: {
        nameInputLabel: 'Nhập tên',
        nameInputPlaceholder: 'Tên của bạn',
        commentInputLabel: 'Ghi chú',
        commentInputPlaceholder: 'Lời nhắn đến chủ kèo',
        pickButton: 'Nhập kèo',
        deadline: 'Thời hạn pick kèo:',
        pickTime: 'Chọn giờ',

        deselectDay: 'Bỏ chọn ngày',
        entryDeadlineTitle: 'Thời hạn pick kèo đã hết',
        entryDeadlineDescription: 'Dưới đây là kết quả pick kèo lần này',

        entryFinishTitle: 'Nhập kèo thành công!',
        ownerLabel: 'Chủ kèo: ',
        entryFinishLinkLabel: 'Gửi cho bạn cùng pick kèo:',
    },
    notFound: {
        title: 'OOPS!! Không tìm thấy trang',
        description: 'Xin lỗi, chúng tôi không tìm thấy yêu cầu truy cập của bạn',
        backHomeButton: 'VỀ TRANG CHỦ',
        errorTitle: 'OOPS!! Mã số kèo không tồn tại',
        errorDescription: 'Xin lỗi, chúng tôi không tìm thấy mã số kèo trên tại hệ thống',
        backHomeButton: 'VỀ TRANG CHỦ',
    },
    generate: {
        title: 'LINK QUẢN LÝ SỰ KIỆN',
        urlaHelp: '(!) Hãy lưu đường link trên để quản lý kèo',
        titleInputPlaceholder: 'Tên kèo',
        ownerInputPlaceholder: 'Chủ kèo',
        deadlinePanelTitle: 'Thời hạn pick kèo',
        radio12: 'Sau 12 giờ',
        radio24: 'Sau 24 giờ',
        radio48: 'Sau 48 giờ',
        radioUnlimited: 'Vô hạn',
        deadlineHelp: '(!) Thời hạn pick kèo chỉ kéo dài theo những sự lựa chọn trên',
        infoTextareaPlaceholder: 'Thông tin kèo',
        infoInputPanelTitle: 'Nhập thông tin kèo',
        dayPickerHelp: 'Chọn những ngày dự kiến tổ chức kèo',
        generateSubmitButton: 'TUNG SỰ KIỆN',


        resultPageTitle: 'Trang theo dõi kết quả pick kèo',
        resultListTitle: 'Những người đã pick kèo',
        resultDeleteHelp: '(!) Hãy xóa những pick kèo không hợp lệ để kết quả được chính xác hơn.',
        urlbLabel: 'Link cho người pick kèo',
        urlaLabel: 'Link Quản lý kèo',

        resultPanelTitle: 'Kết qủa thời gian tổ chức kèo',
        editPanelTitle: 'Chỉnh sửa thông tin kèo',
        infoPanelTitle: 'Thông tin kèo',
        voteEditBoardTitle: 'Kết quả bình chọn địa điểm tổ chức kèo',

        editButton: 'Sửa',
        editButtonLong: 'Chỉnh sửa',
        cancelButton: 'Hủy bỏ',
        saveButton: 'Lưu lại',

        congratsTitle: 'CHÚC MỪNG BẠN ĐÃ TẠO SỰ KIỆN THÀNH CÔNG !!',
        congratsUrlb: 'Hãy chia sẽ link bên dưới cho bạn bè vào pick kèo',
        resultUrla: 'Chờ xem kết quả pick kèo tại link bên dưới',
        resultUrlaDescription: '(Link dành cho quản lý kèo)',

    },
    components: {
        nullBoard: 'Chưa có bình luận nào',
        noComment: 'Không nói gì.',
        noName: 'Vô danh',
        deleteButton: 'Xóa bỏ',
        saveButton: 'Lưu lại',
        addButton: 'Thêm',
        detailButton: 'Chi tiết',

        resultNullBoard: 'Chưa có người nào pick kèo',
        resultPeople: 'người',
        resultSeeMore: '(Xem thêm)',

        urlbResultTitle1: 'Đã có ',
        urlbResultTitle2: 'người pick kèo',

        locationResultTitle: 'Kết quả bình chọn địa điểm tổ chức kèo',
        timeResultTitle: 'Kết quả thời gian tổ chức kèo',
        mapButton: 'Bản đồ',

        votePanelTitle: 'Thêm sự lựa chọn địa điểm của bạn',
        addVotePanelTitle: 'Thêm vote địa điểm',
        pickVotePanelTitle: 'Vote địa điểm',
        addVoteButton: 'Thêm sự lựa chọn địa điểm của bạn',

    }
}

export default Vn;

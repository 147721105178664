exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".e-TA6ouazfVH56PTG-mX6{\n    display: flex;\n    flex-direction: row;\n    padding: 5px 12px 5px 12px;\n}\n._34jL3GsDpU7SE_cPTwoP9L{\n    border-bottom: solid 1px #e2e2e2;\n}\n.e-TA6ouazfVH56PTG-mX6:hover{\n    background-color: #02499d;\n    cursor: pointer;\n}\n\n\n._3n2tnoXN58f4sON5Ntc_aX{\n    width: 35px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n._33AKEcSA8jWc0XEomFlW2D{\n    flex:1;\n}\n._10YbmM7tjdq9J1CL8vXAXS{\n    font-size: 24px;\n    color: #575757;\n}\n\n._35MvURjb4Jp8OE795-7WUr{\n    font-size: 14px;\n    font-weight: 700;\n    color: #575757;\n    margin-bottom: 0;\n}\n._3ECFxeDQEg3l9gi3dzhEPh{\n    color: #999;\n    font-size: 11px;\n    margin-bottom: 0;\n}\n\n\n.e-TA6ouazfVH56PTG-mX6:hover p{\n    color: #fff;\n}\n.e-TA6ouazfVH56PTG-mX6:hover i{\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"container": "e-TA6ouazfVH56PTG-mX6",
	"underline": "_34jL3GsDpU7SE_cPTwoP9L",
	"left": "_3n2tnoXN58f4sON5Ntc_aX",
	"right": "_33AKEcSA8jWc0XEomFlW2D",
	"icon": "_10YbmM7tjdq9J1CL8vXAXS",
	"title": "_35MvURjb4Jp8OE795-7WUr",
	"description": "_3ECFxeDQEg3l9gi3dzhEPh"
};
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import CommentBox from 'app/components/comment/commentBox.jsx';

import * as commentBoxActions from 'app/actions/comment/commentBox_action.js';
import * as collectionActions from 'app/actions/collection/collection_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 commentBoxActions : bindActionCreators(commentBoxActions,dispatch),
     collectionActions : bindActionCreators(collectionActions,dispatch),
     controlPanelActions : bindActionCreators(controlPanelActions,dispatch),
  };
}

function mapStateToProps(state) {
	return {
        commentBox:state.commentBox,
        collectionBox:state.collectionBox,
        user:state.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(CommentBox);

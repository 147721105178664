import {
    ENTRY_PICK_VOTE,
    ENTRY_UPDATE_VOTE_DATA,
    TOGGLE_MAP,
    SWITCH_ADD_VOTE_MODE,
    SET_FETCHED_VOTE_DATA,
    SET_SHOW_MODE_PICK_VOTE_PANEL,
    PICK_ALL} from 'app/actions/vote/pickVotePanel_action.js';

const defaultValue = {
    votes: [],
    addVoteMode: 0,
    showMode:'pick'
};
function voteData(state = defaultValue, action) {
    switch (action.type) {
    case ENTRY_PICK_VOTE:
        var newState = Object.assign({}, state);
        var index = action.index;
        newState.votes[index].picked = (newState.votes[index].picked) ? false : true;
        return newState;
    case ENTRY_UPDATE_VOTE_DATA:
        var newState = Object.assign({}, state);
        action.data = (action.data) ? action.data : [];
        newState.votes = action.data.map((item) => {
            item.toggleMap = 0;
            return item;
        });
        return newState;
    case SET_FETCHED_VOTE_DATA:
        var newState = Object.assign({}, state);
        action.data.forEach(function(item) {
            var pickIndex = newState.votes.findIndex(function(vote) {
                return vote.id == item.voteId;
            });
            if (pickIndex >= 0) {
                newState.votes[pickIndex].picked = true;
            }
        });
        return newState;
    case TOGGLE_MAP:
        var newState = Object.assign({}, state);
        if (action.data) {
            newState.votes = newState.votes.map((item) => {
                item.toggleMap = 0;
                return item;
            });
            newState.votes[action.index].toggleMap = action.data;
        } else {
            newState.votes[action.index].toggleMap = action.data;
        }
        return newState;
    case SWITCH_ADD_VOTE_MODE:
        var newState = Object.assign({}, state);
        newState.addVoteMode = action.data;
        return newState;
    case SET_SHOW_MODE_PICK_VOTE_PANEL:
        var newState = Object.assign({}, state);
        newState.showMode = action.data;
        return newState;
    case PICK_ALL:
        var newState = Object.assign({}, state);
        var votes = Object.assign([], state.votes);
        votes = votes.map(function(item,index){
            item.picked=true;
            return item;
        });
        newState.votes = votes;
        return newState;
    default:
        return state;
    }
}

export default voteData;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3lh3FBtyZ9vxbCHWGWnWlb{\n}\n\n._3M_muhWY5GfEMlQoBaghGD{\n    /*background-color: #eee;*/\n    display: flex;\n    align-items: center;\n    font-weight: 900;\n}\n\n._3qNDC5Z50ivblgyNgCKyPt{\n}\n\n.NA7zZOiltPCWV4b80kGsT{\n    font-weight: 900;\n    font-size: 15px;\n}\n.dA3NPCTwVNv4TnLmu2lbz{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"main_board": "_3lh3FBtyZ9vxbCHWGWnWlb",
	"top_ribon": "_3M_muhWY5GfEMlQoBaghGD",
	"result_board": "_3qNDC5Z50ivblgyNgCKyPt",
	"function_title": "NA7zZOiltPCWV4b80kGsT",
	"load_more_board": "dA3NPCTwVNv4TnLmu2lbz"
};
export const ADD_URLA_LIST = 'ADD_URLA_LIST';
export const SET_URLA_LIST_IS_LOADING = 'SET_URLA_LIST_IS_LOADING';
import {
    API_GENERATE_FETCH_LIST
} from 'app/js/api.js';

export function fetchUrlaList(latestDate){
    var submitData = {
        latestDate:latestDate
    }
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_GENERATE_FETCH_LIST, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            dispatch(setIsLoading(false));
            if(responseJson.content){
                var isLoadMore = (responseJson.message)?true:false;
                dispatch(addUrlaList(responseJson.content,isLoadMore,latestDate));
            }
        });
    }
}
export function addUrlaList(data,isLoadMore,latestDate) {
    return {
        type: ADD_URLA_LIST,
        data: data,
        isLoadMore:isLoadMore,
        latestDate:latestDate
    }
}

export function setIsLoading(data) {
    return {
        type: SET_URLA_LIST_IS_LOADING,
        data: data,
    }
}

import {fetchCommentFromServer,
        submitReplyToServerApi,
        fetchReplyFromServerApi} from 'app/actions/api/comment_action.js';
export const SET_COMMENT_COUNT = 'SET_COMMENT_COUNT';
export const SET_COMMENT_DATA = 'SET_COMMENT_DATA';
export const ADD_COMMENT_DATA = 'ADD_COMMENT_DATA';
export const DELETE_COMMENT_ITEM = 'DELETE_COMMENT_ITEM';
export const SET_PICK_DETAILS = 'SET_PICK_DETAILS';
export const SWITCH_REPLY_BOX = 'SWITCH_REPLY_BOX';
export const UPDATE_REPLY_DATA = 'UPDATE_REPLY_DATA';
export const ADD_REPLY_DATA = 'ADD_REPLY_DATA';
export const SET_REPLY_DATA = 'SET_REPLY_DATA';
export const ADD_COMMENT_TO_LIST = 'ADD_COMMENT_TO_LIST';

import {generateResultFetchData} from 'app/actions/generate/generateResult_action.js';
import {cancelSticker} from 'app/actions/collection/collection_action.js';

import {API_GENERATE_DELETE_COMMENT,API_ENTRY_FETCH_PICK_USER} from 'app/js/api.js';

export function fetchCommentData(hashUrlb,latestDate) {
    return function(dispatch){
        fetchCommentFromServer(hashUrlb,latestDate,function(jsonResponse){
            if (jsonResponse.content){
                var isLoadMore = (jsonResponse.message)?true:false;
                dispatch(setCommentData(jsonResponse.content,isLoadMore,latestDate));
            }
        });
    }
}

export function setCommentData(data,isLoadMore,latestDate) {
    return {
        type: SET_COMMENT_DATA,
        data: data,
        isLoadMore: isLoadMore,
        latestDate:latestDate
    }
}

export function addCommentToList(data) {
    return {
        type: ADD_COMMENT_TO_LIST,
        data: data
    }
}
export function setCommentCountData(data) {
    return {
        type: SET_COMMENT_COUNT,
        data: data
    }
}


export function switchReplyBox(index,data) {
    return {
        type: SWITCH_REPLY_BOX,
        index:index,
        data: data
    }
}


export function updateReplyData(index,data) {
    return {
        type: UPDATE_REPLY_DATA,
        index:index,
        data: data
    }
}

export function submitReplyToServer(index,hashUrlb,commentId,value) {
    return function(dispatch){
        submitReplyToServerApi(hashUrlb,commentId,value,function(content){
            if (content){
                dispatch(addReply(index,content));
            }
        });
    }
}

export function fetchReplyFromServer(index,hashUrlb,commentId,latestDate) {
    return function(dispatch){
        fetchReplyFromServerApi(hashUrlb,commentId,latestDate,function(jsonResponse){
            if (jsonResponse.content){
                var isLoadMore = (jsonResponse.message)?true:false;
                dispatch(setReplyData(index,jsonResponse.content,isLoadMore));
            }
        });
    }
}
export function setReplyData(index,data,isLoadMore) {
    return {
        type: SET_REPLY_DATA,
        index:index,
        data: data,
        isLoadMore:isLoadMore
    }
}

export function addReply(index,data) {
    return {
        type: ADD_REPLY_DATA,
        index:index,
        data: data
    }
}


export function deleteCommentFromServer(index,hashUrla,urlbId) {
    var submitData = {
            hashUrla: hashUrla,
            urlbId: urlbId
        };

    return function(dispatch) {
        dispatch(deleteCommentItemData(index));
        return fetch(API_GENERATE_DELETE_COMMENT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(generateResultFetchData(responseJson.content));
            }
        });
    }
}
export function deleteCommentItemData(index) {
    return{
        type: DELETE_COMMENT_ITEM,
        index: index
    };
}


export function fetchPickDetailsFromServer(index,hashUrlb,userId) {
    var submitData = {
            hashUrlb: hashUrlb,
            userId: userId
        };
    return function(dispatch) {
        return fetch(API_ENTRY_FETCH_PICK_USER, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setPickDetails(index,responseJson.content));
            }
        });
    }
}

export function setPickDetails(index,data) {
    return{
        type: SET_PICK_DETAILS,
        index: index,
        data:data
    };
}

export function resetReplyBox(index) {
    return function(dispatch){
        dispatch(updateReplyData(index,''));
        dispatch(cancelSticker("commentTextBox"+index));
    }
}

import {
    API_LIKE_SUBMIT,
    API_LIKE_CHECK,
    API_LIKE_FETCH
} from 'app/js/api.js';
export const INC_LIKE_COUNT = 'INC_LIKE_COUNT';
export const SWITCH_HIGHT_LIGHT_LIKE_BUTTON = 'SWITCH_HIGHT_LIGHT_LIKE_BUTTON';
export const SET_LIKE_DATA = 'SET_LIKE_DATA';
export const SWITCH_IS_SHOW_LIST_LIKE = 'SWITCH_IS_SHOW_LIST_LIKE';
export const SET_LIKE_COUNT = 'SET_LIKE_COUNT';
export const SET_VIEW_COUNT = 'SET_VIEW_COUNT';

export function submitLikeToServer(hashUrlb) {
    var submitData = {
        hashUrlb: hashUrlb
    };
    return function(dispatch) {
        return fetch(API_LIKE_SUBMIT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.message=='like') {
                dispatch(incLikeCount(1));
                dispatch(switchHighLightLikeButton(true));
            }else if(responseJson.message=='unlike'){
                dispatch(incLikeCount(-1));
                dispatch(switchHighLightLikeButton(false));
            }
        });
    }
}

export function incLikeCount(data) {
    return{
        type: INC_LIKE_COUNT,
        data: data
    };
}

export function setLikeCount(data) {
    return{
        type: SET_LIKE_COUNT,
        data: parseInt(data)
    };
}
export function setViewCount(data) {
    return{
        type: SET_VIEW_COUNT,
        data: data
    };
}

export function switchHighLightLikeButton(data) {
    return{
        type: SWITCH_HIGHT_LIGHT_LIKE_BUTTON,
        data: data
    };
}

export function checkLikeFromServer(hashUrlb) {
    var submitData = {
        hashUrlb: hashUrlb
    };
    return function(dispatch) {
        return fetch(API_LIKE_CHECK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content.isLike) {
                dispatch(switchHighLightLikeButton(true));
            }else {
                dispatch(switchHighLightLikeButton(false));
            }
            dispatch(setLikeCount(responseJson.content.likeCount));
        });
    }
}



export function fetchLikeFromServer(hashUrlb) {
    var submitData = {
        hashUrlb: hashUrlb
    };
    return function(dispatch) {
        return fetch(API_LIKE_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setLikeData(responseJson.content));
            }
        });
    }
}

export function setLikeData(data) {
    return{
        data: data,
        type: SET_LIKE_DATA
    };
}

export function switchIsShowListLike(data) {
    return{
        data: data,
        type: SWITCH_IS_SHOW_LIST_LIKE
    };
}

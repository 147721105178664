import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import VoteTimePanel from 'app/components/entry/voteTimePanel.jsx';

import * as voteTimePanelActions from 'app/actions/entry/voteTimePanel_action.js';
import * as entryDatePickerActions from 'app/actions/entry/entryDatePicker_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
    return {
        voteTimePanelActions: bindActionCreators(voteTimePanelActions, dispatch),
        entryDatePickerActions: bindActionCreators(entryDatePickerActions, dispatch),
        controlPanelActions: bindActionCreators(controlPanelActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {voteTimePanel: state.voteTimePanel, entryDatePicker: state.entryDatePicker, entry: state.entry, user: state.user};
}

export default connect(mapStateToProps, mapDispatchToProps)(VoteTimePanel);

import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles/tabPanel_style.css';


export default class TabPanel extends React.Component {
    _renderMain(){
        const selectedValue = this.props.selectedValue;
        var list = this.props.tabs.map(function(item,index){
            var isDisplay = (selectedValue == item.value)?'flex':'none';
            return (
                <div style={{display:isDisplay,flex:1,flexDirection:'column'}}>
                    {item.component}
                </div>
            );
        })
        return list;
    }
	render() {
		return (
			<div className={styles.container}>
				{this._renderMain()}
			</div>
		);
	}
}

/* eslint-disable */
let HOST_TEMP = '';
if (process.env.NODE_ENV === 'development') {
    HOST_TEMP = 'http://localhost:5000';
} else {
    HOST_TEMP = '';
}
//----------------------GENARATE API----------------------
//GENERATE CREATE API
export const API_GENERATE_CREATE_URLA = HOST_TEMP + '/api/generate/create/urla';
//GENERATE FETCH API
export const API_GENERATE_FETCH = HOST_TEMP + '/api/generate/fetch';
export const API_GENERATE_FETCH_LIST = HOST_TEMP + '/api/generate/fetch/list';
//GENERATE RESULT API
export const API_GENERATE_RESULT_FETCH = HOST_TEMP + '/api/generate/result/fetch';
export const API_GENERATE_RESULT_FETCH_SEE_MORE = HOST_TEMP + '/api/generate/result/seemore';
//GENERATE COMMENT API
export const API_GENERATE_COMMENT_FETCH = HOST_TEMP + '/api/generate/comment/fetch';
//GENERATE EDIT API
export const API_GENERATE_EDIT_URLA = HOST_TEMP + '/api/generate/edit/urla';
export const API_GENERATE_EDIT_VOTES = HOST_TEMP + '/api/generate/edit/votes';
export const API_GENERATE_PICKER_ADD_VOTES = HOST_TEMP + '/api/generate/edit/picker/votes';

//DELETE COMMENT API
export const API_GENERATE_DELETE_COMMENT = HOST_TEMP + '/api/generate/comment/delete';
//COUNT COMMENT API
export const API_GENERATE_COUNT_COMMENT = HOST_TEMP + '/api/generate/comment/count';
//REPLY COMMENT API
export const API_COMMENT_REPLY_SUBMIT = HOST_TEMP + '/api/comment/reply/submit';
export const API_COMMENT_REPLY_FETCH = HOST_TEMP + '/api/comment/reply/fetch';
//SUBMIT COMMENT API
export const API_COMMENT_SUBMIT = HOST_TEMP + '/api/comment/submit';
export const API_COMMENT_FETCH = HOST_TEMP + '/api/comment/fetch';

//----------------------ENTRY API
export const API_ENTRY_FETCH = HOST_TEMP + '/api/entry/fetch';
export const API_ENTRY_FETCH_PICK = HOST_TEMP + '/api/entry/fetch/pick';
export const API_ENTRY_FETCH_PICK_USER = HOST_TEMP + '/api/entry/fetch/pick/user';
export const API_ENTRY_FETCH_PICK_LIST = HOST_TEMP + '/api/entry/fetch/picklist';
export const API_ENTRY_FETCH_LIST = HOST_TEMP + '/api/entry/fetch/list';
export const API_ENTRY_EDIT_CANCEL = HOST_TEMP + '/api/entry/edit/cancel';
export const API_ENTRY_DELETE = HOST_TEMP + '/api/entry/delete';




export const API_ENTRY_SUBMIT = HOST_TEMP + '/api/entry/submit';
export const API_ENTRY_BOOKMARK = HOST_TEMP + '/api/entry/bookmark';

export const API_ENTRY_FETCH_EXISTED = HOST_TEMP + '/api/entry/fetch/existed';
export const API_ENTRY_SUBMIT_UPDATE = HOST_TEMP + '/api/entry/submit/update';


//----------------------ENTRY API_PARSE
export const API_PARSE_LINK = HOST_TEMP + '/api/parse/';

//----------------------LOCATION API
export const API_LOCATION_SEARCH = HOST_TEMP + '/api/location/search';


//----------------------LIKE API----------------------
export const API_LIKE_SUBMIT = HOST_TEMP + '/api/like/submit';
export const API_LIKE_CHECK = HOST_TEMP + '/api/like/check';
export const API_LIKE_FETCH = HOST_TEMP + '/api/like/fetch';



//----------------------LANGUAGE API----------------------
export const API_LANGUAGE_FETCH = HOST_TEMP + '/api/language/fetch';

//----------------------USER API----------------------
export const API_USER_LOGIN_CHECK = HOST_TEMP + '/api/user/login/check';
export const API_USER_LOGIN_DESTROY = HOST_TEMP + '/api/user/login/destroy';

//----------------------COLLECTION API----------------------
export const API_COLLECTION_FETCH = HOST_TEMP + '/api/collection/fetch';
export const API_STICKER_FETCH = HOST_TEMP + '/api/collection/fetch/stickers';


//----------------------FEEDBACK API----------------------
export const API_FEEDBACK_SUBMIT = HOST_TEMP + '/api/feedback/submit';

//----------------------SETTING API----------------------
export const API_SETTING_SEARCH_UPDATE_CITYID = HOST_TEMP + '/api/setting/search/update/cityid';


//----------------------VOTE API----------------------
export const API_VOTE_CREATE = HOST_TEMP + '/api/vote/create';
export const API_VOTE_FETCH = HOST_TEMP + '/api/vote/fetch';
export const API_VOTE_FETCH_USER = HOST_TEMP + '/api/vote/fetch/user';
export const API_VOTE_DELETE = HOST_TEMP + '/api/vote/delete';

//----------------------TIME VOTE API----------------------
export const API_TIME_VOTE_EDIT = HOST_TEMP + '/api/timevote/edit';
export const API_ADD_TIME_VOTE = HOST_TEMP + '/api/timevote/edit/add'

//----------------------NOTIFICAION API----------------------
export const API_NOTIFICATION_FETCH = HOST_TEMP + '/api/notify/fetch';
export const API_NOTIFICATION_CHECK = HOST_TEMP + '/api/notify/check';

//----------------------EXPLORER API----------------------
export const API_EXPLORER_FETCH = HOST_TEMP + '/api/explorer/fetch';

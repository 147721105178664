import {
    SET_ENTRY_DATE_PICKER_DATA,
    DESELECT_DAY,
    SET_TIME_RANGE,
    PICK_DATE_ENTRY,
    RESET_TIME_RANGE,
    SET_ENTRY_DATE_PICKER_FETCHED_DATA,
    ADD_AND_PICK_DATE_ENTRY,
    RESET_ENTRY_DATE_PICKER,
    SET_DISPLAY_ENTRY_SELECTED_DAY,
    SET_ENTRY_TIME_PICKER_DATA,
    ADD_ENTRY_TIME_UNIT,
    PICK_TIME_ENTRY,
    UNCHECK_ALL_TIME_ENTRY,
    CHECK_ALL_TIME_ENTRY,
    SET_ENTRY_DATE_PICKER_DATA_2,
    ADD_TIME_RANGE,
    SPLICE_TIME_RANGE,
    COPY_TIME_RANGE_FROM_ABOVE
} from 'app/actions/entry/entryDatePicker_action.js';

import { DateUtils} from 'react-day-picker';

import entryTimePicker from 'app/reducers/entry/entryTimePicker_reducer.js';

function entryUnit(state = {
    day:null,
    time:[],
    isEnd:0,
    genTime:null,
    picked:false
},action) {
    switch (action.type) {
        case PICK_DATE_ENTRY:
            var newState = Object.assign({},state);
            if(newState.picked){
                newState.picked = false;
                if(newState.genTime){
                    var newAction={
                        type:UNCHECK_ALL_TIME_ENTRY
                    }
                    newState.genTime = entryTimePicker(newState.genTime,newAction);
                }else {
                    newState.time = null;
                }
            }else {
                newState.picked = true;
                if(newState.genTime){
                    var newAction={
                        type:CHECK_ALL_TIME_ENTRY
                    }
                    newState.genTime = entryTimePicker(newState.genTime,newAction);
                    newState.time = newState.time;
                }else {
                    newState.time = null;
                }
            }
            return newState;
        case SET_TIME_RANGE:
            var newState = Object.assign({},state);
            newState.time = action.data;
            if(action.data.length>0){
                newState.picked = true;
            }else {
                //newState.picked = false;
                newState.time = null;
            }
            return newState;
        case ADD_TIME_RANGE:
            var newState = Object.assign({},state);
            var time = Object.assign([],state.time);

            time = time.concat(action.data);
            newState.time = time;
            return newState;
        case SPLICE_TIME_RANGE:
            var newState = Object.assign({},state);
            var time = Object.assign([],state.time);
            time.splice(action.timeIndex,1);
            newState.time = time;
            return newState;
        case RESET_TIME_RANGE:
            var newState = Object.assign({},state);
            newState.time = Object.assign([],state.time);
            newState.time = null;
            return newState;
        case SET_ENTRY_DATE_PICKER_FETCHED_DATA:
            var newState = Object.assign({},state);
            newState.picked = action.picked;
            if(!newState.genTime){
                newState.time = action.time;
            }else {
                newState.genTime = entryTimePicker(newState.genTime,action);
            }
            return newState;
        case DESELECT_DAY:
            var newState = Object.assign({},state);
            return state;
        default:
            return state;
    }
}

const entryDatePickerDefault = {
    selectedDays:[],
    urlbId:null,
    allowSelect:true,
    pickMode:'calendar',
    isShowSelectedDay:true,
    deleteList:[],
    isChanged:false
};
function groupTimes(selectedDays){
    var newSelectedDays = {};
    for(var i=0;i<selectedDays.length;i++){
        if(newSelectedDays[selectedDays[i].day]){
            newSelectedDays[selectedDays[i].day].time = newSelectedDays[selectedDays[i].day].time.concat(selectedDays[i].time);
        }else {
            newSelectedDays[selectedDays[i].day] = {
                day:selectedDays[i].day,
                time:(selectedDays[i].time)?newSelectedDays[selectedDays[i].day]:[]
            }
        }
    }
    newSelectedDays = Object.keys(newSelectedDays).map(function(k) {
        return newSelectedDays[k]
    });
    return newSelectedDays;
}

function entryDatePicker(state=entryDatePickerDefault,action) {
    switch (action.type) {
        case SET_ENTRY_DATE_PICKER_DATA:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            var newSelectedDays = groupTimes(action.data);
            selectedDays = newSelectedDays.map(function(item){
                var newAction = {
                    type:SET_ENTRY_TIME_PICKER_DATA,
                    data:item.time
                };
                return {
                            day:new Date(item.day),
                            genTime:entryTimePicker(null,newAction),
                            time:item.time,
                            picked:(item.time)?true:false
                        };
            });
            newState.selectedDays = selectedDays;
            if(selectedDays.length==0){
                newState.allowSelect=true;
                newState.pickMode='calendar';
                newState.isShowSelectedDay=true;
            }else {
                newState.allowSelect=false;
                newState.pickMode='list';
            }
            newState.urlbId=null;
            newState.isChanged = false;
            return newState;
        case SET_ENTRY_DATE_PICKER_DATA_2:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],action.data);
            newState.selectedDays = selectedDays;
            newState.deleteList = [];
            newState.isChanged = false;
            newState.pickMode='calendar';
            return newState;
        case SET_ENTRY_DATE_PICKER_FETCHED_DATA:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            var pickedDays = action.data;
            if(!action.allowSelect){
                for(var i=0;i<selectedDays.length;i++){
                    var selectedIndex = pickedDays.findIndex(unit => DateUtils.isSameDay(new Date(unit.day),selectedDays[i].day));
                    if(selectedIndex!=-1){
                        action.picked = true;
                        action.time = pickedDays[selectedIndex].time;
                        selectedDays[i]=entryUnit(selectedDays[i],action);
                    }else {
                        action.picked = false;
                        action.time = null;
                        selectedDays[i]=entryUnit(selectedDays[i],action);
                    }
                }
            }else {
                for(var i=0;i<pickedDays.length;i++){
                    var newEntryUnit = {
                        day: new Date(pickedDays[i].day),
                        time: pickedDays[i].time,
                        allowSelect:true,//truong hop them khong trong danh sach generate
                        picked:true
                    };
                    selectedDays.push(newEntryUnit);
                }
            }
            newState.selectedDays = selectedDays;
            newState.urlbId = action.urlbId;
            return newState;
        case SET_TIME_RANGE:
        case ADD_TIME_RANGE:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays[action.index] = entryUnit(selectedDays[action.index],action);
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
            return newState;
        case COPY_TIME_RANGE_FROM_ABOVE:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays[action.index].time = selectedDays[action.index-1].time;
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
        return newState;
        case SPLICE_TIME_RANGE:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            var deleteList = Object.assign([],state.deleteList);
            var id = selectedDays[action.index].time[action.timeIndex].id;
            if(id){
                deleteList.push(id);
            }
            selectedDays[action.index] = entryUnit(selectedDays[action.index],action);
            newState.deleteList = deleteList;
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
            return newState;
        case PICK_DATE_ENTRY:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            var deleteList = Object.assign([],state.deleteList);
            // if(!selectedDays[action.index].allowSelect){
            //     selectedDays[action.index] = entryUnit(selectedDays[action.index],action);
            // }else {
            if(selectedDays[action.index].picked){
                if(selectedDays[action.index].time){
                    for(var i = 0;i<selectedDays[action.index].time.length;i++){
                        var id = selectedDays[action.index].time[i].id;
                        if(id){
                            deleteList.push(id);
                        }
                    }
                }
                selectedDays.splice(action.index,1);
            }
            // }
            newState.deleteList = deleteList;
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
            return newState;
        case PICK_TIME_ENTRY:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays[action.daySelectedIndex].genTime = entryTimePicker(selectedDays[action.daySelectedIndex].genTime,action);
            var genTime = selectedDays[action.daySelectedIndex].genTime;
            if(checkTimeListIsPicked(genTime)){
                selectedDays[action.daySelectedIndex].picked = true;
            }else {
                selectedDays[action.daySelectedIndex].picked = false;
            }
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
            return newState;
        case ADD_AND_PICK_DATE_ENTRY:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            var newEntryUnit = {
                day: new Date(action.day),
                time:null,
                allowSelect:true,//truong hop them khong trong danh sach generate
                picked:true
            };
            selectedDays.push(newEntryUnit);
            newState.selectedDays = selectedDays;
            newState.isChanged = true;
            return newState
        case SET_DISPLAY_ENTRY_SELECTED_DAY:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays = selectedDays.map(function(item,index){
                item.time = null;
                return item;
            });
            newState.isShowSelectedDay = action.data;
            newState.selectedDays = selectedDays;
            return newState;
        case RESET_ENTRY_DATE_PICKER:
            var newState = Object.assign({},state);
            newState = entryDatePickerDefault;
            return newState;
        default:
            return state;
    }
}
function checkTimeListIsPicked(genTime){
    for(var i=0;i<genTime.length;i++){
        if(genTime[i].picked) return true;
    }
    return false;
}
export default entryDatePicker;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import UrlaList from 'app/components/keoList/urlaList.jsx';

import * as urlaListActions from 'app/actions/keoList/urlaList_action.js';

function mapDispatchToProps(dispatch) {
    return {
        urlaListActions:bindActionCreators(urlaListActions,dispatch)
    }
}

function mapStateToProps(state) {
    return ({
        user:state.user,
        urlaList:state.urlaList
    })
}

export default connect(mapStateToProps,mapDispatchToProps)(UrlaList);

import {
    ADD_ENTRY_TIME_UNIT,
    SET_ENTRY_TIME_PICKER_DATA,
    PICK_TIME_ENTRY,
    UNCHECK_ALL_TIME_ENTRY,
    CHECK_ALL_TIME_ENTRY,
    SET_ENTRY_DATE_PICKER_FETCHED_DATA
} from 'app/actions/entry/entryDatePicker_action.js';

function timeUnit(state = {
    picked:false
},action) {
    switch (action.type) {
        case ADD_ENTRY_TIME_UNIT:
            var newState = Object.assign({},state);
            newState.start = action.data.start;
            newState.end = action.data.end;
            newState.picked = true;
            return newState;
        case PICK_TIME_ENTRY:
            var newState = Object.assign({},state);
            newState.picked = !newState.picked;
            return newState;
        default:
            return state;
    }
}

function entryTimePicker(state=[],action) {
    switch (action.type) {
        case SET_ENTRY_TIME_PICKER_DATA:
            var newState = Object.assign([],state);
            if(!action.data) return null;
            newState = action.data.map(function(item,index){
                var newAction = {
                    type:ADD_ENTRY_TIME_UNIT,
                    data:item
                };
                return timeUnit(null,newAction);
            });
            return newState;
        case SET_ENTRY_DATE_PICKER_FETCHED_DATA:
            var newState = Object.assign([],state);
            var time = action.time;
            if (!time){//truong hop khong da khong pick nen set vote time la false het
                newState = newState.map(function(item){
                    item.picked = false;
                    return item;
                });
                return newState;
            }
            newState = newState.map(function(item){
                for(var i=0;i<time.length;i++){
                    //tim nhung vote time da vote de pick vao
                    if(item.start==time[i].start && item.end == time[i].end){
                        item.picked = true;
                        return item;
                    }
                }
                item.picked = false;
                return item;
            });
            return newState;
        case PICK_TIME_ENTRY:
            var newState = Object.assign([],state);
            newState[action.index] = timeUnit(newState[action.index],action);
            return newState;
        case UNCHECK_ALL_TIME_ENTRY:
            var newState = Object.assign([],state);
            newState = newState.map(function(item){
                item.picked = false;
                return item;
            })
            return newState;
        case CHECK_ALL_TIME_ENTRY:
            var newState = Object.assign([],state);
            newState = newState.map(function(item){
                item.picked = true;
                return item;
            })
            return newState;
        default:
            return state;
    }
}

export default entryTimePicker;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import VotePanel from 'app/components/vote/votePanel.jsx';

import * as votePanelActions from 'app/actions/vote/votePanel_action.js';
import * as userActions from 'app/actions/user/user_action.js';

function mapDispatchToProps(dispatch) {
    return {
        votePanelActions : bindActionCreators(votePanelActions,dispatch),
		userActions : bindActionCreators(userActions,dispatch)
    }
}

function mapStateToProps(state) {
	return ({
        votePanelData:state.votePanelData,
		user:state.user,
		language:state.language
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(VotePanel);

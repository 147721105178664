import {
    SET_LIKE_DATA,
    SWITCH_IS_SHOW_LIST_LIKE,
    SET_LIKE_COUNT,
    INC_LIKE_COUNT,
    SET_VIEW_COUNT,
    SWITCH_HIGHT_LIGHT_LIKE_BUTTON
} from 'app/actions/entry/entryHeaderControlBar_action.js';

function controlData(state={
    data:[],
    isShow:false,
    likeCount:0,
    viewCount:0,
    isLike:false
},action) {
    switch (action.type) {
        case SET_LIKE_DATA:
            var newState = Object.assign({},state);
            newState.data= action.data;
            return newState;
        case SET_LIKE_COUNT:
            var newState = Object.assign({},state);
            newState.likeCount= action.data;
            return newState;
        case SET_VIEW_COUNT:
            var newState = Object.assign({},state);
            newState.viewCount= action.data;
            return newState;
        case INC_LIKE_COUNT:
            var newState = Object.assign({},state);
            newState.likeCount=newState.likeCount+ action.data;
            return newState;
        case SWITCH_HIGHT_LIGHT_LIKE_BUTTON:
            var newState = Object.assign({},state);
            newState.isLike= action.data;
            return newState;
        case SWITCH_IS_SHOW_LIST_LIKE:
            var newState = Object.assign({},state);
            newState.isShow= action.data;
            return newState;
        default:
            return state;
    }
}

export default controlData;

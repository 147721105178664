exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3jD-4KcPwpd0Ae5tTakM3b {\n    border-top: solid 5px #e0e0e0;\n}\n\n._3NTBG6pWADCTsB6SQsobG- {\n\tdisplay: flex;\n    align-items: baseline;\n\tcursor: pointer;\n    color: #777;\n    font-size: 12px;\n\ttransition: 200ms cubic-bezier(.08,.52,.52,1) transform;\n}\n\n._3sSWhHMJcjKCixOsyXKj4B {\n    font-size: 14px;\n    cursor: pointer;\n}\n\n._2H3hfmfPt7Z-gP2X1sThMh {\n\tfont-weight: 500;\n    color: #4b4f56;\n}\n\n._3NTBG6pWADCTsB6SQsobG-:hover ._3sSWhHMJcjKCixOsyXKj4B {\n\topacity: 1;\n}\n\n._3NTBG6pWADCTsB6SQsobG-:hover ._2H3hfmfPt7Z-gP2X1sThMh {\n\ttext-decoration: underline;\n}\n\n._3NTBG6pWADCTsB6SQsobG-:active {\n\ttransform: scale(.95);\n}\n\n\n._3HoZR0uv4ujXU_N1_H2z5n {\n\tdisplay: flex;\n\tflex-direction: column;\n\n}\n\n._2LvHtbfcKfOtoIu2CbpTxu{\n\tdisplay: flex;\n    flex-direction: row;\n    margin: 0 0 5px 0;\n    background-color: #f7f7f7;\n    padding: 5px;\n    border: 1px solid #e2e2e2;\n    border-radius: 3px;\n    margin-top: 5px;\n}\n\n._1CkcYiSssRk7lCMoNlYXyu{\n\theight: 85px;\n\twidth: 85px;\n\tmargin-right: 7px;\n}\n._2MGjSpWneZgWyszrnX7Z84{\n\tobject-fit: cover;\n\twidth: 100%;\n\theight: 100%;\n}\n._2GjjASbuSJK5FHg-w4SmEs{\n\twidth: 100%;\n\theight: 100%;\n}\n\n._12ITgMxbbE1NPPGPxwZYC_{\n\t flex: 1;\n     display: flex;\n     flex-direction: column;\n}\n._3JferJ7NGQfRQswu5evkbB{\n\t color: #4b4f56;\n}\n\n._3JferJ7NGQfRQswu5evkbB{\n\tmargin-bottom: 0;\n}\n._396F_FtLS5j08A74UNY6eK{\n\tfont-size: 12px;\n\tcolor: #4b4f56;\n    flex:1;\n}\n\n._3kGxqGfhhpfWIvHAh90zQv{\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tcolor: #444;\n    margin-bottom: 2px;\n}\n\n.buvXY4XGZrtU9KzBcTDfR{\n\tfont-weight: 500;\n\tcolor: #4b4f56;\n}\n\n.COwbwJniDHgy-SSrS0FOF {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tborder-top: solid 1px #ccc;\n    padding-top:5px;\n}\n", ""]);

// exports
exports.locals = {
	"border_top": "_3jD-4KcPwpd0Ae5tTakM3b",
	"icon_button": "_3NTBG6pWADCTsB6SQsobG-",
	"icon_button_icon": "_3sSWhHMJcjKCixOsyXKj4B",
	"icon_button_label": "_2H3hfmfPt7Z-gP2X1sThMh",
	"item_info_container": "_3HoZR0uv4ujXU_N1_H2z5n",
	"item_info_board": "_2LvHtbfcKfOtoIu2CbpTxu",
	"item_info_right": "_1CkcYiSssRk7lCMoNlYXyu",
	"item_info_image": "_2MGjSpWneZgWyszrnX7Z84",
	"item_info_image_link": "_2GjjASbuSJK5FHg-w4SmEs",
	"item_info_left": "_12ITgMxbbE1NPPGPxwZYC_",
	"item_info_description": "_3JferJ7NGQfRQswu5evkbB",
	"item_info_address": "_396F_FtLS5j08A74UNY6eK",
	"item_info_title": "_3kGxqGfhhpfWIvHAh90zQv",
	"item_info_source": "buvXY4XGZrtU9KzBcTDfR",
	"item_info_footer": "COwbwJniDHgy-SSrS0FOF"
};
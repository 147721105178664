import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton} from 'app/components/gTools/gTools.jsx';
import globalStyles from 'app/styles/global_styles.css';

import styles from './styles/notFoundPage_style.css';
import Language from 'app/international/international.js';
import { FormattedMessage, injectIntl } from 'react-intl';

var Lang = new Language('vn');

export default class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);
    }
    _onClickBackToHomePage(){
        browserHistory.push('/');
    }
    render() {
        return (
            <div>
                <div className={globalStyles.main_background}>
                    <div className={globalStyles.main_board}
                        style={{paddingTop:'0px'}}>
                        <div className={globalStyles.white_box}>
                            <center>
                                <h1 style={{fontWeight:'bold',fontSize:'25px', color:'#02499d'}}>{<FormattedMessage id="notFoundPage.pageNotFoundMessage" />}</h1>
                                <img style={{width:230, marginBottom: 10}} src='/images/art/404.png'/>
                                <p>{<FormattedMessage id="notFoundPage.accessRequestNotFoundMessage" />}</p>
                                <GButton
                                    default = 'primary'
                                    style={{height:'45',width:'135',backgroundColor:'#ffa239'}}
                                    onClick={this._onClickBackToHomePage.bind(this)}>{<FormattedMessage id="notFoundPage.backToHomePageMessage" />}</GButton>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import { applyMiddleware, createStore } from "redux"

import logger from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"

import RootReducer from "app/reducers/root.js"
var middleware = null;
if(process.env.LOGGER_ENV =='development'){
    middleware = applyMiddleware(promise(), thunk, logger());
}else {
    middleware = applyMiddleware(promise(), thunk);
}

export default createStore(RootReducer, middleware);

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {browserHistory,Link} from 'react-router'
import styles from './styles/loadMoreButton_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class UrlbPage extends React.Component {
    _onClickLoadMore(){
        if(this.props.onClick){
            this.props.onClick();
        }
    }
	render() {
		return (
            <div className={styles.reply_load_more_button_board}>
                <i style={{fontSize:'24',color:'#02499d',marginBottom:'5'}} className="fa fa-sort-desc" aria-hidden="true"></i>
                <span className={styles.reply_load_more_button} onClick={this._onClickLoadMore.bind(this)}><FormattedMessage id="loadMoreButton.seeMoreMessage" /></span>
            </div>
		)
	}
}

export const SWITCH_FEEDBACK_DISPLAY = 'SWITCH_FEEDBACK_DISPLAY';
export const FEED_BACK_RESET_FORM = 'FEED_BACK_RESET_FORM';
import {
    API_FEEDBACK_SUBMIT} from 'app/js/api.js';

export function submitFeedbackToServer(title,email,content) {
	var submitData = {
		title:title,
		email:email,
		content:content
	};
    return function(dispatch) {
        return fetch(API_FEEDBACK_SUBMIT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
			body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.message=='ok') {
                dispatch(switchFeedbackDisplay(1));//1 la chuyen qua thanh cong
            }
        });
    }
}

export function switchFeedbackDisplay(data) {
    return{
        type: SWITCH_FEEDBACK_DISPLAY,
    	data:data
    };
}

export function resetFrom(data) {
    return{
        type: FEED_BACK_RESET_FORM
    };
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GFormInput, GButton, RadioButton, Panel, AlertBox } from 'app/components/gTools/gTools.jsx';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generatePage3_style.css';
import mainStyles from './styles/generateMain_style.css';
import GenerateDatePicker from 'app/containers/generate/generateDatePicker_container.jsx';
import EntryDatePicker from 'app/containers/entry/entryDatePicker_container.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class GeneratePage3 extends React.Component {
    _onClickNext() {
        // this.props.generatePageActions.setGenerateMainTabValue('check');
        // this.props.generatePageActions.setGenerateMainStepValue(3);
        this.props.next();
        window.scrollTo(0, 0);
    }
    _onClickBack() {
        this.props.generatePageActions.setGenerateMainTabValue('location');
        window.scrollTo(0, 0);
    }
    _onRadioButtonChanged(checked) {
        this.props.generatePageActions.changeDeadLineRadioButton(checked);
    }
    _onClickHideSelectedDayList() {
        this.props.entryDatePickerActions.setDisplayEntrySelectedDay(false);
    }
    _renderNextButton() {
        if (this.props.entryDatePicker.selectedDays.length > 0) {
            return (
                <GButton
                    default='orange'
                    style={{ width: '100%', height: 47, fontSize: 10 }}
                    onClick={this._onClickNext.bind(this)}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage3.continueMessage" />
                </GButton>
            );
        } else {
            return (
                <GButton
                    default='block'
                    style={{ width: '100%', height: 47, fontSize: 10 }}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage3.continueMessage" />
                </GButton>
            );
        }
    }
    _renderHint() {
        if (this.props.entryDatePicker.selectedDays.length <= 0) {
            return (
                <AlertBox color='blue'>
                    <FormattedMessage id="generatePage3.instructionMessage" />
                </AlertBox>
            );
        } else {
            if (!this.props.entryDatePicker.isShowSelectedDay) {
                return (
                    <div style={{ marginBottom: 10 }}>
                        <div className={styles.itemButton} onClick={() => this.props.entryDatePickerActions.setDisplayEntrySelectedDay(true)}>
                            <i className="fa fa-square-o" aria-hidden="true"></i> <FormattedMessage id="generatePage3.fixedTimeSlotMessage" />
                        </div>
                        <AlertBox color='blue'>
                            <p><FormattedMessage id="generatePage3.participantVoteInstructionMessage" /></p>
                        </AlertBox>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div style={{ marginTop: 10 }} className={styles.itemButton} onClick={() => this._onClickHideSelectedDayList()}>
                            <i className="fa fa-check-square-o" aria-hidden="true"></i> <FormattedMessage id="generatePage3.disableFixedTimeSlotMessage" />
                        </div>
                        <AlertBox color='blue'>
                            <p><span>(!) <FormattedMessage id="generatePage3.fixedTimeSlotEnabledMessage" /></span></p>
                            <p><FormattedMessage id="generatePage3.multipleTimeSlotsMessage" /></p>
                            <p><FormattedMessage id="generatePage3.noteUnselectedDaysMessage" /></p>
                        </AlertBox>
                    </div>
                );
            }
        }
    }
    render() {
        var radioButtonChecked = this.props.urlaData.deadLine;
        return (
            <div className={styles.container}>
                <div className={mainStyles.tabHeader}>
                    <p className={mainStyles.tabHeaderStep}><FormattedMessage id="generatePage3.step3Message" /></p>
                    <p className={mainStyles.tabHeaderDescription}><FormattedMessage id="generatePage3.selectEventDaysMessage" /></p>
                </div>
                <EntryDatePicker generateMode />
                {this._renderHint()}
                <Panel title={<FormattedMessage id="generatePage3.eventPickDeadlineMessage" />} className={styles.deadLine_board}>
                    <div className={globalStyles.container}>
                        <div className={globalStyles.col_md_6}>
                            <RadioButton
                                name="deadLine"
                                checked={radioButtonChecked}
                                value="12" title={<FormattedMessage id="generatePage3.after12HoursMessage" />}
                                onChange={() => this._onRadioButtonChanged('12')} />
                            <RadioButton
                                name="deadLine"
                                checked={radioButtonChecked}
                                value="24" title={<FormattedMessage id="generatePage3.after24HoursMessage" />}
                                onChange={() => this._onRadioButtonChanged('24')} />
                        </div>
                        <div className={globalStyles.col_md_6}>
                            <RadioButton
                                name="deadLine"
                                checked={radioButtonChecked}
                                value="48" title={<FormattedMessage id="generatePage3.after48HoursMessage" />}
                                onChange={() => this._onRadioButtonChanged('48')} />
                            <RadioButton
                                name="deadLine"
                                checked={radioButtonChecked}
                                value="unlimited" title={<FormattedMessage id="generatePage3.unlimitedMessage" />}
                                onChange={() => this._onRadioButtonChanged('unlimited')} />
                        </div>
                    </div>
                </Panel>
                <div style={{ flex: 1 }}></div>
                <div className={mainStyles.submitBox}>
                    <div style={{ display: 'flex' }}>
                        <GButton
                            default='gray'
                            style={{ width: '100%', height: 47, fontSize: 10, marginRight: 10 }}
                            onClick={this._onClickBack.bind(this)}>
                            <i className="fa fa-arrow-left" aria-hidden="true" style={{ display: 'block', fontSize: 24 }}></i> <FormattedMessage id="generatePage3.goBackMessage" />
                        </GButton>
                        <div style={{ flex: 1 }}></div>
                        {this._renderNextButton()}
                    </div>
                </div>
            </div>
        );
    }
}


export const SET_RESULT_DATA = 'SET_RESULT_DATA';
export const SWITCH_DISPLAY_RESULT_TAB = 'SWITCH_DISPLAY_RESULT_TAB';

import {fetchResultFromServer} from 'app/actions/api/result_action.js';

export function fetchResultData(urlaId) {
    return function(dispatch){
        fetchResultFromServer(urlaId,function(content){
            if (content){
                dispatch(setResultData(content));
            }
        });
    }
}

export function setResultData(data) {
    return {
        type: SET_RESULT_DATA,
        data: data
    }
}

export function switchDisplayResultTab(data) {
    return {
        type: SWITCH_DISPLAY_RESULT_TAB,
        data: data
    }
}

export function setKeyword(data) {
    return{
        type: 'SET_KEYWORD',
        data: data
    };
}

export function resizeScreen(data) {
    return {
        type: 'RESIZE_SCREEN',
        data: data,
    }
}

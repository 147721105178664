import React from 'react';
import globalStyles from 'app/styles/global_styles.css';

import EntryError from './entryError.jsx';
import EntryFinish from './entryFinish.jsx';
import EntryMain from './entryMain/entryMain.jsx';

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.randomCounter = Math.floor((Math.random() * 14) + 1);
        //neu truy cap tu link generate thi dung hashUrla
        //neu truy cap tu link entry thi dung hashUrlb
        this.hashUrlb = this.props.params.urlb;
        this.hashUrla = this.props.params.urla;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var tabValue =  this.props.location.query.tab;
        tabValue = (tabValue)?tabValue:'pick';
        //gui ca hai hashUrlb va hashUrla cho hai truong hop tu generate hoac entry
        this.props.entryActions.fetchUrlbData(this.hashUrlb,this.hashUrla,tabValue);
    }
    componentWillReceiveProps(nextProps) {
        if ((this.props.params.urlb != nextProps.params.urlb)|
            (this.props.params.urla != nextProps.params.urla)|
            (this.props.location != nextProps.location)) {

            this.hashUrlb = nextProps.params.urlb;
            this.hashUrla  = nextProps.params.urla;
            var tabValue =  nextProps.location.query.tab;
            tabValue = (tabValue)?tabValue:'pick';
            this.props.entryActions.fetchUrlbData(this.hashUrlb,this.hashUrla,tabValue);
            window.scrollTo(0, 0);
        }
    }
    //render methods
    _renderEntry() {
        switch (this.props.entry.pageController.step){
            case 0:
                return (
                   <div>
                        <center>
                            <i className={"fa fa-refresh fa-spin "} style={{fontSize:'44',color:'#02499d'}} aria-hidden="true"></i>
                        </center>
                    </div>
                );
            case 1:
                var entry = this.props.entry;
                //truong hop hashUrla dc tra ve sau khi kiem tra chinh chu tren server nen phai lay tu du lieu fetch
                var hashUrla = this.props.entry.entryData.urlaData.hashUrla;
                return (<EntryMain entry={entry} hashUrla={hashUrla} {...this.props}/>);
            case 2:
                return (<EntryFinish
                            entryActions={this.props.entryActions}
                            language={this.props.language}
                            entry={this.props.entry}/>);
            case 3:
                return (<EntryError language={this.props.language} urlb={this.hashUrlb}/>);
            case 4:
                var entry = this.props.entry;
                return (<EntryMain
                            entryActions={this.props.entryActions}
                            entry={entry}
                            {...this.props}
                            deadLine/>);
            default:
                return null;
        }
    }
    collapseContext(selectedDay) {
        this.props.entryInputFormActions.toggleDropdown(0,selectedDay);
    }
	render() {
		return (
            <div className={globalStyles.main_background}>
                <div className={globalStyles.background_img}>
                </div>
                    <div className={globalStyles.main_board}
                         style={{paddingTop:'0px'}}>
                        {this._renderEntry()}
                    </div>
            </div>
		)
	}
}

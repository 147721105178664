import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/timeResult2_style.css';
import SendButton from 'app/components/socialbar/sendButton.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
moment.locale('vi');
import {KeywordList,GNullBoard} from 'app/components/gTools/gTools.jsx';
import * as func from 'app/js/func.js';
export default class TimeResult2 extends React.Component {
    _renderList(){
        if(this.props.data.length>0){
            var self = this;
            var data = this.props.data;
            var nodes = data.map(function(item,index){
                return (<ItemDay data={item} index={index} key={index}/>);
            });
            return nodes;
        }else {
            return null;
        }
    }
    _renderUser(){
        var self = this;
        var users = this.props.users;
        console.log('users', users);
        var nodes = users.map(function(item,index){
            return (<UserUnit data={item} index={index} key={index}/>);
        });
        return nodes;
    }
    _renderTimePickList(){
        var self = this;
        var users = this.props.users;
        var selectedDays = this.props.data;
        var nodes = users.map(function(item,index){
            return (<TimePickList userData={item} selectedDays={selectedDays} index={index} key={index}/>);
        });
        return nodes;
    }
    _onClickSeemore(){
      var hashUrlb = this.props.hashUrlb;
      var latestDate = this.props.users[this.props.users.length-1].createdAt;
      this.props.resultActions.fetchSeemoreResultFromServer(hashUrlb,latestDate);
    }
    _renderSeemore(){
        if(this.props.urlbCount>this.props.users.length){
            return(
                <div className={styles.userUnit}>
                    <p className={styles.loadMoreButton} onClick={this._onClickSeemore.bind(this)}><FormattedMessage id="timeResult2.seeMoreMessage" /> <i className="fa fa-caret-down" aria-hidden="true"></i></p>
                </div>
            );
        }else {
            return null;
        }
    }
    render() {
        var urlbCount = this.props.urlbCount;
        return (
            <div  className={styles.main_board}>
                <div className={styles.result_board}>
                    <div className={styles.left}>
                        <div className={styles.userListBoard}>
                            <div className={styles.userUnit}>
                                <SendButton><FormattedMessage id="timeResult2.totalCountMessage" values={{ urlbCount }} /></SendButton>
                            </div>
                            {this._renderUser()}
                            {this._renderSeemore()}
                        </div>
                    </div>
                    <div className={styles.timeItemBoard}>
                        <div>
                            <div className={styles.timeBoardTop}>
                                {this._renderList()}
                            </div>
                            <div className={styles.timeBoardBottom}>
                                {this._renderTimePickList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// <div className={styles.loadMoreBoard}>
// </div>
class TimePickList extends React.Component {
    _renderList(){
        var self = this;
        var selectedDays = this.props.selectedDays;
        var userData = this.props.userData;
        var nodes = selectedDays.map(function(item,index){
            var selectedIndex = userData.user_time_votes.findIndex(userTimeVote =>
                (userTimeVote.timeVoteId==item.id)
            );
            if(selectedIndex == -1){
                return(
                    <div className={styles.timeCheckBox} key={index}>
                    </div>
                );
            }else{
                var color = (index==0)?styles.selected:'';
                return(
                    <div className={styles.timeCheckBox+' '+color} key={index}>
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                );
            }
        });
        return nodes;
    }
    render() {
        return (
            <div className={styles.timePickList}>
                {this._renderList()}
            </div>
        );
    }
}

class UserUnit extends React.Component {
    render() {
        var name = this.props.data.name;
        var avatarUrl = (this.props.data.user)?this.props.data.user.profile.avatarUrl:'/images/control/nullAvatar.jpg';
        return (
            <div className={styles.userUnit}>
                <img src={avatarUrl} className={styles.avatar}/><span>{name}</span>
            </div>
        );
    }
}

class ItemDay extends React.Component {
    render() {
        var {weight,value} = this.props.data;
        var time = value.time;
        var color = (this.props.index ==0 && weight>0)?styles.selected:'';
        return (
            <div className={styles.itemDay+' '+color}>
                <div className={styles.itemTop}>
                    <span className={styles.textTop}>
                        {moment(new Date(this.props.data.day)).format('dddd')}
                    </span>
                    <span className={styles.textBody}>
                        {moment(new Date(this.props.data.day)).format('DD')}
                    </span>
                    <span className={styles.textFooter}>
                        {moment(new Date(this.props.data.day)).format('MMM')}
                    </span>
                    <span className={styles.textTime}>
                        {convertToTimeString(time.start)+' ~ '+convertToTimeString(time.end)}
                    </span>
                </div>
                <div className={styles.itemFooter}>
                    <span><i className="fa fa-user" aria-hidden="true"></i> {weight}</span>
                </div>
            </div>
        );
    }
}

function convertToTimeString(time){
    if(!time&&time!=0) return "__:__";
    var hour = Math.floor(time/3600);
    var minute = (time-hour*3600)/60;
    return hour + ':'+((minute.toString().length>1)?minute:'0'+minute);
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import Feedback from 'app/views/feedback/feedback.jsx';

import * as userActions from 'app/actions/user/user_action.js';
import * as languageActions from 'app/actions/api/language_action.js';
import * as GFormInputAction from 'app/components/gTools/actions/gFormInput_action.js';
import * as feedbackActions from 'app/actions/feedback/feedback_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 gFormInputActions : bindActionCreators(GFormInputAction,dispatch),
     userActions:bindActionCreators(userActions, dispatch),
     languageActions:bindActionCreators(languageActions, dispatch),
     feedbackActions:bindActionCreators(feedbackActions, dispatch)
  };
}

function mapStateToProps(state) {
	return {
        user:state.user,
        language:state.language,
		gFormInput:state.gFormInput,
        feedback:state.feedback
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(Feedback);

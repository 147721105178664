
import React from 'react';
import styles from './styles/alertBox_style.css';


export default class AlertBox extends React.Component{
    render(){
        var {style,color,alignItems} = this.props;
        var myStyle = {};
        switch (color) {
            case 'blue':
                myStyle.backgroundColor = '#e6f7ff';
                myStyle.borderColor = '#02499d';
                myStyle.color= '#02499d';
                break;
            case 'yellow':
                myStyle.backgroundColor = '#fff3cd';
                myStyle.borderColor = '#ffeeba';
                myStyle.color= '#856404';
                break;
            case 'green':
                myStyle.backgroundColor = '#d4edda';
                myStyle.borderColor = '#c3e6cb';
                myStyle.color= '#155724';
                break;
            case 'gray':
                myStyle.backgroundColor = '#e7e8ea';
                myStyle.borderColor = '#dddfe2';
                myStyle.color= '#464a4e';
                break;
            default:
                myStyle.backgroundColor = '#fff3cd';
                myStyle.borderColor = '#ffeeba';
                myStyle.color= '#856404';

        }
        if(alignItems=='center') myStyle.alignItems = 'center';
        return(
            <div className={styles.container} style={myStyle} >
                {this.props.children}
            </div>
        );
    }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import GFormInput from '../gFormInput.jsx';

import * as gFormInputActions from '../actions/gFormInput_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 inputActions : bindActionCreators(gFormInputActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        inputData:state.gFormInput
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(GFormInput);

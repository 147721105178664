import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GFormInput,GButton,AlertBox} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generatePage1_style.css';
import mainStyles from './styles/generateMain_style.css';

class GeneratePage1 extends React.Component {
    _onClickNext(){
        this.props.next();
        window.scrollTo(0, 0);
        //this.props.generatePageActions.setGenerateMainTabValue('location');
    }
    _renderNextButton(){
        if(this.props.gFormInputData.generateSettingForm&&this.props.gFormInputData.generateSettingForm.finalCheck){
            return(
                <GButton
                    default='primary'
                    style={{width:'100%',height:47,fontSize:10}}
                    onClick={this._onClickNext.bind(this)}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="generatePage1.continueMessage" />
                </GButton>
            );
        }else {
            return(
                <GButton
                    default='block'
                    style={{width:'100%',height:47,fontSize:10}}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="generatePage1.continueMessage" />
                </GButton>
            );
        }
    }
    render() {
        const {intl} = this.props;
        return (
            <div className={styles.container}>
                <div className={mainStyles.tabHeader}>
                    <p className={mainStyles.tabHeaderStep}><FormattedMessage id="generatePage1.step1Message" /></p>
                    <p className={mainStyles.tabHeaderDescription}><FormattedMessage id="generatePage1.shareInfoMessage" /></p>
                </div>
                <AlertBox color="blue">
                    <p>(!) <FormattedMessage id="generatePage1.fillInfoMessage" /></p>
                </AlertBox>
                <GFormInput
                   type="text"
                   name="title"
                   autoFocus
                   form="generateSettingForm"
                   placeholder= {intl.formatMessage({ id: 'generatePage1.eventNameMessage' })}
                   checks={['required']}
                   default="large"/>
               <GFormInput
                   type="text"
                   name="owner"
                   form="generateSettingForm"
                   placeholder={intl.formatMessage({ id: 'generatePage1.eventOwnerMessage' })}
                   checks={['required']}
                   default="large"/>
               <GFormInput
                   type="text"
                   format="textarea"
                   name="description"
                   form="generateSettingForm"
                   containerStyle={{marginBottom:'5'}}
                   placeholder= {intl.formatMessage({ id: 'generatePage1.eventInformationMessage' })}
                   style={{fontSize:'17',minHeight:'100'}}
                   default="large"/>
                <div style={{flex:1}}></div>
                <div className={mainStyles.submitBox}>
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:10,width:'100%',height:47,fontSize:10}}></div>
                        <div style={{flex:1}}></div>
                        {this._renderNextButton()}
                    </div>
                </div>
            </div>
        );
    }
}

const GeneratePage1WithInjectIntl = injectIntl(GeneratePage1);

export default GeneratePage1WithInjectIntl;
export const SET_LANG_DATA = 'SET_LANG_DATA';

import {
    API_NOTIFICATION_FETCH,
    API_NOTIFICATION_CHECK
} from 'app/js/api.js';

export function setLangData(data) {
    return{
        type: SET_LANG_DATA,
    	data: data
    };
}
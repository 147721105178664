exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._10hbOC9ChyW4Y3hX6s9w55{\n}\n.B40nQowfv6OdVgIgZf4zM{\n    margin-bottom: 14px;\n}\n.uKF1R3QTSyzluYKsxqiLO{\n    font-weight: bold;\n    color: #02499d !important;\n    margin-bottom: 3px;\n}\n\n._1mYGAoZ_AQHxkhW-BldYCI{\n    display: flex;\n    flex-direction: row;\n    min-height: 25px;\n    border-radius: 5px;\n    /*background-color: #ddd;*/\n    margin-bottom: 10px;\n    cursor: pointer;\n}\n._1mYGAoZ_AQHxkhW-BldYCI:hover{\n    opacity: 0.8;\n}\n._1CsWG911Mc1PLREefAiMbd{\n    background-color: #02499d;\n    border-radius: 3px ;\n    display: flex;\n    align-items: center;\n    padding-right: 10px;\n}\n._2Afxt39RAycsM_BkIsrjGz{\n}\n\n.wUaTZqlfVTKexMPJ52zhy{\n    margin-left: auto;\n    font-weight: bold;\n    color: #fff;\n}\n\n._3qwH7D-drOg3TPL5shXffx{\n    font-size: 13px;\n    padding: 0 5px 0 5px;\n    font-weight: 600;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    line-height: 19px;\n    -webkit-line-clamp: 1;\n    -webkit-box-orient: vertical;\n    color: #333;\n}\n.LJEUvHDdFkJgrtCg49fMg{\n    -webkit-line-clamp: unset;\n}\n\n._2rn84HkU-JV1db4RIsnRTv{\n    cursor: pointer;\n    font-weight: 600;\n}\n\n._25BEfoNnUhuS4Ub-AbKY0i{\n    display: flex;\n    border-bottom: 1px solid #e2e2e2;\n    padding-bottom: 10px;\n    align-items: center;\n}\n._3Irf1OLx0l1gtq34XviehD{\n    text-align: left;\n    font-weight: 900;\n    color: #02499d;\n    -webkit-font-smoothing: antialiased;\n    letter-spacing: -.02em;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    font-size: 14px;\n    text-align: inherit;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    line-height: 16px;\n    -webkit-line-clamp: 1;\n    -webkit-box-orient: vertical;\n}\n._2kJV-BRx0JS6vRFv4ZUgnD{\n    color: #999;\n    margin-left:10px;\n}\n.zecFtfBk4jSB0ZoHQhe3n{\n    min-height: 300px;\n    max-height: 450px;\n    overflow-y: auto;\n}\n", ""]);

// exports
exports.locals = {
	"main_board": "_10hbOC9ChyW4Y3hX6s9w55",
	"vote_item_board": "B40nQowfv6OdVgIgZf4zM",
	"item_title": "uKF1R3QTSyzluYKsxqiLO",
	"stamina_board": "_1mYGAoZ_AQHxkhW-BldYCI",
	"stamina_green": "_1CsWG911Mc1PLREefAiMbd",
	"stamina_black": "_2Afxt39RAycsM_BkIsrjGz",
	"stamina_green_text": "wUaTZqlfVTKexMPJ52zhy",
	"name_list_text": "_3qwH7D-drOg3TPL5shXffx",
	"name_list_text_load_more": "LJEUvHDdFkJgrtCg49fMg",
	"load_more": "_2rn84HkU-JV1db4RIsnRTv",
	"nameListTop": "_25BEfoNnUhuS4Ub-AbKY0i",
	"nameListTitle": "_3Irf1OLx0l1gtq34XviehD",
	"userCount": "_2kJV-BRx0JS6vRFv4ZUgnD",
	"nameListBoard": "zecFtfBk4jSB0ZoHQhe3n"
};
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryHeader from 'app/components/entry/entryHeader.jsx';

import * as entryHeaderActions from 'app/actions/entry/entryHeader_action.js';
import * as entryActions from 'app/actions/entry/entry_action.js';
import * as gFormInputActions from 'app/components/gTools/actions/gFormInput_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 entryHeaderActions : bindActionCreators(entryHeaderActions,dispatch),
     entryActions : bindActionCreators(entryActions,dispatch),
     gFormInputActions : bindActionCreators(gFormInputActions,dispatch),
  };
}

function mapStateToProps(state) {
	return {
        entryHeader:state.entryHeader,
        entry:state.entry,
        gFormInput:state.gFormInput
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(EntryHeader);

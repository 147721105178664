import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {CheckBox} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/timeRangeList_style.css';

export default class TimeRangeList extends React.Component {
    _renderTimeRange(){
        var timeRangeList = <AddButton disable={this.props.disable}/>
        if((this.props.picked&&this.props.pickMode=='list')||this.props.voteTimeMode){
            timeRangeList = <p className={styles.timeRangeText}>0:00 ~ 23:59</p>;
        }
        var self = this;
        if(this.props.data&&this.props.data.length>0){
            var length = this.props.data.length;
            timeRangeList = this.props.data.map(function(item,index){
                return <TimeUnit
                            entryDatePickerActions={self.props.entryDatePickerActions}
                            data={item}
                            index={index}
                            length={length}
                            selectedDayIndex={self.props.selectedDayIndex}/>;
            });
        }
        return timeRangeList;
    }
    render() {
        return (
            <div className={styles.container}>
                {this._renderTimeRange()}
            </div>
        );
    }
}

class TimeUnit extends React.Component {
    _onCheckBoxChanged(){
        var {selectedDayIndex,index} = this.props;
        this.props.entryDatePickerActions.pickTimeEntry(selectedDayIndex,index);
    }
    render() {
        var timeData = this.props.data;
        var timeLabel = convertToTimeString(timeData.start)+' ~ '+convertToTimeString(timeData.end);
        var orText = (this.props.index!=this.props.length-1)?<p className={styles.or}>Hoặc</p>:null;
        if(timeData.picked==undefined){
            return(
                <div>
                    <p className={styles.timeRangeText} key={this.props.index}>
                        {timeLabel}
                    </p>
                    {orText}
                </div>
            );
        }else {
            return(
                <CheckBox
                    disable={false}
                    name ={'entryTimePicker'+this.props.index}
                    className={styles.timeCheckBox}
                    checked={timeData.picked}
                    value={this.props.index} title={timeLabel}
                    onChange={this._onCheckBoxChanged.bind(this)}/>
            );
        }
    }
}

class AddButton extends React.Component {
    render() {
        if(!this.props.disable){
            return (
                <p className={styles.addButton}>+ <FormattedMessage id="timeRangeList.selectTimeSlotMessage" /></p>
            );
        }else {
            return (
                <p className={styles.disableText}><FormattedMessage id="timeRangeList.additionalTimeSlotMessage" /></p>
            );
        }
    }
}

function convertToTimeString(time){
    if(!time&&time!=0) return "__:__";
    var hour = Math.floor(time/3600);
    var minute = (time-hour*3600)/60;
    return hour + ':'+((minute.toString().length>1)?minute:'0'+minute);
}

export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_NOTIFICATION_ISLOADING = 'SET_NOTIFICATION_ISLOADING';

import {
    API_NOTIFICATION_FETCH,
    API_NOTIFICATION_CHECK
} from 'app/js/api.js';

export function fetchNotificationFromServer() {
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_NOTIFICATION_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => response.json()).then((responseJson) => {
            var content = responseJson.content;
            if (content) {
                dispatch(setNofificationData(content));
                dispatch(setNofificationCount(0));
            }
            dispatch(setIsLoading(false));
        });
    }
}

export function checkNotificationFromServer(notifyId) {
    var submitData = {
        notifyId: notifyId
    };
    return function(dispatch) {
        return fetch(API_NOTIFICATION_CHECK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {

        });
    }
}

export function setNofificationData(data) {
    return{
        type: SET_NOTIFICATION_DATA,
    	data: data
    };
}

export function setNofificationCount(data) {
    return{
        type: SET_NOTIFICATION_COUNT,
    	data: data
    };
}
export function setIsLoading(data) {
    return{
        type: SET_NOTIFICATION_ISLOADING,
    	data: data
    };
}
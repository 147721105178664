exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".oBGR7RcwBBKG6aliq8BQ- {\n    margin: 0 0 10px 0;\n    align-items: center;\n    color: #999;\n    display: flex;\n    font-size: 12px;\n    line-height: 12px;\n    font-weight: bold;\n}\n\n.oBGR7RcwBBKG6aliq8BQ- a {\n    color: #999;\n    text-decoration: none;\n}\n\n.oBGR7RcwBBKG6aliq8BQ- a:hover{\n    color: #449D69;\n    text-decoration: none;\n}\n\n._15KoegCaDw6jZg0TTNCzHB {\n    margin: 0 0 10px 0;\n    align-items: center;\n    color: #333;\n    display: flex;\n    font-size: 16px;\n    line-height: 16px;\n    font-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"main": "oBGR7RcwBBKG6aliq8BQ-",
	"remarkable": "_15KoegCaDw6jZg0TTNCzHB"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1-4vfRAEIar1oD4lTBJtgM{\n    /*border-radius: 3px;*/\n    border: 1px solid #e2e2e2;\n}\n.w_8OENvaWqmNYyYJFPG--{\n    background-color: #eee;\n    padding: 10px;\n    color: #333;\n    font-weight: bold;\n    font-size: 13px;\n    /*border-radius: 3px 3px 0 0;*/\n    border-bottom: 1px solid #e2e2e2;\n    display: flex;\n    flex-direction: row;\n}\n._1GuJ49iwXsp124WN54x5CV{\n    flex : 1;\n    display: flex;\n    flex-direction: row;\n}\n.etiJO_lAaBTDjBf7PUovg{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: flex-end;\n}\n._1p8Oz_uwILcvtW0FHa0VlA{\n    padding: 10px;\n}\n\n._2MLXzUkQ4_Ce7_MlrzvVFp{\n    height: 24px;\n    width: 24px;\n    margin-right: 5px;\n}\n\n._1w99aQHCS1bkSB7q5BhPqL{\n    flex : 1;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"main": "_1-4vfRAEIar1oD4lTBJtgM",
	"top": "w_8OENvaWqmNYyYJFPG--",
	"top_left": "_1GuJ49iwXsp124WN54x5CV",
	"top_right": "etiJO_lAaBTDjBf7PUovg",
	"body": "_1p8Oz_uwILcvtW0FHa0VlA",
	"icon": "_2MLXzUkQ4_Ce7_MlrzvVFp",
	"title": "_1w99aQHCS1bkSB7q5BhPqL"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1g0l1bfGLPi9Ipa2v3DxLH{\n    display: flex;\n    width: 70%;\n    justify-content: space-evenly;\n}\n._3vgixTf32hTyfzrWFT168D{\n    height: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1g0l1bfGLPi9Ipa2v3DxLH",
	"imageButton": "_3vgixTf32hTyfzrWFT168D"
};
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {browserHistory,Link} from 'react-router'
import UrlbList from 'app/containers/keoList/urlbList_container.jsx';
import styles from './styles/urlbPage_style.css';
import globalStyles from 'app/styles/global_styles.css';

export default class UrlbPage extends React.Component {
	render() {
		return (
            <div style={{minHeight:500}}>
                <UrlbList />
			</div>
		)
	}
}

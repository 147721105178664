exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._6fhPPwhvjfIokv8WdKGj8{\n    width: 142px;\n    padding: 3px 5px;\n    border: solid 1px #777;\n    border-radius: 5px;\n    margin-top: 5px;\n    cursor: pointer;\n    text-align: center;\n}\n._6fhPPwhvjfIokv8WdKGj8:hover{\n    background-color: #d3ffc2;\n}\n._20Stn42wGAFqqJsFnhCqUQ{\n    background-color: #d3ffc2;\n}\n\n._3gbL1SYR0dBYjNERGZFEuS{\n    border: none;\n    box-shadow: none;\n    width: 100%;\n    color: rgb(179, 179, 179);\n    resize: none;\n    font-weight: 200;\n    background-color: #f4f4f4;\n    font-size: 19px;\n    padding: 10px 40px 10px 20px;\n    border-radius: 5px;\n    margin-bottom: 5px;\n}\n\n\n@media only screen and (max-width: 768px) {\n    ._3gbL1SYR0dBYjNERGZFEuS{\n        font-size: 14px;\n    }\n}\n\n._1Uj4DzaKO7ZklCJNM4wlJD{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 10px;\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"copyButton": "_6fhPPwhvjfIokv8WdKGj8",
	"copied": "_20Stn42wGAFqqJsFnhCqUQ",
	"input_share": "_3gbL1SYR0dBYjNERGZFEuS",
	"container": "_1Uj4DzaKO7ZklCJNM4wlJD"
};
import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton,Dropdown,DialogModal,GModal,GNullBoard} from 'app/components/gTools/gTools.jsx';
import SocialBar from 'app/components/socialBar/socialBar.jsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import LinkBox from 'app/components/link/linkBox.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/entryHeaderControlBar_style.css';


export default class EntryHeaderControlBar extends React.Component {
    componentDidMount(){
        var {hashUrlb} = this.props;
        this.props.entryHeaderControlBarActions.checkLikeFromServer(hashUrlb);
    }
    componentWillReceiveProps(nextProps){
        if(this.props.hashUrlb!=nextProps.hashUrlb){
            this.props.entryHeaderControlBarActions.checkLikeFromServer(nextProps.hashUrlb);
        }
    }
    _onClickOpenShareFrom(){
        this.refs.shareForm.show();
    }
    _onClickLike(){
        if(this.props.user.isLogin){
            var {hashUrlb} = this.props;
            this.props.entryHeaderControlBarActions.submitLikeToServer(hashUrlb);
        }else {
            this.props.controlPanelActions.switchIsShowLoginModal(true);
        }
    }
    _renderLikeButton(){
        var className = '';
        var {likeCount} = this.props.entryHeaderControlBar;
        var icon = 'fa fa-thumbs-up';
        if(this.props.entryHeaderControlBar.isLike){
            className = styles.liked;
            icon = 'fa fa-check';
        }
        return (
            <span className={styles.likeButton+' '+className}>
                <span className={styles.likeLabel} onClick={this._onClickLike.bind(this)}>
                    <i className={icon} aria-hidden="true"></i> <FormattedMessage id="entryHeaderControlBar.tooGoodMessage" />
                </span>
                <span className={styles.likeCount} onClick={this._onClickShowListLike.bind(this)}>
                    {likeCount}
                </span>
            </span>
        );
    }
    _onClickShowListLike(){
		this.props.entryHeaderControlBarActions.switchIsShowListLike(true);
	}
    render() {
        var {viewCount,isShow} = this.props.entryHeaderControlBar;
        var {urlbs} = this.props.entryData.urlaData;
        var pickCount = (urlbs)?urlbs.length:0;
        //var pickCount = this.props.pickList.list.length;
        return (
            <div className={styles.container}>
                <ShareForm ref='shareForm' {...this.props}/>
                <GModal closeModal={this.props.entryHeaderControlBarActions.switchIsShowListLike} visible={isShow}>
                    <ListLike
                        likeData={this.props.entryHeaderControlBar}
                        hashUrlb={this.props.hashUrlb}
                        fetchLikeFromServer={this.props.entryHeaderControlBarActions.fetchLikeFromServer}/>
                </GModal>
                {this._renderLikeButton()}
                <span className={styles.lable}>
                    <i className={'fa fa-eye'} aria-hidden="true"></i>{' '+viewCount}
                </span>
                <span className={styles.lable}>
                    <i className={'fa fa-user'} aria-hidden="true"></i>{' '+pickCount}
                </span>
                <div style={{flex:1}}></div>
                <span className={styles.button} onClick={this._onClickOpenShareFrom.bind(this)}>
                    <i className={'fa fa-share'} aria-hidden="true"></i> <FormattedMessage id="entryHeaderControlBar.shareMessage" />
                </span>
            </div>
        );
    }
}
// <span className={styles.lable}>
//     <i className={'fa fa-user'} aria-hidden="true"></i>{' '+pickCount}
// </span>

export class ListLike extends React.Component {
	componentDidMount(){
		var {hashUrlb} = this.props;
		this.props.fetchLikeFromServer(hashUrlb);
	}
	_renderList(){

		var nodes = this.props.likeData.data.map(function(item,index){

			return <LikeItem data={item} index={index}/>;
		});
		if(nodes.length>0){
			return nodes;
		}else {
			return <GNullBoard><FormattedMessage id="entryHeaderControlBar.noTooGoodFeedbackMessage" /></GNullBoard>;
		}
	}
    render () {
        return (
            <div>
				<div className={styles.list_like_header}>
					<span style={{color:'#ff7e00'}}>{this.props.likeData.data.length}</span> <FormattedMessage id="entryHeaderControlBar.userDroppedSweetEventMessage" />
				</div>
				<div style={{marginTop:10}}>
					{this._renderList()}
				</div>
            </div>
        );
    }
}

export class LikeItem extends React.Component {
    render () {
		var avatarUrl = this.props.data.user.profile.avatarUrl;
        return (
        	<div className={styles.like_item_board}>
				<img src={avatarUrl} className={styles.like_item_avatar}/>
				{this.props.data.user.displayName}
			</div>
        );
    }
}



class ShareForm extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            visible:false
        };
    }
    show(){
        this.setState({
            visible:true
        });
    }
    hide(){
        this.setState({
            visible:false
        });
    }

    render() {
        var urlb = 'https://pickboz.com/entry/'+this.props.hashUrlb;
        return (
            <div>
                <DialogModal
                    description={<FormattedMessage id="entryHeaderControlBar.shareToFacebookMessage" />}
                    cancelLabel={<FormattedMessage id="entryHeaderControlBar.closeMessage" />}
                    visible={this.state.visible}
                    closeModal={this.hide.bind(this)}>
                    <div className={styles.socialBar}>
                        <SocialBar url={urlb}/>
                    </div>
                    <p className={styles.title}><FormattedMessage id="entryHeaderControlBar.eventLinkShareMessage" /></p>
                    <LinkBox url={urlb} />
                </DialogModal>
            </div>
        );
    }
}

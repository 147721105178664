exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3rDfwFsFHbc69wE8aF8ADc{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n._1fqI8wsAxl-DBD7eg5PM1h{\n    color: #fff;\n    cursor: pointer;\n    background-color: #02499d;\n    padding: 3px 10px;\n    border-radius: 5px;\n    font-size: 12px;\n}\n._1fqI8wsAxl-DBD7eg5PM1h:hover{\n    background-color: #555;\n}\n", ""]);

// exports
exports.locals = {
	"reply_load_more_button_board": "_3rDfwFsFHbc69wE8aF8ADc",
	"reply_load_more_button": "_1fqI8wsAxl-DBD7eg5PM1h"
};
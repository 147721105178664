import {API_COMMENT_FETCH,
        API_GENERATE_COUNT_COMMENT,
        API_COMMENT_REPLY_SUBMIT,
        API_COMMENT_REPLY_FETCH} from 'app/js/api.js';

export function fetchCommentFromServer(hashUrlb,latestDate, callback) {
    var submitData = {
        hashUrlb: hashUrlb,
        latestDate:latestDate
    };
    fetch(API_COMMENT_FETCH, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson) {
            callback(responseJson);
        }
    });
}

export function fetchCommentCountFromServer(hashUrlb, callback) {
    var submitData = {
        hashUrlb: hashUrlb
    };
    fetch(API_GENERATE_COUNT_COMMENT, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        callback(responseJson.content);
    });
}


export function submitReplyToServerApi(hashUrlb,commentId,message,callback) {
    var submitData = {
        hashUrlb: hashUrlb,
        commentId:commentId,
        message:message
    };
    fetch(API_COMMENT_REPLY_SUBMIT, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.content) {
            callback(responseJson.content);
        }
    });
}

export function fetchReplyFromServerApi(hashUrlb,commentId,latestDate,callback) {
    var submitData = {
        hashUrlb: hashUrlb,
        commentId:commentId,
        latestDate:latestDate
    };
    fetch(API_COMMENT_REPLY_FETCH, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson) {
            callback(responseJson);
        }
    });
}

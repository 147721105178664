exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3fG63kkqH8gCr6q2jbnzgp{\n    margin-bottom: 10px;\n}\n._1ZpXKHup66G4s7n-LQnP5A{\n    background-color: #fff;\n    padding: 10px;\n    font-size: 14px;\n    font-weight: 500;\n    color: #999;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    border: solid 1px #ccc;\n}\n._1ZpXKHup66G4s7n-LQnP5A i{\n    font-size: 19px;\n    margin-right: 3px;\n}\n._1ZpXKHup66G4s7n-LQnP5A:hover{\n    opacity: 0.8;\n}\n\n._3gUH4-pm5vzr0w4tlYxSSK{\n    background-color: #fdf0f0;\n    color: #e24a4a;\n    border: solid 1px #ffb2ad;\n}\n", ""]);

// exports
exports.locals = {
	"deadLine_board": "_3fG63kkqH8gCr6q2jbnzgp",
	"itemButton": "_1ZpXKHup66G4s7n-LQnP5A",
	"red": "_3gUH4-pm5vzr0w4tlYxSSK"
};
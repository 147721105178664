import React from 'react';
import {findDOMNode} from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton} from 'app/components/gTools/gTools.jsx';

import UrlaHistoryList from 'app/components/controlPanel/urlaHistoryList.jsx';
import UrlbHistoryList from 'app/components/controlPanel/urlbHistoryList.jsx';
import CPuserHeader from 'app/components/controlPanel/userHeader.jsx';
import SignupPanel from 'app/components/controlPanel/signupPanel.jsx';

import styles from './styles/controlPanel_style.css';
import globalStyles from 'app/styles/global_styles.css';

export default class ControlPanel extends React.Component {
	componentDidMount(){
		this.props.controlPanelActions.fetchGenerateHistory('');
		this.props.controlPanelActions.fetchEntryHistory('');
	}
	switchControlPanel() {
		this.props.controlPanelActions.switchDisplayControlPanel();
	}
	_renderMain() {
		if (this.props.user.isLogin) {
			return(
				<CPmainContent
					headerStyle = {this.props.headerStyle}
					user={this.props.user}
					path={this.props.path}
					query={this.props.query}
					urlaHistoryData={this.props.controlPanelData.urlaHistoryData}
					urlbHistoryData={this.props.controlPanelData.urlbHistoryData}
					switchControlPanel={this.switchControlPanel.bind(this)}/>
			)
		}
		else {
			return (
				<SignupPanel />
			)
		}
	}
	render() {
		console.log('path: ',this.props.path);
		console.log('query: ',this.props.query);
		var borderRadius = (this.props.headerStyle=='white')?styles.borderRadius:'';
		return (
			<div className={styles.control_panel_board+' '+borderRadius}>
				{this._renderMain()}
			</div>
		);
	}
}

export class CPmainContent extends React.Component {
	render() {
		return (
			<div className={styles.controPanel_main_container}>
				<div className={styles.controPanel_main_scroll}>
					<CPuserHeader
						switchControlPanel={this.props.switchControlPanel}
						urlaHistoryData={this.props.urlaHistoryData}
						urlbHistoryData={this.props.urlbHistoryData}
						user={this.props.user} />
					<div className={globalStyles.divider}></div>
					{/* <a style={{textDecoration:'none'}} href="/generate">
						<GButton default='orange' style={{fontSize:14,width:'100%',height:45}}><i className="fa fa-plus-circle" aria-hidden="true"></i> Tạo kèo mới</GButton>
					</a> */}
					<UrlbHistoryList
						path={this.props.path}
						query={this.props.query}
						urlbHistoryData={this.props.urlbHistoryData}/>
					<div className={globalStyles.divider}></div>

					<UrlaHistoryList
						path={this.props.path}
						query={this.props.query}
						urlaHistoryData={this.props.urlaHistoryData}/>
					<div style={{marginBottom:75}}></div>
				</div>
			</div>
		)
	}
}
// <div className={globalStyles.add_button}>
// 	<span className={globalStyles.add_button_plus}><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
// 	<span className={globalStyles.add_button_description}>Tạo kèo mới</span>
// </div>

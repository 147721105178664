import {
    SET_ENTRY_HEADER_SHOW_MODE,
    RESET_ENTRY_HEADER
} from 'app/actions/entry/entryHeader_action.js';

const defaultValue ={
    showMode:'info'
};
function entryHeader(state = defaultValue,action) {
    switch (action.type) {
        case SET_ENTRY_HEADER_SHOW_MODE:
            var newState = Object.assign({},state);
            newState.showMode = action.data;
            return newState;
        case RESET_ENTRY_HEADER:
            var newState = Object.assign({},state);
            newState = defaultValue;
            return newState;
        default:
            return state;
    }
}

export default entryHeader;

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/timePicker_style.css';
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
moment.updateLocale('vi', {months : 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });

export default class TimePicker extends React.Component {
    constructor(props){
        super(props);
        var visibleList = [];
        var range = this.props.range*60;
        var length = 86400/range;
        for(var i=0;i<length;i++){
            visibleList.push(true);
        }
        this.state={
            time:[{}],
            visibleList:visibleList,
            selectedIndex:-1
        }
    }
    componentWillReceiveProps(nextProps){
        if(this.props.range!=nextProps.range){
            var visibleList = [];
            var range = nextProps.range*60;
            var length = 86400/range;
            for(var i=0;i<length;i++){
                visibleList.push(true);
            }
            this.setState({
                visibleList:visibleList
            });
        }
    }
    componentDidMount(){
        var time = Object.assign([],this.props.time);
        time.push({});
        var visibleList = this.state.visibleList;
        var start,end;
        for(var i = 0;i<time.length;i++){
            start = time[i].start/1800;
            end = time[i].end/1800;
            if(this.props.isOnlyOneInterval){
                for(var j=start;j<=end;j++){
                    visibleList[j]=false;
                }
            }
        }
        this.setState({
            time:time,
            visibleList:visibleList
        });
        //this.refs.timeList.scrollTo(0,400);
    }
    getTimeRanges(){
        var time = this.state.time;
        var length = time.length;
        if(time[length-1].start||time[length-1].start==0){
            if(!time[length-1].end){
                time[length-1].end = 86340;
            }
        }else {
            time.splice(time.length-1,1);
        }
        return time;
    }
    getTimeUnit(index,timeString){
        if(this.state.visibleList[index]){
            if(this.state.selectedIndex==index){
                return(<p key={index} className={styles.selectedLabel}>{timeString}</p>);
            }
            else {
                return(
                    <TimeUnit key={index} index={index} setTime={this.setTime.bind(this)}>{timeString}</TimeUnit>
                );
            }

        }else {
            return(<p key={index} className={styles.disableTimeUnit}>{timeString}</p>);
        }
    }
    _renderList(){
        var nodes = [];
        var timeString = '';
        var index;
        var range = this.props.range*60;
        var length = 86400/range;
        for(var h = 0;h<length;h++){
            timeString = convertToTimeString(h*range);
            nodes.push(this.getTimeUnit(h,timeString));
        }
        return(nodes);
    }
    setTime(index){
        var time = this.state.time;
        var timeLen = time.length;
        var range = this.props.range*60;
        var second = index*range;
        var stateData = {};
        if(time[timeLen-1].start||time[timeLen-1].start==0){
            if(second<=time[timeLen-1].start){
                time[timeLen-1]={};
                stateData.selectedIndex = -1;
            }else {
                time[timeLen-1].end = second;
                time.push({});
                var startIndex = time[timeLen-1].start/1800;
                var visibleList = this.state.visibleList;
                if(this.props.isOnlyOneInterval){
                    for(var i=startIndex;i<=index;i++){
                         visibleList[i]=false;
                    }
                }
                stateData.selectedIndex = -1;
                stateData.visibleList = visibleList;
            }
        }else {
            stateData.selectedIndex = index;
            time[timeLen-1].start = second;
        }
        stateData.time = time;
        this.setState(stateData);
    }
    deleteSelectedTime(index){
        var time = this.state.time;
        var start = time[index].start/1800;
        var end = time[index].end/1800;
        var visibleList = this.state.visibleList;
        if(this.props.isOnlyOneInterval){
            for(var i=start;i<=end;i++){
                visibleList[i]=true;
            }
        }
        time.splice(index,1);
        if(time.length==0){
            time.push({});
        }
        this.setState({
            time:time,
            visibleList:visibleList
        });
    }
    _renderSelectedTimes(){
        var self = this;
        var nodes = this.state.time.map(function(item,index){
            return <SelectedTimeUnit
                        data={item}
                        key={index}
                        index={index}
                        deleteSelectedTime={self.deleteSelectedTime.bind(self)}/>;
        });
        return nodes;
    }
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.left} ref='timeList'>
                    <div  className={styles.timeList}>
                        {this._renderList()}
                    </div>
                </div>
                <div className={styles.right}>
                    {this._renderSelectedTimes()}
                </div>
            </div>
        );
    }
}

TimePicker.defaultProps = {
  range: 15
};

class TimeUnit extends React.Component {
    _onPressPickTime(){
        this.props.setTime(this.props.index);
    }
	render() {
		return (
            <div className={styles.timeUnit} onClick={this._onPressPickTime.bind(this)}>
                <p className={styles.timeUnitText}>{this.props.children}</p>
            </div>
		);
	}
}

class SelectedTimeUnit extends React.Component {
    _onPressDelete(){
        this.props.deleteSelectedTime(this.props.index);
    }
    _renderDeleteButton(){
        if(this.props.data.start||this.props.data.start==0){
            return(
                <div onClick={this._onPressDelete.bind(this)}>
                    <i className={"fa fa-times "+styles.selectedTimeUnitIcon} aria-hidden="true"></i>
                </div>
            );
        }else {
            return null;
        }
    }
	render() {
        var timeRangeString = convertToTimeString(this.props.data.start)+' ~ '+convertToTimeString(this.props.data.end);
		return (
            <div className={styles.selectedTimeUnit}>
                <p className={styles.selectedTimeUnitText}>{timeRangeString}</p>
                <div style={{flex:1}}></div>
                {this._renderDeleteButton()}
            </div>
		);
	}
}

function convertToTimeString(time){
    if(!time&&time!=0) return "__:__";
    var hour = Math.floor(time/3600);;
    var minute = (time-hour*3600)/60;
    return hour + ':'+((minute.toString().length>1)?minute:'0'+minute);
}

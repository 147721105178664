exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._34TdHlGJw2euse71vfNWaV{\n    font-size: 19px;\n    color: #333;\n    text-transform: uppercase;\n    text-align: center;\n    font-weight: 700;\n}\n._3Qws96TCbSaAk9sTZTwnun{\n    font-size: 17px;\n    text-align: center;\n    color: #777;\n}\n\n\n._31uG8eRqGQ_hmf5Vs1JheU {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n._2Wn6ojqyFlgYjBrCPDlVSX {\n    width: 35px;\n    height: 35px;\n    border-radius: 28px;\n    margin-right: 5px;\n    border: 1px solid #e3e3e3;\n}\n.jo5tTkjVUYo_UNActRasY {\n    flex: 1;\n    font-size: 14px;\n    font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"bravoTitle": "_34TdHlGJw2euse71vfNWaV",
	"entryFinishDescription": "_3Qws96TCbSaAk9sTZTwnun",
	"fbUserNameBoard": "_31uG8eRqGQ_hmf5Vs1JheU",
	"fbUserAvatar": "_2Wn6ojqyFlgYjBrCPDlVSX",
	"fbUserName": "jo5tTkjVUYo_UNActRasY"
};
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryPage from 'app/views/entry/entry.jsx';

import * as entryPageAction from 'app/actions/entry/entry_action.js';
import * as GFormInputAction from 'app/components/gTools/actions/gFormInput_action.js';
import * as entryResultActions from 'app/actions/entry/entryResult_action.js'
import * as commentListActions from 'app/actions/comment/commentList_action.js';
import * as languageActions from 'app/actions/api/language_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';
import * as collectionActions from 'app/actions/collection/collection_action.js';
import * as commentBoxActions from 'app/actions/comment/commentBox_action.js';
import * as entryInputFormActions from 'app/components/inputForm/entryInputForm/entryInputForm_action.js';
import * as userActions from 'app/actions/user/user_action.js';
import * as pickVoteActions from 'app/actions/vote/pickVotePanel_action.js';
import * as entryDatePickerActions from 'app/actions/entry/entryDatePicker_action.js';

function mapDispatchToProps(dispatch) {
    return {
        entryActions: bindActionCreators(entryPageAction,dispatch),
        gFormInputActions : bindActionCreators(GFormInputAction,dispatch),
        entryInputFormActions : bindActionCreators(entryInputFormActions,dispatch),
        entryResultActions : bindActionCreators(entryResultActions,dispatch),
        commentListActions : bindActionCreators(commentListActions,dispatch),
        languageActions: bindActionCreators(languageActions,dispatch),
        controlPanelActions : bindActionCreators(controlPanelActions,dispatch),
        collectionActions : bindActionCreators(collectionActions,dispatch),
        commentBoxActions : bindActionCreators(commentBoxActions,dispatch),
        userActions : bindActionCreators(userActions,dispatch),
        pickVoteActions: bindActionCreators(pickVoteActions,dispatch),
        entryDatePickerActions: bindActionCreators(entryDatePickerActions,dispatch)
    }
}

function mapStateToProps(state) {
	return ({
		entry:state.entry,
        gFormInput:state.gFormInput,
        commentListData:state.commentListData,
        pickVotePanelData:state.pickVotePanelData,
        entryDatePicker:state.entryDatePicker,
        votePanelData:state.votePanelData,
        user:state.user,
        language:state.language,
        collectionBox :state.collectionBox,
        commentBox:state.commentBox,
        voteTimePanel:state.voteTimePanel
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(EntryPage);

import {infoList} from './util.js';

var self = {
    required: required, // kiem tra khong de trong
    email: email, // kiem tra co phai cau truc dia chi email hay khong
    availableEmail: availableEmail, // kiem tra email ton tai
    username : username, // kiem tra co phai cau truc username
    matching : matching, // kiem tra 2 co co matching voi nhau khong
    vietnameseName : vietnameseName, // kiem tra co phai ten co dau nguoi viet hay khong
    password : password // kiem tra password co dung cau truc hay khong
};
export function ValidURL(str) {
       var regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
       if(!regex .test(str)) {
         return false;
       } else {
         return true;
       }
}
export function isEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function isUserName(username) {
    var re = /^[A-Za-z0-9_]{5,20}$/;
    return re.test(username);
}
export function isPassword(password) {
    var re = /^[A-Za-z0-9!@#$%^&*()_]{6,20}$/;
    return re.test(password);
}
export function isVietnameseName(displayName) {
    var re = /^[A-Za-z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]{5,100}$/;
    return re.test(displayName);
}
export function isMatching(value1,value2) {
    return (value1==value2);
}

export function email(value,callback) {
    var result ='';
    if (value && !isEmail(value)) {
        result = infoList.i0002.info; //email khong hop le
    }
    callback(result);
}
export function username(value,callback) {
    var result ='';
    if (value && !isUserName(value)) {
        result = infoList.i0005.info; //username khong hop le
    }
    callback(result);
}

export function password(value,callback) {
    var result ='';
    if (value && !isPassword(value)) {
        result = infoList.i0007.info; //password khong hop le
    }
    callback(result);
}

export function vietnameseName(value,callback) {
    var result ='';
    if (value && !isVietnameseName(value)) {
        result = infoList.i0008.info; //password khong hop le
    }
    callback(result);
}



export function availableEmail(value,callback) {
    var result ='';
    checkAvailabilityFromServer('email',value,function(message) {
        if (message!='ok') {
            result = infoList.i0003.info; //email da ton tai
        }
        callback(result);
    });
}

export function required(value,callback,lang) {
    var result = '';
    if (!value) {
        switch (lang) {
            default:
                result = "validate.cannotBeEmptyMessage"; //required
        }
    }
    callback(result);
}

export function matching(value,params,values,callback) {
    var result = '';
    var reCheck = [];
    if(!isMatching(value,values[params[1]])){
        result = infoList.i0006.info;// loi khong trung khop hai o (vd luc doi mat khau)
    }else {
        reCheck.push(params[1]);
    }
    callback(result,reCheck);
}

//CHECK VALIDATOR WITH ASYNC LOOP

export function checkValidator(name,check,values,callback,lang){
    var checkString = check.split('&');
    if(checkString.length==1){
        self[check](values[name],function(result){
            callback(result);
        },lang);
    }else {
        self[checkString[0]](values[name],checkString,values,function(result,reCheck){
            callback(result,reCheck);
        },lang);
    }
}

export function asyncForCheckValidator(name,checks,values,callback,i,reCheckList,lang){
    if(i===undefined) i=0;
    if(reCheckList===undefined) reCheckList =[];//reCheckList dung de gom nhung input can check lai khi input nay duoc kiem tra ok
    if (!checks || i==checks.length) {
        callback('',reCheckList);
        return;
    }
    checkValidator(name,checks[i],values,function(result,reCheck){
        if(reCheck && reCheck.length>0){
            reCheckList = reCheckList.concat(reCheck);
        }
        if(result!=''){
            callback(result,reCheckList);
            return;
        }
        asyncForCheckValidator(name,checks,values,callback,i+1,reCheckList,lang);
    },lang);
}

//END OF CHECK WITH ASYNC LOOP

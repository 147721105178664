export const FETCH_VOTE_USER = 'FETCH_VOTE_USER';
import {API_VOTE_FETCH_USER} from 'app/js/api.js';

export function fetchVoteUserList(voteId, hashUrlb,index) {
    var submitData = {
        voteId: voteId,
        hashUrlb: hashUrlb,
    };
    return function(dispatch) {
        return fetch(API_VOTE_FETCH_USER, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setFetchVoteUser(index,responseJson.content));
            }
        });
    }
}


export function setFetchVoteUser(index,data) {
    return ({
        type: FETCH_VOTE_USER,
        data: data,
        index:index
    });
}

import {
    combineReducers
} from 'redux';

import {
    SWITCH_FEEDBACK_DISPLAY,
	FEED_BACK_RESET_FORM
} from 'app/actions/feedback/feedback_action.js';

function feedbackData(state={
	display:0
},action) {
    switch (action.type) {
        case SWITCH_FEEDBACK_DISPLAY:
            var newState = Object.assign({},state);
            newState.display = action.data;
            return newState;
		case FEED_BACK_RESET_FORM:
            var newState = Object.assign({},state);
            newState.display = 0;
            return newState;
        default:
            return state;
    }
}


export default feedbackData;

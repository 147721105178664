import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GButton } from 'app/components/gTools/gTools.jsx';
import globalStyles from 'app/styles/global_styles.css';

// import styles from './styles/policy_style.css';
import Language from 'app/international/international.js';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class PolicyPage extends React.Component {
    constructor(props) {
        super(props);
    }
    _onClickBackToHomePage() {
        browserHistory.push('/');
    }
    render() {
        return (
            <div style={{height:'100vh'}}>
                <div class="container">
                    <h1>Privacy Policy</h1>
                    <p>Pickboz.com ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Pickboz.com.</p>

                    <h2>Information We Collect</h2>
                    <p><strong>Personal Information:</strong> We collect personal information you provide to us, such as your name, email address, phone number, and any other information you choose to provide.</p>
                    <p><strong>Usage Data:</strong> We automatically collect information about your interactions with our website and services, including IP address, browser type, access times, pages viewed, and the page you visited before navigating to our services.</p>

                    <h2>How We Use Your Information</h2>
                    <p><strong>To Provide Services:</strong> We use your information to provide and manage our scheduling services.</p>
                    <p><strong>To Communicate:</strong> We may use your information to send you updates, promotional materials, and other communications.</p>
                    <p><strong>To Improve Services:</strong> We use the information to understand how our services are being used and to make improvements.</p>

                    <h2>Sharing Your Information</h2>
                    <p><strong>With Third Parties:</strong> We may share your information with third-party service providers to facilitate our services.</p>
                    <p><strong>For Legal Reasons:</strong> We may disclose your information if required by law or to protect our rights.</p>

                    <h2>Your Choices</h2>
                    <p><strong>Access and Correction:</strong> You may access, update, or correct your personal information at any time.</p>
                    <p><strong>Data Deletion:</strong> You can request the deletion of your personal information by contacting us.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:pickboz4@gmail.com">pickboz4@gmail.com</a>.</p>
                </div>
            </div>
        );
    }
}

import {
    GENERATE_SET_URLA_TEMP,
    GENERATE_PAGE_RESET,
    GENERATE_SET_URLA_DATA,
    GENERATE_CHANGE_DEAD_LINE_RADIO_BUTTON,
    GENERATE_RENDER_MAIN_COMPONENT,
    SET_TO_URLB_SOCIAL_BAR_IS_LOADING,
    SET_GENERATE_MAIN_TAB_VALUE,
    SET_GENERATE_IS_LOADING
} from 'app/actions/generate/generate_action.js';

import {
    combineReducers
} from 'redux';

function urlaData (state = {
    deadLine:'unlimited',
    toUrlbSocialBarIsLoading:1,
},action){
    switch (action.type) {
        case GENERATE_SET_URLA_TEMP:
            var newState = Object.assign({},state);
            newState.hashUrla = action.data.hashUrla;
            return newState;
        case GENERATE_SET_URLA_DATA:
            var newState = Object.assign({},state);
            var data = action.data;
            newState.id = data.id;
            newState.title = data.title;
            newState.owner = data.owner;
            newState.hashUrla = data.hashUrla;
            newState.description = data.description;
            newState.deadLine = (data.schedule.deadLine)?data.schedule.deadLine:'unlimited';
            newState.hashUrlb = (data.hashUrlb)?data.hashUrlb:null;
            return newState;
        case GENERATE_CHANGE_DEAD_LINE_RADIO_BUTTON:
            var newState = Object.assign({},state);
            newState.deadLine = action.data;
            return newState;
        case GENERATE_PAGE_RESET:
            var newState = {};
            return newState;
        case SET_TO_URLB_SOCIAL_BAR_IS_LOADING:
            var newState = Object.assign({},state);
            newState.toUrlbSocialBarIsLoading = action.data;
            return newState;
        default:
            return state;
    }
}



function mainRenderMod (state = 'checkLogin',action){
    switch (action.type) {
        case GENERATE_RENDER_MAIN_COMPONENT:
            return action.data;
        case GENERATE_PAGE_RESET:
            return 'setting';
        default:
            return state;
    }
}

const generateMainDefault = {
    tabValue:'info',
    isLoading:false
};
function generateMain (state = generateMainDefault,action){
    switch (action.type) {
        case SET_GENERATE_MAIN_TAB_VALUE:
            var newState = Object.assign({},state);
            newState.tabValue = action.data;
            return newState;
        case GENERATE_PAGE_RESET:
            var newState = Object.assign({},state);
            newState = generateMainDefault;
            return newState;
        case SET_GENERATE_IS_LOADING:
            var newState = Object.assign({},state);
            newState.isLoading = action.data;
            return newState;
        default:
            return state;
    }
}


const generateReducer = combineReducers({
    urlaData: urlaData,
    generateMain:generateMain,
    mainRenderMod:mainRenderMod,
});

export default generateReducer;

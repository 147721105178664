import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/urlbResultPanel_style.css';
import globalStyles from 'app/styles/global_styles.css';

import {GButton,Panel} from 'app/components/gTools/gTools.jsx';

import CommentList from 'app/components/comment/commentList.jsx';
import TimeResult2 from 'app/components/result/timeResult2.jsx';
import TimeResult from 'app/components/result/timeResult.jsx';
import VoteResult from 'app/containers/result/voteResult_container.jsx';
import VoteInfo from 'app/components/vote/voteInfo.jsx';

export default class ResultBox extends React.Component {
    componentDidMount(){
        this.props.resultActions.fetchResultData(this.props.hashUrlb);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.hashUrlb !== nextProps.hashUrlb) {
            this.props.resultActions.fetchResultData(nextProps.hashUrlb);
        }
    }
    componentWillUnmount(){
        this.props.resultActions.reset();
    }
    _renderVoteResult(){
        var votes = this.props.result.resultData.votes;
        if(votes.length>1){
            return(
                <div className={globalStyles.white_box}>
                    <VoteResult data={votes} language={this.props.language} hashUrlb={this.props.hashUrlb}/>
                </div>
            );
        }else {
            // if(votes.length==1){
            //     return(
            //         <div className={globalStyles.white_box}>
            //             <VoteInfo disableMap index={0} data={votes[0]} language={this.props.language} />
            //         </div>
            //     );
            // }else {
                return null;
            // }
        }
    }
    _renderTimeResult(){
        var {votes,picks,users,urlbCount} = this.props.result.resultData;
        var hashUrlb = this.props.hashUrlb;
        if(this.props.result.resultData.isLoadingTimeVote){
            return(
                <center>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'24',color:'#02499d'}} aria-hidden="true"></i>
                </center>
            );
        }
        if(this.props.timeMode==1){
            return (
                <TimeResult2
                  hashUrlb={hashUrlb}
                  data={picks}
                  urlbCount={urlbCount}
                  resultActions={this.props.resultActions}
                  users={users}/>
            );
        }else {
            return(
                <TimeResult data={picks}  language={this.props.language}/>
            );
        }
    }
    render() {
        return (
            <div className={styles.mainBoard}>
                {this._renderVoteResult()}
                <div className={globalStyles.white_box}>
                    {this._renderTimeResult()}
                </div>
            </div>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2RznbHw0665Y46ED5IoWJq{\n    margin-bottom: 175px;\n}\n\n._2UdzvRuyGrUIE5tKJRvlzZ{\n    border: none;\n    box-shadow: none;\n    width: 100%;\n    color: #c38282;\n    resize: none;\n    font-weight: 200;\n    background-color: #ffebeb;\n    font-size: 19px;\n    padding: 5px;\n    border-radius: 5px;\n}\n\n._1khh0aHaUJ3y4331NheU_p {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n._1-Ht9UDi-o2d-TnLbrR8US{\n    width: 250px;\n}\n\n._11wDXvJnCzQ-qpHbvCP0Cq{\n    font-size: 19px;\n    color: #02499d;\n    font-weight: bold;\n}\n._3hRkTzee-7NkRiEX_LyDGt{\n    color: #333;\n    font-weight: 200;\n    margin-bottom: 15px;\n    font-size: 14px;\n}\n", ""]);

// exports
exports.locals = {
	"body_board": "_2RznbHw0665Y46ED5IoWJq",
	"input_urla": "_2UdzvRuyGrUIE5tKJRvlzZ",
	"social_bar": "_1khh0aHaUJ3y4331NheU_p",
	"tung_hoa_image": "_1-Ht9UDi-o2d-TnLbrR8US",
	"topTitle": "_11wDXvJnCzQ-qpHbvCP0Cq",
	"topTitle2": "_3hRkTzee-7NkRiEX_LyDGt"
};
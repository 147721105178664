import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/explorer_style.css';
import Language from 'app/international/international.js';

var Lang = new Language('vn');

export default class ExplorerPage extends React.Component {
    constructor(props) {
        super(props);
    }
    _renderMain(){
       return (
           <div>
               EXPLORER
           </div>
       );
    }
    render() {
        return (
            <div>
                <div className={globalStyles.main_background}>
                    <div className={globalStyles.background_img}></div>
                    <div className={globalStyles.main_board}>
                        {this._renderMain()}
                    </div>
                </div>
            </div>
        );
    }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import IndexPage from 'app/views/index/index.jsx';

import * as IndexPageAction from 'app/actions/index/index_action.js';
import * as LanguageActions from 'app/actions/api/language_action.js';
import * as ControlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

import * as userActions from 'app/actions/user/user_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
    return {
        indexPageActions:bindActionCreators(IndexPageAction, dispatch),
        languageActions:bindActionCreators(LanguageActions, dispatch),
        userActions:bindActionCreators(userActions, dispatch),
        controlPanelActions:bindActionCreators(controlPanelActions, dispatch),
    }
}

function mapStateToProps(state) {
	return ({
		index:state.index,
        language:state.language,
        user:state.user,
        controlPanel:state.controlPanel
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(IndexPage);

export const SET_ENTRY_DATE_PICKER_DATA = 'SET_ENTRY_DATE_PICKER_DATA';
export const DESELECT_DAY = 'DESELECT_DAY';
export const SET_START_TIME_AT = 'SET_START_TIME_AT';
export const SET_END_TIME_AT = 'SET_END_TIME_AT';
export const DELETE_TIME_AT = 'DELETE_TIME_AT';
export const SET_TIME_RANGE = 'SET_TIME_RANGE';
export const PICK_DATE_ENTRY = 'PICK_DATE_ENTRY';
export const RESET_TIME_RANGE = 'RESET_TIME_RANGE';
export const SET_ENTRY_DATE_PICKER_FETCHED_DATA = 'SET_ENTRY_DATE_PICKER_FETCHED_DATA';
export const ADD_AND_PICK_DATE_ENTRY = 'ADD_AND_PICK_DATE_ENTRY';
export const RESET_ENTRY_DATE_PICKER = 'RESET_ENTRY_DATE_PICKER';
export const SET_DISPLAY_ENTRY_SELECTED_DAY = 'SET_DISPLAY_ENTRY_SELECTED_DAY';
export const SET_ENTRY_TIME_PICKER_DATA = 'SET_ENTRY_TIME_PICKER_DATA';
export const ADD_ENTRY_TIME_UNIT = 'ADD_ENTRY_TIME_UNIT';
export const PICK_TIME_ENTRY = 'PICK_TIME_ENTRY';
export const UNCHECK_ALL_TIME_ENTRY = 'UNCHECK_ALL_TIME_ENTRY';
export const CHECK_ALL_TIME_ENTRY = 'CHECK_ALL_TIME_ENTRY';
export const SET_ENTRY_DATE_PICKER_DATA_2 ='SET_ENTRY_DATE_PICKER_DATA_2';
export const ADD_TIME_RANGE = 'ADD_TIME_RANGE';
export const SPLICE_TIME_RANGE = 'SPLICE_TIME_RANGE';
export const COPY_TIME_RANGE_FROM_ABOVE = 'COPY_TIME_RANGE_FROM_ABOVE';

export function setEntryDatePickerData(data) {
    return {
        type: SET_ENTRY_DATE_PICKER_DATA,
        data: data
    }
}


export function setEntryDatePickerData2(data) {
    return {
        type: SET_ENTRY_DATE_PICKER_DATA_2,
        data: data
    }
}
export function setFetchedDayData(data,urlbId,allowSelect) {
    return {
        type: SET_ENTRY_DATE_PICKER_FETCHED_DATA,
        data: data,
        urlbId:urlbId,
        allowSelect:allowSelect
    }
}

export function deselectDay(index) {
    return({
        type: DESELECT_DAY,
        index: index
    });
}

export function setTimeRange(data,index) {
    return({
        type: SET_TIME_RANGE,
        data: data,
        index:index
    });
}
export function addTimeRange(data,index) {
    return({
        type: ADD_TIME_RANGE,
        data: data,
        index:index
    });
}

export function sliceTimeRange(timeIndex,index) {
    return({
        type: SPLICE_TIME_RANGE,
        timeIndex: timeIndex,
        index:index
    });
}

export function setIsEnd(data,index) {
    return {
        type: SET_IS_END_AT,
        data: data,
        index: index
    }
}

export function pickDateEntry(index) {
    return({
        type: PICK_DATE_ENTRY,
        index: index
    });
}

export function addAndPickDateEntry(day) {
    return({
        type: ADD_AND_PICK_DATE_ENTRY,
        day: day
    });
}

export function resetTimeRange(index) {
    return {
        type: RESET_TIME_RANGE,
        index: index
    }
}

export function setDisplayEntrySelectedDay(data) {
    return {
        type: SET_DISPLAY_ENTRY_SELECTED_DAY,
        data: data
    }
}

export function reset() {
    return {
        type: RESET_ENTRY_DATE_PICKER
    }
}

export function pickTimeEntry(daySelectedIndex,index) {
    return({
        type: PICK_TIME_ENTRY,
        daySelectedIndex: daySelectedIndex,
        index: index
    });
}

export function copyTimeRangeFromAbove(index) {
    return({
        type: COPY_TIME_RANGE_FROM_ABOVE,
        index: index
    });
}
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {browserHistory,Link} from 'react-router'
import UserHeader from 'app/components/user/userHeader.jsx';
import UrlaPage from 'app/views/user/urlaPage.jsx';
import UrlbPage from 'app/views/user/urlbPage.jsx';
import SignupPanel from 'app/components/controlPanel/signupPanel.jsx';

import styles from './styles/userHome_style.css';
import globalStyles from 'app/styles/global_styles.css';

export default class UserHome extends React.Component {

    componentDidMount(){
        var tabValue =  this.props.location.query.tab;
        tabValue = (tabValue)?tabValue:'urlb';
        this.props.userHomeActions.setTabValue(tabValue);
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.location.query.tab!=nextProps.location.query.tab){
            var tabValue =  nextProps.location.query.tab;
            tabValue = (tabValue)?tabValue:'urlb';
            this.props.userHomeActions.setTabValue(tabValue);
        }
    }
    _renderTab(){
        var tabValue = this.props.userHome.tabValue;
        switch (tabValue) {
            case 'urla':
                return <UrlaPage {...this.props} />;
                break;
            case 'urlb':
                return <UrlbPage {...this.props} />;
                break;
            default:
        }
    }
    _renderMain(){
        if(this.props.user.isFetch){//kiem tra login da fetch chua
            if(this.props.user.isLogin){//neu login roi
                var userData = this.props.user.userData;
                return(
                    <div>
                        <UserHeader userData={userData} {...this.props}/>
                        {this._renderTab()}
                    </div>
                );
            }else {
                return(
                    <div className={globalStyles.white_box}>
                        <SignupPanel />
                    </div>
                );
            }
        }else {//chua login
            return(
                <div>
                     <center>
                         <i className={"fa fa-refresh fa-spin "} style={{fontSize:'44',color:'#02499d'}} aria-hidden="true"></i>;
                     </center>
                 </div>
            );
        }
    }
    render() {
        return (
            <div>
                <div className={globalStyles.main_background}>
                    <div className={globalStyles.background_img}>
                    </div>
                        <div className={globalStyles.main_board}
                             style={{paddingTop:'0px'}}>
                             {this._renderMain()}
                        </div>
                </div>
            </div>
        )
    }
}

import  {
    API_LANGUAGE_FETCH
} from 'app/js/api.js';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export function fetchLanguage(data) {
    return function(dispatch) {
        var submitData = {
            lang: data
        };
        return fetch(API_LANGUAGE_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setLanguage(responseJson.content.language,responseJson.content.lang));
            }
            else {
                console.log('error: ',responseJson.error);
            }
        })
    }
}

export function setLanguage(data,lang) {
    return {
        type: SET_LANGUAGE,
        data: data,
        lang: lang
    }
}

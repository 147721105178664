import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import ListPick from 'app/components/entry/listPick.jsx';

import * as listPickActions from 'app/actions/entry/listPick_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 listPickActions : bindActionCreators(listPickActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        pickList:state.pickList
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ListPick);

export const SET_RESULT_DATA = 'SET_RESULT_DATA';
export const SWITCH_DISPLAY_RESULT_TAB = 'SWITCH_DISPLAY_RESULT_TAB';
export const RESET_RESULT_DATA = 'RESET_RESULT_DATA';
export const ADD_URLB_RESULT_DATA = 'ADD_URLB_RESULT_DATA';
export const SET_TIME_VOTE_RESULT_LOADING = 'SET_TIME_VOTE_RESULT_LOADING';
import {API_GENERATE_RESULT_FETCH,API_GENERATE_RESULT_FETCH_SEE_MORE} from 'app/js/api.js';

export function fetchResultFromServer(hashUrlb, callback) {
    var submitData = {
            hashUrlb: hashUrlb
        };
    fetch(API_GENERATE_RESULT_FETCH, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.content) {
            callback(responseJson.content);
        }else {
            callback(null);
        }
    });
}


export function fetchSeemoreResultFromServer(hashUrlb,latestDate) {
    return function(dispatch){
      var submitData = {
              hashUrlb: hashUrlb,
              latestDate:latestDate
          };
      fetch(API_GENERATE_RESULT_FETCH_SEE_MORE, {
          credentials: 'include',
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(submitData)
      }).then((response) => response.json()).then((responseJson) => {
          console.log('====?>>>',responseJson.content);
          if (responseJson.content) {
              dispatch(addUrlbData(responseJson.content));
          }
      });
    }
}



export function fetchResultData(hashUrlb) {
    return function(dispatch){
        dispatch(setLoading(true));
        fetchResultFromServer(hashUrlb,function(content){
            dispatch(setLoading(false));
            if (content){
                dispatch(setResultData(content));
            }
        });
    }
}

export function setResultData(data) {
    return {
        type: SET_RESULT_DATA,
        data: data
    }
}
export function addUrlbData(data) {
    return {
        type: ADD_URLB_RESULT_DATA,
        data: data
    }
}

export function switchDisplayResultTab(data) {
    return {
        type: SWITCH_DISPLAY_RESULT_TAB,
        data: data
    }
}

export function reset() {
    return {
        type: RESET_RESULT_DATA
    }
}

export function setLoading(data) {
    return {
        type: SET_TIME_VOTE_RESULT_LOADING,
        data: data
    }
}

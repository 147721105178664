exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._3jajEPjqsmwqfsjBUCC9_v {\n    position: relative;\n    width: 100%;\n    height: auto;\n    padding: 15px 15px 15px 15px;\n    background: #fafafa;\n    border: #e2e2e2 solid 1px;\n    -webkit-border-radius: 4px;\n    -moz-border-radius: 4px;\n    border-radius: 4px;\n    margin-bottom: 10px;\n    color: #333;\n    font-size: 14px;\n    font-weight: normal;\n    line-height: 1.38;\n}\n\n._3jajEPjqsmwqfsjBUCC9_v ._1FH-XRyEokT4Kuvk9AbKZ9 {\n    width: 13px;\n    opacity: 0.3;\n    position: absolute;\n    right: 5px;\n    top: 5px;\n}\n\n._3jajEPjqsmwqfsjBUCC9_v:after {\n    content: \"\";\n    position: absolute;\n    top: -10px;\n    left: 5px;\n    border-style: solid;\n    border-width: 0 10px 10px;\n    border-color: #fafafa transparent;\n    display: block;\n    width: 0;\n    z-index: 1;\n}\n\n._3jajEPjqsmwqfsjBUCC9_v:before {\n    content: \"\";\n    position: absolute;\n    top: -11px;\n    left: 4px;\n    border-style: solid;\n    border-width: 0 11px 11px;\n    border-color: #e2e2e2 transparent;\n    display: block;\n    width: 0;\n    z-index: 0;\n}\n", ""]);

// exports
exports.locals = {
	"bubble": "_3jajEPjqsmwqfsjBUCC9_v",
	"icon_right": "_1FH-XRyEokT4Kuvk9AbKZ9"
};
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import Main from 'app/views/main/main.jsx';

import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';
import * as userActions from 'app/actions/user/user_action.js';
import * as langBarActions from 'app/actions/langBar/langBar_action.js';

function mapDispatchToProps(dispatch) {
  return {
     controlPanelActions:bindActionCreators(controlPanelActions, dispatch),
     userActions:bindActionCreators(userActions, dispatch),
     langBarActions:bindActionCreators(langBarActions, dispatch)
  };
}


function mapStateToProps(state) {
	return {
        user:state.user,
        language:state.language,
        controlPanel:state.controlPanel
    };
}



export default connect(mapStateToProps,mapDispatchToProps)(Main);

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/keywordList_style.css';
/*item constructor JSON{id,key}*/
export default class KeywordList extends React.Component {
	_itemClick(item,index){
		if(this.props.onItemClick){
			this.props.onItemClick(item,index);
		}
	}
	_renderList(){
		if(this.props.data && this.props.data.length>0){
			var self = this;
			var nodes = this.props.data.map(function(item,index){
				return(
					<li className={styles.search_box_keywords_item+' '+self.props.itemClassName} onClick={()=>self._itemClick(item,index)} key={index} >{item.key}</li>
				);
			});
			return nodes;
		}else {
			return null;
		}
	}
	render() {
		return (
            <div className={styles.search_box_keywords}>
				<ul>
					{this._renderList()}
				</ul>
                {this.props.children}
            </div>
		);
	}
}

function gFormInputList(state={},action){
    var formName = (action.form)?action.form:'yukichan';
    switch (action.type) {
        case 'INIT_INPUT':
        case 'UPDATE_DATA':
        case 'SET_CHECKS':
        case 'VALIDATE_DATA':
        case 'CLEAR_ERROR':
        case 'RESET_DATA':
        case 'FINAL_CHECK':
        case 'CHANGED_DATA':
            var newState = Object.assign({},state);
            newState[formName] = gFormInputReducer(newState[formName],action);
            return newState;
        case 'RESET_ALL':
            var newState={};
            return newState;
        default:
            return state;
    }
}

function gFormInputReducer(state={
    data: {},
    errors: {},
    checks:{},
    finalCheck: false,
    isChanged: false
},action){
    switch (action.type) {
        case 'INIT_INPUT':
            var newState = Object.assign({},state);
            newState.data[action.name] = action.data;
            newState.checks[action.name] = (action.checks&&action.checks.length>0)?action.checks:[];
            newState.errors[action.name] = '';
            newState.finalCheck = false;
            return newState;
        case 'UPDATE_DATA':
            var newState = Object.assign({},state);
            newState.data[action.name] = action.data;
            newState.finalCheck = false;
            newState.isChanged = true;
            return newState;
        case 'CHANGED_DATA':
            var newState = Object.assign({},state);
            newState.isChanged = action.data;
            return newState;
        case 'VALIDATE_DATA':
            var newState = Object.assign({},state);
            newState.errors[action.name] = action.data;
            //kiem tra xem cac o input kia co loi hay khong
            var totalError = Object.keys(newState.errors).map(item => newState.errors[item].length).reduce((a,b) => a+b,0);
            var notDataNull = checkRequired(newState.data,newState.checks);

            if(totalError==0 && notDataNull){
                newState.finalCheck = true;
            }else {
                newState.finalCheck = false;
            }
            return newState;
        case 'CLEAR_ERROR':
            var newState = Object.assign({},state);
            newState.errors[action.name] = '';
            return newState;
        case 'RESET_DATA':
            var newState = Object.assign({},state);
            newState = {};
            return newState;
        case 'FINAL_CHECK':
            var newState = Object.assign({},state);
            newState.finalCheck = action.data;
            return newState;
        default:
            return state;
    }
}

function isRequired(checks){
    for(var i=0;i<checks.length;i++){
        if (checks[i]=='required') return true;
    }
    return false;
}

function checkRequired(dataValues,dataChecks){
    var dataKeys = Object.keys(dataValues);
    for(var i=0;i<dataKeys.length;i++){
        if (isRequired(dataChecks[dataKeys[i]]) && dataValues[dataKeys[i]]==''){
            return false;
        }
    }
    return true;
}

export default gFormInputList;

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/gButton_style.css';

export default class GButton extends React.Component {
    _onClick(){
        if(this.props.onClick){
            this.props.onClick();
        }
    }
    render(){
        var style = (this.props.style)?this.props.style:{};
        switch (this.props.default) {
            case 'primary':
                style.backgroundColor='#02499d';
                style.border='1px solid #02499d';
                break;
            case 'green':
                style.backgroundColor='#57cb5e';
                style.border='1px solid #5ab45a';
                break;
            case 'orange':
                style.backgroundColor='#02499d';
                style.border='1px solid #02499d';
                break;
            case 'red':
                style.backgroundColor= '#c9302c';
                style.border='1px solid #ac2925';
                break;
            case 'blue':
                style.backgroundColor= '#02499d';
                style.border= '1px solid #02499d';
                break;
            case 'gray':
                style.backgroundColor= '#f9f9f9';
                style.border='1px solid #d6d6d6';
                style.color = '#777';
                break;
            case 'block':
                style.backgroundColor= '#f9f9f9';
                style.border='1px solid #e2e2e2';
                style.color = '#c2c2c2';
                style.cursor = 'not-allowed';
                break;
        }
        var className = (this.props.className)? this.props.className : {};
        return(
            <button onClick={this._onClick.bind(this)} className={styles.button_gariim+' '+className} style={style}>
                {this.props.children}
            </button>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/voteResult_style.css';
import {GButton,GModal} from 'app/components/gTools/gTools.jsx';
import UserList from './userList.jsx';
export default class VoteResult extends React.Component {
    _renderItems(){
        var self = this;
        var full = 0;
        for(var i=0;i<this.props.data.length;i++){
            full += this.props.data[i].weight;
        }
        var max = (this.props.data.length>0)?this.props.data[0].weight:0;
        var nodes = this.props.data.map(function(item,index){
            return <VoteItem
                        voteResultActions = {self.props.voteResultActions}
                        hashUrlb = {self.props.hashUrlb}
                        data={item}
                        full={full}
                        max={max}
                        index={index}/>;
        });
        return nodes;
    }
    render() {
        return (
            <div className={styles.main_board}>
                {this._renderItems()}
            </div>
        );
    }
}

class VoteItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isShowUserList:false
        };
    }
    getListName(names,limit){
        var result = '';
        var name = this.props.data.name;
        var len = (name.length>3)?3:name.length;
        if(len==0) return '';
        if(!limit){
            len = name.length;
        }
        for(var i=0;i<len;i++){
            result += name[i]+', ';
        }
        return result.slice(0,result.length-2);
    }
    loadMore(){
        var {id} = this.props.data;
        var {hashUrlb,index} = this.props;
        this.props.voteResultActions.fetchVoteUserList(id,hashUrlb,index);
        this.setState({
            isShowUserList: true
        });
    }
    closeModal(){
        this.setState({
            isShowUserList:false
        });
    }
    _renderNameList(){
        var data = this.props.data.urlbs;
        var title = this.props.data.title;
        return(
            <GModal closeModal={this.closeModal.bind(this)} visible={this.state.isShowUserList}>
                <div className={styles.nameListBoard}>
                    <div className={styles.nameListTop}>
                        <span className={styles.nameListTitle}>{title}</span>
                        <div style={{flex:1}}></div>
                        <span className={styles.userCount}><i className="fa fa-user" aria-hidden="true"></i> {data.length}</span>
                    </div>
                    <UserList data={data}/>
                </div>
            </GModal>
        );
    }
    render(){
        var green = this.props.data.weight;
        var black = this.props.max - this.props.data.weight;
        return (
            <div className={styles.vote_item_board}>
                {this._renderNameList()}
                <p className={styles.item_title} style={{color:'#777'}}>
                    <i className="fa fa-map-marker" aria-hidden="true" style={{fontSize:17}}></i> {this.props.data.title}
                </p>
                <div className={styles.stamina_board} onClick={this.loadMore.bind(this)}>
                    <div className={styles.stamina_green} style={{flex:green}}>
                        <span className={styles.stamina_green_text}>{(green==0)?'':green}</span>
                    </div>
                    <div className={styles.stamina_black} style={{flex:black}}></div>
                </div>
            </div>
        );
    }
}
//{this._renderNameList()}

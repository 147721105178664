import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/radioButton_style.css';

export default class RadioButton extends React.Component {
	_onSiteChanged(){
		if(this.props.onChange){
			this.props.onChange();
		}
	}
	render() {
        var {name,value,title} = this.props;
		return (
            <label className={styles.main}>
                <input
					type="radio"
					name={name}
					value={value}
					checked={this.props.checked === value}
					onChange={this._onSiteChanged.bind(this)}
				/>
                &nbsp;{title}
            </label>
		);
	}
}

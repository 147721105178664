import React from 'react';
import styles from './styles/languageSwitch_style.css';

import Flag from './flag.jsx';

export default class LanguageSwitch extends React.Component {
    componentDidMount() {
        if (this.select) {
            this.select.value=this.props.defaultLang;
        }
    }
    componentDidUpdate() {
        if (this.select) {
            if (this.props.lang) {
                this.select.value=this.props.lang;
            }
        }
    }
    render() {
        var flagName=(this.props.lang)? this.props.lang : this.props.defaultLang;
        return (
            <div className={styles.container}>
                <div className={styles.select_label}>
                    <Flag className={styles.select_flag} flagName={flagName} />
                    <select
                        className={styles.language_selector}
                        onChange={this.props.onChange.bind(this)}
                        ref={(select)=>{this.select=select}}
                    >
                        <option value="en">
                                English
                        </option>
                        <option value="jp">
                                日本語
                        </option>
                        <option selected="selected" value="vn">
                                Tiếng Việt
                        </option>
                    </select>
                </div>
            </div>
        )
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/deadLineTimer_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class DeadLineTimer extends React.Component {
    constructor(props) {
        super(props);
        this.timerInterval = null;
        this.state = {
            showTime:''
        }
    }
    componentDidMount() {
        if(this.props.deadLine) {
            this.timerInterval = setInterval(this.timer.bind(this),1000);
        }
    }
    componentWillUnmount() {
        clearInterval(this.timerInterval);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.deadLine != nextProps.deadLine) {
            clearInterval(this.timerInterval);
            if (nextProps.deadLine) {
                var self = this;
                this.timerInterval = setInterval(this.timer.bind(this),1000);
            }
        }
    }
    timer() {
        this.setState({
            showTime:updateDeadtime(this.props.deadLine)
        });
    }
    render () {
        if (this.props.deadLine && !this.props.isDeadLine) {
            return (
                <div className={styles.deadline_container}>
                    <div className={styles.label}><FormattedMessage id="deadLineTimer.eventPickDeadlineMessage" /></div>
                    <div className={styles.deadline}>{this.state.showTime}</div>
                </div>
            )
        }else {
            return null;
        }
    }
}

function updateDeadtime(data,dayString) {
    var dString = (dayString)? dayString : ' ngày ';
    var deadtime = new Date(data);
    var nowTime = new Date();
    var duration = deadtime.getTime() - nowTime.getTime();
    duration = Math.floor(duration/1000);
    var h = Math.floor(duration/3600);
    if (h<24) {
        var hh= h.toString();
    }
    else {
        var d = Math.floor(h/24);
        var dd = d.toString() + dString;
        var h1 = h%24;
        var hh = dd+h1.toString();
    }
    var m = Math.floor((duration % 3600)/60);
    var mm = (m<10)? ('0'+m.toString()):(m.toString());
    var s = duration-((3600*h)+(60*m));
    var ss = (s<10)?('0'+s.toString()):s.toString();
    var separator = (duration%2==0)? ' ' : ':';
    var showtime = hh+':'+mm+':'+ss;
    return showtime;
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import ResultBox from 'app/components/result/resultBox.jsx';

import * as pickVotePanelActions from 'app/actions/vote/pickVotePanel_action.js';
import * as resultActions from 'app/actions/result/result_action.js';

function mapDispatchToProps(dispatch) {
    return {
        resultActions : bindActionCreators(resultActions,dispatch),
    }
}

function mapStateToProps(state) {
	return ({
        result:state.result,
        language:state.language
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(ResultBox);

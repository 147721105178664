export const SET_ENTRY_HEADER_SHOW_MODE = 'SET_ENTRY_HEADER_SHOW_MODE';
export const RESET_ENTRY_HEADER = 'RESET_ENTRY_HEADER';
import {API_GENERATE_EDIT_URLA} from 'app/js/api.js';
import {setHeaderData} from 'app/actions/entry/entry_action.js';
export function setShowMode(data) {
    return {
        type: SET_ENTRY_HEADER_SHOW_MODE,
        data: data
    }
}

export function reset() {
    return {
        type: RESET_ENTRY_HEADER
    }
}

export function editHeaderDataToServer(hashUrla, title, owner, description) {
    var submitData = {
        hashUrla: hashUrla,
        title: title,
        owner: owner,
        description: description,
    };
    return function(dispatch) {
        return fetch(API_GENERATE_EDIT_URLA, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setShowMode('info'));
                dispatch(setHeaderData(responseJson.content));
            }
        });
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/entryDatePicker_style.css';
import SelectTimePanel from './selectTimePanel.jsx';
import DayPicker, { DateUtils} from 'react-day-picker';
import pickerStyles from 'app/styles/picker_style.css';
import {GModal,GButton,DialogModal,AlertBox} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
import 'moment/locale/ja';
import 'moment/locale/en-ie';

moment.locale('vi');
moment.updateLocale('vi', {months : 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });


export default class EntryDatePicker extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            visibleDeleteTimeModal:false
        };
    }
    componentWillUnmount(){
        this.props.entryDatePickerActions.reset();
    }
    componentDidMount(){
        if(this.props.autoFocus){
            window.scrollTo(0, this.refs.main.offsetTop);
        }
    }
    closeDeleteTimeModal(){
        this.setState({
            visibleDeleteTimeModal:false
        });
    }
    openDeleteTimeModal(selectedIndex){
        this.setState({
            visibleDeleteTimeModal:true,
            selectedIndex:selectedIndex
        });
    }
    getLang(){
        switch (this.props.language.lang) {
            case 'vn':
                return 'vi';
            case 'en':
                return 'en-ie';
            case 'jp':
                return 'ja';
            default:
                return 'vi';
        }
    }
    renderDay(day) {
        const date = day.getDate();
        var selectedDays = this.props.entryDatePicker.selectedDays;
        var selectedIndex = selectedDays.findIndex(unit => DateUtils.isSameDay(unit.day,day));
        if (selectedIndex!=-1) {
            if(selectedDays[selectedIndex].picked){
                return (
                    <div className={styles.day+' '+styles.day_selected}>
                        <div className={styles.day_text}>{date}</div>
                    </div>
                );
            }
        }
        return (
            <div className={styles.day}>
                {date}
            </div>
        );
    }
    handleDayClick(day){
        if(this.props.deadLine) return;
        var today = new Date();
        day = new Date(day);
        day.setHours(23);
        day.setMinutes(59);
        day.setSeconds(59);
        if (today>day) {
            return;
        }
        var selectedDays = this.props.entryDatePicker.selectedDays;
        const selectedIndex = selectedDays.findIndex(selectedDay =>
             DateUtils.isSameDay(selectedDay.day, day)
        );
        if(selectedIndex!=-1){
            var selectedDays = this.props.entryDatePicker.selectedDays;
            if(selectedDays[selectedIndex].picked&&(selectedDays[selectedIndex].time&&selectedDays[selectedIndex].time.length>0)&&!selectedDays[selectedIndex].genTime){
                //showDeleteModal
                this.openDeleteTimeModal(selectedIndex);
            }else {
                this.props.entryDatePickerActions.pickDateEntry(selectedIndex);
            }
        }else {
            if(this.props.entryDatePicker.allowSelect){
                this.props.entryDatePickerActions.addAndPickDateEntry(day);
            }
        }
    }
    pickDateEntry(){
        this.props.entryDatePickerActions.pickDateEntry(this.state.selectedIndex);
        this.closeDeleteTimeModal();
    }
    _renderCalendarSelectedDayList(){
        if(this.props.entryDatePicker.isShowSelectedDay){
            return(
                <SelectTimePanel
                    deadLine={this.props.deadLine}
                    data={this.props.entryDatePicker.selectedDays}
                    entryDatePickerActions={this.props.entryDatePickerActions}/>
            );
        }else {
            return null;
        }
    }
    _renderMode(){
        switch (this.props.entryDatePicker.pickMode) {
            case 'calendar':
                var start = new Date();
                var selectedDays = this.props.entryDatePicker.selectedDays;
                const modifiers = {
                    selectedDays: day => selectedDays.findIndex(unit => DateUtils.isSameDay(unit.day,day))!==-1,
                    toDay: day => moment(day).isSame(start,'day')
                }
                const selectedDayMappedArray = selectedDays.map(unit => unit.day);
                const month = getMaxMonth(selectedDays);
                var deadLine = this.props.deadLine;
                return(
                    <div>
                        <DayPicker
                            classNames={pickerStyles}
                            locale={this.getLang()}
                            month={month}
                            localeUtils={MomentLocaleUtils}
                            selectedDays={selectedDayMappedArray}
                            onDayClick={this.handleDayClick.bind(this)}
                            modifiers={modifiers}
                            modifiersStyles={{
                                selectedDays: {
                                    background: "#fff",
                                    fontWeight: "bold",
                                    color: '#fff'
                                }
                            }}
                            renderDay={this.renderDay.bind(this)}
                            disabledDays={[{ before: start }]}
                        />
                        {this._renderCalendarSelectedDayList()}
                    </div>
                );
            case 'list':
                var deadLine = this.props.deadLine;
                return(
                    <SelectTimePanel
                        deadLine={this.props.deadLine}
                        data={this.props.entryDatePicker.selectedDays}
                        entryDatePickerActions={this.props.entryDatePickerActions}/>
                );
            default:

        }
    }
    render() {
        return (
            <div className={styles.container} ref='main'>
                <DialogModal
                    description={<FormattedMessage id="entryDatePicker.confirmRemoveDateMessage" />}
                    submitModal={this.pickDateEntry.bind(this)}
                    visible={this.state.visibleDeleteTimeModal}
                    closeModal={this.closeDeleteTimeModal.bind(this)}/>
                {this._renderMode()}
                <HintBoard {...this.props}/>
            </div>
        );
    }
}

class HintBoard extends React.Component {
    _renderHint(){
        if(this.props.entryDatePicker.pickMode=='list'){
            //chức năng vote thời gian không có calendar
            if(checkAllGenTime(this.props.entryDatePicker.selectedDays)){
                if(this.props.entryDatePicker.selectedDays.length==1){
                    //trường hợp chỉ có 1 mốc thời gian
                    return (
                        <AlertBox>
                            <p><FormattedMessage id="entryDatePicker.eventTimeMessage" /></p>
                        </AlertBox>
                    );
                }else {
                    //trường hợp thời gian đã ấn định và chỉ vote mà thôi
                    return(
                        <AlertBox>
                            <p><FormattedMessage id="entryDatePicker.uncheckTimeSlotsMessage" /></p>
                        </AlertBox>
                    );
                }
            }else {
                // trường hợp cho phép thêm các khung thời gian
                return(
                    <AlertBox color='blue'>
                        <p><FormattedMessage id="entryDatePicker.instructionMessage" /></p>
                    </AlertBox>
                );
            }
        }else {
            //chức năng vote thời gian có calendar
            if(!this.props.generateMode){
                if(this.props.entryDatePicker.selectedDays.length==0){
                    return(
                        <AlertBox>
                            <p><FormattedMessage id="entryDatePicker.selectAvailableDaysMessage" /></p>
                        </AlertBox>
                    );
                }else {
                    return(
                        <AlertBox>
                            <p><FormattedMessage id="entryDatePicker.defaultTimeRangeMessage" /></p>
                        </AlertBox>
                    );
                }
            }else {
                return null;
            }
        }
    }
    render() {
        return (
            <div className={styles.container}>
                {this._renderHint()}
            </div>
        );
    }
}
function checkAllGenTime(selectedDays){
    for(var i=0;i<selectedDays.length;i++){
        if(!selectedDays[i].genTime) return false;
    }
    return true;
}
function getMaxMonth(selectedDays){
    var startMonth = new Date();
    if (selectedDays.length) {
        startMonth = selectedDays[0].day;
        var endMonth = selectedDays[0].day;
        selectedDays.forEach((item) => {
            if (item.day < startMonth) {
                startMonth = item.day;
            }
            if (item.day > endMonth) {
                endMonth = item.day;
            }
        })
    }
    return (startMonth);
}

export const ADD_PICK_LIST_DATA = 'ADD_PICK_LIST_DATA';
export const PICK_LIST_RESET ='PICK_LIST_RESET';
export const SET_PICK_LIST_DETAILS = 'SET_PICK_LIST_DETAILS';
export const SPLICE_LIST_PICK = 'SPLICE_LIST_PICK';

import {API_ENTRY_FETCH_PICK_LIST,API_ENTRY_FETCH_PICK,API_ENTRY_DELETE} from 'app/js/api.js';

export function fetchListPick(hashUrlb,latestDate) {
    var submitData = {
        hashUrlb: hashUrlb,
        latestDate:latestDate
    };
    return function(dispatch) {
        return fetch(API_ENTRY_FETCH_PICK_LIST, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(addPickListData(responseJson.content));
            }
        });
    }
}

export function fetchPickDetailsFromServer(index,hashUrlb,urlbId) {
    var submitData = {
            hashUrlb: hashUrlb,
            urlbId: urlbId
        };
    return function(dispatch) {
        return fetch(API_ENTRY_FETCH_PICK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setPickDetails(index,responseJson.content));
            }
        });
    }
}

export function  deleteEntryFromServer(hashUrla,urlbId,userId,index) {
    var data = {
              urlbId:urlbId,
              hashUrla:hashUrla,
              userId:userId
          }
    return function(dispatch) {
        return fetch(API_ENTRY_DELETE, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.message){
                //linkToHome(true);
                dispatch(spliceListPick(index));
            }
        });
    }
}

export function addPickListData(data) {
    return {
        type: ADD_PICK_LIST_DATA,
        data: data
    }
}
export function spliceListPick(index) {
    return {
        type: SPLICE_LIST_PICK,
        index: index
    }
}

export function setPickDetails(index,data) {
    return {
        type: SET_PICK_LIST_DETAILS,
        index:index,
        data: data
    }
}


export function reset() {
    return {
        type: PICK_LIST_RESET
    }
}

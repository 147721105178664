import React from 'react';
import ReactDOM from 'react-dom';
import LoginGoogleButton from 'app/components/login/loginGoogleButton.jsx';
import styles from './styles/signupPanel_style.css';
import globalStyles from 'app/styles/global_styles.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class SignupPanel extends React.Component {
	render() {
		return (
			<div className={styles.signup_panel}>
				<center>
					<img src='/images/art/login_2.webp' className={styles.mainImage}/>
					<p className={styles.title}><FormattedMessage id="signupPanel.signInMessage" /></p>
					<p className={styles.description}><b>Pickboz.com</b> <FormattedMessage id="signupPanel.encouragementMessage" /></p>
				</center>
				<LoginGoogleButton align="center" style={{width: 198}}/>
				<a style={{textDecoration:'none'}} href="/generate">
					<div className={globalStyles.add_button} style={{marginTop:10, width: 198}}>
						<span className={globalStyles.add_button_plus}><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
						<span className={globalStyles.add_button_description}><FormattedMessage id="signupPanel.newEventMessage" /></span>
					</div>
				</a>
			</div>
		)
	}
}

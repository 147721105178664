import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GButton, GFormInput } from 'app/components/gTools/gTools.jsx';
import globalStyles from 'app/styles/global_styles.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/feedback_style.css';

export default class Feedback extends React.Component {
	_renderMainForm() {
		if (this.props.feedback.display == 0) {
			return (
				<SubmitFormWithInjectIntl
					gFormInputActions={this.props.gFormInputActions}
					gFormInput={this.props.gFormInput}
					feedbackActions={this.props.feedbackActions}
					language={this.props.language} />
			);
		} else {
			return (
				<FinalForm />
			);
		}
	}
	componentWillUnmount() {
		this.props.feedbackActions.resetFrom();
	}
	render() {
		return (
			<div>
				<div className={globalStyles.main_background}>
					<div className={globalStyles.background_img}></div>
					<div className={globalStyles.main_board} style={{
						paddingTop: '0px'
					}}>
						<div className={globalStyles.white_box}>
							{this._renderMainForm()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class SubmitForm extends React.Component {
	_onClickSubmit() {
		if (this.props.gFormInput.feedbackForm && this.props.gFormInput.feedbackForm.finalCheck) {
			this.props.feedbackActions.submitFeedbackToServer(
				this.props.gFormInput.feedbackForm.data.title,
				this.props.gFormInput.feedbackForm.data.email,
				this.props.gFormInput.feedbackForm.data.content
			);
		}
	}
	render() {
		const {intl} = this.props;
		var submitButtonColor = (this.props.gFormInput.feedbackForm && this.props.gFormInput.feedbackForm.finalCheck) ? 'green' : 'gray';
		return (
			<div>
				<p className={styles.header_title}>
					<FormattedMessage id="feedback.productFeedbackMessage" /> <i className="fa fa-handshake-o" aria-hidden="true" style={{ color: '#02499d' }}></i> </p>
				<p className={styles.intro_title}><FormattedMessage id="feedback.systemDescription" /></p>

				<GFormInput
					type="text"
					name="title"
					autoFocus
					form="feedbackForm"
					checks={['required']}
					placeholder={intl.formatMessage({ id: 'feedback.productFeedbackMessage' })}
					inputActions={this.props.gFormInputActions}
					inputData={this.props.gFormInput}
					default="large"
					lang={this.props.language.lang} />
				<GFormInput
					type="text"
					name="email"
					form="feedbackForm"
					placeholder={intl.formatMessage({ id: 'feedback.emailAddressMessage' })}
					checks={['required', 'email']}
					inputActions={this.props.gFormInputActions}
					inputData={this.props.gFormInput}
					default="large"
					lang={this.props.language.lang} />
				<GFormInput
					type="text"
					format="textarea"
					name="content"
					form="feedbackForm"
					checks={['required']}
					containerStyle={{
						marginBottom: '5'
					}} placeholder={intl.formatMessage({ id: 'feedback.feedbackContentMessage' })} style={{
						fontSize: '14',
						minHeight: '250'
					}} inputActions={this.props.gFormInputActions} inputData={this.props.gFormInput} default="large" />
				<p className={styles.description_title}>(!) <FormattedMessage id="feedback.feedbackResponseMessage" /></p>
				<center className={styles.submit_button_board}>
					<GButton default={submitButtonColor} style={{
						height: '45',
						width: '135'
					}} onClick={this._onClickSubmit.bind(this)}><FormattedMessage id="feedback.sendButtonLabel" /></GButton>
				</center>
			</div>
		);
	}
}

const SubmitFormWithInjectIntl = injectIntl(SubmitForm);

class FinalForm extends React.Component {
	render() {
		return (
			<center>
				<p className={styles.header_title} style={{ fontSize: '32', color: '#02499d' }}><FormattedMessage id="feedback.feedbackSentSuccessMessage" /></p>
				<img src="/images/control/tungHoa.png" />
				<p className={styles.intro_title} style={{ fontSize: '17' }}>
					<FormattedMessage id="feedback.thankYouMessage" />
				</p>
			</center>
		);
	}
}

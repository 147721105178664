import {
    API_USER_LOGIN_CHECK,
    API_USER_LOGIN_DESTROY,
    API_SETTING_SEARCH_UPDATE_CITYID
} from 'app/js/api.js';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_IS_LOGIN = 'SET_USER_IS_LOGIN';
export const DESTROY_USER = 'DESTROY_USER';
export const SET_SEARCH_SETTING_CITY_ID = 'SET_SEARCH_SETTING_CITY_ID';
export const SET_USER_IS_FETCH = 'SET_USER_IS_FETCH';
export const SET_USER_IS_ISLOADING = 'SET_USER_IS_ISLOADING';
import 'whatwg-fetch';
import {setNofificationCount} from 'app/actions/notification/notification_action.js';

export function checkUserLoginFromServer() {
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_USER_LOGIN_CHECK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setUserData(responseJson.content));
                dispatch(setNofificationCount(parseInt(responseJson.content.notifyCount)));
                dispatch(setIsLogin(true));
            }
            dispatch(setIsFetch(true));
            dispatch(setIsLoading(false));
        });
    }
}

export function setUserData(data) {
    return{
        type: SET_USER_DATA,
        data: data
    };
}

export function setIsLogin(data) {
    return{
        type: SET_USER_IS_LOGIN,
        data: data
    };
}

export function setIsFetch(data) {
    return{
        type: SET_USER_IS_FETCH,
        data: data
    };
}

export function setIsLoading(data) {
    return{
        type: SET_USER_IS_ISLOADING,
        data: data
    };
}

export function destroyUser() {
    return{
        type: DESTROY_USER
    };
}
export function logoutUserFromServer() {
    return function(dispatch) {
        return fetch(API_USER_LOGIN_DESTROY, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.message =='ok') {
                dispatch(setIsLogin(false));
                dispatch(destroyUser());
            }
        });
    }
}

export function updateSearchSettingCityIdToServer(cityId) {
    var submitData = {
        cityId:cityId
    };
    return function(dispatch) {
        dispatch(setSearchSettingCityId(cityId));
        return fetch(API_SETTING_SEARCH_UPDATE_CITYID, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            // if (responseJson.message) {
            //
            // }
        });
    }
}

export function setSearchSettingCityId(cityId) {
    return{
        type: SET_SEARCH_SETTING_CITY_ID,
        data:cityId
    };
}

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/bubble_style.css';

export default class Bubble extends React.Component {
	_onClick(){
		if(this.props.onClick){
			this.props.onClick();
		}
	}
	render() {
        var {style} = this.props;
		return (
            <div onClick={this._onClick.bind(this)} className={styles.bubble} style={style}>
                <img src="/images/control/nhay.png" className={styles.icon_right}/>
                {this.props.children}
            </div>
		);
	}
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton,AlertBox} from 'app/components/gTools/gTools.jsx';
import VotePanel from 'app/containers/vote/votePanel_container.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generatePage2_style.css';
import mainStyles from './styles/generateMain_style.css';

export default class GeneratePage2 extends React.Component {
    _onClickNext(){
        // this.props.generatePageActions.setGenerateMainTabValue('time');
        // this.props.generatePageActions.setGenerateMainStepValue(2);
        this.props.next();
        window.scrollTo(0, 0);
    }
    _onClickBack(){
        this.props.generatePageActions.setGenerateMainTabValue('info');
        //this.props.generatePageActions.setGenerateMainStepValue(0);
        window.scrollTo(0, 0);
    }
    _renderNextButton(){
        if(this.props.votePanelData.votes[0].title.length>0){
            return(
                <GButton
                    default='orange'
                    style={{width:'100%',height:47,fontSize:10}}
                    onClick={this._onClickNext.bind(this)}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="generatePage2.continueMessage" />
                </GButton>
            );
        }else {
            return(
                <GButton
                    default='block'
                    style={{width:'100%',height:47,fontSize:10}}>
                    <i className="fa fa-arrow-right" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="generatePage2.continueMessage" />
                </GButton>
            );
        }
    }
    render() {
        return (
            <div className={styles.container}>
                <div className={mainStyles.tabHeader}>
                    <p className={mainStyles.tabHeaderStep}><FormattedMessage id="generatePage2.step2Message" /></p>
                    <p className={mainStyles.tabHeaderDescription}><FormattedMessage id="generatePage2.selectLocationMessage" /></p>
                </div>
                <VotePanel />
                <AlertBox color='blue'>
                    <FormattedMessage id="generatePage2.locationGuideMessage" />
                </AlertBox>
                <div style={{flex:1}}></div>
                <div className={mainStyles.submitBox}>
                    <div style={{display:'flex'}}>
                        <GButton
                            default='gray'
                            style={{width:'100%',height:47,fontSize:10,marginRight:10}}
                            onClick={this._onClickBack.bind(this)}>
                            <i className="fa fa-arrow-left" aria-hidden="true" style={{display:'block',fontSize:24}}></i> <FormattedMessage id="generatePage2.goBackMessage" />
                        </GButton>
                        <div style={{flex:1}}></div>
                        {this._renderNextButton()}
                    </div>
                </div>
            </div>
        );
    }
}

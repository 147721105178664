export const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA';
export const SET_STICKER_DATA ='SET_STICKER_DATA';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const SET_STICKER_INDEX = 'SET_STICKER_INDEX';
export const SET_EXISTED_STICKER_DATA = 'SET_EXISTED_STICKER_DATA';
import {switchStickerBoxDisplay} from 'app/actions/comment/commentBox_action.js';

import {
    API_COLLECTION_FETCH,
    API_STICKER_FETCH} from 'app/js/api.js';

export function fetchCollectionFromServer() {
    return function(dispatch) {
        return fetch(API_COLLECTION_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => response.json()).then((responseJson) => {
            var content = responseJson.content;
            if (content) {
                dispatch(setCollectionData(content));
                dispatch(selectCollection(0,content[0].id));
            }
        });
    }
}

export function setCollectionData(data) {
    return{
        type: SET_COLLECTION_DATA,
    	data:data
    };
}
export function setSelectedIndex(index) {
    return{
        type: SET_SELECTED_INDEX,
    	index:index
    };
}


export function fetchStickersFromServer(collectionId) {
    var submitData = {
        collectionId:collectionId
    };
    return function(dispatch) {
        return fetch(API_STICKER_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setStickerData(responseJson.content));
            }
        });
    }
}
export function setStickerData(data) {
    return{
        type: SET_STICKER_DATA,
    	data:data
    };
}
export function selectCollection(index,collectionId) {
    return function(dispatch) {
        dispatch(setSelectedIndex(index));
        dispatch(fetchStickersFromServer(collectionId));
    }
}

export function selectSticker(index,parentId) {
    return function(dispatch) {
        dispatch(setStickerIndex(index,parentId));
        dispatch(switchStickerBoxDisplay());
    }
}
export function setStickerIndex(index,parentId) {
    return{
        type: SET_STICKER_INDEX,
    	index:index,
        parentId:parentId
    };
}

export function setExistedStickerData(data,parentId) {
    return {
        type: SET_EXISTED_STICKER_DATA,
        data: data,
        parentId: parentId
    }
}

export function cancelSticker(parentId) {
    return function(dispatch) {
        dispatch(setStickerIndex(-1,parentId));
    }
}

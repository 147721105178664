//date to hh:mm format
export function tohhmm(value) {
    value = parseInt(value);
    var h = Math.floor(value/3600);
    var m = (value % 3600)/60;
    var mm = (m<10)? ('0'+m.toString()):(m.toString());
    var hh = h.toString();
    return hh+':'+mm;
}

//check if an selectedDays array is deadline (can be picked) or not
export function checkDeadline(deadline,selectedDays,flag) {
    var deadline = (deadline)?new Date(deadline):null;
    var message = 'picked';
    if (deadline && deadline <= new Date()) {
        message = "deadline";
    }
    if (selectedDays.length) {
        var today = new Date();
        var isOver = selectedDays.findIndex(function(item) {
            var itemDay = new Date(item.day);
            itemDay.setHours(23,59);
            return (itemDay >= today)
        });
        if (isOver==-1) {
            message = "deadline";
        }
    }
    if(flag==2&&message!='deadline'){
        message = 'bookmark';
    }else if(!flag) {
        message = 'created';
    }
    return message;
}

export function checkDeadlineUrla(deadline,selectedDays) {
    var deadline = (deadline)?new Date(deadline):null;
    var message = 'created';
    if (deadline && deadline <= new Date()) {
        message = "deadline";
    }
    if (selectedDays.length) {
        var today = new Date();
        var isOver = selectedDays.findIndex(function(item) {
            var itemDay = new Date(item.day);
            itemDay.setHours(23,59);
            return (itemDay >= today)
        });
        if (isOver==-1) {
            message = "deadline";
        }
    }
    return message;
}

//return index of the smallest day in a selectedDays array
export function minDate(selectedDays) {
    if (selectedDays && selectedDays.length) {
        var output = new Date(selectedDays[0].day);
        for (var i = 1; i < selectedDays.length; i++) {
            var day = new Date(selectedDays[i].day);
            if (day<output) {
                output = day;
            }
        }
        return output;
    }
    else return null;
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._29fU25jLAhE7_c6Y7B5E28 {\n    left: -10px;\n    position: relative;\n}\n\n._1IjS0upn0JFIvzJW8UBgMv {\n    position: relative;\n    font: normal normal normal 14px/1 FontAwesome;\n    font-size: inherit;\n    text-rendering: auto;\n    border: solid 1px #ccc;\n    border-radius: 2px;\n}\n\n@media only screen and (max-width: 768px) {\n    ._1IjS0upn0JFIvzJW8UBgMv {\n        transform: scale(.8);\n        right: -13px;\n    }\n}\n._1IjS0upn0JFIvzJW8UBgMv:after {\n    content: \"\\F0D7\";\n    padding: 3px 0 2px;\n    position: absolute;\n    pointer-events: none;\n    color: #ff7f50;\n    right: 5px;\n    top: 4px;\n}\n\n._1HLcYX8k1HL-MCoOT6UHod {\n    height: 22px;\n    left: 4px;\n    top: 4px;\n    position: absolute;\n    z-index: -1;\n    border: solid 0.5px #ccc;\n    border-radius: 4px;\n}\n\n.FnV2SY2QntGduNbqdaQHv {\n    font-family: 'Roboto', sans-serif;\n    border: none;\n    -webkit-appearance: none;\n    -moz-appearance:none;\n    background-color:transparent;\n    color: #ff7f50;\n    font-size: 19px;\n    line-height: 21px;\n    font-weight: 500;\n    outline: none;\n    cursor: pointer;\n    padding: 4px 29px 4px 40px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_29fU25jLAhE7_c6Y7B5E28",
	"select_label": "_1IjS0upn0JFIvzJW8UBgMv",
	"select_flag": "_1HLcYX8k1HL-MCoOT6UHod",
	"language_selector": "FnV2SY2QntGduNbqdaQHv"
};
import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/setupProgressBar_style.css';


export default class SetupProgressBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step:0
        };
    }
    next(){
        var selectedIndex = this.props.data.findIndex(unit => this.props.selectedValue==unit.value);
        if(selectedIndex!=-1){
            var finalChecks = calFinalChecks(this.props.finalChecks);
            this._onClickItem(this.props.data[selectedIndex+1].value,selectedIndex+1,finalChecks[selectedIndex])
        }
    }
    _onClickItem(value,step,isChecked){
        if (this.props.onSelect && isChecked) {
            if(this.state.step<step){
                this.setState({
                    step:step
                })
            }
            this.props.onSelect(value,isChecked);
        }
    }
    _renderMain() {
        var self = this;
        if (this.props.data) {
            var listNodes = this.props.data.map(function (item, index) {
                return (
                    <IconText
                        onClick={self._onClickItem.bind(self)}
                        selectedValue={self.props.selectedValue}
                        finalChecks={calFinalChecks(self.props.finalChecks)}
                        data={item}
                        step={self.state.step}
                        index={index}/>
                );
            });
            return (listNodes);
        }else {
            return null;
        }
    }
    render() {
        return (
            <div className={styles.tab_menu_board}>
                {this._renderMain()}
            </div>
        );
    }
}

function calFinalChecks(finalChecks){
    if(finalChecks.length==0) return [];
    var result =[];
    result.push(finalChecks[0]);
    for(var i=1;i<finalChecks.length;i++){
        result.push(result[i-1]&&finalChecks[i]);
    }
    return result;
}
export class IconText extends React.Component{
    _onClick(){
        if(this.props.onClick){
            var index = this.props.index;
            var step = index;
            var isChecked = (index==0)?true:this.props.finalChecks[index-1];
            this.props.onClick(this.props.data.value,step,isChecked);
        }
    }
    render() {
        var {title,icon,value}= this.props.data;
        var {index,finalChecks,step,selectedValue} = this.props;
        icon= (icon)?icon:'fa fa-sort-asc ';
        var className = '';
        var allowSelect = (index==0)?true:finalChecks[index-1];
        if(allowSelect&&(index<=this.props.step+1)) className = className +' '+styles.allowSelect;

        if(finalChecks[index] && (index<=this.props.step)) {
            className = className +' '+styles.item_checked;
            icon = 'fa fa-check '
        }

        if(value == selectedValue){
            className = className +' '+styles.main_menu_item_selected;
            icon=this.props.data.icon;
        }
        return (
            <div className={styles.main_menu_item+' '+className} onClick={this._onClick.bind(this)}>
                <i className={icon+' '+styles.tab_menu_icon} aria-hidden="true"></i>
                <span >
                    {title}
                </span>
            </div>
        );
    }
}

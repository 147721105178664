import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import CommentList from 'app/components/comment/commentList.jsx';

import * as commentListActions from 'app/actions/comment/commentList_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';
import * as collectionActions from 'app/actions/collection/collection_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 commentListActions : bindActionCreators(commentListActions,dispatch),
     controlPanelActions : bindActionCreators(controlPanelActions,dispatch),
     collectionActions : bindActionCreators(collectionActions,dispatch),
  };
}

function mapStateToProps(state) {
	return {
        commentList:state.commentList,
        collectionBox:state.collectionBox,
        user:state.user,
        language:state.language
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(CommentList);

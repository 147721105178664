exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1HGOgy95Bnf4ZO8vhjSDtc{\n    display: flex;\n    flex-direction: row;\n    background-color: #fff;\n    height: 45px\n}\n\n.NvIaRZYnlJElQ6lRQtVTY {\n    padding: 7px 5px 7px 5px;\n    cursor: pointer;\n    border-right: solid 1px #e2e2e2;\n    flex: 1;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    position: relative;\n}\n\n.NvIaRZYnlJElQ6lRQtVTY img {\n    width: 20px;\n    height: 20px;\n    margin-right: 5px;\n    opacity: 0.4;\n}\n\n.NvIaRZYnlJElQ6lRQtVTY span {\n    font-size: 13px;\n    font-weight: bold;\n    color: #919191;\n}\n\n\n.NvIaRZYnlJElQ6lRQtVTY:hover {\n    background-color: #02499d;\n}\n.NvIaRZYnlJElQ6lRQtVTY:hover span {\n    color: #fff;\n}\n.NvIaRZYnlJElQ6lRQtVTY:hover i {\n    color: #fff;\n}\n.NvIaRZYnlJElQ6lRQtVTY:hover img {\n    opacity :1;\n    filter : invert(100%);\n}\n\n._2g24k7rxRNdh24JZu7giRW  span{\n    color: #02499d;\n}\n._2g24k7rxRNdh24JZu7giRW  i{\n    color: #02499d;\n}\n._2g24k7rxRNdh24JZu7giRW  img{\n    opacity :1;\n    filter : invert(100%);\n}\n._2A6PIFJE7a3x1myvWt94Fm{\n    font-size: 17px;\n    color: #919191;\n    margin-right: 5px;\n}\n._2g24k7rxRNdh24JZu7giRW::after{\n    content: '';\n    display: block;\n    position: absolute;\n    bottom: -6px;\n    width: 12px;\n    height: 12px;\n    background: #FFFFFF;\n    border-left:1px solid #e2e2e2;\n    border-bottom:1px solid #e2e2e2;\n    -moz-transform:rotate(-225deg);\n    -webkit-transform:rotate(-225deg);\n    background-color:#e0e0e0;\n    left: 50%;\n}\n", ""]);

// exports
exports.locals = {
	"tab_menu_board": "_1HGOgy95Bnf4ZO8vhjSDtc",
	"main_menu_item": "NvIaRZYnlJElQ6lRQtVTY",
	"main_menu_item_selected": "_2g24k7rxRNdh24JZu7giRW",
	"tab_menu_icon": "_2A6PIFJE7a3x1myvWt94Fm"
};
import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton,Dropdown,DialogModal} from 'app/components/gTools/gTools.jsx';
import SocialBar from 'app/components/socialBar/socialBar.jsx';
import CopyToClipboard from 'react-copy-to-clipboard';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/linkBox_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class LinkBox extends React.Component {
    _onClickOpenShareFrom(){
        this.refs.shareForm.show();
    }

    render() {
        var url = this.props.url;
        var style = {textAlign:'center'};
        switch (this.props.default) {
            case 'red':
                style.backgroundColor = '#ffebeb';
                style.color = '#c36666';
                break;
            default:
                style.backgroundColor = '#e6f4ff';
                style.color = '#02499d';
        }
        return (
            <div className={styles.container}>
                <div className={styles.input_share}
                     style={style}>
                     <a style={{color:style.color,textDecoration:'none'}}href={url} target="_blank">{url}</a>
                </div>
                <CopyButton　urlb={url}/>
            </div>
        );
    }
}

class CopyButton extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            copied:false
        };
    }
    _onCopied(){
        this.setState({
            copied: true
        });
    }
    render() {
        if(!this.state.copied){
            return (
                <CopyToClipboard text={this.props.urlb}
                    onCopy={this._onCopied.bind(this)}>
                    <span className={styles.copyButton}>
                        <i className={'fa fa-copy'} aria-hidden="true"></i> <FormattedMessage id="linkBox.copyLinkMessage" />
                    </span>
                </CopyToClipboard>
            );
        }else {
            return (
                <span className={styles.copyButton+' '+styles.copied}>
                    <i className={'fa fa-check'} aria-hidden="true"></i> <FormattedMessage id="linkBox.linkCopiedMessage" />
                </span>
            );
        }
    }
}

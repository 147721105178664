export const VOTE_ITEM_ADD = 'VOTE_ITEM_ADD';
export const VOTE_ITEM_DELETE = 'VOTE_PANEL_DELETE';
export const VOTE_ITEM_UPDATE = 'VOTE_ITEM_UPDATE';
export const VOTE_ITEM_RESET = 'VOTE_ITEM_RESET';
export const VOTE_ITEM_UPDATE_DATA = 'VOTE_ITEM_UPDATE_DATA';
export const VOTE_ITEM_SEARCH = 'VOTE_ITEM_SEARCH';
export const VOTE_SET_DATA = 'VOTE_SET_DATA';
export const PICKER_ADD_VOTE_TO_SERVER = 'PICKER_ADD_VOTE_TO_SERVER';
export const SWITCH_IS_EDIT = 'SWITCH_IS_EDIT';

export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
export const RESET_SEARCH_DATA = 'RESET_SEARCH_DATA';
export const SELECT_SEARCH_DATA = 'SELECT_SEARCH_DATA';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const RESET_VOTE_DATA = 'RESET_VOTE_DATA';
export const SET_VOTES_DATA = 'SET_VOTES_DATA';
export const TRIGGER_EDIT = 'TRIGGER_EDIT';
export const SET_VOTE_ITEM_LOADING = 'SET_VOTE_ITEM_LOADING';

import { API_PARSE_LINK, API_LOCATION_SEARCH, API_GENERATE_EDIT_VOTES, API_GENERATE_PICKER_ADD_VOTES, API_VOTE_FETCH, API_VOTE_DELETE } from 'app/js/api.js';

import { updateVoteData, switchAddVoteMode } from 'app/actions/vote/pickVotePanel_action.js';

export function addItem(title, editMode) {
    var data = {
        title: title
    };
    return {
        type: VOTE_ITEM_ADD,
        data: data,
        editMode: editMode
    };
}

export function deleteItem(id, editMode) {
    return {
        type: VOTE_ITEM_DELETE,
        id: id,
        editMode: editMode
    };
}

export function resetItem() {
    return {
        type: VOTE_ITEM_RESET
    };
}


export function updateItem(data, id, editMode) {
    return {
        type: VOTE_ITEM_UPDATE,
        data: data,
        id: id,
        editMode: editMode
    };
}

export function parseLinkItem(link, id) {
    var submitData = {
        url: link
    };
    return function(dispatch) {
        dispatch(setVoteItemLoading(true, id));
        return fetch(API_PARSE_LINK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                var content = responseJson.content;
                dispatch(setVoteItemLoading(false, id));
                dispatch(updateItemData(content, id, true));
            }
        });
    }
}



export function switchIsEdit(data) {
    return {
        type: SWITCH_IS_EDIT,
        data: data
    };
}


export function updateItemData(data, id, isParse) {
    return {
        type: VOTE_ITEM_UPDATE_DATA,
        data: data,
        id: id,
        isParse: isParse
    };
}

export function voteSetData(data) {
    return {
        type: VOTE_SET_DATA,
        data: data
    };
}


export function searchLocation(keyword, cityId, id) {
    var submitData = {
        keyword: keyword,
        cityId: cityId
    };
    return function(dispatch) {
        if (keyword.trim() == '') {
            dispatch(resetSearchData(id));
            return;
        }
        dispatch(setVoteItemLoading(true, id));
        return fetch(API_LOCATION_SEARCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setVoteItemLoading(false, id));
                var content = responseJson.content;
                dispatch(updateSearchData(content, id));
            }
        });
    }
}

export function updateSearchData(data, id) {
    return {
        type: UPDATE_SEARCH_DATA,
        data: data,
        id: id
    };
}

export function resetSearchData(id) {
    return {
        type: RESET_SEARCH_DATA,
        id: id
    };
}

export function selectSearchData(index, id) {
    return {
        type: SELECT_SEARCH_DATA,
        index: index,
        id: id
    };
}

export function toggleMap(index, data) {
    return {
        type: TOGGLE_MAP,
        index: index,
        data: data,
    }
}

export function triggerEdit(data) {
    return {
        type: TRIGGER_EDIT,
        data: data
    };
}
export function setVoteItemLoading(data, id) {
    return {
        type: SET_VOTE_ITEM_LOADING,
        data: data,
        id: id
    };
}

export function saveLocationsToServer(votes, deleteList, hashUrla) {
    var newVotes = [];
    for (var i = 0; i < votes.length; i++) {
        if (votes[i].title.trim() != '' && votes[i].isNew) {
            newVotes.push({
                locationId: votes[i].locationId,
                title: votes[i].title,
                content: votes[i].content
            });
        }
    }
    var submitData = {
        votes: newVotes,
        deleteList: deleteList,
        hashUrla: hashUrla
    };
    return function(dispatch) {
        return fetch(API_GENERATE_EDIT_VOTES, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(updateVoteData(responseJson.content));
                dispatch(resetItem());
                dispatch(switchIsEdit(0));
            }
        });
    }
}

export function pickerAddVoteToServer(votes, hashUrlb) {
    var newVotes = [];
    for (var i = 0; i < votes.length; i++) {
        if (votes[i].title.trim() != '') {
            newVotes.push({
                locationId: votes[i].locationId,
                title: votes[i].title,
                content: votes[i].content
            });
        }
    }
    var submitData = {
        votes: newVotes,
        hashUrlb: hashUrlb
    };
    return function(dispatch) {
        return fetch(API_GENERATE_PICKER_ADD_VOTES, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(updateVoteData(responseJson.content));
                dispatch(resetItem());
                dispatch(switchAddVoteMode(0));
            }
        });
    }
}

export function resetVoteData() {
    return {
        type: RESET_VOTE_DATA
    }
}

export function fetchVotesFromServer(hashUrlb) {
    var submitData = {
        hashUrlb: hashUrlb
    };
    return function(dispatch) {
        return fetch(API_VOTE_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(voteSetData(responseJson.content));
            }
        });
    }
}

export function deleteItemToServer(hashUrlb, voteId) {
    var submitData = {
        hashUrlb: hashUrlb,
        voteId: voteId
    };
    return function(dispatch) {
        return fetch(API_VOTE_DELETE, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.message) {
                dispatch(deleteItem(voteId, true));
            }
        });
    }
}

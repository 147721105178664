exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Scj9e2QUdrsZZDQYXUXuM{\n    z-index: 1;\n    min-width: 0;\n    width: 100%;\n    height: 62px;\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    padding: 10px 10px;\n}\n\n._3l_f9tLV2DDabVwWXVYHVI {\n    position: relative;\n    z-index: 6;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 10px 0px 10px;\n}\n\n._2MGbhnT6MfPTzDs2Eq3mRE {\n    width: 165px;\n}\n@media only screen and (max-width: 768px) {\n    ._2MGbhnT6MfPTzDs2Eq3mRE {\n        width: 125px;\n    }\n}\n._2x6H9L8eUX7a51yTYKvBk7 {\n    height: 80px;\n}\n\n._18P-Ei8Khj9vJD9QQrv4b9{\n    font-size: 28px;\n    margin-right: 7px;\n    cursor: pointer;\n}\n._18P-Ei8Khj9vJD9QQrv4b9:hover{\n    color: #666;\n}\n\n\n.drViUAVdafjtHSLzO8sXl{\n    background: rgba(255, 255, 255, 0.84);\n    border-bottom: 1px solid rgba(0,0,0,0.15);\n    /*position: fixed;*/\n}\n.drViUAVdafjtHSLzO8sXl ._3l_f9tLV2DDabVwWXVYHVI {\n    background-color: #fff;\n    opacity: 0.9;\n}\n.drViUAVdafjtHSLzO8sXl ._18P-Ei8Khj9vJD9QQrv4b9{\n    color: #999;\n}\n\n._3FWn_pZCYdYH0tpgNFcgrf{\n    /*border-bottom: 1px solid #fff;*/\n    position: absolute;\n}\n._3FWn_pZCYdYH0tpgNFcgrf ._3l_f9tLV2DDabVwWXVYHVI {\n}\n._3FWn_pZCYdYH0tpgNFcgrf ._18P-Ei8Khj9vJD9QQrv4b9{\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"header_board": "Scj9e2QUdrsZZDQYXUXuM",
	"search_box_container": "_3l_f9tLV2DDabVwWXVYHVI",
	"main_logo": "_2MGbhnT6MfPTzDs2Eq3mRE",
	"main_logo_mobile": "_2x6H9L8eUX7a51yTYKvBk7",
	"show_menu_button": "_18P-Ei8Khj9vJD9QQrv4b9",
	"gray": "drViUAVdafjtHSLzO8sXl",
	"white": "_3FWn_pZCYdYH0tpgNFcgrf"
};
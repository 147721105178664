import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/dropButton_style.css';

export var DropdownStyles = styles;
export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = 'Dropdown';
        this.onWindowClick =  this._onWindowClick.bind(this);
        this.state = {
            active: false
        }
    }

    componentDidMount() {
        var width = window.innerWidth;
        if(width>768){
            window.addEventListener('click', this.onWindowClick,false);
        }else {
            window.addEventListener( 'touchend', this.onWindowClick,false);
        }
    }

    componentWillUnmount() {
        var width = window.innerWidth;
        if(width>768){
            window.removeEventListener('click', this.onWindowClick,false);
        }else {
            window.removeEventListener( 'touchend', this.onWindowClick,false);
        }
    }
    _onClickMe(){
        if(this.state.active && !this.props.keepMe){
            this.hide();
        }else{
            if(this.props.onOpen){
                //call open event
                this.props.onOpen();
            }
        }
    }

    render() {
        const {children, className} = this.props;
        // create component classes
        const active = this.isActive();
        var dropdown_classes = (this.state.active)
            ? styles.dropdown_active
            : styles.nothing;
        dropdown_classes += ' '+ styles.dropdown +' '+ className;
        // stick callback on trigger element

        return (
            <span onClick={this._onClickMe.bind(this)} style={this.props.style} className={dropdown_classes}>
                <a onClick={this._onToggleClick.bind(this)} style={{textDecoration:'none'}}>{this.props.dropdownTrigger}</a>
                {this.props.children}
            </span>
        );
    }

    isActive() {
        return (typeof this.props.active === 'boolean')
            ? this.props.active
            : this.state.active;
    }

    hide() {
        this.setState({active: false});
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    show() {
        this.setState({active: true});
        if (this.props.onShow) {
            this.props.onShow();
        }
    }

    _onWindowClick(event) {
        const dropdown_element = findDOMNode(this);
        if (event.target !== dropdown_element && !dropdown_element.contains(event.target) && this.isActive()) {
            this.hide();
        }
    }

    _onToggleClick() {
        if (this.isActive()) {
            this.hide();
        } else {
            this.show();
        }
    }
}

export class DropdownContent extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = 'DropdownContent';
    }
    render() {
        return (
            <div className={styles.dropdown_content+' '+this.props.className} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}

export class DropdownTrigger extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = 'DropdownTrigger';
    }
    render() {
        return (
            <a className={styles.dropdown_trigger+' '+this.props.className}>
                {this.props.children}
            </a>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._15vwWjmeIya0nPfv73_gDA{\n\n}\n._2y_jdttSLZilEkn_w8o33L{\n    background-color: #f6f7f9;\n    border-bottom: 1px solid #e2e2e2;\n    display: flex;\n}\n._1fi_TfxeFe_ItGh_ItdDMv{\n    display: flex;\n    height: 320px;\n    border-bottom: 1px solid #e2e2e2;\n    overflow-y: scroll;\n    flex-direction: column;\n}\n\n._2tCSGR2hmz6nfz6aGUv5H5{\n    width: 55px;\n    height: 55px;\n    padding: 3px;\n}\n.pkAX5LiryvGhXP9SOEhG9{\n    background-color: #c0ddff;\n    border-right: 1px solid #e2e2e2;\n}\n._2tCSGR2hmz6nfz6aGUv5H5:hover{\n    background-color: #e2e2e2;\n    cursor: pointer;\n}\n._2tCSGR2hmz6nfz6aGUv5H5 img{\n    height: 100%;\n    width: 100%;\n}\n\n\n._3I_oPyEyNcCfxngqASkq5M{\n    display: flex;\n    flex-wrap: wrap;\n}\n._27voQoVeXlGis6QUwx5BzC{\n    width: 110px;\n    height: 110px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n}\n._27voQoVeXlGis6QUwx5BzC:hover .deSHkejagwpm2iJox2wwc{\n    width: 95%;\n}\n.deSHkejagwpm2iJox2wwc{\n    width: 75%;\n}\n._3TDIAx3jSWynQf4EOFJgrr{\n\n}\n", ""]);

// exports
exports.locals = {
	"sticker_box_board": "_15vwWjmeIya0nPfv73_gDA",
	"header": "_2y_jdttSLZilEkn_w8o33L",
	"body_board": "_1fi_TfxeFe_ItGh_ItdDMv",
	"collection_item_board": "_2tCSGR2hmz6nfz6aGUv5H5",
	"collection_item_selected": "pkAX5LiryvGhXP9SOEhG9",
	"sticker_list_board": "_3I_oPyEyNcCfxngqASkq5M",
	"sticker_item_board": "_27voQoVeXlGis6QUwx5BzC",
	"sticker_image": "deSHkejagwpm2iJox2wwc",
	"sticker_control_board": "_3TDIAx3jSWynQf4EOFJgrr"
};
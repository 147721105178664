import {
    combineReducers
} from 'redux';

import {
    SET_URLA_DATA,
    SET_STEP,
    TOGGLE_DEADTIME,
    UPDATE_DEADTIME,
    ENTRY_PICK_VOTE,
    SET_SOCIAL_BAR_IS_LOADING,
    SET_ENTRY_TAB_VALUE,
    SET_ENTRY_HASH_URLA,
    SET_ENTRY_HEADER_DATA,
    SET_ENTRY_EDITED,
    SET_ENTRY_IS_LOADING,
    SET_ENTRY_URLB_DATA,
    SET_DISPLAY_ENTRY_GUIDE,
    SET_DISPLAY_LIST_PICK
} from 'app/actions/entry/entry_action.js';

function entryData(state = {
    urlaData:{},
    scheduledDays:[]
},action) {
    switch (action.type) {
        //urlbData
        case SET_ENTRY_HASH_URLA:
            var newState = Object.assign({},state);
            newState.urlaData.hashUrla = action.data;
            return newState;
        case SET_URLA_DATA:
            var newState = Object.assign({},state);
            newState.urlaData = action.data;
            if (action.data.schedule) {
                newState.scheduledDays = action.data.schedule.selectedDays.map(function(item) {
                    return new Date(item.day)
                });
            }
            return newState;
        case SET_ENTRY_HEADER_DATA:
            var newState = Object.assign({},state);
            var urlaData = Object.assign({},newState.urlaData);
            urlaData.title = action.data.title;
            urlaData.owner = action.data.owner;
            urlaData.description = action.data.description;
            newState.urlaData = urlaData;
            return newState;
        default:
            return state;
    }
}

function pageController(state= {
    step:0,
    isDeadtime: 0,
    deadtime:null,
    socialBarIsLoading:1,
    tabValue:'pick',
    isEdited:false,
    isLoading:false,
    isShowguide:false,
    isShowListPick:false
},action) {
    switch (action.type) {
        case SET_STEP:
            var newState = Object.assign({},state);
            newState.step = action.index;
            return newState;
        case SET_ENTRY_TAB_VALUE:
            var newState = Object.assign({},state);
            newState.tabValue = action.data;
            return newState;
        case TOGGLE_DEADTIME:
            var newState = Object.assign({},state);
            newState.isDeadtime = !newState.isDeadtime;
            return newState;
        case UPDATE_DEADTIME:
            var newState = Object.assign({},state);
            newState.deadtime = action.data;
            return newState;
        case SET_SOCIAL_BAR_IS_LOADING:
            var newState = Object.assign({},state);
            newState.socialBarIsLoading = action.data;
            return newState;
        case SET_ENTRY_EDITED:
            var newState = Object.assign({},state);
            newState.isEdited = action.data;
            return newState;
        case SET_ENTRY_IS_LOADING:
            var newState = Object.assign({},state);
            newState.isLoading = action.data;
            return newState;
        case SET_DISPLAY_ENTRY_GUIDE:
            var newState = Object.assign({},state);
            newState.isShowguide = action.data;
            return newState;
        case SET_DISPLAY_LIST_PICK:
            var newState = Object.assign({},state);
            newState.isShowListPick = action.data;
            return newState;
        default:
            return state;
    }
}


function urlbData(state = {},action) {
    switch (action.type) {
        case SET_ENTRY_URLB_DATA:
            var newState = Object.assign({},state);
            newState = action.data
            return newState;
        default:
            return state;
    }
}

const entryReducer = combineReducers({
    entryData:entryData,
    pageController:pageController,
    urlbData:urlbData
});

export default entryReducer;

import {
    combineReducers
} from 'redux';

import {
    ADD_PICK_LIST_DATA,
    PICK_LIST_RESET,
    SET_PICK_LIST_DETAILS,
    SPLICE_LIST_PICK
} from 'app/actions/entry/listPick_action.js';

const pickDataDefault = {
    list:[],
    selectedData:null,
    selectedIndex:null
}
function resultData(state=pickDataDefault,action) {
    switch (action.type) {
        case ADD_PICK_LIST_DATA:
            var newState = Object.assign({},state);
            var newList = Object.assign([],state.list);
            if(action.latestDate){
                newList = newList.concat(action.data);
            }else {
                newList = action.data;
                newState.selectedIndex = null;
                newState.selectedData = null;
            }
            newState.list = newList;
            return newState;
        case SET_PICK_LIST_DETAILS:
            var newState = Object.assign({},state);
            newState.selectedIndex = action.index;
            newState.selectedData = action.data;
            return newState;
        case PICK_LIST_RESET:
            var newState = Object.assign({},state);
            newState = pickDataDefault;
            return newState;
        case SPLICE_LIST_PICK:
            var newState = Object.assign({},state);
            var list = Object.assign([],state.list);
            list.splice(action.index,1);
            newState.list = list;
            return newState;
        default:
            return state;
    }
}

export default resultData;

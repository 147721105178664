import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryDatePicker from 'app/components/entry/entryDatePicker.jsx';

import * as entryDatePickerActions from 'app/actions/entry/entryDatePicker_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 entryDatePickerActions : bindActionCreators(entryDatePickerActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        entryDatePicker:state.entryDatePicker,
        language:state.language
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(EntryDatePicker);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MV89aVASVNsZq_WH4iC_D {\n    height: 55px;\n    border-radius: 28px;\n    margin-right: 10px;\n}\n\n._2J0OVCPA3SV9oVerl5WdHz {\n    font-size: 13px;\n    color: #999;\n}\n\n.cyylSY4n_E8vF0sFvkXcr {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    filter: blur(5px);\n}\n._1Ko0GBTywSouymp8nvUXd-{\n    font-size: 19px;\n    color: #999;\n}\n._1Ko0GBTywSouymp8nvUXd-:hover{\n    color: #777;\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"avatar_img": "MV89aVASVNsZq_WH4iC_D",
	"stats_text": "_2J0OVCPA3SV9oVerl5WdHz",
	"cover_img": "cyylSY4n_E8vF0sFvkXcr",
	"hideButton": "_1Ko0GBTywSouymp8nvUXd-"
};
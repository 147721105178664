import {API_GENERATE_RESULT_FETCH} from 'app/js/api.js';

export function fetchResultFromServer(hashUrlb, callback) {
    var submitData = {
            hashUrlb: hashUrlb
        };
    fetch(API_GENERATE_RESULT_FETCH, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData)
    }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.content) {
            callback(responseJson.content);
        }else {
            callback(null);
        }
    });
}

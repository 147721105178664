exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*GARIIM BUTTON*/\n._2zNHpjA7U377hKAFRLEk6F{\n    color: #fff;\n    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.05));\n    box-shadow: inset 0 1px 0 rgba(255,255,255,0.15);\n    font-size: 12px;\n    border: 1px solid;\n    border-radius: 2px;\n    font-weight: 700;\n    height: 34px;\n    min-width: 73px;\n    outline: none;\n}\n._2zNHpjA7U377hKAFRLEk6F:hover{\n    opacity: 0.7;\n}\n/*END GARIIM BUTTON*/\n", ""]);

// exports
exports.locals = {
	"button_gariim": "_2zNHpjA7U377hKAFRLEk6F"
};
import * as validators from 'app/js/validators.js'

export function validateData(name,checks,values,form,notRecheck,lang) {
    return function(dispatch){
        return validators.asyncForCheckValidator(name,checks[name],values,function(result,reCheckList){
            dispatch({
                type: 'VALIDATE_DATA',
                name: name,
                data: result,
                form: form
            });
            //reCheck
            if(!notRecheck){// notRecheck dung do tranh check lapj di lap lai giua hai input co quan he
                for(var i=0;i<reCheckList.length;i++){
                    dispatch(validateData(reCheckList[i],checks,values,form,true));
                }
            }
        },undefined,undefined,lang);
    };
}

export function updateData(name,data,form) {
    return {
        type: 'UPDATE_DATA',
        name: name,
        data: data,
        form: form
    }
}

export function finalCheck(val,form) {
    return {
        type: 'FINAL_CHECK',
        data: val,
        form: form
    }
}

export function resetData(form){
    return {
        type: 'RESET_DATA',
        form: form
    }
}

export function resetAll() {
    return {
        type: 'RESET_ALL',
    }
}

export function clearError(name,form) {
    return {
        type: 'CLEAR_ERROR',
        name: name,
        form: form
    }
}

export function initInput(name,form,data,checks){
    return {
        type: 'INIT_INPUT',
        name:name,
        form: form,
        data : data,
        checks : checks
    }
}

export function setInputChanged(data,form) {
    return {
        type: 'CHANGED_DATA',
        data: data,
        form: form
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import GenerateToUrlb from './generateToUrlb.jsx';
import GenerateMain from './generateMain/generateMain.jsx';
import GenerateError from './generateError.jsx';
import GenerateCheckLogin from './generateCheckLogin.jsx';
import globalStyles from 'app/styles/global_styles.css';

export default class GeneratePage extends React.Component {
    constructor(props) {
        super(props);
        this.randomCounter = Math.floor((Math.random() * 14) + 1);
    }
    componentWillUnmount(){
        this.props.generatePageActions.generatePageReset();
        this.props.inputActions.resetAll();
    }
    _renderMain(){
        switch (this.props.generate.mainRenderMod) {
            case 'checkLogin':
                return (<GenerateCheckLogin
                            {...this.props}/>);
            case "setting":
                return (<GenerateMain
                                {...this.props}/>);
            case "urlb":
                return (<GenerateToUrlb
                            generatePageActions={this.props.generatePageActions}
                            urlaData={this.props.generate.urlaData}
                            language={this.props.language} />)
            case "error":
                return (<GenerateError language={this.props.language}/>)
            default:
        }
    }
    render() {
        return (
            <div>
                <div className={globalStyles.main_background}>
                    <div className={globalStyles.background_img}></div>

                    <div className={globalStyles.main_board}>
                        {this._renderMain()}
                    </div>
                </div>
            </div>
        );
    }
}

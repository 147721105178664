import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/controlPanel_style.css';
import { GNullBoard } from 'app/components/gTools/gTools.jsx';

import StatusTag from 'app/components/controlPanel/statusTag.jsx';

import { checkDeadline, minDate } from 'app/js/func.js';
import moment from 'moment';
import 'moment/locale/vi';

export default class UrlbHistoryList extends React.Component {
    _renderSeeMore() {
        if (this.props.urlbHistoryData.length > 5) {
            return (
                <div>
                    <Link style={{ fontSize: '13px' }} to="/home?tab=urlb"><FormattedMessage id="urlbHistoryList.viewMoreMessage" /></Link>
                </div>
            );
        }
        else {
            return null;
        }
    }
    _renderList() {
        var data = (this.props.urlbHistoryData.length > 5) ? this.props.urlbHistoryData.slice(0, 5) : this.props.urlbHistoryData.slice(0);
        var urlb = null
        if (this.props.path.slice(1, 8) == 'entry') {
            urlb = this.props.path.slice(9);
        }
        if (this.props.path.slice(1, 6) == 'entry') {
            urlb = this.props.query.id;
        }
        var nodeList = data.map((item, index) => {
            var highlight = (item.urla.hashUrlb == urlb) ? true : false;
            return (
                <UrlbUnit
                    highlight={highlight}
                    indexKey={index}
                    urlbUnitData={item}
                />)
        })
        if (nodeList.length > 0) {
            return nodeList;
        }
        else {
            return (<GNullBoard><FormattedMessage id="urlbHistoryList.noEventsMessage" /></GNullBoard>)
        }
    }
    render() {

        return (
            <div style={{ marginBottom: '10px' }}>
                <h4 className={styles.nav_header}>
                    <FormattedMessage id="urlbHistoryList.eventParticipationMessage" />
                </h4>
                {this._renderList()}
                {this._renderSeeMore()}
            </div>
        )
    }
}

export class UrlbUnit extends React.Component {
    handleOnClick() {
        browserHistory.push('/entry/' + this.props.urlbUnitData.urla.hashUrlb);
    }
    _renderNewMark() {
        var { createdAt } = this.props.urlbUnitData;
        createdAt = new Date(createdAt).getTime() / 1000;
        var toDay = new Date().getTime() / 1000;

        console.log(toDay - createdAt);
        if (toDay - createdAt < 86400) {
            return (
                <div className={styles.newMarkB}></div>
            );
        } else {
            return null;
        }
    }
    render() {
        var urlbUnitData = this.props.urlbUnitData;
        var flag = urlbUnitData.flag;
        var deadline = (urlbUnitData.urla.deadLine) ? (urlbUnitData.urla.deadLine) : null;
        var selectedDays = (urlbUnitData.urla.schedule.selectedDays) ? (urlbUnitData.urla.schedule.selectedDays) : {};
        var status = checkDeadline(deadline, selectedDays, flag);
        var thumbnail = (urlbUnitData.urla.setting) ? urlbUnitData.urla.setting.thumbnail : null;
        var iconUrl = (thumbnail) ? thumbnail : '/images/icon/select.png';
        var timeStamp = moment(new Date(urlbUnitData.createdAt)).fromNow();
        var highlightStyle = (this.props.highlight) ? { backgroundColor: "#e6f4ff", color: '#02499d' } : {};
        return (
            <div onClick={(event) => { this.handleOnClick() }} className={styles.url_unit_board} style={highlightStyle}>
                <div className={styles.url_unit_icon}>
                    {this._renderNewMark()}
                    <img className={styles.urlb_unit_icon_img} src={iconUrl} />
                </div>
                <div className={styles.url_unit_content}>
                    <div className={styles.urlb_unit_title}>
                        {this.props.urlbUnitData.urla.title}
                    </div>
                    <div className={styles.urlb_unit_description} style={{ color: this.props.highlight ? '#02499d' : '#999' }}>
                        <div className={styles.unit_owner}>
                            {this.props.urlbUnitData.urla.owner}
                        </div>
                        <span style={{ margin: '0 5px' }}> · </span>
                        {timeStamp}
                    </div>
                    <div className={styles.status_bar} style={{ color: this.props.highlight ? '#02499d' : '#999' }}>
                        <div className={styles.stats_board}>
                            <i style={{ marginRight: '5px' }} className="fa fa-eye" />
                            <span>{this.props.urlbUnitData.urla.viewCount}</span>
                            <i style={{ marginRight: '5px', marginLeft: '5px' }} className="fa fa-user" />
                            <span>{this.props.urlbUnitData.urla.urlbs.length}</span>
                        </div>
                        <StatusTag status={status} />
                    </div>
                </div>
            </div>
        )
    }
}

import {
    GENERATE_SELECTED_DAY,
    GENERATE_SPLICE_DAY,
    GENERATE_FETCH_DAY,
    RESET_DAY,
} from 'app/actions/generate/generateDatePicker_action.js';

function dayPickerData (state = {
    selectedDays:[]
},action){
    switch (action.type) {
        case GENERATE_FETCH_DAY:
            var newState = Object.assign({},state);
            newState.selectedDays = action.data.selectedDays.map(function(item){
                return new Date(item.day);
            });
            console.log(action.data.selectedDays);
            return newState;
        case GENERATE_SELECTED_DAY:
            var newState = Object.assign({},state);
            newState.selectedDays.push(action.data);
            return newState;
        case GENERATE_SPLICE_DAY:
            var newState = Object.assign({},state);
            newState.selectedDays.splice(action.index,1);
            return newState;
        case RESET_DAY:
            var newState = Object.assign({},state);
            newState.selectedDays = [];
            return newState;
        default:
            return state;
    }
}

export default dayPickerData;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mH1b3sgDutaW33mL0t7Wz{\n    display: flex;\n    flex-direction: column;\n}\n._2wDkIJW_FmrEeCDGB_570e{\n    flex: 1;\n    /*overflow-y: scroll;\n    height: 100%;\n    -webkit-overflow-scrolling:touch;*/\n}\n", ""]);

// exports
exports.locals = {
	"main_container": "mH1b3sgDutaW33mL0t7Wz",
	"main_body": "_2wDkIJW_FmrEeCDGB_570e"
};
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".KnfsORUst-r0gy8VKXS9i{\n    margin-bottom: 10px;\n    margin-top: 50px;\n}\n\n._3-sAiR-na8bS6FD8Ir2VD3{\n    margin-bottom: 24px;\n}\n\n.j0wHvf68GKeuAHKYfHKpJ{\n    font-weight: bold;\n    color: #333;\n    text-align: center;\n    margin-top: 0;\n}\n\n._1hhpmc4-l0PyiJV03GavX-{\n    padding: 20px;\n}\n\n._2D-4UeesQWdzfRBCWF0Vap{\n    height: 45px;\n}\n\n._22RvHsIXj0SrFezcPE7WNQ{\n    color: #999;\n}\n\n.oJRGhz_rhN_mNdjfLN4Oo{\n    margin-bottom: 10px;\n}\n\n\n.UILlMofOtubuGFJLq5pg5{\n    border-bottom: solid 1px #e2e2e2;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 10px;\n    margin: 0 -10px 10px -10px;\n}\n\n._2Mn4cfNW9i0BHGvEXDYkF9{\n    font-size: 17px;\n    color: #02499d;\n    font-weight: 700;\n    margin-bottom: 0;\n}\n._1sc1LBwnrOo71eaA1c-5vm{\n    color: #999;\n    font-size: 14px;\n}\n\n._1z4APfcAhI_sOZVseXLy07{\n    box-shadow: 0px -1.5px 2px rgba(0,0,0,0.15), 0 25.5px 18px rgba(0,0,0,0.12);\n    background-color: rgba(255, 255, 255, 0.7);\n    position: -webkit-sticky;\n    position: sticky;\n    bottom: 0px;\n    padding: 10px;\n    margin: 10px -10px -15px -10px;\n}\n", ""]);

// exports
exports.locals = {
	"input_board": "KnfsORUst-r0gy8VKXS9i",
	"deadLine_board": "_3-sAiR-na8bS6FD8Ir2VD3",
	"page_title": "j0wHvf68GKeuAHKYfHKpJ",
	"header_board": "_1hhpmc4-l0PyiJV03GavX-",
	"header_logo": "_2D-4UeesQWdzfRBCWF0Vap",
	"description_text": "_22RvHsIXj0SrFezcPE7WNQ",
	"picker_day_board": "oJRGhz_rhN_mNdjfLN4Oo",
	"tabHeader": "UILlMofOtubuGFJLq5pg5",
	"tabHeaderStep": "_2Mn4cfNW9i0BHGvEXDYkF9",
	"tabHeaderDescription": "_1sc1LBwnrOo71eaA1c-5vm",
	"submitBox": "_1z4APfcAhI_sOZVseXLy07"
};
import {
    combineReducers
} from 'redux';

import {
    SET_LANGUAGE
} from 'app/actions/api/language_action.js';

function language (state = {
    index:{},
    entry:{},
    notFound:{},
    generate:{},
    components:{},
    controlPanel:{},
    lang:'vn'
},action){
    switch (action.type) {
        case SET_LANGUAGE:
            var newState = Object.assign({},state);
            newState = action.data;
            newState.lang = action.lang;
            return newState;
        default:
            return state;
    }
}

export default language;

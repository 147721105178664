import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/dropdownItem_style.css';

export default class DropdownItem extends React.Component {
    render() {
        if(!this.props.disable){
            var {title,description,icon,underline} = this.props;
            var underlineStyle= (underline)?styles.underline:'';
            return (
                <div className={styles.container+' '+underlineStyle} {...this.props}>
                    <div className={styles.left}>
                        <i className={icon+' '+styles.icon} aria-hidden="true"></i>
                    </div>
                    <div className={styles.right}>
                        <p className={styles.title}>{title}</p>
                        <p className={styles.description}>{description}</p>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

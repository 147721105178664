export const GENERATE_SET_URLA_TEMP = 'GENARATE_SET_URLA_TEMP';
export const GENERATE_PAGE_RESET = 'GENERATE_PAGE_RESET';
export const GENERATE_SET_URLA_DATA = 'GENERATE_SET_URLA_DATA';
export const GENERATE_CHANGE_DEAD_LINE_RADIO_BUTTON = 'GENERATE_CHANGE_DEAD_LINE_RADIO_BUTTON';
export const GENERATE_RENDER_MAIN_COMPONENT = 'GENERATE_RENDER_MAIN_COMPONENT';
export const SET_TO_URLB_SOCIAL_BAR_IS_LOADING = 'SET_TO_URLB_SOCIAL_BAR_IS_LOADING';
export const SET_GENERATE_MAIN_TAB_VALUE = 'SET_GENERATE_MAIN_TAB_VALUE';
export const SET_GENERATE_IS_LOADING = 'SET_GENERATE_IS_LOADING';
import {API_GENERATE_FETCH, API_GENERATE_CREATE_URLA } from 'app/js/api.js';

import { voteSetData } from 'app/actions/vote/votePanel_action.js';
import {fetchGenerateHistory}  from 'app/actions/controlPanel/controlPanel_action.js';

export function generateFetchFromServer(hashUrla) {
    var submitData = {
        hashUrla: hashUrla
    };
    return function(dispatch) {
        return fetch(API_GENERATE_FETCH, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                var content = responseJson.content;
                var votes = content.schedule.votes;
                dispatch(setUrlaData(content));
                dispatch(voteSetData(votes));
                //dispatch(pickerDayActions.fetchData(content.schedule));
                //truong hop da co hashurlb roi thi se render tran result
                if (!content.hashUrlb) {
                    dispatch(changeMainRenderMod('setting'));
                } else {
                    dispatch(changeMainRenderMod('result'));
                }
            } else {
                dispatch(changeMainRenderMod('error'));
            }
        });
    }
}

export function submitUrlaDataToServer(title, owner, description, deadLine, entryDatePicker, votes) {
    var newVotes = [];
    for (var i = 0; i < votes.length; i++) {
        if (votes[i].title.trim() != '') {
            newVotes.push({
                locationId: votes[i].locationId,
                title: votes[i].title,
                content: votes[i].content
            });
        }
    }
    var selectedDays = entryDatePicker.selectedDays;
    //if(entryDatePicker.isShowSelectedDay){//Phân biết với trường hợp người tham gia có quyền thêm khung giờ
        selectedDays = selectedDays.map(function(item){
            if(!item.time){
                item.time = [{
                    start:0,
                    end:86340
                }];
                return item;
            }
            return item;
        })
    //}
    var submitData = {
        title: title,
        owner: owner,
        description: description,
        deadLine: deadLine,
        selectedDays: selectedDays,
        votes: newVotes
    };
    return function(dispatch) {
        dispatch(setGenerateIsLoading(true));
        return fetch(API_GENERATE_CREATE_URLA, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            dispatch(setGenerateIsLoading(false));
            if (responseJson.content) {
                var content = responseJson.content;
                dispatch(setUrlaData(content));
                dispatch(changeMainRenderMod('urlb'));
                if(responseJson.message){
                    dispatch(fetchGenerateHistory(''));
                }
            }
        });
    }
}



export function setUrlaTemp(data) {
    return {
        type: GENERATE_SET_URLA_TEMP,
        data: data
    };
}

export function generatePageReset() {
    return {
        type: GENERATE_PAGE_RESET
    };
}
export function setUrlaData(data) {
    return {
        type: GENERATE_SET_URLA_DATA,
        data: data
    };
}

export function changeDeadLineRadioButton(data) {
    return {
        type: GENERATE_CHANGE_DEAD_LINE_RADIO_BUTTON,
        data: data
    };
}

export function changeMainRenderMod(data) {
    return {
        type: GENERATE_RENDER_MAIN_COMPONENT,
        data: data
    };
}

export function setToUrlbSocialBarIsLoading(data) {
    return {
        type: SET_TO_URLB_SOCIAL_BAR_IS_LOADING,
        data: data
    }
}

export function setGenerateMainTabValue(data) {
    return {
        type: SET_GENERATE_MAIN_TAB_VALUE,
        data: data
    }
}

export function setGenerateIsLoading(data) {
    return {
        type: SET_GENERATE_IS_LOADING,
        data: data
    }
}

export const GENERATE_SELECTED_DAY = 'GENERATE_SELECTED_DAY';
export const GENERATE_SPLICE_DAY = 'GENERATE_SPLICE_DAY';
export const GENERATE_FETCH_DAY = 'GENERATE_FETCH_DAY';
export const RESET_DAY = 'RESET_DAY';

export function selectDay(data) {
    return{
        type: GENERATE_SELECTED_DAY,
        data: data
    };
}

export function spliceDay(data){
    return{
        type : GENERATE_SPLICE_DAY,
        index : data
    };
}

export function fetchData(data){
    return{
        type : GENERATE_FETCH_DAY,
        data : data
    };
}

export function resetDay() {
    return {
        type : RESET_DAY
    }
}

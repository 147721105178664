import React from 'react';
import styles from './styles/sendButton_style.css';
//import {GButton} from 'app/components/gTools/gTools.jsx';

export default class SendButton extends React.Component {
    _onClickSend(){
        console.log("+++++++>>>>");

        var width = window.innerWidth;

        var url = 'https://pickboz.com/entry/'+this.props.hashUrlb;
        if(width>768){
            if(window.FB){
                FB.ui({
                  method: 'send',
                  link: url
                });
            }
        }else {
            window.open('fb-messenger-share://share?link=' + encodeURIComponent(url) + '&app_id=' + encodeURIComponent('795287573961298'));
        }
    }
    render() {
        var text = (this.props.children)?this.props.children:'Mời thêm bạn';
        return(
            <div className={styles.button} onClick={this._onClickSend.bind(this)}>
                <i className="fa fa-user-plus" aria-hidden="true"></i> {text}
            </div>
        );
    }
}
//<img src='/images/control/fb-share.png' className={styles.imageButton}/>

import React, {cloneElement, createClass, PropTypes} from 'react';
import styles from './styles/dialogModal_style.css';
import {GModal,GButton} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class DialogModal extends React.Component {
    _onClickCancel(){
        this.props.closeModal();
    }
    _onClickOk(){
        this.props.submitModal();
        this.props.closeModal();
    }
    _renderSubmitButton(){
        if(this.props.submitModal){
            return (
                <GButton
                    default='green'
                    style={{marginBottom:10,width:'100%',marginTop:10}}
                    onClick={this._onClickOk.bind(this)}><FormattedMessage id="dialogModal.confirmMessage" /></GButton>
            );
        }else {
            return null;
        }
    }
    _renderImage(){
        if(this.props.image){
            return <img src={this.props.image} className={styles.image}/>;
        }else {
            return null;
        }
    }
    _renderChildren(){
        if(this.props.children){
            return (
                <div style={{marginBottom:10}}>
                    {this.props.children}
                </div>
            );
        }
    }
    render() {
        var {description,cancelLabel} = this.props;
        var cancelLabel = (cancelLabel)?cancelLabel:'Bỏ qua';
        return (
            <GModal
                visible={this.props.visible}
                closeModal={this._onClickCancel.bind(this)}>
                    <div className={styles.container}>
                        <p className={styles.description}>
                            {description}
                        </p>
                        {this._renderImage()}
                        {this._renderChildren()}
                        {this._renderSubmitButton()}
                        <GButton
                            default='gray'
                            style={{marginBottom:10,width:'100%'}}
                            onClick={this._onClickCancel.bind(this)}>{cancelLabel}</GButton>
                    </div>
           </GModal>
        );
    }
}

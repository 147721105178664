import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton} from 'app/components/gTools/gTools.jsx';
import globalStyles from 'app/styles/global_styles.css';
import { FormattedMessage } from 'react-intl';

export default class EntryError extends React.Component {
    constructor(props) {
        super(props);
    }
    _onClickBackToHomePage(){
        browserHistory.push('/');
    }
    render() {
        var urlb = this.props.urlb;
        return (
            <div className={globalStyles.white_box}>
                <center>
                    <h2 style={{fontWeight:'bold', color:'#02499d'}}>{this.props.language.notFound.errorTitle}</h2>
                    <img style={{width:230, marginBottom: 10}} src='/images/art/404.png'/>
                    <div className={globalStyles.input_share}
                         style={{textAlign:'center',backgroundColor:'#e6f4ff',fontSize:'35', marginBottom:10}}>
                         <span style={{color:'#02499d',textDecoration:'none'}}>{urlb}</span>
                    </div>
                    <p>{this.props.language.notFound.errorDescription}</p>
                    <GButton
                        default = 'primary'
                        style={{height:'45',width:'135',backgroundColor:'#ffa239'}}
                        onClick={this._onClickBackToHomePage.bind(this)}><FormattedMessage id="entryError.goHomeMessage" /></GButton>
                </center>
            </div>
        );
    }
}

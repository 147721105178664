
import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/commentList_style.css';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';
import PickDetails from 'app/components/comment/pickDetails.jsx';
import LoginGoogleButton from 'app/components/login/loginGoogleButton.jsx';
import {Dropdown} from 'app/components/gTools/gTools.jsx';
import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import StickerBox from 'app/components/comment/stickerBox.jsx';
import StickerView from 'app/components/comment/stickerView.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');

export default class CommentList extends React.Component{
    //kieu du lieu data [{name:'string',comment:'string'},...]
    componentDidMount(){
        this.props.commentListActions.fetchCommentData(this.props.hashUrlb,'');
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.hashUrlb !== nextProps.hashUrlb) {
            this.props.commentListActions.fetchCommentData(nextProps.hashUrlb,'');
        }
    }
    _onClickLoadMore(){
        var latestDate = this.props.commentList.list;
        latestDate = latestDate[0].createdAt;
        this.props.commentListActions.fetchCommentData(this.props.hashUrlb,latestDate);
    }
    _renderLoadMoreButton(){
        if(this.props.commentList.isLoadMore){
            return(
                <div className={styles.reply_load_more_button_board}>
                    <i style={{fontSize:'24'}} className="fa fa-sort-asc" aria-hidden="true"></i>
                    <span className={styles.reply_load_more_button} onClick={this._onClickLoadMore.bind(this)}><FormattedMessage id="commentList.viewPreviousCommentsMessage" /></span>
                </div>
            );
        }else {
            return null;
        }
    }
    _renderList(){
        var {list} = this.props.commentList;
        var self = this;
        var length = list.length;
        if(list.length>0){
            var nodes = list.map(function(item,index){
                var newItem = item;
                if(newItem.user=='me'&&self.props.user){
                    newItem.user = self.props.user.userData;
                }
                return <CommentItemInject
                            user = {self.props.user}
                            controlPanelActions={self.props.controlPanelActions}
                            collectionBox={self.props.collectionBox}
                            collectionActions={self.props.collectionActions}
                            data = {newItem}
                            length={length}
                            index={index}
                            hashUrlb={self.props.hashUrlb}
                            commentListActions={self.props.commentListActions}
                            generateDeleteCommentFromServer={self.props.generateDeleteCommentFromServer}
                            language={self.props.language}/>
            });
            return (nodes);
        }else {
            return (
                <GNullBoard><FormattedMessage id="commentList.noCommentsMessage" /></GNullBoard>
            );
        }
    }
    render(){
        return(
            <div className={styles.comment_board}>
                {this._renderLoadMoreButton()}
                {this._renderList()}
            </div>
        );
    }
}

class CommentItem extends React.Component{
    //kieu du lieu data {name:'string',comment:'string'}
    _renderTextComment(){
        if(this.props.data.message.text.trim()){
            return (
                <span className={styles.text_comment}>{this.props.data.message.text}</span>
            );
        }else {
            if(!this.props.data.message.sticker){
                return (
                    <span className={styles.text_comment} style={{color:'#999'}}><FormattedMessage id="commentList.nothingToSayMessage" /></span>
                );
            }else {
                return null;
            }
        }
    }
    deleleItem(){
        var msg = intl.formatMessage({ id: 'commentList.confirmDeleteMessage' });
        var self = this;
        if( window.confirm( msg ) ) {
            this.props.commentListActions.deleteCommentFromServer(this.props.index,this.props.hashUrla,this.props.data.id);
        }
    }
    _renderAvatar(){
        if(this.props.data.user){
            var userAvatarUrl = this.props.data.user.profile.avatarUrl;
            return(
                <object data={userAvatarUrl} type="image/jpg" className={styles.comment_item_avatar}>
                    <img src='/images/control/nullAvatar.jpg' className={styles.comment_item_avatar}/>
                </object>
            );
        }else {
            return(
                <img src="/images/control/nullAvatar.jpg" className={styles.comment_item_avatar}/>
            );
        }
    }
    _renderUserName(){
        if(this.props.data.user){
            return(
                <span className={styles.comment_item_display_name}>
                    {this.props.data.user.displayName}
                </span>
            );
        }else {
            if(this.props.data.name.trim()){
                return (
                    <span className={styles.user_name}>{this.props.data.name}</span>
                );
            }else {
                return (
                    <span className={styles.user_name} style={{color:'#999'}}><FormattedMessage id="commentList.noNameMessage" /> {this.props.index}</span>
                );
            }
        }

    }
    _renderSticker(){
        if(this.props.data.message.sticker){
            return(
                <StickerImage data={this.props.data.message.sticker}/>
            );
        }else{
            return null;
        }
    }
    _renderDeleteButton(){
        if(this.props.hashUrla){
            return(
                <a className={styles.comment_delete_button} onClick={this.deleleItem.bind(this)}><FormattedMessage id="commentList.deleteMessage" /> </a>
            );
        }else {
            return null;
        }
    }
    render(){
        var borderBottom = (this.props.index==this.props.length-1)?'':styles.borderBottom;
        return(
            <div className={styles.comment_item_board+' '+borderBottom}>
                <div className={styles.comment_item_left}>
                    {this._renderAvatar()}
                </div>
                <div className={styles.comment_item_right}>
                    <div className={styles.comment_item_right_left}>
                        {this._renderUserName()}
                        {this._renderTextComment()}
                        {this._renderSticker()}
                        <CommentToolBar
                            createdAt={this.props.data.createdAt}
                            index={this.props.index}
                            user = {this.props.user}
                            controlPanelActions={this.props.controlPanelActions}
                            collectionBox={this.props.collectionBox}
                            collectionActions={this.props.collectionActions}
                            hashUrlb={this.props.hashUrlb}
                            commentData={this.props.data}
                            commentListActions={this.props.commentListActions}/>
                    </div>
                    {this._renderDeleteButton()}
                </div>
            </div>
        );
    }
}

const CommentItemInject = injectIntl(CommentItem);

class StickerImage extends React.Component{
    render(){
        var imageUrl=this.props.data.content.imageUrl;
        var style = (this.props.width)?{width:this.props.width}:null;
        return(
            <div className={styles.sticker_image_board}>
                <img src={imageUrl} style={style}/>
            </div>
        );
    }
}

class CommentToolBar extends React.Component{
    _renderMain(){
        if(this.props.commentData.replyBoxDisplay){
            if(this.props.user.isLogin){
                return(
                    <div>
                        <CommentTextBox
                            index={this.props.index}
                            hashUrlb={this.props.hashUrlb}
                            commentData={this.props.commentData}
                            commentListActions={this.props.commentListActions}
                            collectionBox={this.props.collectionBox}
                            collectionActions={this.props.collectionActions}/>
                    </div>
                );
            }else{
                return(
                    <div className={styles.login_board}>
                        <p><FormattedMessage id="commentList.loginToCommentMessage" /></p>
                        <LoginGoogleButton />
                    </div>
                );
            }
        }else{
            return null;
        }
    }
    _onClickFetchReply(){
        var index = this.props.index;
        this.props.commentListActions.fetchReplyFromServer(
            index,
            this.props.hashUrlb,
            this.props.commentData.id,
            'now');
        this.props.commentListActions.switchReplyBox(index,1);
    }
    _renderReply(){
        var replyCount = this.props.commentData.replyCount;
        var replyList = this.props.commentData.replyList;

        if(replyCount>0){
            if(replyList.length==0){
                return(
                    <div className={styles.reply_count} onClick={this._onClickFetchReply.bind(this)}>
                        <i className="fa fa-commenting" aria-hidden="true"></i>
                        &nbsp;<FormattedMessage id="commentList.replyCountMessage" values={{ replyCount }} />
                    </div>
                );
            }else {
                return(
                    <div>
                        <ReplyList  data={replyList}
                                    user={this.props.user}
                                    isLoadMore={this.props.commentData.isReplyLoadMore}
                                    index={this.props.index}
                                    hashUrlb={this.props.hashUrlb}
                                    commentId={this.props.commentData.id}
                                    fetchReplyFromServer={this.props.commentListActions.fetchReplyFromServer}/>
                    </div>
                );
            }
        }else {
            return null;
        }
    }
    _renderPickDetails(){
        if(this.props.commentData.pickData.id){
            return (
                <PickDetails
                    data={this.props.commentData.pickData}/>
            );
        }else {
            return null;
        }
    }
    _onClickSwitchReplyBox(){
        var newReplyBoxDisplay = (this.props.commentData.replyBoxDisplay)?0:1;
        var index = this.props.index;
        this.props.commentListActions.switchReplyBox(index,newReplyBoxDisplay);
        this.props.commentListActions.fetchReplyFromServer(
            index,
            this.props.urlaId,
            this.props.commentData.id,
            'now');
    }
    _onClickShowPickDetails(){
        var index = this.props.index;
        this.props.commentListActions.fetchPickDetailsFromServer(
            index,
            this.props.hashUrlb,
            this.props.commentData.user.id);
    }
    render(){
        var time = moment(this.props.createdAt, "YYYYMMDD").fromNow();
        return(
            <div className={styles.comment_tool_bar_board}>
                <div>
                    <span
                        className={styles.comment_tool_bar_button_text}
                        onClick={this._onClickShowPickDetails.bind(this)}><FormattedMessage id="commentList.pickContentMessage" /></span>
                    <span style={{color:"#999",margin:"0px 5px 0px 5px"}}> · </span>
                    <span
                        className={styles.reply_time}>{time}</span>
                </div>
                {this._renderPickDetails()}
                {this._renderReply()}
                {this._renderMain()}
            </div>
        );
    }
}
// <span
//     className={styles.comment_tool_bar_button_text}
//     onClick={this._onClickSwitchReplyBox.bind(this)}>Bình luận</span>
// <span style={{color:"#999",margin:"0px 5px 0px 5px"}}> · </span>
class CommentTextBox extends React.Component{
    _onChangeText(event){
        this.props.commentListActions.updateReplyData(this.props.index,event.target.value);
    }
    _onClickSubmitReply(){
        var textComment = this.props.commentData.replyValue.trim();
        var stickerData = this.props.collectionBox.stickerData.selectedData['commentTextBox'+this.props.index];
        if(textComment==''&& !stickerData) return;
        var commentData = {
            text:textComment,
            stickerId:(!stickerData)?null:stickerData.id
        };
        this.props.commentListActions.submitReplyToServer(
            this.props.index,
            this.props.hashUrlb,
            this.props.commentData.id,
            commentData);
        this.props.commentListActions.resetReplyBox(this.props.index);
    }
    closeDropdown(){
        this.refs.dropDown.hide();
    }
    _renderStickerButton(){
        var dropdownTrigger =    <span className={styles.reply_sticker_button}>
                                    <i className="fa fa-smile-o" aria-hidden="true" style={{fontSize:'24',marginRight:'4'}}></i>
                                    Nhãn dán
                                </span>;
        return(
            <Dropdown dropdownTrigger={dropdownTrigger} keepMe={true} ref='dropDown'>
                <DropdownContent className={DropdownStyles.drop_up+' '+styles.comment_text_box_sticker_board}>
                   <StickerBox
                       parentId={'commentTextBox'+this.props.index}
                       closeDropdown ={this.closeDropdown.bind(this)}
                       collectionBox={this.props.collectionBox}
                       collectionActions={this.props.collectionActions}/>
                </DropdownContent>
            </Dropdown>
        );
    }
    render(){
        var selectedStickerData = this.props.collectionBox.stickerData.selectedData['commentTextBox'+this.props.index];
        return(
            <div className={styles.comment_text_box_board}>
                <div className={styles.reply_input_board}>
                    <textarea
                        type="textarea"
                        autoFocus
                        placeholder={"Phản hồi"}
                        value={this.props.commentData.replyValue}
                        className={styles.reply_input_style}
                        onChange={this._onChangeText.bind(this)}/>
                    <StickerView
                        parentId={'commentTextBox'+this.props.index}
                        data={selectedStickerData}
                        collectionActions={this.props.collectionActions}/>

                    <div className={styles.reply_box_footer}>
                        {this._renderStickerButton()}
                        <div style={{flex:1}}></div>
                        <span className={styles.reply_sticker_button+' '+styles.button_right} onClick={this._onClickSubmitReply.bind(this)}>
                            Đăng
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

class ReplyList extends React.Component{
    _renderMain(){
        var self = this;
        var nodes = this.props.data.map(function(item,index){
            var newItem = item;
            if(newItem.user=='me'&&self.props.user){
                newItem.user = self.props.user.userData;
            }
            return <ReplyItem data={newItem} index={index} user={self.props.user}/>
        })
        return nodes;
    }
    _onClickLoadMore(){
        var {index,hashUrlb,commentId} = this.props;
        this.props.fetchReplyFromServer(
            index,
            hashUrlb,
            commentId,
            this.props.data[this.props.data.length-1].createdAt
        );
    }
    _renderLoadMoreButton(){
        if(this.props.isLoadMore){
            return(
                <div className={styles.reply_load_more_button_board}>
                    <i style={{fontSize:'24',color:'#02499d',marginBottom:'5'}} className="fa fa-sort-desc" aria-hidden="true"></i>
                    <span className={styles.reply_load_more_button} onClick={this._onClickLoadMore.bind(this)}><FormattedMessage id="commentList.loadMoreMessage" /></span>
                </div>
            );
        }else {
            return null;
        }
    }
    render(){
        return(
            <div className={styles.reply_board}>
                {this._renderMain()}
                {this._renderLoadMoreButton()}
            </div>
        );
    }
}

class ReplyItem extends React.Component{
    _renderTextComment(){
        if(this.props.data.message.text.trim()){
            return (
                <span className={styles.text_comment}>{this.props.data.message.text}</span>
            );
        }else {
            if(!this.props.data.message.sticker){
                return (
                    <span className={styles.text_comment} style={{color:'#999'}}><FormattedMessage id="commentList.nothingToSayMessage" /></span>
                );
            }else {
                return null;
            }
        }
    }
    _renderSticker(){
        if(this.props.data.message.sticker){
            return(
                <StickerImage data={this.props.data.message.sticker} width='100'/>
            );
        }else{
            return null;
        }
    }
    render(){
        var avatarUrl = this.props.data.user.profile.avatarUrl;
        var time = moment(this.props.data.createdAt, "YYYYMMDD").fromNow();
        return(
            <div className={styles.reply_item_board}>
                <div className={styles.reply_item_left}>
                    <span className={styles.reply_user_info_board}>
                        <img src={avatarUrl} className={styles.reply_avatar_image}/>
                    </span>
                </div>
                <div className={styles.reply_item_right}>
                    <span className={styles.reply_display_name}>{this.props.data.user.displayName}</span>
                    {this._renderTextComment()}
                    {this._renderSticker()}
                    <div className={styles.reply_time}>
                        {time}
                    </div>
                </div>
            </div>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._3k7TmYaRuX4Dvw4CXNe7CX{\n    display: flex;\n    padding: 10px;\n    align-items: center;\n    flex-direction:  column;\n}\n._1NLhajh3jY7Z0imno5CB_z{\n    width: 165px;\n}\n._1UX0rsOgDtG6u28VZEBusd{\n    display: flex;\n    font-size: 24px;\n    width: 100%;\n}\n.FAoKnzWRggApfpYYK1QzN{\n    cursor: pointer;\n    color: #ff7979;\n}\n.FAoKnzWRggApfpYYK1QzN:hover{\n    color: #ff4a4a;\n}\n", ""]);

// exports
exports.locals = {
	"sticker_view_board": "_3k7TmYaRuX4Dvw4CXNe7CX",
	"sticker_image_view": "_1NLhajh3jY7Z0imno5CB_z",
	"close_bar": "_1UX0rsOgDtG6u28VZEBusd",
	"close_icon": "FAoKnzWRggApfpYYK1QzN"
};
import React from 'react';
import { browserHistory, Link } from 'react-router';
import { DropdownTrigger, DropdownContent, DropdownStyles } from 'app/components/gTools/dropButton.jsx';
import { Dropdown, GNullBoard } from 'app/components/gTools/gTools.jsx';
import moment from 'moment';
import 'moment/locale/vi';

import styles from './styles/langBar_style.css';

export default class LangBar extends React.Component {
    _clickLang(lang) {
        this.props.langBarActions.setLangData(lang);
        localStorage.setItem('language', lang);
    }
    render() {
        const { lang } = this.props.user;
        var dropdownTrigger = <span className={styles.headerIcon}>
            <img width={25} src={parseFlag(lang)} />
        </span>;
        return (
            <Dropdown dropdownTrigger={dropdownTrigger}>
                <DropdownContent className={DropdownStyles.drop_down + ' ' + DropdownStyles.drop_right + ' ' + styles.container}>
                    <ul>
                        <li >
                            <a onClick={this._clickLang.bind(this, 'en')}>
                                <img className={styles.icon} src='/images/icon/usa.png' /> English
                            </a>
                        </li>
                        <li >
                            <a onClick={this._clickLang.bind(this, 'ja')}>
                                <img className={styles.icon} src='/images/icon/japan.png' /> 日本語
                            </a>
                        </li>
                        <li >
                            <a onClick={this._clickLang.bind(this, 'vi')}>
                                <img className={styles.icon} src='/images/icon/vietnam.png' /> Tiếng Việt
                            </a>
                        </li>
                        <li >
                            <a onClick={this._clickLang.bind(this, 'th')}>
                                <img className={styles.icon} src='/images/icon/thailan.png' /> ภาษาไทย
                            </a>
                        </li>
                    </ul>
                </DropdownContent>
            </Dropdown>
        );
    }
}

function parseFlag(lang) {
    switch (lang) {
        case 'en':
            return '/images/icon/usa.png';
        case 'vi':
            return '/images/icon/vietnam.png';
        case 'ja':
            return '/images/icon/japan.png';
        case 'th':
            return '/images/icon/thailan.png';
    }
}
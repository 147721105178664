exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QcOOPlmpj3AdcGwqfKYpn{\n    display: flex;\n    flex-direction: row;\n    margin: 0 5px;\n}\n\n.eMug53EqhFAhJgR64AKYw{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    font-weight: 500;\n    cursor: pointer;\n}\n\n.eMug53EqhFAhJgR64AKYw:hover{\n    opacity: 0.7;\n}\n\n._24wQVAeeuQABqjTiyxRDD2{\n    width: 34px;\n    height: 34px;\n    border-radius: 17px;\n}\n\n.IhcbFe6beAGddZWy5eBvN{\n    right: 15px;\n}\n\n._1sIy2PZOEnQLQFzg7FgPkd{\n    color: #999;\n}\n\n._1h-g39UQaUBgG3lEmO-2IN{\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"main_board": "_1QcOOPlmpj3AdcGwqfKYpn",
	"avatar_button": "eMug53EqhFAhJgR64AKYw",
	"avatar": "_24wQVAeeuQABqjTiyxRDD2",
	"dropdown_user": "IhcbFe6beAGddZWy5eBvN",
	"gray": "_1sIy2PZOEnQLQFzg7FgPkd",
	"white": "_1h-g39UQaUBgG3lEmO-2IN"
};
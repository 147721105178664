import React from 'react';
import { browserHistory, Link } from 'react-router';

import styles from './styles/userHeaderControl_style.css';
import { Dropdown, GButton } from 'app/components/gTools/gTools.jsx';
import { DropdownTrigger, DropdownContent, DropdownStyles } from 'app/components/gTools/dropButton.jsx';
import globalStyles from 'app/styles/global_styles.css';
import NotificationBar from 'app/containers/notification/notificationBar_container.jsx';

import { FormattedMessage, injectIntl } from 'react-intl';

export default class UserHeaderControl extends React.Component {
    _onClickLogout() {
        this.props.userActions.logoutUserFromServer();
        window.location.reload();
    }
    render() {
        var avatarUrl = this.props.data.profile.avatarUrl;
        var displayName = this.props.data.displayName;
        var style = styles.gray;
        switch (this.props.default) {
            case 'gray':
                style = styles.gray;
                break;
            case 'white':
                style = styles.white;
                break;
            default:
        }
        var dropdownTrigger = <div className={styles.main_board + ' ' + style}>
            <div className={styles.avatar_button}>
                <object data={avatarUrl} type="image/jpg" className={styles.avatar}>
                    <img src='/images/control/nullAvatar.jpg' className={styles.avatar} />
                </object>
            </div>
        </div>;
        return (
            <div style={{ display: 'flex' }}>
                <a href='/generate'>
                    <GButton default='orange' style={{ marginRight: 5 }}><i className="fa fa-plus-circle" aria-hidden="true"></i> <FormattedMessage id="userHeaderControl.createEventMessage" /></GButton>
                </a>
                <NotificationBar iconStyle={style} />
                <Dropdown dropdownTrigger={dropdownTrigger}>
                    <DropdownContent className={DropdownStyles.drop_down + ' ' + styles.dropdown_user}>
                        <ul>
                            <li >
                                <Link to="/home">
                                    {this.props.data.displayName}
                                </Link>
                            </li>

                            <li className={globalStyles.divider}></li>
                            <li >
                                <Link to="/feedback">
                                    <i className="fa fa-thumbs-up" aria-hidden="true"></i> <FormattedMessage id="userHeaderControl.feedbackMessage" />
                                </Link>
                            </li>
                            <li >
                                <a onClick={this._onClickLogout.bind(this)}>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i> <FormattedMessage id="userHeaderControl.logoutMessage" />
                                </a>
                            </li>
                        </ul>
                    </DropdownContent>
                </Dropdown>
            </div>
        );
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/timeResult_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
moment.locale('vi');
import {KeywordList,GNullBoard} from 'app/components/gTools/gTools.jsx';
import * as func from 'app/js/func.js';

export default class TimeResult extends React.Component {
    _renderList(){
        if(this.props.data.length>0){
            var self = this;
            var nodes = this.props.data.map(function(item){
                return (<ItemDay language={self.props.language} data={item}/>)
            });
            return nodes;
        }else {
            return (
                <GNullBoard><FormattedMessage id="timeResult.noTimeSelectedMessage" /></GNullBoard>
            );
        }
    }
    render() {
        return (
            <div className={styles.main_board}>
                {this._renderList()}
            </div>
        );
    }
}

var rankColor = ['#c8ffca','#bfedff','#fdffbf','#fdffbf','#ff9c9c'];
// style={{
//             // flex:weight,
//             backgroundColor:rankColor[(index<=4)?index:4]
//         }}
class ItemDay extends React.Component {
    _renderTimePart(){
        var obj = this;
        var parts = this.props.data.time;
        if(parts.length>5){
            parts.splice(5,parts.length-5);
        }
        parts = parts.map(function(item,index){
            var weight = (item.weight)?item.weight:1;
            return (<TimePart
                        data={item}
                        language={obj.props.language} />)
        });
        return <div style={{
                        display:'flex',
                        width:'100%',
                        height:'100%',
                        flexDirection:'column'
                    }}>{parts}</div>;
    }
    _renderTimeList(){
        var times = this.props.data.time.map(function(item,index){
            return {
                id:index,
                key: parseTime(item.start)+" ~ "+parseTime(item.end)
            };
        });
        return <KeywordList itemClassName={styles.time_item} data={times}/>;
    }
    render() {
        return (
            <div className={styles.item_board}>
                <div className={styles.item_top}>
                    <div className={styles.item_top_left}>
                        <p className={styles.time_top_date}>{moment(new Date(this.props.data.day)).format('dddd DD/MM/YYYY').capitalize()}</p>
                    </div>
                    <div className={styles.item_top_right}>
                        <div className={styles.picker_counter_board}>
                            <p style={{margin:'0'}}>{this.props.data.weight}{' '+this.props.language.components.resultPeople}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.item_body}>
                    {this._renderTimePart()}
                </div>
            </div>
        );
    }
}

class TimePart extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isLoadMore:false
        };
    }
    getListName(names,limit){
        var result = '';
        var name = this.props.data.name;
        var len = (name.length>3)?3:name.length;
        if(!limit){
            len = name.length;
        }
        for(var i=0;i<len;i++){
            result += name[i]+', ';
        }
        return result.slice(0,result.length-2);
    }
    loadMore(){
        this.setState({
            isLoadMore: (this.state.isLoadMore)?false:true
        });
    }
    _renderNameList(){
        var name = this.props.data.name;
        var result = this.getListName(name);
        if(name.length>3){
            if(this.state.isLoadMore){
                return (<p className={styles.name_list}>{this.getListName(name)}</p>);
            }else {
                return (<p className={styles.name_list_full}>
                            {this.getListName(name,3)+',...'}
                            <a className={styles.load_more} onClick={this.loadMore.bind(this)}>{this.props.language.components.resultSeeMore}</a>
                        </p>);
            }
        }else {
            return <p className={styles.name_list}>{this.getListName(name,3)}</p>
        }
    }
    render() {

        return (
            <div className={styles.time_part_board} style={this.props.style}>
                <div className={styles.time_part_board_left}>
                    <p className={styles.time_text}>
                        {func.tohhmm(this.props.data.start)+" ~ "+func.tohhmm(this.props.data.end)}
                    </p>
                </div>
                <div className={styles.time_part_board_right}>
                    <span>
                        {this._renderNameList()}
                    </span>
                </div>
            </div>
        );
    }
}
//+"("+this.props.data.weight} {this.props.language.components.resultPeople+')'
class TimeNull extends React.Component {
    render() {
        return (
            <div className={styles.time_null_board} style={this.props.style}>

            </div>
        );
    }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import NotificationBar from 'app/components/headerBar/notificationBar.jsx';

import * as notificationActions from 'app/actions/notification/notification_action.js';

function mapDispatchToProps(dispatch) {
  return {
    notificationActions : bindActionCreators(notificationActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        notification:state.notification,
        user:state.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationBar);

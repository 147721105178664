import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton,SetupProgressBar,TabPanel} from 'app/components/gTools/gTools.jsx';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generateMain_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import GeneratePage1 from './generatePage1.jsx';
import GeneratePage2 from './generatePage2.jsx';
import GeneratePage3 from './generatePage3.jsx';
import GeneratePage4 from './generatePage4.jsx';

const tabMenuData = [
    {
        title: <FormattedMessage id="generateMain.infoMessage" />,
        icon:'fa fa-info',
        value:'info'
    },
    {
        title: <FormattedMessage id="generateMain.whereInfoMessage" />,
        icon:'fa fa-map-marker',
        value:'location'
    },
    {
        title: <FormattedMessage id="generateMain.whenInfoMessage" />,
        icon:'fa fa-calendar',
        value:'time'
    },
    {
        title: <FormattedMessage id="generateMain.confirmMessage" />,
        icon:'fa fa-eye',
        value:'check'
    }
];
export default class GenerateMain extends React.Component {
    componentDidMount(){
        if(this.props.user.isLogin){
            this.props.inputActions.updateData(
                'owner',
                this.props.user.userData.displayName,
                'generateSettingForm');
        }
        this.props.entryDatePickerActions.reset();
        this.props.entryDatePickerActions.setDisplayEntrySelectedDay(false);
    }
    componentWillReceiveProps(nextProps){
        if(this.props.user.isLogin!=nextProps.user.isLogin){
            if(nextProps.user.isLogin){
                this.props.inputActions.updateData(
                    'owner',
                    this.props.user.userData.displayName,
                    'generateSettingForm');
            }
        }
    }
    _renderMain(){
        var tabs = [
            {
                value:'info',
                component:<GeneratePage1
                            {...this.props}
                            next={this.next.bind(this)}/>
            },
            {
                value:'location',
                component:<GeneratePage2
                            {...this.props}
                            next={this.next.bind(this)}/>
            },
            {
                value:'time',
                component:<GeneratePage3
                            {...this.props}
                            next={this.next.bind(this)}
                            urlaData={this.props.generate.urlaData}
                            language={this.props.language}/>
            },
            {
                value:'check',
                component:<GeneratePage4
                            {...this.props}
                            next={this.next.bind(this)}/>
            }
        ];
        return <TabPanel
                    tabs={tabs}
                    selectedValue={this.props.generate.generateMain.tabValue}/>

    }
    _onClickSetMainTabValue(value,isChecked){
        var {step} = this.props.generate.generateMain;
        if(isChecked){
            this.props.generatePageActions.setGenerateMainTabValue(value);
        }
    }
    next(){
        this.refs.setupProgressBar.next();
    }
    render() {
        var {tabValue} = this.props.generate.generateMain;
        var conditions = [
            this.props.gFormInputData.generateSettingForm&&this.props.gFormInputData.generateSettingForm.finalCheck,
            this.props.votePanelData.votes[0].title.length>0,
            this.props.entryDatePicker.selectedDays.length>0,
            this.props.entryDatePicker.selectedDays.length>0
        ];
        return (
            <div className={globalStyles.white_box} style={{minHeight:500,marginBottom:205}}>
                <SetupProgressBar
                    ref='setupProgressBar'
                    data={tabMenuData}
                    finalChecks={conditions}
                    selectedValue={tabValue}
                    onSelect={this._onClickSetMainTabValue.bind(this)}/>
                {this._renderMain()}
            </div>
        );
    }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import GenerateDatePicker from 'app/components/generate/generateDatePicker.jsx';

import * as generateDatePickerActions from 'app/actions/generate/generateDatePicker_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 generateDatePickerActions : bindActionCreators(generateDatePickerActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        generateDatePicker:state.generateDatePicker,
        language:state.language
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(GenerateDatePicker);


import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/pickDetails_style.css';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';
import moment from 'moment';
moment.locale('vi');
import * as func from 'app/js/func.js';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class PickDetails extends React.Component{
    _renderVoteBoard(){
        if(this.props.data.user_votes.length>0){
            return (
                <VoteBoard
                    data={this.props.data.user_votes}/>
            );
        }else {
            return null;
        }
    }
    render(){
        return(
            <div className={styles.pick_details_board} style={this.props.style}>
				{this._renderVoteBoard()}
				<TimeBoard data={this.props.data.user_time_votes}/>
            </div>
        );
    }
}


class TimeBoard extends React.Component{
	_renderItems(){
		var nodes = this.props.data.map(function(item,index){
			return <TimeItem data={item.time_vote} index={index}/>
		});
		return nodes;
	}
    render(){
        return(
            <div className={styles.time_board}>
				<p className={styles.tab_title}><FormattedMessage id="pickDetails.timePickedMessage" /></p>
				{this._renderItems()}
            </div>
        );
    }
}


class TimeItem extends React.Component{
	_renderListTime(){
		if(this.props.data.value.time){
            var time = this.props.data.value.time;
			// var len = this.props.data.time.length;
			// var timeNodes = this.props.data.time.map(function(item,index){
			// 	var dot = (index!=(len-1))?',  ':'';
			// 	var text = '['+func.tohhmm(item.start)+" ~ "+func.tohhmm(item.end)+']'+dot;
			// 	return <span>{text}</span>;
			// });
			// return timeNodes;
            return <span>{func.tohhmm(time.start)+" ~ "+func.tohhmm(time.end)}</span>;
		}else {
			return <span>0:00 ~ 23:59</span>;
		}
	}
    render(){
		var day = moment(new Date(this.props.data.day)).format('ddd ').capitalize();
		var month = moment(new Date(this.props.data.day)).format('DD/MM').capitalize();
        return(
            <div className={styles.time_item_board}>
				<div className={styles.time_item_left}>
					<span>{day}</span>&nbsp;
					<p style={{margin:'0'}}>{month}</p>
				</div>
				<div className={styles.time_item_right}>
					{this._renderListTime()}
				</div>
            </div>
        );
    }
}


class VoteBoard extends React.Component{
	_renderItems(){
        var self = this;
		var nodes = this.props.data.map(function(item,index){
			return <VoteItem
                        data={item.vote}
                        index={index}/>
		});
		return nodes;
	}
    render(){
        return(
            <div className={styles.vote_board}>
				<p className={styles.tab_title}><FormattedMessage id="pickDetails.votedMessage" /></p>
				{this._renderItems()}
            </div>
        );
    }
}

class VoteItem extends React.Component{
    render(){
        var title = this.props.data.title;
        return(
            <div className={styles.vote_item_board}>
				{title}
            </div>
        );
    }
}


import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/stickerBox_style.css';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';

export default class StickerBox extends React.Component{
    componentDidMount(){
        this.props.collectionActions.fetchCollectionFromServer();
    }
    _renderCollectionList(){
        var self = this;
        var nodes = this.props.collectionBox.collectionData.list.map(function(item,index){
            return <CollectionItem
                        selectedIndex={self.props.collectionBox.collectionData.selectedIndex}
                        collectionActions={self.props.collectionActions}
                        data={item} index={index}/>;
        });
        return nodes;
    }
    render(){
        return(
            <div className={styles.sticker_box_board}>
                <div className={styles.header}>
                    {this._renderCollectionList()}
                </div>
                <div className={styles.body_board}>
                    <StickerList
                        parentId={this.props.parentId}
                        closeDropdown={this.props.closeDropdown}
                        data={this.props.collectionBox.stickerData}
                        collectionActions={this.props.collectionActions}/>
                    <div style={{flex:1}}></div>
                </div>
            </div>
        );
    }
}

class CollectionItem extends React.Component{
    _onClickSelectCollection(){
        this.props.collectionActions.selectCollection(this.props.index,this.props.data.id);
    }
    render(){
        var imageUrl = this.props.data.info.thumbnail;
        var selectedStyle = (this.props.selectedIndex == this.props.index)?styles.collection_item_selected:'';
        return(
            <div className={styles.collection_item_board+' '+selectedStyle} onClick={this._onClickSelectCollection.bind(this)}>
                <img src={imageUrl}/>
            </div>
        );
    }
}

class StickerList extends React.Component{
    _renderStickerList(){
        var self = this;
        var nodes = this.props.data.list.map(function(item,index){
            return <StickerItem
                        parentId={self.props.parentId}
                        data={item}
                        index={index}
                        collectionActions={self.props.collectionActions}
                        closeDropdown={self.props.closeDropdown}/>;
        });
        return nodes;
    }
    render(){
        return(
            <div className={styles.sticker_list_board}>
                {this._renderStickerList()}
            </div>
        );
    }
}

class StickerItem extends React.Component{
    _onClickSelect(){
        this.props.collectionActions.selectSticker(this.props.index,this.props.parentId);
        if(this.props.closeDropdown){
            this.props.closeDropdown();
        }
    }
    render(){
        var imageUrl = this.props.data.content.imageUrl;
        return(
            <div className={styles.sticker_item_board} onClick={this._onClickSelect.bind(this)}>
                <img src={imageUrl} className={styles.sticker_image}/>
            </div>
        );
    }
}

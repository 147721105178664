import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/stickerView_style.css';

export default class StickerView extends React.Component{
    _onClickCancelSticker(){
        this.props.collectionActions.cancelSticker(this.props.parentId);
    }
    render(){
        if(this.props.data){
            var imageUrl = this.props.data.content.imageUrl;
            return(
                <div className={styles.sticker_view_board}>
                    <div className={styles.close_bar}>
                        <div style={{flex:1}}></div>
                        <i
                            className={"fa fa-times "+styles.close_icon}
                            aria-hidden="true"
                            onClick={this._onClickCancelSticker.bind(this)}></i>
                    </div>
                    <img src={imageUrl} className={styles.sticker_image_view}/>
                </div>
            );
        }else {
            return null;
        }
    }
}

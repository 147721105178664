import {
    combineReducers
} from 'redux';

import {
    SWITCH_DISPLAY_RESULT_TAB,
    SET_RESULT_DATA,
    RESET_RESULT_DATA,
    ADD_URLB_RESULT_DATA,
    SET_TIME_VOTE_RESULT_LOADING
} from 'app/actions/result/result_action.js';

import {
    FETCH_VOTE_USER,
} from 'app/actions/result/voteResult_action.js';

const resultDataDefault = {
    picks:[],
    votes:[],
    users:[],
    urlbCount:0,
    resultTabDisplay:0,
    isLoadingTimeVote:false
}
function resultData(state=resultDataDefault,action) {
    switch (action.type) {
        case SET_RESULT_DATA:
            var newState = Object.assign({},state);
            newState.users = action.data.users;
            newState.picks = action.data.days;
            newState.votes = action.data.votes.map(function(item){
                item.weight = parseInt(item.weight);
                item.urlbs = [];
                return item;
            });
            newState.urlbCount = action.data.urlbCount;
            return newState;
        case ADD_URLB_RESULT_DATA:
            var newState = Object.assign({},state);
            var users = Object.assign([],state.users);
            users = users.concat(action.data);
            newState.users = users;
            return newState;
        case SWITCH_DISPLAY_RESULT_TAB:
            var newState = Object.assign({},state);
            newState.resultTabDisplay = action.data;
            return newState;
        case RESET_RESULT_DATA:
            var newState = Object.assign({},state);
            newState = resultDataDefault;
            return newState;
        case FETCH_VOTE_USER:
            var newState = Object.assign({},state);
            var votes = Object.assign([],state.votes);
            votes[action.index].urlbs = action.data;
            newState.votes = votes;
            return newState;
        case SET_TIME_VOTE_RESULT_LOADING:
            var newState = Object.assign({},state);
            newState.isLoadingTimeVote = action.data;
            return newState;
        default:
            return state;
    }
}


const resultReducer = combineReducers({
    resultData:resultData
})

export default resultReducer;

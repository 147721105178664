exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._20M5mgoOK7PKzs1KUYGh7O{\n\n}\n._1b5CjzCACDqwZbIitbtwaa{\n    font-size: 14px;\n    color: #333;\n    /*font-weight: 200;*/\n}\n._2-6B-AKc1MHgf7po2f1eI4{\n    border: dotted 2px #a7c6f5;\n    padding: 2px 5px;\n    border-radius: 3px;\n    color: #6fb4ff;\n    border-color: #6fb4ff;\n    font-size: 13px;\n}\n._1_NUN4R8zCxPhOxKvLJmHk{\n    color: #999;\n    font-size: 14px;\n    font-weight: 200;\n}\n.zs8XO6BRcOKkeLoAzIauQ{\n    padding: 0;\n    padding-bottom: 10px;\n}\n._3PVv9hHV4zVPRa_TdUrIpF{\n    color: #999;\n    font-weight: 200;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_20M5mgoOK7PKzs1KUYGh7O",
	"timeRangeText": "_1b5CjzCACDqwZbIitbtwaa",
	"addButton": "_2-6B-AKc1MHgf7po2f1eI4",
	"disableText": "_1_NUN4R8zCxPhOxKvLJmHk",
	"timeCheckBox": "zs8XO6BRcOKkeLoAzIauQ",
	"or": "_3PVv9hHV4zVPRa_TdUrIpF"
};
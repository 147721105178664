import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/statusTag_style.css';

export default class StatusTag extends React.Component {
    _renderTag() {
        switch (this.props.status) {
            case 'picked':
                return (<Tag1 />);
            case 'deadline':
                return (<Tag0 />);
            case 'created':
                return (<Tag2 />);
            case 'bookmark':
                return (<Tag3 />);
            default:
                return null;
        }
    }
    render() {
        return (
            this._renderTag()
        )
    }
}

export class Tag2 extends React.Component {
    render() {
        return(
            <div className={styles.status_tag_2}>
                <span className={styles.tag_text}>
                    <FormattedMessage id="statusTag.pickInProgressMessage" />
                </span>
            </div>
        )
    }
}

export class Tag1 extends React.Component {
    render() {
        return(
            <div className={styles.status_tag_1}>
                <span className={styles.tag_text}>
                    <FormattedMessage id="statusTag.pickedMessage" />
                </span>
            </div>
        )
    }
}

export class Tag0 extends React.Component {
    render() {
        return(
            <div className={styles.status_tag_0}>
                <span className={styles.tag_text}>
                    <FormattedMessage id="statusTag.expiredMessage" />
                </span>
            </div>
        )
    }
}

export class Tag3 extends React.Component {
    render() {
        return(
            <div className={styles.status_tag_3}>
                <span className={styles.tag_text}>
                    <FormattedMessage id="statusTag.followMessage" />
                </span>
            </div>
        )
    }
}

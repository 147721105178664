import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GFormInput,GButton,GTimePicker,Dropdown,Panel,TabMenu} from 'app/components/gTools/gTools.jsx';
import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/entryResultPage_style.css';

import ListPick from 'app/containers/entry/listPick_container.jsx';
import CommentBox from 'app/containers/comment/commentBox_container.jsx';
import CommentList from 'app/containers/comment/commentList_container.jsx';
import ResultBox from 'app/containers/result/resultBox_container.jsx';
import SocialBar from 'app/components/socialBar/socialBar.jsx';

export default class EntryResultPage extends React.Component {
    render() {
        var hashUrlb = this.props.hashUrlb;
        var timeMode = this.props.entry.entryData.urlaData.schedule.mode;
        return (
            <div>
                <ResultBox hashUrlb={hashUrlb} timeMode={timeMode}/>
                <div className={globalStyles.white_box}>
                    <CommentList hashUrlb={hashUrlb}/>
                    <CommentBox hashUrlb={hashUrlb} />
                </div>
            </div>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3K-KHtuD4iPxLV1tqZWEpM{\n    display: flex;\n    flex-direction: column;\n    border: 1px solid #e2e2e2;\n}\n._2WxrAFrvaGgzr35WaMd98S{\n    height: 65px;\n    width: 100%;\n    resize: none;\n    align-items: center;\n    background-color: #fff;\n    border: 0px solid #e2e2e2;\n    border-bottom: 1px solid #e2e2e2;\n    display: flex;\n    flex-wrap: wrap;\n    padding: 3px 5px;\n}\n._1G_FCiFsAeUL5moVMSzQMR{\n    display: flex;\n    background-color: #f6f7f9;\n    border-top: solid 1px #e2e2e2;\n}\n\n.zHQTiKObFkmIsiOy6Va8R{\n    font-size: 13px;\n    color: #999;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    font-weight: 600;\n    padding: 5px 7px;\n    border-right: 1px solid #e2e2e2;\n}\n.zHQTiKObFkmIsiOy6Va8R:hover{\n    color: #f99f39;\n}\n\n._1EE8DdC3ikUH1XAcprTZG6{\n    color: #f99f39;\n}\n", ""]);

// exports
exports.locals = {
	"comment_box_board": "_3K-KHtuD4iPxLV1tqZWEpM",
	"input_style": "_2WxrAFrvaGgzr35WaMd98S",
	"control_bar": "_1G_FCiFsAeUL5moVMSzQMR",
	"control_button": "zHQTiKObFkmIsiOy6Va8R",
	"tab_selected": "_1EE8DdC3ikUH1XAcprTZG6"
};
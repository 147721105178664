import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GButton} from 'app/components/gTools/gTools.jsx';
import globalStyles from 'app/styles/global_styles.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/generateError_style.css';


export default class GenerateError extends React.Component {
    constructor(props) {
        super(props);
    }
    _onClickBackToHomePage(){
        browserHistory.push('/');
    }
    render() {
        var urla = this.props.urla;
        return (
            <div className={globalStyles.white_box}>
                <center>
                    <h2 style={{fontWeight:'bold'}}><FormattedMessage id="generateError.pageNotFoundMessage" /></h2>
                    <div className={globalStyles.input_share}
                         style={{textAlign:'center',backgroundColor:'#ffebeb',fontSize:'35'}}>
                         <span style={{color:'#c36666',textDecoration:'none'}}>{urla}</span>
                    </div>
                    <h4 style={{color:'#999'}}><FormattedMessage id="generateError.entryCodeNotFoundMessage" /></h4>
                    <GButton
                        style={{height:'45',width:'135',backgroundColor:'#ffa239'}}
                        onClick={this._onClickBackToHomePage.bind(this)}><FormattedMessage id="generateError.backToHomePageMessage" /></GButton>
                </center>
            </div>
        );
    }
}

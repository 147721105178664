import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryPickPage from 'app/views/entry/entryMain/entryPickPage.jsx';

import * as entryActions from 'app/actions/entry/entry_action.js';
import * as gFormInputActions from 'app/components/gTools/actions/gFormInput_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 entryActions : bindActionCreators(entryActions,dispatch),
     inputActions : bindActionCreators(gFormInputActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        entryData:state.entry.entryData,
        user:state.user,
        collectionBox:state.collectionBox,
        commentBox:state.commentBox,
        pickVotePanel:state.pickVotePanel,
        entryDatePicker:state.entryDatePicker,
        language:state.language,
        gFormInput:state.gFormInput,
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(EntryPickPage);

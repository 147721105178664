import {
    combineReducers
} from 'redux';

import {
    SET_USER_DATA,
    SET_USER_IS_LOGIN,
    DESTROY_USER,
    SET_USER_IS_FETCH,
    SET_SEARCH_SETTING_CITY_ID,
    SET_USER_IS_ISLOADING
} from 'app/actions/user/user_action.js';

import { SET_LANG_DATA } from '../../actions/langBar/langBar_action';

const userDataDefault = {
    step: {
        search: { cityId: 1 }
    }
};

function userData(state = userDataDefault, action) {
    switch (action.type) {
        case SET_USER_DATA:
            var newState = Object.assign({}, state);
            newState = action.data;
            return newState;
        case DESTROY_USER:
            var newState = Object.assign({}, state);
            newState = userDataDefault;
            return newState;
        case SET_SEARCH_SETTING_CITY_ID:
            var newState = Object.assign({}, state);
            newState.step.search.cityId = action.data;
            return newState;
        default:
            return state;
    }
}


function isLogin(state = false, action) {
    switch (action.type) {
        case SET_USER_IS_LOGIN:
            var newState = Object.assign(false, state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

function isFetch(state = false, action) {
    switch (action.type) {
        case SET_USER_IS_FETCH:
            var newState = Object.assign(false, state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

function isLoading(state = false, action) {
    switch (action.type) {
        case SET_USER_IS_ISLOADING:
            var newState = Object.assign(false, state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

function langData(state = 'en', action) {
    switch (action.type) {
        case SET_LANG_DATA:
            return action.data;
        default:
            return state;
    }
}

const userReducer = combineReducers({
    userData: userData,
    isLogin: isLogin,
    isFetch: isFetch,
    isLoading: isLoading,
    lang: langData
});

export default userReducer;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import EntryHeaderControlBar from 'app/components/entry/entryHeaderControlBar.jsx';
import * as entryHeaderControlBarActions from 'app/actions/entry/entryHeaderControlBar_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
    return {
        entryHeaderControlBarActions : bindActionCreators(entryHeaderControlBarActions,dispatch),
        controlPanelActions : bindActionCreators(controlPanelActions,dispatch)
    }
}

function mapStateToProps(state) {
	return ({
        entryHeaderControlBar:state.entryHeaderControlBar,
        entryData:state.entry.entryData,
        pickList: state.pickList,
        user:state.user
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(EntryHeaderControlBar);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Aj8jCWmC55FMIJuPct5Cc{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n}\n._2IdvOFZO8i22dTWLDpCrSi{\n    display: flex;\n    justify-content: center;\n}\n.nyThL679k2OjLO_rzfrk0{\n    font-size: 19px;\n    text-align: center;\n    color: #02499d;\n    font-weight: bold;\n}\n._34i27kyB-YuCkMnQZlzS78{\n    font-size: 14px;\n    font-weight: 200;\n    color: #333;\n    text-align: center;\n}\n.NZBFJiKXXQBgcuDxWtf1-{\n    height: 256px;\n}\n._3wivVXqlYgw2Xv_YVlThiM{\n    color: #02499d;\n    cursor: pointer;\n}\n._3wivVXqlYgw2Xv_YVlThiM:hover{\n    text-decoration: underline;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1Aj8jCWmC55FMIJuPct5Cc",
	"loadingBoard": "_2IdvOFZO8i22dTWLDpCrSi",
	"title": "nyThL679k2OjLO_rzfrk0",
	"description": "_34i27kyB-YuCkMnQZlzS78",
	"mainImage": "NZBFJiKXXQBgcuDxWtf1-",
	"skipButton": "_3wivVXqlYgw2Xv_YVlThiM"
};
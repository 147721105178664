export const SET_USER_HOME_TAB_VALUE = 'SET_USER_HOME_TAB_VALUE';
export const SET_USER_HOME_IS_LOADING = 'SET_USER_HOME_IS_LOADING';

export function setTabValue(data) {
    return {
        type: SET_USER_HOME_TAB_VALUE,
        data: data
    }
}

export function setIsLoading(data) {
    return {
        type: SET_USER_HOME_IS_LOADING,
        data: data
    }
}

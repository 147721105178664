exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36TFI3ytmSsMI94jWpaeBp{\n    display: flex;\n    align-items: center;\n    padding: 5px 0;\n    margin-bottom: 0;\n}\n\n._36TFI3ytmSsMI94jWpaeBp input{\n    margin: 0;\n}\n\n._36TFI3ytmSsMI94jWpaeBp:hover{\n    cursor: pointer;\n    background-color: #e6f4ff;\n}\n", ""]);

// exports
exports.locals = {
	"main": "_36TFI3ytmSsMI94jWpaeBp"
};
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import LangBar from 'app/components/headerBar/langBar.jsx';

import * as langBarActions from 'app/actions/langBar/langBar_action.js';

function mapDispatchToProps(dispatch) {
  return {
    langBarActions : bindActionCreators(langBarActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        notification:state.notification,
        user:state.user
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(LangBar);

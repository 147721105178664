import {
    SWITCH_IS_SHOW_LOGIN_MODAL,
    SWITCH_DISPLAY_CONTROL_PANEL,
    SET_DISPLAY_CONTROL_PANEL,
    SET_URLA_HISTORY,
    SET_URLB_HISTORY,
    SET_DOCKED_CONTROL_PANEL,
    SET_SLIDE_MODE_CONTROL_PANEL
} from 'app/actions/controlPanel/controlPanel_action.js';

function controlPanelData(state={
    isShowLoginModal:false,
    controlPanelDisplay:false,
    controlPanelDocked:false,
    controlPanelSlideMode:'normal',
    urlaHistoryData:[],
    urlbHistoryData:[]
},action) {
    switch (action.type) {
        case SWITCH_IS_SHOW_LOGIN_MODAL:
            var newState = Object.assign({},state);
            newState.isShowLoginModal= action.data;
            return newState;
        case SET_DISPLAY_CONTROL_PANEL:
            var newState = Object.assign({},state);
            if(newState.controlPanelSlideMode=='normal'){
                newState.controlPanelDisplay= action.data;
            }else {
                newState.controlPanelDisplay= action.data;
                newState.controlPanelDocked= action.data;
            }
            newState.controlPanelDisplay= action.data;
            return newState;
        case SWITCH_DISPLAY_CONTROL_PANEL:
            var newState = Object.assign({},state);
            if(newState.controlPanelSlideMode=='normal'){
                newState.controlPanelDisplay= !newState.controlPanelDisplay;
            }else {
                newState.controlPanelDisplay= !newState.controlPanelDocked;
                newState.controlPanelDocked= !newState.controlPanelDocked;
            }
            return newState;
        case SET_DOCKED_CONTROL_PANEL:
            var newState = Object.assign({},state);
            newState.controlPanelDocked = action.data;
            return newState;
        case SET_SLIDE_MODE_CONTROL_PANEL:
            var newState = Object.assign({},state);
            newState.controlPanelSlideMode= action.data;
            return newState;
        case SET_URLA_HISTORY:
            var newState = Object.assign({},state);
            if (action.data) {
                newState.urlaHistoryData = action.data;
            }
            return newState;
        case SET_URLB_HISTORY:
            var newState = Object.assign({},state);
            if (action.data) {
                newState.urlbHistoryData = action.data;
            }
            return newState;
        default:
            return state;
    }
}

export default controlPanelData;

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GFormInput,GButton} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/entryHeaderEdit_style.css';

class EntryHeaderEdit extends React.Component {
    componentDidMount(){
        var {owner,title,description} =this.props.entry.entryData.urlaData;
        this.props.gFormInputActions.updateData('title',title,'headerEditFrom');
        this.props.gFormInputActions.updateData('owner',owner,'headerEditFrom');
        this.props.gFormInputActions.updateData('description',description,'headerEditFrom');
        this.props.gFormInputActions.setInputChanged(false,'headerEditFrom');
    }
    _renderSubmitButton(){
        var headerEditFrom = this.props.gFormInput.headerEditFrom;
        if(headerEditFrom && headerEditFrom.finalCheck && headerEditFrom.isChanged){
            return(
                <GButton default="green" style={{height:'45',width:'135'}} onClick={this._onClickSubmit.bind(this)}><FormattedMessage id="entryHeaderEdit.saveMessage" /></GButton>
            );
        }else {
            return (
                <GButton default="gray" style={{height:'45',width:'135'}}><FormattedMessage id="entryHeaderEdit.saveMessage" /></GButton>
            );
        }
    }
    _onCancel(){
        this.props.entryHeaderActions.setShowMode('info');
    }
    _onClickSubmit(){
        var hashUrla = this.props.hashUrla;
        var {title,description,owner} = this.props.gFormInput['headerEditFrom'].data;
        this.props.entryHeaderActions.editHeaderDataToServer(hashUrla,title,owner,description);
    }
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headerTop}>
                    <div className={styles.ownerBoard}>
                        <GFormInput
                            type="text"
                            name="owner"
                            autoFocus
                            form="headerEditFrom"
                            placeholder= {intl.formatMessage({ id: 'entryHeaderEdit.eventOwnerMessage' })}
                            inputClassName={styles.ownerInput}
                            containerStyle={{width:300}}
                            checks={['required']}
                            default="medium"/>
                    </div>
                    <div className={styles.titleBoard}>
                        <GFormInput
                            type="text"
                            name="title"
                            autoFocus
                            form="headerEditFrom"
                            placeholder= {intl.formatMessage({ id: 'entryHeaderEdit.eventNameMessage' })}
                            inputClassName={styles.titleInput}
                            checks={['required']}
                            default="large"/>
                    </div>
                </div>
                <div className={styles.headerFooter}>
                    <GFormInput
                        type="text"
                        format="textarea"
                        name="description"
                        form="headerEditFrom"
                        containerStyle={{marginBottom:'5'}}
                        placeholder= {intl.formatMessage({ id: 'entryHeaderEdit.eventInformationMessage' })}
                        style={{minHeight:100}}
                        inputClassName={styles.descriptionInput}
                        default="medium"/>
                    <div className={styles.buttonBoard}>
                        <GButton default="red" style={{height:'45',width:'135'}} onClick={this._onCancel.bind(this)}><FormattedMessage id="entryHeaderEdit.cancelMessage" /></GButton>
                        <div style={{flex:1}}></div>
                        {this._renderSubmitButton()}
                    </div>
                </div>

            </div>
        );
    }
}

const EntryHeaderEditInjectIntl = injectIntl(EntryHeaderEdit);

export default EntryHeaderEditInjectIntl;

var ReactDOM = require('react-dom');
var React = require('react');
module.exports.errorList = {
    e0002 : {
        title:'Rất tiếc !',
        info :'Ảnh minh hoạ của url không khả dụng...'
    },
    e0005 : {
        title:'Rất tiếc !',
        info :'Username này đã được sử dụng bởi một người khác...'
    },
    e0006 : {
        title:'Cảnh báo !',
        info :'Username không được sử dụng dấu ...'
    },
    e0007 : {
        title:'Cảnh báo !',
        info :'Chưa login.'
    },
    e0008 : {
        title:'Cảnh báo !',
        info :'Mật khẩu không được quá ngắn hoặc quá dài(tối thiểu 8 ký tự)'
    },
    e0009 : {
        title:'Cảnh báo !',
        info :'Mật khẩu cũ không đúng...'
    },
    e0010 : {
        title:'Cảnh báo !',
        info :'Username mới bị trùng với username cũ của bạn...'
    },
    e0011 : {
        title:'Cảnh báo !',
        info :'Bạn không thể cùng một lúc đăng nhập 2 tài khoản...'
    },
     e0012 : {
       title:'Cảnh báo !',
        info :'Mật khẩu hoặc tên đăng nhập không đúng...'
    },
     e0013 : {
        title:'Cảnh báo !',
        info :'Mật khẩu hoặc tên đăng nhập không đúng...'
    },
    e0014 : {
        title:'Cảnh báo !',
        info :'Link này đã được bạn đăng rồi ...'
    },
    e0015 : {
        title:'Cảnh báo !',
        info :'Link không đầy đủ nội dung.'
    },
    e0016 : {
        title:'Cảnh báo !',
        info :'Tên chuyên trang không hợp lệ.'
    }
};

module.exports.infoList = {
    i0001 : {
        title:'Thành công !',
        info :'Chúc mừng bạn đã đổi mật khẩu thành công ...'
    },
    i0002 : {
        title:'Không hợp lệ!',
        info :'Địa chỉ email không hợp lệ.'
    },
    i0003 : {
        title:'Đã tồn tại!',
        info:'Địa chỉ email đã tồn tại.'
    },
    i0004 : {
        title:'Bắt buộc!',
        info:'Bạn không thể để trống mục này.'
    },
    i0005 : {
        title:'Không hợp lệ!',
        info :'Username không hợp lệ.'
    },
    i0006 : {
        title:'Không hợp lệ!',
        info :'Mật khẩu mới không trùng khớp.'
    },
    i0007 : {
        title:'Không hợp lệ!',
        info :'Mật khẩu không hợp lệ.'
    },
    i0008 : {
        title:'Không hợp lệ!',
        info :'Tên hiển thị không hợp lệ.'
    }
};

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/urlbList_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

import StatusTag from 'app/components/controlPanel/statusTag.jsx';
import LoadMoreButton from 'app/components/list/loadMoreButton.jsx';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';

import {checkDeadline,minDate} from 'app/js/func.js';
import moment from 'moment';
import 'moment/locale/vi';

export default class UrlbList extends React.Component {
    componentDidMount(){
        this.props.urlbListActions.fetchUrlbList('');
    }
    handleOnClick() {
        //this.props.historyPageActions.switchUrlHistory(1);
    }
    loadMore(){
        var list = this.props.urlbList.list;
        this.props.urlbListActions.fetchUrlbList(list[list.length-1].createdAt);
    }
    _renderLoadMoreButton(){
        if(this.props.urlbList.isLoading){
            return(
                <center>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'44',color:'#02499d'}} aria-hidden="true"></i>
                </center>
            );
        }else {
            if(this.props.urlbList.isLoadMore){
                return(
                    <LoadMoreButton onClick={this.loadMore.bind(this)}/>
                );
            }else {
                return null;
            }
        }
    }
    _linkToGenerate(){
        browserHistory.push('/generate');
    }
    _renderList(){
        var data = this.props.urlbList.list.slice(0);
        if(data.length>0){
            var nodeList = data.map((item,index) => {
                return (
                    <UrlbUnit
                        indexKey={index}
                        urlbUnitData={item}
                    />)
            })
            return (
                <div>
                    {nodeList}
                </div>
            );
        }else {
            if(this.props.urlbList.isLoading) return null;
            return(
                <GNullBoard>
                    <p><FormattedMessage id="urlbList.notJoinedEventMessage" /></p>
                    <GButton default='primary' onClick={this._linkToGenerate.bind(this)}><i className="fa fa-plus-circle" aria-hidden="true"></i> New event</GButton>
                </GNullBoard>
            );
        }
    }
    render() {

        return (
            <div style={{marginBottom:'10px'}}>
                {this._renderList()}
                {this._renderLoadMoreButton()}
            </div>
        )
    }
}

export class UrlbUnit extends React.Component {
    handleOnClick() {
        browserHistory.push('/entry/'+this.props.urlbUnitData.urla.hashUrlb);
    }
    _renderNewMark(){
        var {createdAt} = this.props.urlbUnitData;
        createdAt = new Date(createdAt).getTime()/1000;
        var toDay = new Date().getTime() / 1000;

        console.log(toDay - createdAt);
        if(toDay - createdAt<86400){
            return(
                <div className={styles.newMark}>New</div>
            );
        }else {
            return null;
        }
    }
    render() {
        var {description,hashUrlb,user} = this.props.urlbUnitData.urla;
        var flag = this.props.urlbUnitData.flag;
        var deadline = (this.props.urlbUnitData.urla.deadLine)? (this.props.urlbUnitData.urla.deadLine) : null;
        var selectedDays = (this.props.urlbUnitData.urla.schedule.selectedDays)? (this.props.urlbUnitData.urla.schedule.selectedDays) : {};
        var status = checkDeadline(deadline,selectedDays,flag);
        var votes = this.props.urlbUnitData.urla.schedule.votes;
        var thumbnail = (this.props.urlbUnitData.urla.setting)?this.props.urlbUnitData.urla.setting.thumbnail:null;
        var iconUrl = (thumbnail)?thumbnail : '/images/icon/select.png';
        var timeStamp = moment(minDate(selectedDays)).fromNow();
        timeStamp = (timeStamp!='Invalid date')?timeStamp:'';
        var avatarUrl = (user)?user.profile.avatarUrl:'/images/control/nullAvatar.jpg';
        return (
            <div className={globalStyles.white_box}>
                <div className={styles.url_unit_board}>
                    <Link to={'/entry/'+hashUrlb} className={styles.title}>{this.props.urlbUnitData.urla.title}</Link>
                    <div className={styles.body}>
                        <div className={styles.url_unit_content}>
                            <p className={styles.description} onClick={(event) => {this.handleOnClick()}}>{description}</p>
                            <div className={styles.stats_board}>
                                <i style={{marginRight:'5px'}} className="fa fa-eye"/>
                                <span className={styles.status_text}>{this.props.urlbUnitData.urla.viewCount}</span>
                                <i style={{marginRight:'5px',marginLeft:'5px'}} className="fa fa-user"/>
                                <span className={styles.status_text}>{this.props.urlbUnitData.urla.urlbs.length}</span>
                                <span style={{margin:'0 5px'}}> · </span>
                                {timeStamp}
                            </div>
                        </div>
                        <div className={styles.url_unit_icon} onClick={(event) => {this.handleOnClick()}}>
                            {this._renderNewMark()}
                            <img className={styles.urlb_unit_icon_img} src={iconUrl} />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.unit_owner}>
                            <object data={avatarUrl} type="image/jpg" className={styles.avatar}>
                                <img src="/images/control/nullAvatar.jpg" className={styles.avatar}/>
                            </object>
                            <div className={styles.left}>
                                <span className={styles.ownerLable}>Chủ kèo</span>
                                <span className={styles.ownerName}>{this.props.urlbUnitData.urla.owner}</span>
                            </div>
                        </div>
                        <div className={styles.status_bar}>
                            <StatusTag status={status} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {GModal,GButton,CheckBox} from 'app/components/gTools/gTools.jsx';
import styles from './styles/selectTimePanel_style.css';
import TimeRangeList from 'app/components/time/timeRangeList.jsx';
import TimePicker from 'app/components/time/timePicker.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
moment.updateLocale('vi', {months : 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
export default class SelectTimePanel extends React.Component {
    render() {
        var nodes =[];
        var selectedDays = this.props.data;
        var {deadLine,pickMode,disable,voteTimeMode} = this.props;
        var length = selectedDays.length;
        for(var i=0;i<selectedDays.length;i++){
            //if(selectedDays[i].picked ||selectedDays[i].time){
                nodes.push(<DateItem
                                deadLine={deadLine}
                                pickMode={pickMode}
                                disable={disable}
                                voteTimeMode={voteTimeMode}
                                data={selectedDays[i]}
                                key={i}
                                index={i}
                                length={length}
                                entryDatePickerActions={this.props.entryDatePickerActions}
                                pickDate={this.props.pickDate}/>);
            //}
        }
        return (
            <div className={styles.container}>
                {nodes}
            </div>
        );
    }
}


class DateItem extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            visiblePickTimeModal:false,
            timeRange:30
        }
    }
    _onPressCancel(){
        this.setState({
            visiblePickTimeModal:false
        });
    }
	_onPressSetTimeRange(){
		var time = this.refs.timePicker.getTimeRanges();
		this.props.entryDatePickerActions.addTimeRange(time,this.props.index);
		this._onPressCancel();
	}
    swtichTimeRange(){
        this.setState({
            timeRange:(this.state.timeRange==30)?15:30
        });
    }
    _renderSwitchTimeRangeButton(){
        if(this.state.timeRange==30){
            return(
                <span className={styles.linkButton} onClick={this.swtichTimeRange.bind(this)}>
                    <i className="fa fa-square-o" aria-hidden="true"></i> <FormattedMessage id="selectTimePanel.advance15MinutesMessage" />
                </span>
            );
        }else {
            return(
                <span className={styles.linkButton+' '+styles.linkButtonSelect} onClick={this.swtichTimeRange.bind(this)}>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i> <FormattedMessage id="selectTimePanel.advance15MinutesMessage" />
                </span>
            );
        }
    }
    _renderPickTimeModal(){
		//var time = (this.props.data.time)?this.props.data.time:[];
        var time = [];
        return(
            <GModal
                visible={this.state.visiblePickTimeModal}
                closeModal={this._onPressCancel.bind(this)}>
    				<p className={styles.pickTimeModalText}>
    					{moment(this.props.data.day).format('dddd')+' '+moment(this.props.data.day).format('DD/MM/YYYY')}
    				</p>
    				<p className={styles.pickTimeModalDescription}>
                        <FormattedMessage id="selectTimePanel.selectTimeRangeMessage" />
    				</p>
    				<TimePicker ref='timePicker' time={time} range={this.state.timeRange}/>
                    <div className={styles.controlBar}>
                        {this._renderSwitchTimeRangeButton()}
                    </div>
                    <GButton
                        default='primary'
                        style={{marginBottom:10,width:'100%',marginTop:10}}
                        onClick={this._onPressSetTimeRange.bind(this)}><FormattedMessage id="selectTimePanel.confirmMessage" /></GButton>
    				<GButton
                        default='gray'
                        style={{marginBottom:10,width:'100%'}}
                        onClick={this._onPressCancel.bind(this)}><FormattedMessage id="selectTimePanel.skipMessage" /></GButton>
           </GModal>
        );
    }
    _renderTimeRange(){
        var time = (this.props.data.genTime)?this.props.data.genTime:this.props.data.time;
        var {disable,pickMode,deadLine,voteTimeMode} = this.props;
        //time = this.props.data.time;
		var timeRangeList = <TimeRangeList
                                selectedDayIndex={this.props.index}
                                data={time}
                                voteTimeMode={voteTimeMode}
                                entryDatePickerActions={this.props.entryDatePickerActions}
                                disable={disable}
                                picked={this.props.data.picked}
                                pickMode={pickMode}/>;
        var pencil = (deadLine||this.props.data.genTime)?
                        null:
                        <div style={{alignSelf:'flex-start'}}>
                             <i className='fa fa-pencil' style={{color: '#70b4ff'}} aria-hidden="true"></i>
                        </div>;
        return(
            <div className={styles.timeRangeBoard}>
				<div>
					{timeRangeList}
				</div>
                <div style={{flex:1}}></div>
            </div>
        )
    }
    _onPressShowPickTimeModal(){
        if(this.props.deadLine||this.props.data.genTime) return;
        this.setState({
            visiblePickTimeModal:true
        });
    }
    _onCheckBoxChanged(){
        this.props.pickDate(this.props.data.day);
    }
    _renderDate(){
        var dateString = moment(this.props.data.day).format('dddd').capitalize()+' '+moment(this.props.data.day).format('DD/MM/YYYY');
        return (
            <div style={{display:'flex'}} className={styles.dateText}>
                {dateString}
            </div>
        );
    }
    _renderTimeList(){
        var time = (this.props.data.time)?this.props.data.time:[];
        return(
            <TimeList
                data={time}
                entryDatePickerActions={this.props.entryDatePickerActions}
                dayIndex={this.props.index}/>
        );
    }
    _onClickCopyTimeRangeFromAbove(){
        this.props.entryDatePickerActions.copyTimeRangeFromAbove(this.props.index);
    }
    renderSimilarButton(){
        if(this.props.index!=0){
            return(
                <span className = {styles.similarButton} onClick={this._onClickCopyTimeRangeFromAbove.bind(this)}>
                    <i className='fa fa-download' aria-hidden="true"></i> <FormattedMessage id="selectTimePanel.sameAsAboveMessage" />
                </span>
            );
        }
    }
	render() {
        var rightHover = (this.props.data.genTime)?'':styles.rightHover
        var borderBottom = (this.props.length-1 == this.props.index)?'':styles.bottomBorder;
		return (
			<div className={styles.dateItem+' '+borderBottom}>
                {this._renderPickTimeModal()}
                <div className={styles.dateItemLeft}>
                    {this._renderDate()}
                </div>
                <div className={styles.dateItemRight+' '+rightHover}>
                    {this._renderTimeList()}
                    <div className={styles.extraBar}>
                        <p className={styles.addButton} onClick={this._onPressShowPickTimeModal.bind(this)}>+ <FormattedMessage id="selectTimePanel.addTimeSlotMessage" /></p>
                        <div style={{flex:1}}></div>
                        {this.renderSimilarButton()}
                    </div>
                </div>
			</div>
		);
	}
}

class TimeList extends React.Component {
    render() {
        var self = this;
        var nodes = this.props.data.map(function(item,index){
            return <TimeItem
                        data={item}
                        index={index}
                        dayIndex={self.props.dayIndex}
                        entryDatePickerActions={self.props.entryDatePickerActions}/>;
        });
        return (
            <div className={styles.timeList}>
                {nodes}
            </div>
        );
    }
}

class TimeItem extends React.Component {
    _onClickSliceTime(){
        this.props.entryDatePickerActions.sliceTimeRange(this.props.index,this.props.dayIndex);
    }
    render() {
        var data = this.props.data;
        return (
            <div className={styles.timeItem}>
                <span>{convertToTimeString(data.start)+' ~ '+convertToTimeString(data.end)}</span>
                <div style={{flex:1}}></div>
                <div style={{alignSelf:'flex-start'}}>
                     <i className={'fa fa-times '+styles.deleteIcon} onClick={this._onClickSliceTime.bind(this)} aria-hidden="true"></i>
                </div>
            </div>
        );
    }
}

function convertToTimeString(time){
    if(!time&&time!=0) return "__:__";
    var hour = Math.floor(time/3600);
    var minute = (time-hour*3600)/60;
    return hour + ':'+((minute.toString().length>1)?minute:'0'+minute);
}

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/panel_style.css';

export default class Panel extends React.Component {
	_onSiteChanged(){
		if(this.props.onChange){
			this.props.onChange();
		}
	}
	render() {
        var {style,title,className,topClassName,topStyle,topRightButton,icon} = this.props;
        title = {title}?title:'';
		var icon = (icon)?<img src={icon} className={styles.icon}/>:null;
		return (
            <div className={styles.main+' '+className} style={style}>
                <div  className={styles.top+' '+topClassName} style={topStyle}>
					<div className={styles.top_left}>
						{icon}
						<div className={styles.title}>
							{title}
						</div>
					</div>
					<div className={styles.top_right}>{topRightButton}</div>
				</div>
                <div className={styles.body}>
                    {this.props.children}
                </div>
            </div>
		);
	}
}

import React from 'react';
import styles from './styles/socialBar_style.css';

export default class SocialBar extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isLoading:true
        };
    }
    componentDidMount(){
        if (window.FB) {
            var obj=this;
              // Read the entire document for `fb-*` classnames
              FB.XFBML.parse(this._scope,function(){
                  obj.setState({
                      isLoading:false
                  });
              });
        }
    }
    _onClickMobileSendFb(){
        window.open('fb-messenger-share://share?link=' + encodeURIComponent(this.props.url) + '&app_id=' + encodeURIComponent('795287573961298'));
    }
    _renderMain(){
        var width = window.innerWidth;
        if(width>768){
            return(
                <div className={styles.container}>
                    <div
                        className="fb-send"
                        data-href={this.props.url}
                        data-size="small"
                        data-mobile-iframe="true"
                        data-layout="button_count">
                    </div>
                    <div
                        className="fb-share-button"
                        data-href={this.props.url}
                        data-layout="button"
                        data-size="small"
                        data-mobile-iframe="true">
                    </div>
                    <div className="fb-save" data-uri={this.props.url} data-size="small"></div>
                </div>
            );
        }else {
            return(
                <div className={styles.container}>
                    <img src='/images/control/fb-send.png' className={styles.imageButton} onClick={this._onClickMobileSendFb.bind(this)}/>
                    <div
                        className="fb-share-button"
                        data-href={this.props.url}
                        data-layout="button"
                        data-size="small">
                    </div>
                </div>
            );
        }
    }
    render() {
        var loadingDisplay = (this.state.isLoading)? 'block' : 'none';
        var barDisplay = (this.state.isLoading)? 'none' : 'flex';
            return(
                <div ref={(s) => this._scope = s}>
                    <div style={{display:barDisplay,justifyContent:'center'}}>
                        {this._renderMain()}
                    </div>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'17',color:'#02499d',display:loadingDisplay}} aria-hidden="true"></i>
                </div>
         )
    }
}
//<img src='/images/control/fb-share.png' className={styles.imageButton}/>

import {
    combineReducers
} from 'redux';

import {
    SET_COLLECTION_DATA,
    SET_STICKER_DATA,
    SET_SELECTED_INDEX,
    SET_STICKER_INDEX,
    SET_EXISTED_STICKER_DATA
} from 'app/actions/collection/collection_action.js';

function collectionData(state={
	list:[],
	selectedIndex:-1,
    selectedCollectionId:-1
},action) {
    switch (action.type) {
        case SET_COLLECTION_DATA:
            var newState = Object.assign({},state);
            newState.list = action.data;
            return newState;
        case SET_SELECTED_INDEX:
            var newState = Object.assign({},state);
            newState.selectedIndex = action.index;
            newState.selectedCollectionId = newState.list[action.index].id;
            return newState;
        default:
            return state;
    }
}

function stickerData(state={
    list:[],
    selectedData:{}
},action) {
    switch (action.type) {
        case SET_STICKER_DATA:
            var newState = Object.assign({},state);
            newState.list = action.data;
            return newState;
        case SET_STICKER_INDEX:
            var newState = Object.assign({},state);
            if(action.index==-1) {
                newState.selectedData[action.parentId] = null;
            }else {
                newState.selectedData[action.parentId] = newState.list[action.index];
            }
            return newState;
        case SET_EXISTED_STICKER_DATA:
            var newState = Object.assign({},state);
            if(action.index==-1) {
                newState.selectedData[action.parentId] = null;
            }else {
                newState.selectedData[action.parentId] = action.data;
            }
            return newState;

        default:
            return state;
    }
}

const collectionReducer = combineReducers({
    collectionData:collectionData,
    stickerData:stickerData
})
export default collectionReducer;

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/userHeader_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class CPuserHeader extends React.Component {

    render() {
        var urlaData = this.props.user.userData;
        var avatarUrl = (this.props.user.userData.profile)? this.props.user.userData.profile.avatarUrl : '';
        var urlbCount = (urlaData)? urlaData.urlbCount : 0;
        var urlaCount = (urlaData)? urlaData.urlaCount : 0;
        return (
                <div className={globalStyles.flex_container}>
                    <img className={styles.avatar_img} src={avatarUrl}></img>
                    <div className={globalStyles.flex_container} style={{flexDirection:'column',flex:1}}>
                        <p style={{fontSize:'17px', fontWeight:'bold',margin:'0'}}>
                            {this.props.user.userData.displayName}
                        </p>
                        <div className={styles.stats_text}>
                            {urlbCount} <FormattedMessage id="userHeader.eventParticipatedMessage" />
                        </div>
                        <div className={styles.stats_text}>
                            {urlaCount} <FormattedMessage id="userHeader.eventCreatedMessage" />
                        </div>
                    </div>
                    <div>
                        <i
                            className={'fa fa-chevron-left '+styles.hideButton}
                            aria-hidden="true"
                            onClick={this.props.switchControlPanel}></i>
                    </div>
                </div>
        )
    }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import PickVotePanel from 'app/components/vote/pickVotePanel.jsx';

import * as pickVotePanelActions from 'app/actions/vote/pickVotePanel_action.js';
import * as votePanelActions from 'app/actions/vote/votePanel_action.js';
import * as userActions from 'app/actions/user/user_action.js';
import * as controlPanelActions from 'app/actions/controlPanel/controlPanel_action.js';

function mapDispatchToProps(dispatch) {
    return {
        pickVotePanelActions : bindActionCreators(pickVotePanelActions,dispatch),
		votePanelActions : bindActionCreators(votePanelActions,dispatch),
		userActions : bindActionCreators(userActions,dispatch),
        controlPanelActions : bindActionCreators(controlPanelActions,dispatch)
    }
}

function mapStateToProps(state) {
	return ({
        pickVotePanel:state.pickVotePanel,
		votePanelData:state.votePanelData,
		user:state.user,
		language:state.language
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(PickVotePanel);

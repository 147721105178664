exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._10ZN2es26ZnFHwHglP8lVC {\n    margin-bottom: 100px;\n}\n\n._2YtW8vpVOLHi8f77t85qpr {\n    font-size: 30px;\n    text-align: center;\n    font-weight: 600;\n    color: #fff;\n    line-height: 1.4em;\n    text-shadow: 1px 1px 1px black;\n}\n\n._167x5t1py1EtcjTaHRiqVs {\n    text-align: center;\n    font-size: 14px;\n    font-weight: 400;\n    color: #636363;\n    line-height: 1.4em;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 10px;\n}\n\n._1MbmvKZhVOiQler4JPVcfP {\n    background-color: rgba(0,0,0,.5);\n    border-radius: 9px;\n    color: #fff;\n    padding: 3px 10px 3px 10px;\n}\n\n._1wuorBaJ3UkIZBARaHMLgB {\n    font-size: 18px;\n    color: #02499d;\n    font-weight: bold;\n    text-align: center;\n    line-height: 1.4em;\n    letter-spacing: -.02em!important;\n    text-transform: uppercase;\n}\n\n.nnevT6ZKS_P0cMUrfmYS1 {\n    text-align: left;\n    color: #636363;\n    font-size: 15px;\n    text-indent: 4px;\n    margin-top: 30px;\n}\n\n._3VjeBrx-Gq7rKDnOfbAMCO {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n._3cnm7w1izOjB6sRFAUWlf2 {\n    overflow: hidden;\n    position: relative;\n    padding: 20px 0 0 0;\n    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);\n}\n\n._1oV9H8YhkMM2lD3M1sWV-A {\n    position: absolute;\n    top: 0;\n    z-index: -1;\n    left: 0;\n}\n.Ss1o1fuAkFQUNaJzXCmdD{\n    color: #333;\n    text-align: center;\n}\n._1GY22-5rFEFzyat6OjclLN{\n    width: 150px;\n}\n", ""]);

// exports
exports.locals = {
	"margin_bottom": "_10ZN2es26ZnFHwHglP8lVC",
	"header_title": "_2YtW8vpVOLHi8f77t85qpr",
	"header_owner": "_167x5t1py1EtcjTaHRiqVs",
	"header_owner_bg": "_1MbmvKZhVOiQler4JPVcfP",
	"header_description": "_1wuorBaJ3UkIZBARaHMLgB",
	"url_box_label": "nnevT6ZKS_P0cMUrfmYS1",
	"social_bar": "_3VjeBrx-Gq7rKDnOfbAMCO",
	"header_container": "_3cnm7w1izOjB6sRFAUWlf2",
	"header_bg": "_1oV9H8YhkMM2lD3M1sWV-A",
	"shareTitle": "Ss1o1fuAkFQUNaJzXCmdD",
	"tung_hoa_image": "_1GY22-5rFEFzyat6OjclLN"
};
import React from 'react';
import {Link,browserHistory} from 'react-router';
import PickDetails from 'app/components/comment/pickDetails.jsx';
import SendButton from 'app/components/socialbar/sendButton.jsx';
import {DialogModal,GNullBoard} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/listPick_style.css';
import globalStyles from 'app/styles/global_styles.css';

export default class ListPick extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            visibleMessageModal:false,
            userId:null,
            hashUrla:null,
            urlbId:null,
            index:null
        }
    }
    componentDidMount(){
        var {hashUrlb} = this.props;
        this.props.listPickActions.fetchListPick(hashUrlb);
    }
    componentWillReceiveProps(nextProps){
        if(this.props.hashUrlb!=nextProps.hashUrlb){
            this.props.listPickActions.fetchListPick(nextProps.hashUrlb);
        }
    }
    componentWillUnmount(){
        this.props.listPickActions.reset();
    }
    deleteEntryFromServer(){
        var {hashUrla,urlbId,userId,index} = this.state;
        this.props.listPickActions.deleteEntryFromServer(hashUrla,urlbId,userId,index);
    }
    showDeleteMessageBox(hashUrla,urlbId,userId,index){
        this.setState({
            visibleMessageModal:true,
            userId:userId,
            hashUrla:hashUrla,
            urlbId:urlbId,
            index:index
        });
    }
    _renderList(){
        var {hashUrlb,pickList,hashUrla} = this.props;
        var self = this;
        var list = pickList.list.map(function(item,index){
            return <UserItem
                        data={item}
                        index={index}
                        hashUrlb={hashUrlb}
                        hashUrla={hashUrla}
                        selectedIndex={pickList.selectedIndex}
                        showDeleteMessageBox={self.showDeleteMessageBox.bind(self)}
                        fetchPickDetailsFromServer={self.props.listPickActions.fetchPickDetailsFromServer}/>;
        });
        if(list.length>0){
            return list;
        }else {
            return(
                <GNullBoard><FormattedMessage id="listPick.noParticipantsMessage" /></GNullBoard>
            );
        }
        return list;
    }
    _renderPickDetails(){
        if(this.props.pickList.selectedData){
            return (
                <PickDetails
                    style={{marginTop:0}}
                    data={this.props.pickList.selectedData}/>
            );
        }else {
            return null;
        }
    }
	render() {
        var count = this.props.pickList.list.length;
        var {hashUrlb} = this.props;
        return (
			<div className={styles.container}>
                <DialogModal
                    description={<FormattedMessage id="listPick.confirmDeleteMessage" />}
                    submitModal={this.deleteEntryFromServer.bind(this)}
                    visible={this.state.visibleMessageModal}
                    closeModal={()=> this.setState({visibleMessageModal:false})}/>
                <div className={styles.top}>
                    <span className={styles.topTitle}><span className={styles.countText}>{count}</span> <FormattedMessage id="listPick.eventParticipantCountMessage" /></span>
                    <SendButton hashUrlb={hashUrlb}/>
                </div>
                <div className={styles.listBoard}>
                    {this._renderList()}
                </div>
                {this._renderPickDetails()}
			</div>
		);
	}
}


class UserItem extends React.Component {
   _onClick(){
    //    var {index,hashUrlb,data} = this.props;
    //    this.props.fetchPickDetailsFromServer(index,hashUrlb,data.id);
   }
   _onClickDelete(){
       var {hashUrla,index} = this.props;
       var urlbId = this.props.data.id;
       var userId= (this.props.data.user)?this.props.data.user.id:null;
       this.props.showDeleteMessageBox(hashUrla,urlbId,userId,index);
   }
   _renderDeleteButton(){
       if(this.props.hashUrla){
           return(
               <i className={'fa fa-times '+styles.deleteButton} aria-hidden="true" onClick={this._onClickDelete.bind(this)}></i>
           );
       }else {
           return null;
       }
   }
   render() {
       var avatarUrl = '/images/control/nullAvatar.jpg';
       var {user,name} = this.props.data;
       if(user){
           avatarUrl = user.profile.avatarUrl;
           name = user.displayName;
       }
       var className = (this.props.selectedIndex==this.props.index)?styles.selected:'';
       return (
           <div className={styles.userItemContainer+' '+className} onClick={this._onClick.bind(this)}>
                <object data={avatarUrl} type="image/jpg" className={styles.avatar}>
                   <img src='/images/control/nullAvatar.jpg' className={styles.avatar}/>
                </object>
                <span className={styles.userName}>{name}</span>
                <div style={{flex:1}}></div>
                {this._renderDeleteButton()}
           </div>
       );
   }
}

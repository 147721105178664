export const ENTRY_PICK_VOTE = 'ENTRY_PICK_VOTE';
export const ENTRY_UPDATE_VOTE_DATA = 'ENTRY_UPDATE_VOTE_DATA';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const SWITCH_ADD_VOTE_MODE = 'SWITCH_ADD_VOTE_MODE';
export const SET_FETCHED_VOTE_DATA = 'SET_FETCHED_VOTE_DATA';
export const SET_SHOW_MODE_PICK_VOTE_PANEL = 'SET_SHOW_MODE_PICK_VOTE_PANEL';
export const PICK_ALL = 'PICK_ALL';

export function pickVote(index) {
    return({
        type: ENTRY_PICK_VOTE,
        index: index
    });
}
export function updateVoteData(data) {
    return {
        type: ENTRY_UPDATE_VOTE_DATA,
        data: data
    }
}

export function toggleMap(index,data) {
    return {
        type: TOGGLE_MAP,
        index:index,
        data: data,
    }
}

export function switchAddVoteMode(data) {
    return {
        type: SWITCH_ADD_VOTE_MODE,
        data: data
    }
}

export function setFetchedVoteData(data) {
    return {
        type: SET_FETCHED_VOTE_DATA,
        data: data
    }
}
export function setShowMode(data) {
    return {
        type: SET_SHOW_MODE_PICK_VOTE_PANEL,
        data: data
    }
}

export function pickAll() {
    return {
        type: PICK_ALL
    }
}

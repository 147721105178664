import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import { GModal, GButton, CheckBox, AlertBox, Dropdown } from 'app/components/gTools/gTools.jsx';
import { DropdownTrigger, DropdownContent, DropdownStyles } from 'app/components/gTools/dropButton.jsx';
import DropdownItem from 'app/components/menu/dropdownItem.jsx';
import styles from './styles/voteTimePanel_style.css';
import globalStyles from 'app/styles/global_styles.css';
import EntryDatePicker from 'app/containers/entry/entryDatePicker_container.jsx';
import DayPicker, { DateUtils } from 'react-day-picker';
import { FormattedMessage, injectIntl } from 'react-intl';

import TimeRangeList from 'app/components/time/timeRangeList.jsx';
import TimePicker from 'app/components/time/timePicker.jsx';
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
moment.updateLocale('vi', { months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_') });
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
export default class VoteTimePanel extends React.Component {
    _onClickPickAll() {
        this.props.voteTimePanelActions.setAllVoteTime();
    }
    _onClickShowEditPanel() {
        this.props.voteTimePanelActions.setVoteTimePanelMode('edit');
        var selectedDays = this.props.voteTimePanel.selectedDays;
        var entryDatePickerData = [];
        for (var i = 0; i < selectedDays.length; i++) {
            var selectedIndex = entryDatePickerData.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay.day, selectedDays[i].day)
            );
            if (selectedIndex == -1) {
                var time = [];
                time.push(selectedDays[i].value.time);
                time[0].id = selectedDays[i].id;
                entryDatePickerData.push({
                    day: selectedDays[i].day,
                    picked: true,
                    time: time
                });
            } else {
                var time = selectedDays[i].value.time;
                time.id = selectedDays[i].id;
                entryDatePickerData[selectedIndex].time.push(time);
            }
        }
        this.props.entryDatePickerActions.setEntryDatePickerData2(entryDatePickerData);
    }
    _headerEditMenu() {
        if (this.props.deadLine) {
            //het han
            return null;
        }
        var dropdownTrigger = <i className={'fa fa-sliders ' + globalStyles.menuButton} aria-hidden="true"></i>;
        var isOwner = (this.props.hashUrla) ? true : false;
        return (
            <Dropdown dropdownTrigger={dropdownTrigger}>
                <DropdownContent className={DropdownStyles.drop_right} style={{ minWidth: 210 }}>
                    <DropdownItem
                        disable={!isOwner}
                        icon='fa fa-pencil-square-o'
                        title={<FormattedMessage id="voteTimePanel.editMessage" />}
                        description={<FormattedMessage id="voteTimePanel.changeVoteTimeMessage" />}
                        underline
                        onClick={this._onClickShowEditPanel.bind(this)} />
                    <DropdownItem
                        icon='fa fa-check'
                        title={<FormattedMessage id="voteTimePanel.allVotedMessage" />}
                        description={<FormattedMessage id="voteTimePanel.selectAllMessage" />}
                        onClick={this._onClickPickAll.bind(this)} />
                </DropdownContent>
            </Dropdown>
        );
    }
    _onClickAdd() {

    }
    _renderAddButton() {
        if (!this.props.deadLine) {
            return (
                <AddVoteBoard
                    deadLine={this.props.deadLine}
                    hashUrlb={this.props.hashUrlb}
                    urlaId={this.props.urlaId}
                    user={this.props.user}
                    controlPanelActions={this.props.controlPanelActions}
                    voteTimePanelActions={this.props.voteTimePanelActions}
                    isShowAddVoteTime={this.props.voteTimePanel.isShowAddVoteTime}
                    {...this.props} />
            );
        } else {
            return null;
        }
    }
    _renderEntryDatePicker() {
        if (this.props.voteTimePanel.mode == 'vote') {
            var self = this;
            var { deadLine } = this.props;
            var selectedDays = this.props.voteTimePanel.selectedDays;
            var nodes = selectedDays.map(function (item, index) {
                return <DateItem
                    deadLine={deadLine}
                    voteTimePanelActions={self.props.voteTimePanelActions}
                    data={item}
                    key={index}
                    index={index} />
            });
            return (
                <div>
                    <AlertBox color='gray'>
                        <p><i className="fa fa-info-circle" aria-hidden="true"></i> <FormattedMessage id="voteTimePanel.selectTimeSlotsMessage" /></p>
                    </AlertBox>
                    {nodes}
                    {this._renderAddButton()}
                </div>
            );
        } else {
            return (
                <EditMode {...this.props} />
            );
        }
    }
    render() {
        return (
            <div className={styles.container}>
                <div className={globalStyles.menuButtonBoard}>
                    {this._headerEditMenu()}
                </div>
                {this._renderEntryDatePicker()}
            </div>
        );
    }
}
//{this._renderEditMode()}
// <div className={styles.addLocationBoard}>
//     <span className={styles.addLocationButton} onClick={this._onClickAdd.bind(this)}><i className="fa fa-plus-circle" aria-hidden="true"></i> Thêm khung thời gian khác</span>
// </div>
class AddVoteBoard extends React.Component {
    _onClickAdd() {
        if (this.props.user.isLogin && !this.props.deadLine) {
            this.props.voteTimePanelActions.setDisplayAddVoteTime(true);
        } else {
            this.props.controlPanelActions.switchIsShowLoginModal(true);
        }
    }
    closeEditMode() {
        this.props.voteTimePanelActions.setDisplayAddVoteTime(false);
        this.props.entryDatePickerActions.reset();
    }
    _onClickUpdateVote() {
        var { selectedDays } = this.props.entryDatePicker;
        var { hashUrlb } = this.props;
        var user_time_votes = this.props.entry.urlbData.user_time_votes;
        this.props.voteTimePanelActions.addTimeVoteToServer(selectedDays, hashUrlb, user_time_votes);
    }
    _renderSaveButton() {
        var { deleteList, selectedDays, isChanged } = this.props.entryDatePicker;
        if (selectedDays.length > 0) {
            return (
                <GButton default="blue" style={{ width: 95 }} onClick={this._onClickUpdateVote.bind(this)}>
                    <FormattedMessage id="voteTimePanel.addMessage" />
                </GButton>
            );
        } else {
            return (
                <GButton default="block" style={{ width: 95 }}>
                    <FormattedMessage id="voteTimePanel.addMessage" />
                </GButton>
            );
        }
    }
    render() {
        if (this.props.isShowAddVoteTime == true) {
            return (
                <div>
                    <EntryDatePicker allowSelect autoFocus />
                    <div className={styles.saveButtonBoard}>
                        <GButton default="red" onClick={this.closeEditMode.bind(this)} style={{ width: '95' }}>
                            <FormattedMessage id="voteTimePanel.cancelMessage" />
                        </GButton>
                        <div style={{ flex: '1' }} className={styles.saveButtonDescription}>Thêm vote thời gian</div>
                        {this._renderSaveButton()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.addLocationBoard}>
                    <span className={styles.addLocationButton} onClick={this._onClickAdd.bind(this)}><i className="fa fa-plus-circle" aria-hidden="true"></i> <FormattedMessage id="voteTimePanel.addAnotherTimeSlotMessage" /></span>
                </div>
            );
        }
    }
}

class DateItem extends React.Component {
    _onCheckBoxChanged() {
        if (this.props.deadLine) return;
        this.props.voteTimePanelActions.pickTime(this.props.index);
    }
    render() {
        var { day, picked, value, description } = this.props.data;
        var dateString = moment(day).format('dddd').capitalize() + ' ' + moment(day).format('DD/MMM');
        var time = value.time;
        time = convertToTimeString(time.start) + ' - ' + convertToTimeString(time.end);
        var selected = (picked) ? styles.selected : '';
        return (
            <div className={styles.dateItem + ' ' + selected} onClick={this._onCheckBoxChanged.bind(this)}>
                <div className={styles.dateItemLeft}>
                    <CheckBox
                        className={styles.timeCheckBox}
                        checked={picked}
                        onChange={this._onCheckBoxChanged.bind(this)} />
                </div>
                <div className={styles.dateItemRight}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
                        <span className={styles.date}>{moment(day).format('DD')}</span>
                        <span className={styles.month}>{moment(day).format('MMM')}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={styles.day}>{moment(day).format('dddd')}</span>
                        <span className={styles.time}>{time}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1, marginLeft: 20 }}>
                        <span className={styles.description}>{description}</span>
                    </div>
                </div>
            </div>
        );
    }
}


class EditMode extends React.Component {
    _onClickUpdateVote() {
        var { deleteList, selectedDays } = this.props.entryDatePicker;
        //this.props.voteTimePanelActions.setVoteTimePanelMode('vote');
        var user_time_votes = this.props.entry.urlbData.user_time_votes;
        this.props.voteTimePanelActions.saveTimeVoteToServer(selectedDays, deleteList, this.props.hashUrla, user_time_votes);
    }
    closeEditMode() {
        this.props.voteTimePanelActions.setVoteTimePanelMode('vote');
    }
    _renderSaveButton() {
        var { deleteList, selectedDays, isChanged } = this.props.entryDatePicker;
        if (selectedDays.length > 0) {
            if (isChanged) {
                return (
                    <GButton default="blue" style={{ width: 95 }} onClick={this._onClickUpdateVote.bind(this)}>
                        <FormattedMessage id="voteTimePanel.saveMessage" />
                    </GButton>
                );
            } else {
                return (
                    <GButton default="block" style={{ width: 95 }}>
                        <FormattedMessage id="voteTimePanel.saveMessage" />
                    </GButton>
                );
            }
        } else {
            return (null);
        }
    }
    render() {
        return (
            <div>
                <EntryDatePicker allowSelect />
                <div className={styles.saveButtonBoard}>
                    <GButton default="red" onClick={this.closeEditMode.bind(this)} style={{ width: '95' }}>
                        <FormattedMessage id="voteTimePanel.cancelMessage" />
                    </GButton>
                    <div style={{ flex: '1' }} className={styles.saveButtonDescription}>Chỉnh sửa vote thời gian</div>
                    {this._renderSaveButton()}
                </div>
            </div>
        );
    }
}
function convertToTimeString(time) {
    if (!time && time != 0) return "__:__";
    var hour = Math.floor(time / 3600);
    var minute = (time - hour * 3600) / 60;
    return hour + ':' + ((minute.toString().length > 1) ? minute : '0' + minute);
}

//Japanese

var Jp = {
    index: {
        indexTitle: 'pickboz.com',
        mainDescription: 'セップケオがあるから日程調整が簡単',
        generateButton: '作成',
        generateIconCaption: 'イベント作成',
        entryIconCaption: '空いている時間を入れる',
        resultIconCaption: '決定',

        generateParagraphTitle: '早速イベントを作ろう！',
        generateParagraphContent: '飲み会・女子会・合コンなど内容を入れて、予定日をすべて入力する。友達へリンクを送ろう。',

        entryParagraphTitle: '日程回答も簡単！',
        entryParagraphContent: 'リンクをクリックし、ログインしなくてもすぐ回答できる。時間帯も選べられる！',

        resultParagraphTitle: '候補が一目で分かる',
        resultParagraphContent1: '候補日の一覧で日程をスムーズに決まる。',
        resultParagraphContent2: '',

        finalParagraph1: '登録せず、ダウンロードせず、',
        finalParagraph2: 'パソコンでも、携帯でも、どこでも',
        finalParagraphButtonCaption: 'イベントスケジュールをすぐ作ろう！',

        footerCopyright: '© GDV |',
        footerHomepage: 'GDV Homepage',

    }
}

export default Jp;

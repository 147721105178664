exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Xz5m-KTJwzsoWVmqzh2m2{\n\n}\n.-rASd4II2HEo9R04a4q1z{\n    display: flex;\n    flex-direction: row;\n    background-color: #fff;\n    margin-bottom: 10px;\n}\n\n._1Z6X3PKeNZXa4SwtLm1VSk {\n    padding: 7px 5px 7px 5px;\n    border-bottom: solid 4px #e2e2e2;\n    flex: 1;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n}\n._1Z6X3PKeNZXa4SwtLm1VSk span {\n    font-size: 13px;\n    font-weight: bold;\n    color: #c2c2c2;\n}\n\n\n._1FfGx6M68DvxHnPsVAFH-1{\n    border-bottom: solid 4px #bfcde5;\n    cursor: pointer;\n}\n._1FfGx6M68DvxHnPsVAFH-1  span{\n    color: #bfcde5;\n}\n._1FfGx6M68DvxHnPsVAFH-1  i{\n    color: #bfcde5;\n}\n\n._3DyCmw3yOcWD6nlP7yyGAM{\n    border-bottom: solid 4px #02499d;\n    cursor: pointer;\n}\n._3DyCmw3yOcWD6nlP7yyGAM i{\n    color: #02499d;\n}\n\n._3DyCmw3yOcWD6nlP7yyGAM span{\n    color: #02499d;\n}\n\n._3DyCmw3yOcWD6nlP7yyGAM:hover{\n    background-color: #02499d;\n}\n\n._3DyCmw3yOcWD6nlP7yyGAM:hover span {\n    color: #fff;\n}\n._3DyCmw3yOcWD6nlP7yyGAM:hover i {\n    color: #fff;\n}\n\n.ZLx2_yQiQHEE-NqCJZUp6{\n    border-bottom: solid 4px #02499d;\n}\n.ZLx2_yQiQHEE-NqCJZUp6  span{\n    color: #02499d;\n}\n.ZLx2_yQiQHEE-NqCJZUp6  i{\n    color: #02499d;\n}\n\n\n\n._3-fiO51NHCdvJG4dTghh3D{\n    font-size: 19px;\n    color: #c2c2c2;\n    margin-right: 5px;\n    margin-bottom: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3Xz5m-KTJwzsoWVmqzh2m2",
	"tab_menu_board": "-rASd4II2HEo9R04a4q1z",
	"main_menu_item": "_1Z6X3PKeNZXa4SwtLm1VSk",
	"allowSelect": "_1FfGx6M68DvxHnPsVAFH-1",
	"item_checked": "_3DyCmw3yOcWD6nlP7yyGAM",
	"main_menu_item_selected": "ZLx2_yQiQHEE-NqCJZUp6",
	"tab_menu_icon": "_3-fiO51NHCdvJG4dTghh3D"
};
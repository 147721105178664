import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/pickVotePanel_style.css';
import globalStyles from 'app/styles/global_styles.css';

import {GButton,GFormInput,CheckBox,Panel,Dropdown,AlertBox} from 'app/components/gTools/gTools.jsx';
import ItemInfo from 'app/components/vote/voteInfo.jsx';
import VotePanel from 'app/containers/vote/votePanel_container.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import DropdownItem from 'app/components/menu/dropdownItem.jsx';

export default class PickVotePanel extends React.Component {
    closeEditMode(){
        this.props.pickVotePanelActions.setShowMode('pick');
    }
    _renderMain(){
        switch (this.props.pickVotePanel.showMode) {
            case 'pick':
                return <PickPanel {...this.props}/>
            case 'edit':
                var {hashUrla,hashUrlb} = this.props;
                return <VotePanel
                            editMode
                            isFetch
                            closeEditMode={this.closeEditMode.bind(this)}
                            hashUrla={hashUrla}
                            hashUrlb={hashUrlb}/>
            default:
        }
    }
    render() {
        return (
            <div>
                {this._renderMain()}
            </div>
        );
    }
}
//closeEditMode={this.closeEditMode.bind(this)}
class PickPanel extends React.Component {
    _renderItems(){
        var self = this;
        var disableCheckbox = (this.props.pickVotePanel.votes.length==1)?true:false;
        var nodes = this.props.pickVotePanel.votes.map(function(item,index){
            return <VoteItem
                        deadLine={self.props.deadLine}
                        disableCheckbox={disableCheckbox}
                        data={item}
                        pickVoteActions={self.props.pickVotePanelActions}
                        language={self.props.language}
                        index={index}/>;
        });
        return nodes;
    }
    _onClickShowVotePanel(){
        this.props.pickVotePanelActions.setShowMode('edit');
    }
    _renderAddButton(){
        if(!this.props.deadLine){
            return(
                <AddVoteBoard
                    deadLine={this.props.deadLine}
                    hashUrlb={this.props.hashUrlb}
                    urlaId={this.props.urlaId}
                    user={this.props.user}
                    controlPanelActions={this.props.controlPanelActions}
                    pickVoteActions={this.props.pickVotePanelActions}
                    addVoteMode={this.props.pickVotePanel.addVoteMode}
                    language={this.props.language}/>
            );
        }else {
            return null;
        }
    }
    _onClickPickAll(){
        this.props.pickVotePanelActions.pickAll();
    }
    _headerEditMenu(){
        if(this.props.deadLine){
            //het han
            return null;
        }
        var dropdownTrigger = <i className={'fa fa-sliders '+globalStyles.menuButton} aria-hidden="true"></i>;
        var isOwner = (this.props.hashUrla)?true:false;
        return(
            <Dropdown dropdownTrigger={dropdownTrigger}>
                <DropdownContent className={DropdownStyles.drop_right} style={{minWidth:210}}>
                    <DropdownItem
                        disable={!isOwner}
                        icon = 'fa fa-pencil-square-o'
                        title= {<FormattedMessage id="pickVotePanel.editMessage" />}
                        description= {<FormattedMessage id="pickVotePanel.changeVoteContentMessage" />}
                        underline
                        onClick={this._onClickShowVotePanel.bind(this)}/>
                    <DropdownItem
                        icon = 'fa fa-check'
                        title= {<FormattedMessage id="pickVotePanel.allSetMessage" />}
                        description= {<FormattedMessage id="pickVotePanel.selectAllMessage" />}
                        onClick={this._onClickPickAll.bind(this)}/>
                </DropdownContent>
            </Dropdown>
        );
    }
    render() {
        if(this.props.pickVotePanel.votes && this.props.pickVotePanel.votes.length>0){
            return (
                <div className={styles.pickVotePanelContainer}>
                    <div className={globalStyles.menuButtonBoard}>
                        {this._headerEditMenu()}
                    </div>
                    <HintBoard {...this.props}/>
                    {this._renderItems()}
                    {this._renderAddButton()}
                </div>
            );
        }else {
            return null;
        }
    }
}
class HintBoard extends React.Component {
    _renderHint(){
        if(this.props.pickVotePanel.votes.length==1){
            return(
                null
            );
        }else {
            return(
                <AlertBox color='gray'>
                    <p><i className="fa fa-info-circle" aria-hidden="true"></i> <FormattedMessage id="pickVotePanel.voteLocationMessage" /></p>
                </AlertBox>
            );
        }
    }
    render() {
        return (
            <div className={styles.container}>
                {this._renderHint()}
            </div>
        );
    }
}


class VoteItem extends React.Component {
    _onCheckBoxChanged(){
        if(!this.props.deadLine){
            this.props.pickVoteActions.pickVote(this.props.index);
        }
    }
    _renderVoteInfo(){
        var data =this.props.data;
        var picked = this.props.data.picked;
        var pickVoteActions = this.props.pickVoteActions;
        var index = this.props.index;
        if(data.location||data.content){
            return (
                <ItemInfo pickVoteActions={pickVoteActions} index={index} data={data} picked={picked} language={this.props.language} />
            );
        }else {
            return null;
        }
    }
    _renderCheckbox(){
        if(!this.props.disableCheckbox){
            var deadLine = this.props.deadLine;
            return(
                <CheckBox
                    disable={deadLine}
                    className={styles.checkbox_style}
                    name ="pickVote"
                    checked={this.props.data.picked}
                    value={this.props.data.id} title={this.props.data.title}
                    onChange={this._onCheckBoxChanged.bind(this)}/>
            );
        }else {
            return (
                <p className={styles.voteTitle}>
                    <i className="fa fa-map-marker" style={{fontSize:24}} aria-hidden="true"></i>&nbsp;
                    {this.props.data.title}
                </p>
            );
        }
    }
    render(){
        return (
            <div className={styles.vote_item_board}>
                {this._renderCheckbox()}
                {this._renderVoteInfo()}
            </div>
        );
    }
}


class AddVoteBoard extends React.Component {
    _onClickAdd(){
        if(this.props.user.isLogin && !this.props.deadLine){
            this.props.pickVoteActions.switchAddVoteMode(1);
        }else {
            this.props.controlPanelActions.switchIsShowLoginModal(true);
        }
    }
    _onClickCancel(){
        this.props.pickVoteActions.switchAddVoteMode(0);
    }
    _renderMain(){
        if(this.props.addVoteMode==1){
            var cancelButton=<div className={styles.delete_btn}
                 onClick={this._onClickCancel.bind(this)}>&#10006;</div>;
            return(
                <VotePanel
                    topRightButton ={cancelButton}
                    entryMode
                    editMode
                    closeEditMode={this._onClickCancel.bind(this)}
                    hashUrlb={this.props.hashUrlb}
                    urlaId={this.props.urlaId}/>
            );
        }else {
            return(
                // <div className={globalStyles.add_button} onClick={this._onClickAdd.bind(this)}>
                //     <span className={globalStyles.add_button_plus}>+ </span>
                //     <span className={globalStyles.add_button_description}>{this.props.language.components.addVoteButton}</span>
                // </div>
                <div className={styles.addLocationBoard}>
                    <span className={styles.addLocationButton} onClick={this._onClickAdd.bind(this)}><i className="fa fa-plus-circle" aria-hidden="true"></i> <FormattedMessage id="pickVotePanel.addAnotherLocationMessage" /></span>
                </div>
            );
        }
    }
    render(){
        return (
            <div>
                {this._renderMain()}
            </div>
        );
    }
}

import React from 'react';

export default class PolygonSVG extends React.Component {
    constructor(props) {
        super(props);
        this.pattern = this._trianglify();
    }
    _trianglify() {
        var pattern = this.props.trianglify({
        width: window.innerWidth,
        height: window.innerHeight
        });
        return (
            <svg
              width={window.innerWidth}
              height={window.innerHeight}
            >
                {
                    pattern.polys.map((poly, index) => (
                        <path
                            key={index}
                            d={`M${poly[1].join('L')}Z`}
                            fill={poly[0]}
                            stroke={poly[0]}
                            strokeWidth={pattern.opts.stroke_width}
                        />
                    ))
                }
            </svg>
        );
    }
    render() {
        return (
            <div className={this.props.className}>{this.pattern}</div>
        )
    }
}

import {setCommentCountData} from 'app/actions/comment/commentList_action.js';
import {updateVoteData,setFetchedVoteData} from 'app/actions/vote/pickVotePanel_action.js';
import {setFetchedDayData} from 'app/actions/entry/entryDatePicker_action.js';
import {updateText} from 'app/actions/comment/commentBox_action.js';
import {setStickerIndex,fetchCollectionFromServer,setExistedStickerData} from 'app/actions/collection/collection_action.js';
import {setLikeCount,setViewCount} from 'app/actions/entry/entryHeaderControlBar_action.js';
import {setEntryDatePickerData} from 'app/actions/entry/entryDatePicker_action.js';
import {fetchEntryHistory}  from 'app/actions/controlPanel/controlPanel_action.js';
import {setVoteTimePanelData,setFetchedVoteTimeData}  from 'app/actions/entry/voteTimePanel_action.js';


export const SET_URLA_DATA = 'SET_URLA_DATA';
export const SET_STEP = 'SET_STEP';
export const TOGGLE_DEADTIME = 'TOGGLE_DEADTIME';
export const UPDATE_DEADTIME = 'UPDATE_DEADTIME';
export const SET_SOCIAL_BAR_IS_LOADING = 'SET_SOCIAL_BAR_IS_LOADING';
export const SET_ENTRY_TAB_VALUE = 'SET_ENTRY_TAB_VALUE';
export const SET_ENTRY_HASH_URLA = 'SET_ENTRY_HASH_URLA';
export const SET_ENTRY_HEADER_DATA = 'SET_ENTRY_HEADER_DATA';
export const SET_ENTRY_EDITED = 'SET_ENTRY_EDITED';
export const SET_ENTRY_IS_LOADING ='SET_ENTRY_IS_LOADING';
export const SET_ENTRY_URLB_DATA = 'SET_ENTRY_URLB_DATA';
export const SET_DISPLAY_ENTRY_GUIDE = 'SET_DISPLAY_ENTRY_GUIDE';
export const SET_DISPLAY_LIST_PICK = 'SET_DISPLAY_LIST_PICK';

import {
    API_ENTRY_FETCH,
    API_ENTRY_SUBMIT,
    API_ENTRY_FETCH_EXISTED,
    API_ENTRY_SUBMIT_UPDATE,
    API_ENTRY_BOOKMARK,
    API_ENTRY_EDIT_CANCEL
} from 'app/js/api.js';

export function fetchUrlbData(hashUrlb,hashUrla,tabValue) {
    return function(dispatch) {
    var submitData = {
        hashUrlb: hashUrlb,
        hashUrla:hashUrla
    };
    dispatch(setStep(0));

    return fetch(API_ENTRY_FETCH, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submitData)
            }).then((response) => response.json()).then((responseJson) => {
                if (responseJson.content) {
                    var content = responseJson.content;
                    dispatch(setIsLoading(false));
                    dispatch(setUrlaData(content.urla));
                    if(responseJson.message=='deadLine'){
                        dispatch(setStep(4));
                        dispatch(setTabValue('result'));
                    }else {
                        dispatch(setStep(1));
                        dispatch(setTabValue(tabValue));
                    }
                    dispatch(setViewCount(parseInt(content.urla.viewCount)));
                    if(content.urla.schedule.mode==1){
                        dispatch(setVoteTimePanelData(content.urla.time_votes));
                    }else {
                        dispatch(setEntryDatePickerData(content.urla.schedule.selectedDays));
                    }
                    dispatch(updateVoteData(content.votes));
                    if(content.urlb){//neu co urlb thi user login da picked roi
                        var urlb = content.urlb;
                        dispatch(setEntryUrlbData(urlb));
                        switch (urlb.flag) {
                            case 1:
                                var selectedDays= content.urla.schedule.selectedDays;
                                if(selectedDays.length==0 && urlb.pick.selectedDays.length>0){
                                    dispatch(setFetchedDayData(urlb.pick.selectedDays,urlb.id,true)); //set ngay da pick
                                }else {
                                    if(content.urla.schedule.mode==1){
                                        dispatch(setFetchedVoteTimeData(urlb.user_time_votes)); //set ngay da pick
                                    }else{
                                        dispatch(setFetchedDayData(urlb.pick.selectedDays,urlb.id)); //set ngay da pick
                                    }
                                }
                                if (urlb.user_votes.length>0) {
                                    dispatch(setFetchedVoteData(urlb.user_votes));
                                }
                                break;
                            case 2:
                                break;
                            default:
                        }
                    }else {
                        dispatch(updateText(''));
                        //if(!content.urla.hashUrla)
                        dispatch(setDisplayEntryGuide(true));
                        dispatch(setEntryUrlbData({}));
                    }
                } else {
                    dispatch(setStep(3));
                }
            });
    }
}

export function bookmarkDataToServer(data,linkToHome) {
    var data = {
              hashUrlb: data.hashUrlb
          }
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_ENTRY_BOOKMARK, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            //dispatch(setStep(2));
            if(responseJson.message=='unBookmark'){
                linkToHome(true);
            }else {
                linkToHome();
                dispatch(fetchEntryHistory(''));
            }
        });
    }
}


export function submitDataToServer(data) {
    var selectedDays = [];
    for (var i=0;i<data.selectedDays.length;i++){
        if(data.selectedDays[i].picked){
            selectedDays.push(data.selectedDays[i].id);
        }
    }
    var voted = [];
    var votes = data.votes;
    if(votes.length>1){
       for(var i =0;i<votes.length;i++){
           if(votes[i].picked){
               voted.push(votes[i].id);
           }
       }
    }
    var data = {
              hashUrlb: data.hashUrlb,
              selectedDays: selectedDays,
              name: data.name,
              voted: voted
          }
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_ENTRY_SUBMIT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            dispatch(setStep(2));
            dispatch(fetchEntryHistory(''));
        });
    }
}

export function updateSubmitDataToServer(data) {
    var selectedDays = [];
    for (var i=0;i<data.selectedDays.length;i++){
        if(data.selectedDays[i].picked){
            selectedDays.push(data.selectedDays[i].id);
        }
    }
    var voted = [];
    var votes = data.votes;
    if(votes.length>1){
       for(var i =0;i<votes.length;i++){
           if(votes[i].picked){
               voted.push(votes[i].id);
           }
       }
    }
    var data = {
              urlbId:data.urlbId,
              hashUrlb: data.hashUrlb,
              selectedDays: selectedDays,
              name: data.name,
              voted: voted
          }
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_ENTRY_SUBMIT_UPDATE, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            dispatch(setStep(2));
        });
    }
}

export function  cancelEntryToServer(urlbId,hashUrlb,linkToHome) {
    var data = {
              urlbId:urlbId,
              hashUrlb:hashUrlb
          }
    return function(dispatch) {
        dispatch(setIsLoading(true));
        return fetch(API_ENTRY_EDIT_CANCEL, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.message){
                linkToHome(true);
            }
        });
    }
}

export function setFetchedStickerData() {
    return function(dispatch) {
        dispatch(fetchCollectionFromServer());
    }
}

export function fetchExistedEntryFromServer(hashUrlb) {
    return function(dispatch) {
        var submitData = {
            hashUrlb:hashUrlb
        }
        return fetch(API_ENTRY_FETCH_EXISTED, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.content) { //neu fetch dc bat dau set thong tin vao cac reducer
                if (responseJson.content.message) { //neu co message
                    var stickerId = (responseJson.content.message.stickerId)?  (responseJson.content.message.stickerId) : -1;
                    if (stickerId!=-1) { //neu message co sticker
                        var stickerData = {
                            content:responseJson.content.message.sticker.content,
                            id:responseJson.content.message.sticker.id,
                            title:responseJson.content.message.sticker.title
                        }
                        dispatch(setExistedStickerData(stickerData,'commentBox'))
                    }
                    dispatch(updateText(responseJson.content.message.text));
                }
                dispatch(setFetchedDayData(responseJson.content)); //set ngay da pick
                if (responseJson.content.pick.voted.length) {
                    dispatch(setFetchedVoteData(responseJson.content.pick.voted));
                }
            }
            else {
                console.log('error: ',responseJson.message);
            }
        });
    }
}


export function setUrlaData(data) {
    return {
        type: SET_URLA_DATA,
        data: data
    }
}
export function setEntryUrlbData(data) {
    return {
        type: SET_ENTRY_URLB_DATA,
        data: data
    }
}


export function setEntryHashUrla(data) {
    return {
        type: SET_ENTRY_HASH_URLA,
        data: data
    }
}

export function setStep(index) {
    return {
        type: SET_STEP,
        index: index
    }
}

export function setTabValue(data) {
    return {
        type: SET_ENTRY_TAB_VALUE,
        data: data
    }
}
export function toggleDeadtime() {
    return {
        type: TOGGLE_DEADTIME
    }
}

export function setSocialBarIsLoading (data) {
    return {
        type: SET_SOCIAL_BAR_IS_LOADING,
        data: data,
    }
}

export function setHeaderData(data) {
    return {
        type: SET_ENTRY_HEADER_DATA,
        data: data
    }
}
export function setIsLoading(data) {
    return {
        type: SET_ENTRY_IS_LOADING,
        data: data
    }
}

export function setDisplayEntryGuide(data) {
    return {
        type: SET_DISPLAY_ENTRY_GUIDE,
        data: data
    }
}

export function setDisplayListPick(data) {
    return {
        type: SET_DISPLAY_LIST_PICK,
        data: data
    }
}

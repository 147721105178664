exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1juM5EMk1yIeyq9zt3m7Fk{\n    background-color: #fbfbfb;\n    border: none;\n    box-shadow: none;\n    height: 34px;\n    padding: 6px 12px;\n    color: #58595b;\n    font-size: 15px;\n    text-align: inherit;\n    border: 1px solid #ccc;\n    border-radius: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"my_input": "_1juM5EMk1yIeyq9zt3m7Fk"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._1z0bI7u4HzsSt3Tikn8Re0{\n    font-size: 17px;\n    font-weight: 500;\n}\n._8nC10m6sEXdbCUZ2mFgrE{\n    color: #888;\n    margin-top: 7px;\n    font-size: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"title": "_1z0bI7u4HzsSt3Tikn8Re0",
	"description": "_8nC10m6sEXdbCUZ2mFgrE"
};
import React from 'react';

export default class Flag extends React.Component {
    render() {
        var source = "/images/control/flag_"+this.props.flagName+".png";
        return (
            <img className={this.props.className} src={source}/>
        )
    }
}

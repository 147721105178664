exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n._2RNYjYd_xKY_qe1qo7xN4j {\n\tborder-radius: 0;\n\tborder: none;\n\tmargin: -20px -10px;\n}\n.GWTh4CqqNYJ7nJTUEvRbY{\n\n}\n\n._1eL1c-4Trp2LAJAZC5KPTO{\n\tcolor: #999;\n\tfont-size: 20px;\n\tcursor: pointer;\n\tfloat: right;\n}\n\n._1eL1c-4Trp2LAJAZC5KPTO:hover{\n\tcolor: #333;\n}\n\n._2qDHrraTOndCVG954KGrgv{\n\tmargin-bottom: 5px;\n}\n._2zPIxzGq_x8VRDQjlqLnfo{\n\tfont-size: 17px;\n\tfont-weight: 700;\n\tcolor: #02499d;\n\tmargin-bottom: 3px;\n}\n.W71BTF4KadZgtDJMzdUsL{\n\tpadding-bottom: 0;\n}\n._3E-7s_81eSCZ0QKXRpjDQB{\n\tdisplay: flex;\n\tflex-direction: row-reverse;\n}\n.vhckJWi6YtAeTBuP9ApMD{\n\tcolor: #999;\n\tcursor: pointer;\n}\n.vhckJWi6YtAeTBuP9ApMD:hover{\n\ttext-decoration: underline;\n\tcolor: #02499d;\n}\n", ""]);

// exports
exports.locals = {
	"vote_panel": "_2RNYjYd_xKY_qe1qo7xN4j",
	"pickVotePanelContainer": "GWTh4CqqNYJ7nJTUEvRbY",
	"delete_btn": "_1eL1c-4Trp2LAJAZC5KPTO",
	"vote_item_board": "_2qDHrraTOndCVG954KGrgv",
	"voteTitle": "_2zPIxzGq_x8VRDQjlqLnfo",
	"checkbox_style": "W71BTF4KadZgtDJMzdUsL",
	"addLocationBoard": "_3E-7s_81eSCZ0QKXRpjDQB",
	"addLocationButton": "vhckJWi6YtAeTBuP9ApMD"
};
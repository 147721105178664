import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles/gModal_style.css';

export default class GModal extends React.Component {
    constructor(props){
        super(props);
    }
    componentWillReceiveProps(nextProps){
        if(this.props.visible!=nextProps.visible){
            if(nextProps.visible){
                document.body.style.overflow = 'hidden';
            }else {
                document.body.style.overflow = 'scroll';
            }
        }
    }
    closeForm() {
        this.props.closeModal(false);
    }
    render() {
        if(this.props.visible){
            var {width} = this.props;
            return (
                <div className={styles.main_background}>
                    <div className={styles.dialog_gray_background} onClick={this.closeForm.bind(this)}></div>
                    <center>
                        <div className={styles.message_box_board} style={{
                            width: width
                        }}>
                            {this.props.children}
                        </div>
                    </center>
                </div>
            )
        }else {
            return null;
        }
    }
}

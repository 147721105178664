
import { VOTE_ITEM_ADD, VOTE_ITEM_DELETE, VOTE_ITEM_UPDATE, VOTE_ITEM_UPDATE_DATA, SWITCH_IS_EDIT, VOTE_SET_DATA, VOTE_ITEM_RESET, UPDATE_SEARCH_DATA, RESET_SEARCH_DATA, SELECT_SEARCH_DATA, TRIGGER_EDIT, TOGGLE_MAP, PICKER_ADD_VOTE_TO_SERVER, RESET_VOTE_DATA, SET_VOTE_ITEM_LOADING } from 'app/actions/vote/votePanel_action.js';

var vote0Id = Math.random().toString(36).substr(2, 7);

function genDefaultVotePanelData(genNew) {
    if (genNew) {
        vote0Id = Math.random().toString(36).substr(2, 7);
    }
    var defaultVotePanelData = {
        votes: [{
            id: vote0Id,
            title: '',
            isNew: true,
            isLoading: false
        }],
        searchData: [{
            id: vote0Id
        }],
        toggleMap: [],
        deleteList: [],
        isEdit: false
    };
    return defaultVotePanelData;
}
function votePanelData(state = genDefaultVotePanelData(), action) {
    switch (action.type) {
    case VOTE_ITEM_ADD:
        var newState = Object.assign({}, state);
        action.data.id = Math.random().toString(36).substr(2, 7);
        newState.searchData.push(action.data);
        //if (action.editMode) {
            action.data.isNew = true;
        //}
        newState.votes.push(action.data);
        return newState;
    case VOTE_SET_DATA:
        var newState = Object.assign({}, state);
        var data = (action.data) ? action.data : [{
            id: vote0Id,
            title: ''
        }];
        newState.votes = data;
        newState.searchData = data.map(function(item) {
            return (
            {
                id: item.id
            }
            );
        });
        return newState;
    case VOTE_ITEM_DELETE:
        var newState = Object.assign({}, state);
        const deleteIndex = newState.votes.findIndex(item => (item.id == action.id)
        );
        if (newState.votes[deleteIndex].title.trim() != '') {
            newState.isEdit = true;
        }
        if (action.editMode && !newState.votes[deleteIndex].isNew) {
            newState.deleteList.push(action.id);
        }
        newState.votes.splice(deleteIndex, 1);
        newState.searchData.splice(deleteIndex, 1);

        return newState;
    case VOTE_ITEM_UPDATE:
        var newState = Object.assign({}, state);
        var index = newState.votes.findIndex(item => (item.id == action.id)
        );
        if (action.editMode && !newState.votes[index].isNew) {
            //new khong phai new thi khong cho edit vote vi mat location
            return newState;
        }
        newState.votes[index] = {
            id: newState.votes[index].id,
            isNew: newState.votes[index].isNew
        };
        newState.votes[index].title = action.data;
        //xoa thumbnail vi dieu kien render itemInfo la co thumb
        //newState.votes[index].thumbnail = null;
        if (newState.votes[index].title != ''){
            newState.isEdit = true;
        } else {
            newState.isEdit = false;
        }
        return newState;
    case VOTE_ITEM_RESET:
        var newState = Object.assign({}, state);
        newState = genDefaultVotePanelData(true);
        return newState;
    case SWITCH_IS_EDIT:
        var newState = Object.assign({}, state);
        newState.isEdit = action.data;
        return newState;
    case VOTE_ITEM_UPDATE_DATA:
        var newState = Object.assign({}, state);
        var index = newState.votes.findIndex(item => (item.id == action.id));
        console.log('action.data', action.data);
        if (action.isParse) {
            newState.votes[index].content = {
                title: action.data.title,
                thumbnail: action.data.thumbnail,
                publisher: action.data.publisher,
                description: action.data.description,
                location: action.data.location,
                url: action.data.url
            }
            newState.votes[index].title = action.data.title;
        }
        newState.isEdit = true;
        return newState;
    case UPDATE_SEARCH_DATA:
        var newState = Object.assign({}, state);
        var index = newState.searchData.findIndex(item => (item.id == action.id)
        );
        if (index >= 0) {
            newState.searchData[index].data = action.data;
        }
        return newState;
    case RESET_SEARCH_DATA:
        var newState = Object.assign({}, state);
        var index = newState.searchData.findIndex(item => (item.id == action.id)
        );
        if (index >= 0) {
            newState.searchData[index].data = null;
        }
        return newState;
    case SET_VOTE_ITEM_LOADING:
        var newState = Object.assign({}, state);
        var index = newState.searchData.findIndex(item => (item.id == action.id)
        );
        if (index >= 0) {
            newState.votes[index].isLoading = action.data;
        }
        return newState;
    case SELECT_SEARCH_DATA:
        var newState = Object.assign({}, state);
        var index = newState.votes.findIndex(item => (item.id == action.id)
        );
        var locationData = newState.searchData[index].data[action.index];
        switch (locationData.publisher) {
            case 'foody':
                newState.votes[index].location = newState.searchData[index].data[action.index];
                newState.votes[index].locationId = newState.searchData[index].data[action.index].id;
                newState.votes[index].title = newState.searchData[index].data[action.index].title;
                newState.votes[index].id = action.id;
                break;
            case 'google':
                newState.votes[index].content = {
                    title: locationData.name,
                    thumbnail: '',
                    // locationData.photos[0].photo_reference
                    publisher: locationData.publisher,
                    description:'',
                    location:{
                        latitude:locationData.geometry.location.lat,
                        longitude:locationData.geometry.location.lng
                    },
                    address: locationData.formatted_address,
                    url: 'https://www.google.com/maps/place/?q=place_id:'+locationData.place_id
                };
                newState.votes[index].title = locationData.name;
                newState.votes[index].id = action.id;
                break;
            default:

        }
        newState.isEdit = true;
        return newState;
    case TOGGLE_MAP:
        var newState = Object.assign({}, state);
        newState.toggleMap[action.index] = action.data;
        return newState;
    case TRIGGER_EDIT:
        var newState = Object.assign({}, state);
        newState.isEdit = action.data;
        return newState;
    case RESET_VOTE_DATA:
        var newState = genDefaultVotePanelData();
        return newState;
    default:
        return state;
    }
}


export default votePanelData;

export const SET_VOTE_TIME_PANEL_DATA = 'SET_VOTE_TIME_PANEL_DATA';
export const PICK_TIME_VOTE_DATA = 'PICK_TIME_VOTE_DATA';
export const SET_FETCHED_VOTE_TIME_DATA = 'SET_FETCHED_VOTE_TIME_DATA';
export const SET_VOTE_TIME_PANEL_MODE = 'SET_VOTE_TIME_PANEL_MODE';
export const SET_ALL_VOTE_TIME = 'SET_ALL_VOTE_TIME';
export const SET_DISPLAY_ADD_VOTE_TIME = 'SET_DISPLAY_ADD_VOTE_TIME';

import {API_TIME_VOTE_EDIT,API_ADD_TIME_VOTE} from 'app/js/api.js';

import * as entryTimePickerActions from './entryDatePicker_action.js';
export function setVoteTimePanelData(data) {
    return {
        type: SET_VOTE_TIME_PANEL_DATA,
        data: data
    }
}

export function setFetchedVoteTimeData(data) {
    return {
        type: SET_FETCHED_VOTE_TIME_DATA,
        data: data
    }
}

export function pickTime(index) {
    return ({
        type: PICK_TIME_VOTE_DATA,
        index: index
    });
}

export function setVoteTimePanelMode(data) {
    return ({
        type: SET_VOTE_TIME_PANEL_MODE,
        data: data
    });
}

export function setAllVoteTime() {
    return ({
        type: SET_ALL_VOTE_TIME
    });
}

export function setDisplayAddVoteTime(data) {
    return ({
        type: SET_DISPLAY_ADD_VOTE_TIME,
        data:data
    });
}

export function saveTimeVoteToServer(selectedDays, deleteList, hashUrla,user_time_votes) {
    var newVotes = [];
    var  newSelectedDays = [];//them time vao neu khong co
    for (var i = 0; i < selectedDays.length; i++) {
        if(selectedDays[i].time){
            for (var j = 0; j < selectedDays[i].time.length; j++) {
                if(!selectedDays[i].time[j].id){
                    newSelectedDays.push({
                        day:selectedDays[i].day,
                        value:{time:selectedDays[i].time[j]}
                    });
                }
            }
        }else {
            newSelectedDays.push({
                day:selectedDays[i].day,
                value:{time:{
                    start:0,
                    end:86340
                }}
            });
        }
    }
    var submitData = {
        timeVotes: newSelectedDays,
        deleteList: deleteList,
        hashUrla: hashUrla
    };
    return function(dispatch) {
        return fetch(API_TIME_VOTE_EDIT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setVoteTimePanelMode('vote'));
                dispatch(setVoteTimePanelData(responseJson.content));
                dispatch(setFetchedVoteTimeData(user_time_votes));
            }
        });
    }
}


export function addTimeVoteToServer(selectedDays, hashUrlb,user_time_votes) {
    var newVotes = [];

    var  newSelectedDays = [];//them time vao neu khong co
    for (var i = 0; i < selectedDays.length; i++) {
        if(selectedDays[i].time){
            for (var j = 0; j < selectedDays[i].time.length; j++) {
                if(!selectedDays[i].time[j].id){
                    newSelectedDays.push({
                        day:selectedDays[i].day,
                        value:{time:selectedDays[i].time[j]}
                    });
                }
            }
        }else {
            newSelectedDays.push({
                day:selectedDays[i].day,
                value:{time:{
                    start:0,
                    end:86340
                }}
            });
        }
    }

    var submitData = {
        timeVotes: newSelectedDays,
        hashUrlb: hashUrlb
    };

    return function(dispatch) {
        return fetch(API_ADD_TIME_VOTE, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson.content) {
                dispatch(setDisplayAddVoteTime(false));
                dispatch(setVoteTimePanelData(responseJson.content));
                dispatch(setFetchedVoteTimeData(user_time_votes));
                dispatch(entryTimePickerActions.reset());
            }
        });
    }
}

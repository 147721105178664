import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/checkBox_style.css';

export default class CheckBox extends React.Component {
	_onSiteChanged(){
		if(this.props.onChange){
			this.props.onChange();
		}
	}
	render() {
        var {name,value,title,className,disable} = this.props;
		return (
            <label className={styles.main+' '+className}>
                <input
					disabled = {disable}
					className={styles.radio01_input}
					type="checkbox"
					name={name}
					value={value}
					checked={this.props.checked}
					onChange={this._onSiteChanged.bind(this)}
				/>
				<span className={styles.radio01_parts}>&nbsp;{title}</span>
            </label>
		);
	}
}

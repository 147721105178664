import React from 'react';
import { browserHistory, Link } from 'react-router';
import { GButton } from 'app/components/gTools/gTools.jsx';

import styles from './styles/entryGuide_style.css';
import globalStyles from 'app/styles/global_styles.css';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class EntryGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        };
    }
    _renderPage() {
        switch (this.state.page) {
            case 1:
                return <Page1 closeModal={this.props.closeModal} gotoPage={this._onPressNext.bind(this)} urlaData={this.props.urlaData} />
            case 2:
                return <Page2 gotoPage={this._onPressNext.bind(this)} />
            case 3:
                return <Page3 closeModal={this.props.closeModal} />
            default:
        }
    }
    _onPressNext(page) {
        this.setState({
            page: page
        });
    }
    render() {
        return (
            <div className={styles.container}>
                {this._renderPage()}
            </div>
        )
    }
}

class Page1 extends React.Component {
    render() {
        var { owner, description, title, user } = this.props.urlaData;
        var avatarUrl = (user) ? user.profile.avatarUrl : '/images/control/nullAvatar.jpg';
        return (
            <div className={styles.page}>
                <p className={styles.introText}><FormattedMessage id="entryGuide.welcomeMessage" /></p>
                <p className={styles.introSmallText}><FormattedMessage id="entryGuide.appDescription" /></p>
                <p className={styles.text}><span>{owner} </span> <FormattedMessage id="entryGuide.inviteMessage" /></p>
                <div className={styles.inviteMessageBox}>
                    <img className={styles.avatar} src={avatarUrl} />
                    <div className={styles.speech_bubble}>
                        <div className={styles.bubbleBox}>
                            <p className={styles.eventTitle}>{title}</p>
                            <p className={styles.eventDescription}>{splitText(description)}</p>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 1 }}></div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}></div>
                    {/* <span onClick={() => this.props.gotoPage(2)} className={globalStyles.linkButton}>
                        <FormattedMessage id="entryGuide.viewGuideMessage" /> <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span> */}
                    {/* <span  className={globalStyles.linkButton}>
                        OK <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span> */}
                    <GButton
                        default='primary'
                        onClick={() => this.props.closeModal()}
                        style={{ marginBottom: 10, width: '100%', marginTop: 10, height: 47, fontSize: 17 }}>
                        <FormattedMessage id="entryGuide.viewGuideCancel" />
                    </GButton>
                </div>
            </div>
        )
    }
}


class Page2 extends React.Component {
    render() {
        return (
            <div className={styles.page}>
                <p className={styles.introText}>Hướng dẫn <span>XẾP SỰ KIỆN</span></p>
                <p className={styles.introSmallText}>Pickboz.com giúp bạn dễ dàng xác định thời gian và địa điểm hẹn hò với nhóm bạn.</p>
                <div style={{ flex: 1 }} className={styles.page}>
                    <img src='/images/message/guide_entry.png' className={styles.image} />
                    <p className={styles.text}>Bấm <span style={{ color: '#5bb75b' }}>tham gia </span>nếu bạn muốn tham gia kèo này.</p>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}></div>
                    <span onClick={() => this.props.gotoPage(3)} className={globalStyles.linkButton}>
                        Tiếp theo <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        )
    }
}

class Page3 extends React.Component {
    _onPressNext() {
        this.props.closeModal();
    }
    render() {
        return (
            <div className={styles.page}>
                <p className={styles.introText}><span>XẾP SỰ KIỆN</span> sẽ giúp bạn</p>
                <p className={styles.introSmallText}>Dễ dàng xác định thời gian và địa điểm hẹn hò với nhóm bạn.</p>
                <img src='/images/message/guide_theoDoi.png' className={styles.image} />

                <p className={styles.text}>Bấm <span style={{ color: '#02499d' }}>theo dõi </span>nếu bạn muốn lưu kèo lại để quyết định sau.</p>
                <div style={{ flex: 1 }}></div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}></div>
                    <span onClick={this._onPressNext.bind(this)} className={globalStyles.linkButton}>
                        Thử tham gia kèo ngay <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        )
    }
}

function splitText(str) {
    if (str.length > 60) {
        str = str.substr(0, str.length * 2 / 3 - 2) + '...';
    }
    return str;
}

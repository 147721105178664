//English

var En = {
    index: {
        indexTitle: 'pickboz.com',
        mainDescription: 'A simple way to arrange a meetup',
        generateButton: 'CREATE',
        generateIconCaption: 'Arrange a meetup',
        entryIconCaption: 'Pick time',
        resultIconCaption: 'Result',

        generateParagraphTitle: 'Quickly create a meetup',
        generateParagraphContent: 'Arrange a meetup for a big group but not sure when everybody is free? Solve the problem by a link. Start a meetup by one click.',

        entryParagraphTitle: '5 second to pick available days',
        entryParagraphContent: 'Members of your meetup will receive the link and pick their available day and time. It is easier than open your IE, we promise!',

        resultParagraphTitle: 'Easily manage your result',
        resultParagraphContent1: 'No more counting, who is up tonight...',
        resultParagraphContent2: 'All your members available time will be organized automatically',

        finalParagraph1: 'No login, no download, just one link',
        finalParagraph2: 'Any device, all platform',
        finalParagraphButtonCaption: 'Start your meetup now',

        footerCopyright: '© GDV |',
        footerHomepage: 'GDV Homepage',

    }
}

export default En;

import {
    combineReducers
} from 'redux';
import _ from 'lodash';
import {
    SET_VOTE_TIME_PANEL_DATA,
    PICK_TIME_VOTE_DATA,
    SET_FETCHED_VOTE_TIME_DATA,
    SET_VOTE_TIME_PANEL_MODE,
    SET_ALL_VOTE_TIME,
    SET_DISPLAY_ADD_VOTE_TIME
} from 'app/actions/entry/voteTimePanel_action.js';

const voteTimeDefault = {
    selectedDays:[],
    mode:'vote',
    isShowAddVoteTime:false
}
import { DateUtils} from 'react-day-picker';

function votePanel(state=voteTimeDefault,action) {
    switch (action.type) {
        case SET_VOTE_TIME_PANEL_DATA:
            var newState = Object.assign({},state);
            var selectedDays = action.data.map(function(item,index){
                item.day = new Date(item.day);
                item.picked = false;
                return item;
            })
            selectedDays = _.orderBy(selectedDays, ['day'], ['asc', 'desc']),
            newState.selectedDays = selectedDays;
            newState.mode = 'vote';
            return newState;
        case SET_VOTE_TIME_PANEL_MODE:
            var newState = Object.assign({},state);
            newState.mode = action.data;
            return newState;
        case SET_DISPLAY_ADD_VOTE_TIME:
            var newState = Object.assign({},state);
            newState.isShowAddVoteTime = action.data;
            return newState;
        case PICK_TIME_VOTE_DATA:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays = selectedDays.map(function(item,index){
                if(index == action.index){
                    item.picked = !item.picked;
                    return item;
                }
                return item;

            })
            newState.selectedDays = selectedDays;
            return newState;
        case SET_FETCHED_VOTE_TIME_DATA:
            var newState = Object.assign({},state);
            if(!action.data){
                return newState;
            }
            var selectedDays = Object.assign([],state.selectedDays);
            var pickedDays = action.data;
            for(var i=0;i<selectedDays.length;i++){
                var selectedIndex = pickedDays.findIndex(
                    unit => (selectedDays[i].id==unit.timeVoteId)
                );
                if(selectedIndex!=-1){
                    selectedDays[i].picked=true;
                }
            }
            newState.selectedDays = selectedDays;
            newState.mode = 'vote';
            return newState;
        case SET_ALL_VOTE_TIME:
            var newState = Object.assign({},state);
            var selectedDays = Object.assign([],state.selectedDays);
            selectedDays = selectedDays.map(function(item,index){
                item.picked = true;
                return item;
            });
            newState.selectedDays = selectedDays;
            return newState;
        default:
            return state;
    }
}

export default votePanel;

import { combineReducers } from 'redux';

import indexReducer from './index/index_reducer.js';
import entryReducer from './entry/entry_reducer.js';
import generateReducer from './generate/generate_reducer.js';
import gFormInputReducer from 'app/components/gTools/reducers/gFormInput_reducer.js';
import votePanelReducer from 'app/reducers/vote/votePanel_reducer.js';
import pickVotePanelReducer from 'app/reducers/vote/pickVotePanel_reducer.js';
import resultBoxReducer from 'app/reducers/result/result_reducer.js';

import languageReducer from './language_reducer.js';
import userReducer from 'app/reducers/user/user_reducer.js';
import controlPanelReducer from 'app/reducers/controlPanel/controlPanel_reducer.js';
import collectionReducer from 'app/reducers/collection/collection_reducer.js';
import commentBoxReducer from 'app/reducers/comment/commentBox_reducer.js';
import commentListReducer from 'app/reducers/comment/commentList_reducer.js';
import feedbackReducer from 'app/reducers/feedback/feedback_reducer.js';
import entryHeaderControlBar from 'app/reducers/entry/entryHeaderControlBar_reducer.js';
import entryHeaderReducer from 'app/reducers/entry/entryHeader_reducer.js';
import entryDatePickerReducer from 'app/reducers/entry/entryDatePicker_reducer.js';
import generateDatePickerReducer from 'app/reducers/generate/generateDatePicker_reducer.js';
import listPickReducer from 'app/reducers/entry/listPick_reducer.js';
import userHomeReducer from 'app/reducers/user/userHome_reducer.js';
import urlaListReducer from 'app/reducers/keoList/urlaList_reducer.js';
import urlbListReducer from 'app/reducers/keoList/urlbList_reducer.js';
import voteTimePanelReducer from 'app/reducers/entry/voteTimePanel_reducer.js';

import notificationReducer from 'app/reducers/notification/notification_reducer.js';



const RootReducer = combineReducers({
    index: indexReducer,
    entry: entryReducer,
    generate: generateReducer,
    gFormInput: gFormInputReducer,
    votePanelData:votePanelReducer,
    pickVotePanel:pickVotePanelReducer,
    language: languageReducer,
    user:userReducer,
    controlPanel:controlPanelReducer,
    collectionBox:collectionReducer,
    commentBox:commentBoxReducer,
    commentList:commentListReducer,
    feedback:feedbackReducer,
    result:resultBoxReducer,
    entryHeaderControlBar:entryHeaderControlBar,
    entryHeader:entryHeaderReducer,
    entryDatePicker:entryDatePickerReducer,
    generateDatePicker:generateDatePickerReducer,
    pickList:listPickReducer,
    userHome:userHomeReducer,
    urlaList:urlaListReducer,
    urlbList:urlbListReducer,
    voteTimePanel:voteTimePanelReducer,
    notification:notificationReducer
});

export default RootReducer;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import UserHome from 'app/views/user/userHome.jsx';
import * as userHomeActions from 'app/actions/user/userHome_action.js';

function mapDispatchToProps(dispatch) {
  return {
	 userHomeActions : bindActionCreators(userHomeActions,dispatch)
  };
}

function mapStateToProps(state) {
	return {
        user:state.user,
        userHome:state.userHome
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(UserHome);

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/votePanel_style.css';
import globalStyles from 'app/styles/global_styles.css';

import {GButton,GFormInput,Panel,GNullBoard,Dropdown} from 'app/components/gTools/gTools.jsx';
import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import ItemInfo from 'app/components/vote/voteInfo.jsx';
import {ValidURL} from 'app/js/validators.js';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class VotePanel extends React.Component {

    componentDidMount(){
        if(this.props.isFetch){
            this.props.votePanelActions.fetchVotesFromServer(this.props.hashUrlb);
        }
    }
    componentWillUnmount(){
        this.props.votePanelActions.resetVoteData();
    }
    _onClickUpdateLocation(){
        if(!this.props.entryMode){
            this.props.votePanelActions.saveLocationsToServer(
                this.props.votePanelData.votes,
                this.props.votePanelData.deleteList,
                this.props.hashUrla);
            this.props.closeEditMode();
        }else {
            this.props.votePanelActions.pickerAddVoteToServer(
                this.props.votePanelData.votes,
                this.props.hashUrlb
            );
        }
    }
    closeEditMode(){
        if(this.props.closeEditMode){
            this.props.closeEditMode();
        }
    }
    _renderEditModeToolBar(){
        if(this.props.editMode){
            var saveButton = (this.props.votePanelData.isEdit)?
                                <GButton default="blue" style={{width:95}} onClick={this._onClickUpdateLocation.bind(this)}>Lưu lại</GButton>:
                                <GButton default="block" style={{width:95}} >Lưu lại</GButton>;
            return(
                <div className={styles.saveButtonBoard}>
                    <GButton default="gray" onClick={this.closeEditMode.bind(this)} style={{width:'95'}}>
                        <FormattedMessage id="votePanel.cancelMessage" />
                    </GButton>
                    <div style={{flex:'1'}}></div>
                    {saveButton}
                </div>
            );
        }
    }
    _onClickAdd(){
        this.props.votePanelActions.addItem('',this.props.editMode);
    }
    _renderItems(){
        var _autoFocus = (this.props.autoFocus&&this.props.editMode)?true:false;
        var self = this;
        var nodes = this.props.votePanelData.votes.map(function(item,index){
            return (<VoteItemInjectIntl
                        editMode = {self.props.editMode}
                        userActions= {self.props.userActions}
                        length = {self.props.votePanelData.votes.length}
                        data={item}
                        searchData={self.props.votePanelData.searchData[index]}
                        index={index}
                        searchSetting={self.props.user.userData.step.search}
                        votePanelActions={self.props.votePanelActions}
                        language={self.props.language}/>);
        });
        return (
            <div>
                {nodes}
                <div className={globalStyles.add_button} onClick={this._onClickAdd.bind(this)}>
                    <span className={globalStyles.add_button_plus}>+ </span>
                    <span className={globalStyles.add_button_description}><FormattedMessage id="votePanel.addLocationChoiceMessage" /></span>
                </div>
                {this._renderEditModeToolBar()}
            </div>
        );
    }
    render() {
        return (
            <div className={styles.main_board}>
                {this._renderItems()}
            </div>
        );
    }
}

class VoteItem extends React.Component {
    constructor(props) {
	  	super(props);
	  	this.timer= 0;
    	this.timeMachine= null;
    	this.keyword= '';
		this.isFire = false;
	}
    countToSearch() {
        this.timer = this.timer + 0.25;
        if (this.timer >= 0.75 && this.timer <= 3.5) {
            this.timer = 5;
            this.search();
        } else if (this.timer > 8) {
            //clear timer
            this.timer = 0;
            clearInterval(this.timeMachine);
        }
    }
    search() {
        if(!this.props.editMode||this.props.data.isNew){
            var title = this.props.data.title;

            if(ValidURL(title)){
                this.props.votePanelActions.parseLinkItem(title,this.props.data.id);
            }
            // if(title.length>0){
            //     this.props.votePanelActions.searchLocation(
            //         title,
            //         this.props.searchSetting.cityId,
            //         this.props.data.id);
            //     this.isFire = true;
            // }
            // }
        }
		// var keyword =this.refs.searchTextBox.value.trim();
		// if ((this.keyword != keyword)&&(keyword.length>0)) {
		// 	if(!this.isFire){
		// 		this.props.searchUserAndPageFromServer(keyword);
		// 		this.refs.searchResultDropdown.show();
		// 	}
		// 	this.isFire = true;
		// }
    }
    _onClickDelete(){
        if(!this.props.editMode){
            this.props.votePanelActions.deleteItem(this.props.data.id);
        }else{
            this.props.votePanelActions.deleteItem(this.props.data.id,true);//true = edit mode de danh dau nhung item can xoa
        }
    }
    showSearchBox(){
        this.refs.dropdown.show();
    }
    hideSearchBox(){
        this.refs.dropdown.hide();
    }
    _onChangeText(event){
        var text = event.target.value;
        this.props.votePanelActions.updateItem(text,this.props.data.id,this.props.editMode);
        // this.showSearchBox();
        this.isFire = false;
        if (this.timer == 0) {
            this.timer = 0.25;
            this.timeMachine = setInterval(this.countToSearch.bind(this), 250);
        } else {
            this.timer = 0.25;
        }
    }
	_renderVoteInfo(){
        var data =this.props.data;
        if(data.location||data.content){
            return (
                <ItemInfo disableMap={1} data={data} color='blue' language={this.props.language} />
            );
        }else {
            return null;
        }
    }
    _renderSearchBox(){
        var searchData = this.props.searchData.data;
        if(searchData && this.props.data.title.trim().length>0){
            return(
                <SearchList
                    keyword={this.props.data.title.trim()}
                    userActions= {this.props.userActions}
                    searchSetting={this.props.searchSetting}
                    data={searchData}
                    hideSearchBox={this.hideSearchBox.bind(this)}
                    votePanelActions={this.props.votePanelActions}
                    id={this.props.data.id}/>
            );
        }else {
            return (
                <SearchList
                    keyword={" "}
                    userActions= {this.props.userActions}
                    searchSetting={this.props.searchSetting}
                    data={[]}
                    votePanelActions={this.props.votePanelActions}
                    id={this.props.data.id}/>
            );
        }
    }
    _renderDeleteButton(){
        if(this.props.length>1){
            return(
                <div className={styles.delete_btn}
                     onClick={this._onClickDelete.bind(this)}><i className="fa fa-times" aria-hidden="true"></i></div>
            );
        }else {
            return null;
        }
    }
    _renderTextBoxIcon(){
        if(this.props.data.isLoading){
            return <i className={"fa fa-refresh fa-spin "+styles.input_search_icon} aria-hidden="true"></i>;
        }else{
            return <i className={"fa fa-search "+styles.input_search_icon} aria-hidden="true"></i>;
        }
    }
    _renderTitle(){
        const {intl} = this.props;
        if(this.props.data.isNew){
            var dropdownTrigger =
                    <div className={styles.vote_item_top}>
                        <div className={styles.input_board} sytle={{marginBottom:'0'}}>
                            <input
                            type="text"
                            autoFocus
                            style={{flex:'1',marginRight:'5',minHeight:'38'}}
                            placeholder={intl.formatMessage({ id: 'votePanel.locationMessage' })+" "+this.props.index}
                            value={this.props.data.title}
                            className={styles.input_style}
                            onChange={this._onChangeText.bind(this)}/>
                            {this._renderTextBoxIcon()}
                        </div>
                        {this._renderDeleteButton()}
                    </div>
            return(
                // <Dropdown ref="dropdown" dropdownTrigger={dropdownTrigger} className={styles.searchDropDown} keepMe>
                //     <DropdownContent className={DropdownStyles.drop_down+' '+styles.searchDropDownContent}>
                //         {this._renderSearchBox()}
                //     </DropdownContent>
                // </Dropdown>
                dropdownTrigger
            );
        }else {
            return(
                <div className={styles.vote_item_top}>
                    <i className="fa fa-map-marker" aria-hidden="true" style={{fontSize:21,color:'#333'}}></i>
                    <p className={styles.vote_title}>{this.props.data.title}</p>
                    {this._renderDeleteButton()}
                </div>
            );
        }
    }
    render(){
        return (
            <div className={styles.vote_item_board}>
                {this._renderTitle()}
                {this._renderVoteInfo()}
            </div>
        );
    }
}

const VoteItemInjectIntl = injectIntl(VoteItem);

export class SearchList extends React.Component{
	_renderList(){
		var self = this;
        var listNodes = this.props.data.map(function(item, index) {
            switch (item.publisher) {
                case 'foody':
                    return (
                        <OriginalLocation
                            key={index}
                            data={item}
                            indexKey={index}
                            hideSearchBox={self.props.hideSearchBox}
                            votePanelActions={self.props.votePanelActions}
                            id={self.props.id}/>
                    );
                case 'google':
                    return (
                        <GoogleLocation
                            key={index}
                            data={item}
                            indexKey={index}
                            hideSearchBox={self.props.hideSearchBox}
                            votePanelActions={self.props.votePanelActions}
                            id={self.props.id}/>
                    );
                default:
            }
        });
        if(listNodes.length>0){
            return(
                <div className={styles.search_list_board}>
                    {listNodes}
                </div>
            );
        }else{
            return(
                <GNullBoard style={{margin:'10'}}><FormattedMessage id="votePanel.noResultsMessage" values={{ keyword : this.props.keyword}}/></GNullBoard>
            );
        }
	}
    _equal(a,b){
        return (a==b)?"selected":null;
    }
    _onChangeCity(e){
        this.props.userActions.updateSearchSettingCityIdToServer(e.target.value);
        this.props.votePanelActions.searchLocation(
            this.props.keyword,
            e.target.value,
            this.props.id);
    }
    render() {
        return (
            <div className={styles.search_board}>
                <div className={styles.search_top_board}>
                    <div className={styles.search_top_col}>
                        <p>Thành phố</p>
                        <select
                            className={styles.select_style}
                            onChange={this._onChangeCity.bind(this)}>
                            <option selected={this._equal(this.props.searchSetting.cityId,1)} value="1">
                                    Đà Nẵng
                            </option>
                            <option selected={this._equal(this.props.searchSetting.cityId,2)} value="2">
                                    Hồ Chí Minh
                            </option>
                            <option selected={this._equal(this.props.searchSetting.cityId,3)} value="3">
                                    Hà Nội
                            </option>
                            <option selected={this._equal(this.props.searchSetting.cityId,4)} value="4">
                                    Hội An
                            </option>
                        </select>
                    </div>
                    <div style={{flex:'1'}}></div>
                    <div className={styles.search_top_icon_board}>
                        <i className="fa fa-sliders" aria-hidden="true"></i>
                    </div>
                </div>
 				{this._renderList()}
      		</div>
        );
    }
}


class OriginalLocation extends React.Component{
    linkTo() {
        this.props.hideSearchBox();
        this.props.votePanelActions.selectSearchData(this.props.indexKey,this.props.id);
        this.props.votePanelActions.resetSearchData(this.props.id);
    }
    render() {
        var avatarUrl = "/images/control/nullAvatar.jpg";
        if (this.props.data.thumbnail && this.props.data.thumbnail.picturePath != "") {
            avatarUrl = this.props.data.thumbnail.picturePath;
        }
        var {address} = this.props.data;
        address = (address.district)?address.district:'';
        return (
	        <div className={styles.one_search_item_board} onClick={this.linkTo.bind(this)}>
				<div className={styles.left}>
					<img className={styles.page_avatar} src={avatarUrl}/>
				</div>
				<div className={styles.right}>
					<b style={{color:'#3C74BB'}}>{this.props.data.title}</b>
                    <span className={styles.subscriber_text}>
						{this.props.data.publisher} <span className={styles.address}>{address}</span>
					</span>
				</div>
	        </div>
        );
    }
}

class GoogleLocation extends React.Component{
    linkTo() {
        this.props.hideSearchBox();
        this.props.votePanelActions.selectSearchData(this.props.indexKey,this.props.id);
        this.props.votePanelActions.resetSearchData(this.props.id);
    }
    render() {
        var {formatted_address,icon} = this.props.data;
        var address = formatted_address.split(',');
        if(address.length>=1){
            address = address[0].trim()+' '+address[1].trim();
        }else {
            address = address[0].trim();
        }
        var avatarUrl = icon;
        return (
	        <div className={styles.one_search_item_board} onClick={this.linkTo.bind(this)}>
				<div className={styles.left}>
					<img className={styles.page_avatar} src={avatarUrl}/>
				</div>
				<div className={styles.right}>
					<b style={{color:'#3C74BB'}}>{this.props.data.name}</b>
                    <span className={styles.subscriber_text}>
						{this.props.data.publisher} <span className={styles.address}>{address}</span>
					</span>
				</div>
	        </div>
        );
    }
}

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3K01RjGGzcuGy8G2K9v0Qr{\n    display: flex;\n    flex-direction: column;\n    flex:1;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3K01RjGGzcuGy8G2K9v0Qr"
};
import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/loginModal_style.css';
import {GButton,Panel,GModal} from 'app/components/gTools/gTools.jsx';
import LoginGoogleButton from 'app/components/login/loginGoogleButton.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

export default class LoginModal extends React.Component {
    render() {
        return (
            <GModal width={390} closeModal={this.props.closeModal} visible={this.props.visible}>
                <div>
                    <p className={styles.title}><FormattedMessage id="loginModal.loginRequiredFunctionality" /></p>
                    <LoginGoogleButton align="center"/>
                    <p className={styles.description}>(!) <FormattedMessage id="loginModal.loginToUseMoreFeatures" /></p>
                </div>
            </GModal>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/entryFinish_style.css';
import entryStyles from './styles/entry_style.css';
import LinkBox from 'app/components/link/linkBox.jsx';
import { FormattedMessage } from 'react-intl';

import { Panel, GButton } from 'app/components/gTools/gTools.jsx';
import SocialBar from 'app/components/socialBar/socialBar.jsx';
import PolygonSVG from 'app/components/polygonSVG/polygonSVG.jsx';


export default class EntryStep3 extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);;
    }
    render() {
        var urlb = "https://pickboz.com/entry/" + this.props.entry.entryData.urlaData.hashUrlb;
        var { hashUrlb } = this.props.entry.entryData.urlaData;
        return (
            <div style={{ minHeight: 1000 }}>
                <div className={entryStyles.header_container}>
                    <PolygonSVG className={entryStyles.header_bg} trianglify={Trianglify} />
                    <div className={globalStyles.side_margin}>
                        <div className={entryStyles.header_owner}>
                            <div className={entryStyles.header_owner_bg}>
                                {this.props.entry.entryData.urlaData.owner}
                            </div>
                        </div>
                        <div className={entryStyles.header_title}>
                            {this.props.entry.entryData.urlaData.title}
                        </div>
                        <div className={entryStyles.header_description}>
                            {this.props.entry.entryData.urlaData.description}
                        </div>
                    </div>
                </div>
                <div className={globalStyles.white_box} style={{ alignItems: 'center' }}>
                    <div className={styles.header_description}>
                        <FormattedMessage id="entryFinish.successJoinEventMessage" />
                    </div>
                    <img src="/images/art/finish_1.png" className={styles.tung_hoa_image} />
                    <p className={styles.shareTitle}><FormattedMessage id="entryFinish.shareLinkMessage" /></p>
                    <LinkBox url={urlb} />
                    <SocialBar url={urlb} />
                    <a href={'/entry/' + hashUrlb + '?tab=result'} style={{ width: '100%' }}>
                        <GButton
                            default='primary'
                            style={{ marginBottom: 10, width: '100%', marginTop: 10, height: 47, fontSize: 17 }}>
                            <FormattedMessage id="entryFinish.viewEventResultMessage" /> <i className="fa fa-arrow-right" aria-hidden="true"></i>
                        </GButton>
                    </a>
                </div>
            </div>
        );
    }
}

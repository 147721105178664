import React from 'react';

import SocialBar from 'app/components/socialBar/socialBar.jsx';
import LinkBox from 'app/components/link/linkBox.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generateToUrlb_style.css';

//set moment lib qua parse tieng viet
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/vi';
moment.locale('vi');

export default class GenerateToUrlb extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (window.FB) {
            var obj = this;
              // Read the entire document for `fb-*` classnames
              FB.XFBML.parse(this._scope,function() {
                  obj.props.generatePageActions.setToUrlbSocialBarIsLoading(0);
              });
        }
    }
    render() {
        var urlb = "https://pickboz.com/entry/"+this.props.urlaData.hashUrlb;
        var urla = "https://pickboz.com/generate/"+this.props.urlaData.hashUrla;
        return (
            <div>
                <div className={globalStyles.white_box}>
                    <center>
                        <h1 className={styles.topTitle}><FormattedMessage id="generateToUrlb.eventCreationSuccessMessage" /></h1>
                        <h3 className={styles.topTitle2}><FormattedMessage id="generateToUrlb.inviteFriendsMessage" /></h3>
                    </center>
                    <div ref={(s) => this._scope = s} className={styles.social_bar}>
                        <SocialBar
                            isLoading={this.props.urlaData.toUrlbSocialBarIsLoading}
                            url={urlb}/>
                    </div>
                    <LinkBox url={urlb}/>
                    <center style={{marginBottom:8}}>
                        <img src="/images/art/finish_2.png" className={styles.tung_hoa_image}/>
                    </center>
                    <center>
                        <span className={styles.topTitle2}><FormattedMessage id="generateToUrlb.manageEventLinkMessage" /></span>
                    </center>
                    <LinkBox url={urla} default='red'/>
                </div>
            </div>
        );
    }
}

// <div className={globalStyles.white_box} style={{marginBottom:'400px'}}>
//     <center>
//         <h3>{this.props.language.generate.resultUrla}</h3>
//         <h4 style={{color:'#999'}}>{this.props.language.generate.resultUrlaDescription}</h4>
//     </center>
//     <div className={globalStyles.input_share}
//          style={{textAlign:'center',backgroundColor:'#ffebeb'}}>
//          <a style={{color:'#c36666',textDecoration:'none'}}href={urla} target="_blank">{urla}</a>
//     </div>
// </div>

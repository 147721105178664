exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1IhRDQxRVUJ4DozGpKQsaH{\n\tpadding: 10px;\n    text-align: left;\n    border-bottom: 1px solid #e2e2e2;\n    margin: -5px -10px 0 -10px;\n    font-weight: 900;\n\tcolor: #02499d;\n}\n._2s37lq8iPTTrBeTHpX0XxO{\n\twidth: 36px;\n\theight: 36px;\n\tmargin-right: 5px;\n\tborder-radius: 18px;\n}\n._1_LAev4vmEUNyOCEr_LDmw{\n\tdisplay: flex;\n    flex-direction: row;\n    align-items: center;\n    border-bottom: 1px solid #e9ebee;\n    padding: 5px 0;\n\tfont-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"list_like_header": "_1IhRDQxRVUJ4DozGpKQsaH",
	"like_item_avatar": "_2s37lq8iPTTrBeTHpX0XxO",
	"like_item_board": "_1_LAev4vmEUNyOCEr_LDmw"
};
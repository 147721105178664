import {
    SET_COMMENT_DATA,
    SET_COMMENT_COUNT,
    ADD_COMMENT_DATA,
    DELETE_COMMENT_ITEM,
    SET_PICK_DETAILS,
    SWITCH_REPLY_BOX,
    UPDATE_REPLY_DATA,
    ADD_REPLY_DATA,
    SET_REPLY_DATA,
    ADD_COMMENT_TO_LIST
} from 'app/actions/comment/commentList_action.js';


const commentDataDefault = {
    list:[],
    count:0,
    isLoadMore:false,
    name:[]
}

function commentListData(state=commentDataDefault,action) {
    switch (action.type) {
        case SET_COMMENT_DATA:
            var newState = Object.assign({},state);
            var newList = action.data.map(function(item){
                var newAction ={
                    type:ADD_COMMENT_DATA,
                    data:item
                };
                return commentData(undefined,newAction);
            });
            if(action.latestDate==''){
                newState.list = newList;
            }else {
                newState.list = newList.concat(newState.list);
            }
            newState.isLoadMore = action.isLoadMore;
            return newState;
        case ADD_COMMENT_TO_LIST:
            var newState = Object.assign({},state);
            var newAction ={
                type:ADD_COMMENT_DATA,
                data:action.data
            };
            var newCommnet = commentData(undefined,newAction);
            newState.list = newState.list.concat(newCommnet);
            return newState;
        case SET_COMMENT_COUNT:
            var newState = Object.assign({},state);
            newState.count = action.data.length;
            var names = action.data.map(function(item){
                return item.name;
            });
            newState.name = names;
            return newState;
        case DELETE_COMMENT_ITEM:
            var newState = Object.assign({},state);
            newState.list.splice(action.index,1);
            return newState;
        case SWITCH_REPLY_BOX:
        case UPDATE_REPLY_DATA:
        case ADD_REPLY_DATA:
        case SET_REPLY_DATA:
        case SET_PICK_DETAILS:
            var newState = Object.assign([], state);
            newState.list[action.index] = commentData(newState.list[action.index],action);
            return newState;
        default:
            return state;
    }
}


function commentData(state={
    replyBoxDisplay:0,
    replyValue:'',
    replyCount:0,
    replyList:[],
    pickData:{},
    user:null,
    message:{
        text:''
    },
    createdAt:new Date(),
    isReplyLoadMore:false
},action) {
    switch (action.type) {
        case ADD_COMMENT_DATA:
            var newState = Object.assign({},state);
            newState.message = (action.data.message)? (action.data.message) :{text:''} ;
            newState.id = action.data.id;
            //newState.name = action.data.name;
            //newState.replyCount = action.data.replyCount;
            newState.user = action.data.user;
            newState.createdAt = new Date(action.data.createdAt);
            return newState;
        case SWITCH_REPLY_BOX:
            var newState = Object.assign({},state);
            newState.replyBoxDisplay = action.data;
            if(action.data==1){
                newState.replyValue='';
            }
            return newState;
        case UPDATE_REPLY_DATA:
            var newState = Object.assign({},state);
            newState.replyValue = action.data;
            return newState;
        case SET_REPLY_DATA:
            var newState = Object.assign({},state);
            var newReplyList = action.data.map(function(item){
                var newAction ={
                    type:ADD_REPLY_DATA,
                    data:item
                };
                return replyData(undefined,newAction);
            });
            newState.replyList = newState.replyList.concat(newReplyList);
            newState.isReplyLoadMore = action.isLoadMore;
            return newState;
        case SET_PICK_DETAILS:
            var newState = Object.assign({},state);
            newState.pickData = action.data;
            return newState;
        case ADD_REPLY_DATA:
            var newState = Object.assign({},state);
            newState.replyList.push(replyData(action.data,action));
            newState.replyCount = newState.replyCount+1;
            return newState;
        default:
            return state;
    }
}

function replyData(state={
    message:{
        text:''
    },
    id:'',
    user:{},
    createdAt:new Date()
},action) {
    switch (action.type) {
        case ADD_REPLY_DATA:
            var newState = Object.assign({},state);
            newState.message = action.data.message;
            newState.id = action.data.id;
            newState.user = action.data.user;
            newState.createdAt = new Date(action.data.createdAt);
            return newState;
        default:
            return state;
    }
}
export default commentListData;

import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles/gTimePicker_style.css';


function insideSlider(val) {
    return (val>-498 && val<1000)? true : false; //min=0, max =757
}

export default class GTimePicker extends React.Component {
    constructor() {
        super();
        this.state = {
            time:'',
            isStart:true,
            dragging:false,
            slideStartPoint:0,
            connector:280,
            sliderY:280, //starting point
            distance:0,
            mouseDown:false,
        };
    }
    handleOnPick(value) {
        if(!this.state.dragging) {
            this.setState({time:value});
            if (!this.props.isEnd) {
                this.props.setStartTime(value,this.props.dayIndex);
                this.props.setIsEnd(1,this.props.dayIndex);
            }
            else {
                this.props.setEndTime(value,this.props.dayIndex);
                this.props.setIsEnd(0,this.props.dayIndex);
            }
            // this.props.update(value,this.state.isStart,this.props.dayIndex);
            // this.setState({isStart: !this.state.isStart});
        }
    }
    slideStart(event) {
        this.setState({slideStartPoint:event.screenY})
        this.setState({mouseDown:true})
    }
    slideMove(event) {
        if(this.state.mouseDown) {
            this.setState({dragging:true});
            if (insideSlider(this.state.sliderY)) {
                this.setState({distance:event.screenY - this.state.slideStartPoint})
                var {distance} = this.state;
                var newY = this.state.connector + distance;
                if(distance!==0) {
                    if(insideSlider(newY)) {
                        this.setState({sliderY:newY});
                    }
                    else {
                        if (distance<0) {
                            this.setState({sliderY:0}); //min
                        }
                        else {
                            this.setState({sliderY:757}) //max
                        }
                    }
                }
            }
        }
    }
    slideEnd() {
        this.setState({mouseDown:false});
        if (insideSlider(this.state.connector + this.state.distance)) {
            this.setState({connector:this.state.connector+this.state.distance})
        }
        setTimeout((obj) => { obj.state.dragging = false }, 200, this)
    }
    componentDidMount() {
        var node = ReactDOM.findDOMNode(this.wrapper);
        node.scrollTop = 290;
    }
    render() {
        var data = [],i,j;
        for (i=0;i<24;i++) {
            for (j=0;j<2;j++) {
                data.push(i+':'+(j===0 ? '00' : 30*j));
            }
        }
        return (
            <div>
                <div>
                    <div ref={(wrapper) => this.wrapper = wrapper} className={styles.slider_wrapper}>
                        <ul
                            className={styles.slider}
                            onMouseDown={this.slideStart.bind(this)}
                            onMouseLeave={this.slideEnd.bind(this)}
                            onMouseEnter={this.slideEnd.bind(this)}
                            onMouseUp={this.slideEnd.bind(this)}
                            onMouseMove={this.slideMove.bind(this)}
                            style={
                                {transform:'translateY('+String(this.state.sliderY)+'px)'}}
                        >
                            {data.map(item =>
                                     <GItem
                                        dragging={this.state.dragging}
                                        onPick={this.handleOnPick.bind(this)}
                                    >{item}</GItem>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export class GItem extends React.Component {
    handleOnClick(event) {
            var str = this.props.children;
            var divIndex = str.indexOf(':');
            var hour = parseInt(str.slice(0,divIndex)); //tach phan truoc dau :
            var minute = parseInt(str.slice(divIndex+1)); //tach phan sau dau :
            var value = (hour * 3600) + (minute * 60);
            console.log('value: ',str.slice(divIndex+1));
            this.props.onPick(value);
            event.stopPropagation();
    }
    render() {
        return (
            <li
                className={styles.item}
                onClick={this.handleOnClick.bind(this)}>
                {this.props.children}
            </li>
        )
    }
}

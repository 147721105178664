import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import VoteResult from 'app/components/result/voteResult.jsx';

import * as voteResultActions from 'app/actions/result/voteResult_action.js';

function mapDispatchToProps(dispatch) {
    return {
        voteResultActions : bindActionCreators(voteResultActions,dispatch),
    }
}

function mapStateToProps(state) {
	return ({
        result:state.result
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(VoteResult);

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import {TabMenu,Dropdown,GModal} from 'app/components/gTools/gTools.jsx';
import {DropdownTrigger,DropdownContent,DropdownStyles} from 'app/components/gTools/dropButton.jsx';
import DropdownItem from 'app/components/menu/dropdownItem.jsx';
import {checkDeadline,minDate} from 'app/js/func.js';
import StatusTag from 'app/components/controlPanel/statusTag.jsx';
import EntryHeaderEdit from 'app/components/entry/entryHeaderEdit.jsx'
import PolygonSVG from 'app/components/polygonSVG/polygonSVG.jsx';
import EntryHeaderControlBar from 'app/containers/entry/entryHeaderControlBar_container.jsx';
import PickList from 'app/containers/entry/listPick_container.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './styles/entryHeader_style.css';


const tabMenuData = [
    {
        title: <FormattedMessage id="entryHeader.joinMessage" />,
        icon:'fa fa-check-square-o',
        value:'pick'
    },
    {
        title: <FormattedMessage id="entryHeader.eventResultMessage" />,
        icon:'fa fa-hand-peace-o',
        value:'result'
    }
];
export default class EntryHeader extends React.Component {
    _onSelectTabMenu(value){
        this.props.entryActions.setTabValue(value);
    }
    _renderMain(){
        switch (this.props.entryHeader.showMode) {
            case 'info':
                return <HeaderInfo {...this.props}/>
            case 'edit':
                return <EntryHeaderEdit {...this.props}/>;
            default:
        }
    }
    _renderBackground(thumbnail){
        if(thumbnail!=''){
            return <div className={styles.blur} style={{backgroundImage: `url(${thumbnail})`}}></div>;
        }else {
            return <PolygonSVG className={styles.header_bg} trianglify={Trianglify}/>;
        }
    }
    render() {
        var hashUrlb = this.props.entry.entryData.urlaData.hashUrlb;
        var hashUrla = this.props.hashUrla;
        var {owner,title,description,setting,schedule,deadLine} =this.props.entry.entryData.urlaData;
        var thumbnail = setting.thumbnail;
        var {isShowListPick} = this.props.entry.pageController;
        var tabValue = this.props.entry.pageController.tabValue;
        var selectedDays = (schedule.selectedDays)? (schedule.selectedDays) : {};
        var deadline = (deadLine)? (deadLine) : null;
        var status = checkDeadline(deadline,selectedDays);
        return (
            <div className={styles.container} >
                <GModal visible={isShowListPick} closeModal={()=> this.props.entryActions.setDisplayListPick(false)}>
                    <PickList hashUrlb={hashUrlb} hashUrla={hashUrla}/>
                </GModal>
                {this._renderBackground(thumbnail)}
                {this._renderMain()}
                <div style={{zIndex:0}}>
                    <TabMenu data={tabMenuData} selectedValue={tabValue} onSelect={this._onSelectTabMenu.bind(this)}/>
                </div>
            </div>
        );
    }
}


class HeaderInfo extends React.Component {
    showEditMode(){
        this.props.entryHeaderActions.setShowMode('edit');
    }
    showListPick(){
        this.props.entryActions.setDisplayListPick(true);
    }
    _headerEditMenu(){
        //if(this.props.deadLine || !this.props.hashUrla) return null;//het han
        var dropdownTrigger = <i className={'fa fa-chevron-down '+styles.menuButton} aria-hidden="true"></i>;
        var isOwner = (this.props.hashUrla)?true:false;
        return(
            <Dropdown dropdownTrigger={dropdownTrigger}>
                <DropdownContent className={DropdownStyles.drop_right} style={{minWidth:210}}>
                    <DropdownItem
                        disable={!isOwner}
                        icon = 'fa fa-pencil-square-o'
                        title= {<FormattedMessage id="entryHeader.editMessage" />}
                        description= {<FormattedMessage id="entryHeader.changeEventInfoMessage" />}
                        onClick={this.showEditMode.bind(this)}/>
                    <DropdownItem
                        icon = 'fa fa-users'
                        title= {<FormattedMessage id="entryHeader.participantsMessage" />}
                        description= {<FormattedMessage id="entryHeader.participantsListMessage" />}
                        onClick={this.showListPick.bind(this)}/>
                </DropdownContent>
            </Dropdown>
        );
    }
    render() {
        var {owner,title,description,setting,schedule,deadLine} =this.props.entry.entryData.urlaData;
        var tabValue = this.props.entry.pageController.tabValue;
        var hashUrlb = this.props.entry.entryData.urlaData.hashUrlb;
        var selectedDays = (schedule.selectedDays)? (schedule.selectedDays) : {};
        var deadline = (deadLine)? (deadLine) : null;
        var flag = this.props.entry.urlbData.flag;
        var status = checkDeadline(deadline,selectedDays,flag);
        return (
            <div className={styles.headerInfoBoard}>
                <div className={styles.headerTop}>
                    <div className={styles.menuButtonBoard}>
                        <StatusTag status={status} />
                        <div style={{flex:1}}></div>
                        {this._headerEditMenu()}
                    </div>
                    <div className={styles.ownerBoard}>
                        <p className={styles.owner}>{owner}</p>
                    </div>
                    <div className={styles.titleBoard}>
                        <p className={styles.title}>{title}</p>
                    </div>
                </div>
                <div className={styles.headerFooter}>
                    <p className={styles.description}>{description}</p>
                    <EntryHeaderControlBar hashUrlb={hashUrlb}/>
                </div>
            </div>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import styles from './styles/voteInfo_style.css';

// import GoogleMaps from 'app/components/map/googleMap.jsx';

export default class ItemInfo extends React.Component {
    handleMapClick() {
        if (!this.props.data.toggleMap) {
            this.props.pickVoteActions.toggleMap(this.props.index,1);
        }
        else {
            this.props.pickVoteActions.toggleMap(this.props.index,0);
        }
    }
    // _renderGoogleMap() {
    //     if (this.props.data.toggleMap) {
    //         var googleLocation = (this.props.data.content)?this.props.data.content.location:this.props.data.location.location;
    //         return (<div className={styles.border_top}>
    //                     <GoogleMaps
    //                         lng={googleLocation.longitude}
    //                         lat={googleLocation.latitude}/>
    //                 </div>)
    //     }
    //     else {
    //         return null;
    //     }
    // }
    // _renderGoogleMapIcon() {
    //     if(this.props.data.content && !this.props.data.content.location.longitude) return null;
    //     if (!this.props.disableMap) {
    //         if (!this.props.data.toggleMap) {
    //             return (
    //                 <div onClick={this.handleMapClick.bind(this)} className={styles.icon_button}>
    //                     <i className={"fa fa-map-marker "+styles.icon_button_icon} aria-hidden="true"></i>&nbsp;
    //                     <span className={styles.icon_button_label}>{this.props.language.components.mapButton}</span>
    //                 </div>
    //             )
    //         }
    //         else {
    //             return (
    //                 <div onClick={this.handleMapClick.bind(this)} className={styles.icon_button}>
    //                     <i className={"fa fa-map-marker "+styles.icon_button_icon} style={{color:'#ff9217'}} aria-hidden="true"></i>&nbsp;
    //                     <span style={{color:'#ff9217'}} className={styles.icon_button_label}>{this.props.language.components.mapButton}</span>
    //                 </div>
    //             )
    //         }
    //     }
    //     else {
    //         return null;
    //     }
    // }

    render(){
        var style = {};
        if(this.props.color == 'blue'){
            style = {backgroundColor:'#unset'};
        }
		style = (this.props.picked)?{backgroundColor:'#e6f4ff'}:style;
        var avatarUrl = '/images/control/marker.png';
        var locationData = (this.props.data.content)?this.props.data.content:this.props.data.location;
        var address = locationData.address;
        if (locationData && locationData.thumbnail && locationData.thumbnail.picturePathLarge != "") {
            avatarUrl = (locationData.thumbnail.picturePathLarge)?locationData.thumbnail.picturePathLarge:'/images/control/marker.png';
        }
        if(address && address.address){
            address = address.address+' - '+address.district+' - '+address.city;
        }else if(locationData.description){
            address = locationData.description;
        }
        return (
            <div ref="main" className={styles.item_info_container}>
                <div className={styles.item_info_board} style={style}>
                    <div className={styles.item_info_right}>
    					<a className={styles.item_info_image_link} href={locationData.url} target="_blank">
    						<img src={avatarUrl} className={styles.item_info_image}/>
    					</a>
                    </div>
                    <div className={styles.item_info_left}>
                        <p className={styles.item_info_title}>{locationData.title}</p>
                        <p className={styles.item_info_address}>{address}</p>
                        <div className={styles.item_info_footer}>
                        <a href={locationData.url} target="_blank">
                            <div className={styles.icon_button}>
                                <i className="fa fa-share" aria-hidden="true"></i>&nbsp;
                                <span className={styles.item_info_source}>
                                    {locationData.publisher}
                                </span>
                            </div>
                        </a>
                            {/* {this._renderGoogleMapIcon()} */}
                        </div>
                    </div>
                </div>
                {/* {this._renderGoogleMap()} */}
            </div>
        );
    }
}
//<p className={styles.item_info_description}>{locationData.description}</p>

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {browserHistory,Link} from 'react-router'
import PolygonSVG from 'app/components/polygonSVG/polygonSVG.jsx';
import {TabMenu,GButton} from 'app/components/gTools/gTools.jsx';

import styles from './styles/userHeader_style.css';
import globalStyles from 'app/styles/global_styles.css';

import { FormattedMessage, injectIntl } from 'react-intl';


const tabMenuData = [
    {
        title: <FormattedMessage id="userHeader.eventParticipationMessage" />,
        icon:'fa fa-check-square',
        value:'urlb'
    },
    {
        title: <FormattedMessage id="userHeader.eventCreatedMessage2" />,
        icon:'fa fa-pencil-square-o',
        value:'urla'
    }
];
// {
//     title:'TỔNG QUAN',
//     icon:'fa fa-user-circle-o',
//     value:'home'
// },
export default class UserHeader extends React.Component {
    _onSelectTabMenu(value){
        this.props.userHomeActions.setTabValue(value);
    }
	render() {
        var avatarUrl = (this.props.userData.profile)?this.props.userData.profile.avatarUrl:'/images/control/nullAvatar.jpg';
        var displayName = this.props.userData.displayName;
        var tabValue = this.props.userHome.tabValue;
        var {urlbCount,urlaCount} = this.props.userData;
		return (
			<div className={styles.container}>
                <PolygonSVG className={styles.trianglify} trianglify={Trianglify}/>
                <div className={styles.headerTop}>
                    <object data={avatarUrl} type="image/jpg" className={styles.avatar}>
                        <img src="/images/control/nullAvatar.jpg" className={styles.avatar}/>
                    </object>
                    <p className={styles.displayName}>{displayName}</p>
                    <p className={styles.info}>
                        <FormattedMessage id="userHeader.userStatsMessage" values={{
                            urlbCount,
                            urlaCount
                        }}/>
                    </p>
                    {/* <a href='/generate'>
                        <GButton default='orange' style={{marginTop:10}}><i className="fa fa-plus-circle" aria-hidden="true"></i> Tạo kèo</GButton>
                    </a> */}
                </div>
                <div style={{zIndex:0}}>
                    <TabMenu data={tabMenuData} selectedValue={tabValue} onSelect={this._onSelectTabMenu.bind(this)}/>
                </div>
			</div>
		)
	}
}
//<div className={styles.blur} style={{backgroundImage: `url(${avatarUrl})`}}></div>

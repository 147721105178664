export const SWITCH_STICKER_BOX_DISPLAY = 'SWITCH_STICKER_BOX_DISPLAY';
export const TEXT_COMMENT_UPDATE  = 'TEXT_COMMENT_UPDATE';
export const RESET_TEXT_COMMENT = 'RESET_TEXT_COMMENT';
import {
    API_COLLECTION_FETCH,
    API_COMMENT_SUBMIT,
    API_STICKER_FETCH} from 'app/js/api.js';
import {setEntryEdited} from 'app/actions/entry/entry_action.js';
import {addCommentToList} from 'app/actions/comment/commentList_action.js';
import {cancelSticker} from 'app/actions/collection/collection_action.js';



export function switchStickerBoxDisplay() {
    return{
        type: SWITCH_STICKER_BOX_DISPLAY
    };
}

export function updateText(data) {
    return {
        type: TEXT_COMMENT_UPDATE,
        data: data
    }
}

export function resetText() {
    return {
        type: RESET_TEXT_COMMENT
    }
}

export function submitCommentToServer(hashUrlb,message) {
    var data = {
        hashUrlb: hashUrlb,
        message:message
    }
    return function(dispatch) {
        return fetch(API_COMMENT_SUBMIT, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            if(responseJson.content){
                dispatch(addCommentToList(responseJson.content,));
                dispatch(resetText());
                dispatch(cancelSticker("commentBox"));
            }
        });
    }
}

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { browserHistory, Link } from 'react-router';

import { GButton } from 'app/components/gTools/gTools.jsx';
import styles from './styles/index_style.css';

export default class Main extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	componentWillMount() {
		window.addEventListener('resize', () => {
			this.props.indexPageActions.resizeScreen(window.innerWidth);
		});
		this.props.indexPageActions.resizeScreen(window.innerWidth);
	}
	_onClickGenerateUrla() {
		if (this.props.location.query.lang && this.props.location.query.lang !== 'vn') {
			browserHistory.push('/generate?lang=' + this.props.location.query.lang);
		}
		else {
			browserHistory.push('/generate');
		}
	}
	render() {
		return (
			<div className={styles.main_board}>
				<div className={styles.main_board_header}>
					<div className={styles.headerBackground} style={{ backgroundImage: 'url(/images/bgIndex_1.webp)' }}></div>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody}>
						<div className={styles.headerLeft}>
							<h1 className={styles.headerDomain}>Pickboz.com</h1>
							<p className={styles.headerDescription}><FormattedMessage id="index.coordinateTimeAndLocationMessage"/></p>
							<p className={styles.headerDescription2}><FormattedMessage id="index.supportingLocationsMessage" values={{countNumber:<span style={{ color: '#fff', fontSize: 28, fontWeight: 'bold' }}>100.000</span>}}/></p>
							<Link to="/generate">
								<GButton
									default='primary'
									className={styles.big_button}
									onClick={this._onClickGenerateUrla.bind(this)}><FormattedMessage id="index.createNowMessage" />
								</GButton>
							</Link>
						</div>
						<Link to="/generate" className={styles.headerRight + ' ' + styles.inviteBox}>
							{/* <img className={styles.girlAvatar} src='/images/control/girl.jpg' /> */}
							<img height={450} className={styles.mobile_demo} src='/images/control/pickEvent.webp' />
							{/* <div className={styles.speech_bubble}>
								<div className={styles.bubbleBox}>
									<p className={styles.eventTitle}>Cuối tuần này nhóm chúng ta đi đâu nhé!</p>
									<p className={styles.eventDescription}>Anh nào vào tạo kèo đi !!</p>
								</div>
							</div> */}
						</Link>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
				<div className={styles.info_container + ' ' + styles.white}>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody + ' ' + styles.le}>
						<div className={styles.headerLeft}>
							<p className={styles.xepKeoWill}><FormattedMessage id="index.helpYouMessage" /></p>
							<div className={styles.paragraph_title}><FormattedMessage id="index.createAndManageEventMessage" /></div>
							<div className={styles.paragraph}><FormattedMessage id="index.createDetailedEventInfoMessage" /></div>
						</div>
						<div className={styles.headerRight} style={{ alignItems: 'center' }}>
							<img height={550} className={styles.iphone} src='/images/demo_3.webp' />
						</div>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
				<div className={styles.info_container + ' ' + styles.gray}>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody}>
						<div className={styles.headerLeft}>
							<img height={550} className={styles.iphone} src='/images/demo_1.webp' />
						</div>
						<div className={styles.headerRight}>
							<p className={styles.xepKeoWill}><FormattedMessage id="index.helpYouMessage" /></p>
							<div className={styles.paragraph_title}><FormattedMessage id="index.joinIn3SecondsMessage" /></div>
							<div className={styles.paragraph}><FormattedMessage id="index.easyJoinAndRequestMessage" /></div>
						</div>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
				<div className={styles.info_container + ' ' + styles.white}>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody + ' ' + styles.le}>
						<div className={styles.headerLeft}>
							<p className={styles.xepKeoWill}><FormattedMessage id="index.helpYouMessage" /></p>
							<div className={styles.paragraph_title}><FormattedMessage id="index.easyJoinAndRequestMessage" /></div>
							<div className={styles.paragraph}><FormattedMessage id="index.autoAggregateRequestsMessage" /></div>
						</div>
						<div className={styles.headerRight} style={{ alignItems: 'center' }}>
							<img height={550} className={styles.iphone} src='/images/demo_2.webp' />
						</div>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
				<div className={styles.info_container + ' ' + styles.gray}>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody} style={{ flexDirection: 'column' }}>
						<div className={styles.paragraph_title + ' ' + styles.final_title}><FormattedMessage id="index.simpleLoginMessage" />
							<div><FormattedMessage id="index.allPlatformsMessage" /> </div></div>
						<div className={styles.paragraph + ' ' + styles.final_paragraph}>
							<FormattedMessage id="index.createEventNowMessage" />
						</div>
						<center>
							<Link to="/generate">
								<GButton
									default='primary'
									className={styles.big_button}
									onClick={this._onClickGenerateUrla.bind(this)}><FormattedMessage id="index.createNowMessage" />
								</GButton>
							</Link>
						</center>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
				<div className={styles.info_container + ' ' + styles.white}>
					<div style={{ flex: 1 }}></div>
					<div className={styles.headerBody} style={{ flexDirection: 'column' }}>
						<p className={styles.xepKeoWill} style={{ textAlign: 'center' }}><FormattedMessage id="index.contactWithMessage" /></p>
						<div className={styles.paragraph_title + ' ' + styles.final_title}>pickboz.com</div>
						<p className={styles.smallDescription}><FormattedMessage id="index.desireServiceMessage" /></p>
						<p className={styles.smallDescription}><FormattedMessage id="index.contactAndFeedbackMessage" /></p>
						<div className={styles.info_container + ' ' + styles.email_container}>
							<div style={{ flex: 1 }}></div>
							{/* <span className={styles.infoItem}>
								<i className="fa fa-envelope" aria-hidden="true"></i> gamarist.dev@gmail.com
							</span>
							<a className={styles.infoItem} style={{ cursor: 'pointer' }} href="https://www.facebook.com/pickboz.com/" target='_blank'>
								<i className="fa fa-facebook-square" aria-hidden="true"></i> Fanpage pickboz.com
							</a> */}
							<div style={{ flex: 1 }}></div>
						</div>
					</div>
					<div style={{ flex: 1 }}></div>
				</div>
			</div>
		)
	}
}
import {
    combineReducers
} from 'redux';

import {
    SET_NOTIFICATION_DATA,
    SET_NOTIFICATION_COUNT,
    SET_NOTIFICATION_ISLOADING
} from 'app/actions/notification/notification_action.js';


function notificationList(state=[],action) {
    switch (action.type) {
        case SET_NOTIFICATION_DATA:
            var newState = Object.assign([],state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

function count(state=0,action) {
    switch (action.type) {
        case SET_NOTIFICATION_COUNT:
            var newState = Object.assign([],state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

function isLoading(state=false,action) {
    switch (action.type) {
        case SET_NOTIFICATION_ISLOADING:
            var newState = Object.assign([],state);
            newState = action.data;
            return newState;
        default:
            return state;
    }
}

const notificationReducer = combineReducers({
    list: notificationList,
    count: count,
    isLoading: isLoading
})
export default notificationReducer;
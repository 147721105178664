import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import UrlbList from 'app/components/keoList/urlbList.jsx';

import * as urlbListActions from 'app/actions/keoList/urlbList_action.js';

function mapDispatchToProps(dispatch) {
    return {
        urlbListActions:bindActionCreators(urlbListActions,dispatch)
    }
}

function mapStateToProps(state) {
    return ({
        user:state.user,
        urlbList:state.urlbList
    })
}

export default connect(mapStateToProps,mapDispatchToProps)(UrlbList);

export const SWITCH_IS_SHOW_LOGIN_MODAL = 'SWITCH_IS_SHOW_LOGIN_MODAL';
export const SWITCH_DISPLAY_CONTROL_PANEL = 'SWITCH_DISPLAY_CONTROL_PANEL';
export const SET_DISPLAY_CONTROL_PANEL = 'SET_DISPLAY_CONTROL_PANEL';
export const SET_URLA_HISTORY = 'SET_URLA_HISTORY';
export const SET_URLB_HISTORY = 'SET_URLB_HISTORY';
export const SET_DOCKED_CONTROL_PANEL = 'SET_DOCKED_CONTROL_PANEL';
export const SET_SLIDE_MODE_CONTROL_PANEL = 'SET_SLIDE_MODE_CONTROL_PANEL';
import { API_GENERATE_FETCH_LIST, API_ENTRY_FETCH_LIST } from 'app/js/api.js';

export function fetchGenerateHistory(latestDate) {
    var submitData = {
        latestDate:latestDate
    }
    return function(dispatch) {
        return fetch(API_GENERATE_FETCH_LIST, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            dispatch(setUrlaHistory(responseJson.content));
        });
    }
}

export function fetchEntryHistory(latestDate) {
    var submitData = {
        latestDate:latestDate
    }
    return function(dispatch) {
        return fetch(API_ENTRY_FETCH_LIST, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData)
        }).then((response) => response.json()).then((responseJson) => {
            console.log('urlb history ===> ', responseJson.content);
            dispatch(setUrlbHistory(responseJson.content));
        });
    }
}

export function switchIsShowLoginModal(data) {
    return {
        type: SWITCH_IS_SHOW_LOGIN_MODAL,
        data: data
    }
}

export function switchDisplayControlPanel(data) {
    return {
        type: SWITCH_DISPLAY_CONTROL_PANEL,
        data: data
    };
}

export function setDisplayControlPanel(data) {
    return {
        type: SET_DISPLAY_CONTROL_PANEL,
        data: data
    };
}

export function setUrlaHistory(data) {
    return {
        type: SET_URLA_HISTORY,
        data: data
    }
}

export function setUrlbHistory(data) {
    return {
        type: SET_URLB_HISTORY,
        data: data
    }
}

export function setDocked(data) {
    return {
        type: SET_DOCKED_CONTROL_PANEL,
        data: data
    }
}
export function setMode(data) {
    return {
        type: SET_SLIDE_MODE_CONTROL_PANEL,
        data: data
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/controlPanel_style.css';
import {GNullBoard} from 'app/components/gTools/gTools.jsx';

import {minDate} from 'app/js/func.js';

import moment from 'moment';
import 'moment/locale/vi';

export default class UrlaHistoryList extends React.Component {
    _renderSeeMore() {
        if (this.props.urlaHistoryData.length > 5) {
            return (
                <div>
                    <Link style={{fontSize:'13px'}} to="/home?tab=urla"><FormattedMessage id="urlaHistoryList.viewMoreMessage" /></Link>
                </div>
            )
        }
        else {
            return null;
        }
    }
    _renderList() {
        var data = (this.props.urlaHistoryData.length > 5)? this.props.urlaHistoryData.slice(0,5) : this.props.urlaHistoryData.slice(0);
        var urla = null
        if (this.props.path.slice(1,7)=='generate') {
            urla = this.props.path.slice(8);
        }
        if (this.props.path.slice(1,9)=='generate') {
            urla = this.props.query.id;
        }
        var nodeList = data.map((item,index) => {
            var highlight = (item.hashUrla == urla)? true : false;
            return (
                <UrlaUnit
                    highlight={highlight}
                    indexKey={index}
                    urlaUnitData={item}
                />)
        })
        if (nodeList.length>0) {
            return nodeList;
        }
        else {
            return (<GNullBoard><FormattedMessage id="urlaHistoryList.noEventsMessage" /></GNullBoard>)
        }
    }
    render() {

        return (
            <div>
                <h4 className={styles.nav_header}>
                    <FormattedMessage id="urlaHistoryList.eventCreatedMessage" />
                </h4>
                {this._renderList()}
                {this._renderSeeMore()}
            </div>
        )
    }
}

export class UrlaUnit extends React.Component {
    handleOnClick() {
        browserHistory.push('/generate/'+this.props.urlaUnitData.hashUrla);
    }
    _renderNewMark(){
        var {createdAt} = this.props.urlaUnitData;
        createdAt = new Date(createdAt).getTime()/1000;
        var toDay = new Date().getTime() / 1000;

        console.log(toDay - createdAt);
        if(toDay - createdAt<86400){
            return(
                <div className={styles.newMark}></div>
            );
        }else {
            return null;
        }
    }
    render() {
        var selectedDays = (this.props.urlaUnitData.schedule.selectedDays)? (this.props.urlaUnitData.schedule.selectedDays) : {};
        var timeStamp = moment(minDate(selectedDays)).fromNow();
        var highlightStyle = (this.props.highlight)? {backgroundColor:"#e6f4ff", color:'#02499d'} : {};

        const urlaUnitData = this.props.urlaUnitData;
        var thumbnail = (urlaUnitData.setting)?urlaUnitData.setting.thumbnail:null;
        var iconUrl = (thumbnail)?thumbnail : '/images/icon/select.png';
        return (
            <div onClick={(event) => {this.handleOnClick()}} className={styles.url_unit_board} style={highlightStyle}>
                <div className={styles.url_unit_icon}>
                    {this._renderNewMark()}
                    <img className={styles.urla_unit_icon_img} src={iconUrl} />
                </div>
                <div className={styles.url_unit_content}>
                    <div className={styles.urla_unit_title}>
                        {this.props.urlaUnitData.title}
                    </div>
                    <div className={styles.stats_board} style={{color: this.props.highlight?'#02499d':'#999'}}>
                        <div>
                            <i style={{marginRight:'5px'}} className="fa fa-eye"/>
                            <span>{this.props.urlaUnitData.viewCount}</span>
                        </div>
                        <div>
                            <i style={{marginRight:'5px',marginLeft:'5px'}} className="fa fa-user"/>
                            <span>{this.props.urlaUnitData.urlbCount}</span>
                        </div>
                        <div style={{flex:1, textAlign:'end'}} className={styles.status_text}>
                            {timeStamp}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1umUyNlbkgdpFWjbMp4TXU {\n    background-color: #b3b3b3;\n    border-radius: 2px;\n}\n\n._1KhdTg7gV9wsLCnYDtEfbo {\n    background-color: #389e0d;\n    border-radius: 2px;\n}\n\n._2Y-oqDiuTPtuulMi5NuIvr {\n    background-color: #02499d;\n    border-radius: 2px;\n}\n.mLEesCXgNdVuiW7D3pW3J {\n    background-color: #02499d;\n    border-radius: 2px;\n}\n.z0fs5hVXvTkYBpubiW8yW {\n    color: #fff;\n    margin: 0px 5px;\n    line-height: 18px;\n    letter-spacing: -0.01em;\n    font-size: 11.5px;\n}\n", ""]);

// exports
exports.locals = {
	"status_tag_0": "_1umUyNlbkgdpFWjbMp4TXU",
	"status_tag_1": "_1KhdTg7gV9wsLCnYDtEfbo",
	"status_tag_2": "_2Y-oqDiuTPtuulMi5NuIvr",
	"status_tag_3": "mLEesCXgNdVuiW7D3pW3J",
	"tag_text": "z0fs5hVXvTkYBpubiW8yW"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._17sZjuakjS1D-wPOmTgeR6{\n\tmargin-top: 5px;\n\tbackground-color: #f9f9f9;\n    padding: 3px 5px;\n\tborder: 1px solid #e2e2e2;\n}\n\n._16nJxZcDg2mJAvn1iGZVIJ{\n\n}\n.no_PH-zqrqBBFyxuA7xKE{\n\tfont-size: 12px;\n    color: #a94442;\n    font-weight: bold;\n\tmargin-bottom: 5px;\n}\n._3vnSeMrw9jlazXxUHJfmsV{\n\tdisplay: flex;\n    flex-direction: row;\n    margin-bottom: 3px;\n    margin-top: 3px;\n}\n._2l9Z5hd-Qjz20F8Ah0OlzK{\n\ttext-align: center;\n    display: flex;\n\tfont-size: 12px;\n\twidth: 60px;\n\tpadding: 3px 0;\n\tfont-weight: bold;\n}\n._1UhHUcZHc9fpbaOemdAYJJ{\n\tflex: 1;\n\tbackground-color: #c8ffca;\n\tborder-right: 2px solid #41f148;\n\tfont-size: 12px;\n\tpadding: 3px 5px;\n}\n\n._3dgFQbB-atMExTmqjfIGVE{\n\tmargin-bottom: 5px;\n}\n\n._1OcXdTrq-W1ZSUtAoWRGX5{\n\tbackground-color: #ffe7c8;\n    font-size: 12px;\n    display: inline;\n    margin-right: 5px;\n    height: 21px;\n    line-height: 22px;\n    padding: 3px 5px;\n    border: 1px solid #e0e0e0;\n    border-radius: 2px;\n    color: #666;\n    text-decoration: none!important;\n    word-break: keep-all;\n\tcursor: pointer;\n}\n\n._1OcXdTrq-W1ZSUtAoWRGX5:hover{\n\tfont-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"pick_details_board": "_17sZjuakjS1D-wPOmTgeR6",
	"time_board": "_16nJxZcDg2mJAvn1iGZVIJ",
	"tab_title": "no_PH-zqrqBBFyxuA7xKE",
	"time_item_board": "_3vnSeMrw9jlazXxUHJfmsV",
	"time_item_left": "_2l9Z5hd-Qjz20F8Ah0OlzK",
	"time_item_right": "_1UhHUcZHc9fpbaOemdAYJJ",
	"vote_board": "_3dgFQbB-atMExTmqjfIGVE",
	"vote_item_board": "_1OcXdTrq-W1ZSUtAoWRGX5"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1EVGZVYvJQtfw7DVMejNU8{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, .75);\n    top: 0;\n    left: 0;\n    z-index: 10000000000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow-y: auto;\n}\n._3H2UDrFE7yOYNHjl-Qu0J5 {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}\n\n._1P3WO07PVt4Dsc3qxMt3Fo {\n    background-color: #ffffff;\n    border-radius: 5px;\n    min-height: 130px;\n    width: 400px;\n    box-shadow: 0 2px 26px rgba(0, 0, 0, .3), 0 0 0 1px rgba(0, 0, 0, .1);\n    margin: 275px 10px;\n    position: relative;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n}\n\n@media only screen and (max-width: 500px) {\n    ._1P3WO07PVt4Dsc3qxMt3Fo {\n        width: 95vw;\n        min-width: unset;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"main_background": "_1EVGZVYvJQtfw7DVMejNU8",
	"dialog_gray_background": "_3H2UDrFE7yOYNHjl-Qu0J5",
	"message_box_board": "_1P3WO07PVt4Dsc3qxMt3Fo"
};
import Vn from './vn.js';
import En from './en.js';
import Jp from './jp.js';

export default class Language {
    constructor(locale) {
        this.data = Vn;
        switch (locale) {
            case 'vn':
                this.data = Vn;
                break;
            case 'en':
                this.data = En;
                break;
            case 'jp':
                this.data = Jp;
                break;
            default:
                return;
        }
    }

}

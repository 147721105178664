exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1SDikvBye8QqrIkW3TExN0 {\n    border: 1px solid #e2e2e2;\n    border-radius: 2px;\n    padding: 40px 10px 40px 10px;\n    background-color: rgba(249, 249, 249, 0.8);\n}\n\n._1SDikvBye8QqrIkW3TExN0 {\n    color: #999;\n}\n", ""]);

// exports
exports.locals = {
	"list_post_null_board": "_1SDikvBye8QqrIkW3TExN0"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._13jy2DWfr49xrjKX8dxMak{\n    width: 86%;\n}\n\n.XhELS3FEVHvSuUd3Om-xp {\n    margin-bottom: 10px;\n    padding: 13px 10px 75px 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: -webkit-fill-available;\n    /*height: 100%;*/\n}\n._1cZAs2jNVq_Hc4pfTtjp8X{\n    font-size: 19px;\n    color: #02499d;\n    font-weight: bold;\n}\n.yUbANmnIl-5FAoK9L2P0h{\n    color: #333;\n    font-weight: 200;\n    margin-bottom: 45px;\n}\n", ""]);

// exports
exports.locals = {
	"mainImage": "_13jy2DWfr49xrjKX8dxMak",
	"signup_panel": "XhELS3FEVHvSuUd3Om-xp",
	"title": "_1cZAs2jNVq_Hc4pfTtjp8X",
	"description": "yUbANmnIl-5FAoK9L2P0h"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3DwYOanZIbvliQ9oPtgaRf{\n    padding: 10px 5px;\n}\n.mKGQLxFFg37VcRY18Z5_q{\n    overflow-y: auto;\n    max-height: 500px;\n    margin-bottom: -1px;\n}\n.o_6Q6lzTHKpfd_SuBftnl{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n._1NrsjcJGuGNmC-Ot6VTokY{\n    font-weight: 700;\n    color: #333;\n}\n.BjnmKK3LWwWLg_JPyLqua{\n    color: red;\n}\n._1zkRbdAybytDVpIZwYfvKG{\n    display: flex;\n    align-items: center;\n    padding:5px;\n    border-bottom: solid 1px #e2e2e2;\n}\n._1zkRbdAybytDVpIZwYfvKG i{\n    color: #c9302c;\n}\n/*.userItemContainer:hover{\n    opacity: 0.8;\n}*/\n\n._1I0E9cOaBR1ceE-0zum48L{\n    width: 32px;\n    height: 32px;\n    border-radius: 23px;\n    margin-right: 5px;\n}\n._2oLI86gnT-6A63YRFD-epA{\n    margin-bottom: 0;\n    margin-top: 3px;\n    color: #333;\n    font-weight: 700;\n    font-size: 12px;\n}\n\n._344_YUBxTUH0hZrgYNE1pk{\n    background-color: #f9f9f9;\n    border: solid 1px #e2e2e2;\n    border-bottom: none;\n    border-radius: 5px 5px 0 0;\n}\n\n._3LEccv3f0vLXkZemlYUcLr{\n    color: #02499d;\n    cursor: pointer;\n}\n._3LEccv3f0vLXkZemlYUcLr:hover{\n    opacity: 0.8;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3DwYOanZIbvliQ9oPtgaRf",
	"listBoard": "mKGQLxFFg37VcRY18Z5_q",
	"top": "o_6Q6lzTHKpfd_SuBftnl",
	"topTitle": "_1NrsjcJGuGNmC-Ot6VTokY",
	"countText": "BjnmKK3LWwWLg_JPyLqua",
	"userItemContainer": "_1zkRbdAybytDVpIZwYfvKG",
	"avatar": "_1I0E9cOaBR1ceE-0zum48L",
	"userName": "_2oLI86gnT-6A63YRFD-epA",
	"selected": "_344_YUBxTUH0hZrgYNE1pk",
	"deleteButton": "_3LEccv3f0vLXkZemlYUcLr"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZT0u3L_bVPjb-1mMwlM1Q{\n    justify-content: center;\n    display: flex;\n    flex-direction: column;\n    flex:1;\n}\n._1iXMhLamgfyFGMS2QoyAe2{\n    font-weight: 400;\n}\n\n._1Bbm6WEsSnbloRY8Yg8a_u{\n    width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"container": "ZT0u3L_bVPjb-1mMwlM1Q",
	"description": "_1iXMhLamgfyFGMS2QoyAe2",
	"image": "_1Bbm6WEsSnbloRY8Yg8a_u"
};
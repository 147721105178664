import React from 'react';
import ReactDOM from 'react-dom';
import {Link,browserHistory} from 'react-router';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/urlaList_style.css';
import {GNullBoard,GButton} from 'app/components/gTools/gTools.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';

import StatusTag from 'app/components/controlPanel/statusTag.jsx';
import LoadMoreButton from 'app/components/list/loadMoreButton.jsx';
import {checkDeadlineUrla,minDate} from 'app/js/func.js';
import moment from 'moment';
import 'moment/locale/vi';

export default class UrlaList extends React.Component {
    componentDidMount(){
        this.props.urlaListActions.fetchUrlaList('');
    }
    handleOnClick() {
        //this.props.historyPageActions.switchUrlHistory(0);
    }
    loadMore(){
        var list = this.props.urlaList.list;
        this.props.urlaListActions.fetchUrlaList(list[list.length-1].createdAt);
    }
    _renderLoadMoreButton(){
        if(this.props.urlaList.isLoading){
            return(
                <center>
                    <i className={"fa fa-refresh fa-spin "} style={{fontSize:'44',color:'#02499d'}} aria-hidden="true"></i>
                </center>
            );
        }else {
            if(this.props.urlaList.isLoadMore){
                return(
                    <LoadMoreButton onClick={this.loadMore.bind(this)}/>
                );
            }else {
                return null;
            }
        }
    }
    _linkToGenerate(){
        browserHistory.push('/generate');
    }
    _renderList(){
        var data = this.props.urlaList.list.slice(0);
        if(data.length>0){
            var nodeList = data.map((item,index) => {
                return (
                    <UrlaUnit
                        indexKey={index}
                        urlaUnitData={item}
                    />)
            })
            return (
                <div>
                    {nodeList}
                </div>
            );
        }else {
            if(this.props.urlaList.isLoading) return null;
            return(
                <GNullBoard>
                    <p><FormattedMessage id="urlaList.noEventMessage" /></p>
                    <GButton default='green' onClick={this._linkToGenerate.bind(this)}><i className="fa fa-plus-circle" aria-hidden="true"></i> Tạo kèo</GButton>
                </GNullBoard>
            );
        }
    }
    render() {
        return (
            <div style={{marginBottom:'10px'}}>
                {this._renderList()}
                {this._renderLoadMoreButton()}
            </div>
        )
    }
}

export class UrlaUnit extends React.Component {
    handleOnClick() {
        browserHistory.push('/generate/'+this.props.urlaUnitData.hashUrla);
    }
    _renderNewMark(){
        var {createdAt} = this.props.urlaUnitData;
        createdAt = new Date(createdAt).getTime()/1000;
        var toDay = new Date().getTime() / 1000;

        console.log(toDay - createdAt);
        if(toDay - createdAt<86400){
            return(
                <div className={styles.newMark}>New</div>
            );
        }else {
            return null;
        }
    }
    render() {
        var deadline = (this.props.urlaUnitData.deadLine)? (this.props.urlaUnitData.deadLine) : null;
        var selectedDays = (this.props.urlaUnitData.schedule.selectedDays)? (this.props.urlaUnitData.schedule.selectedDays) : {};
        var status = checkDeadlineUrla(deadline,selectedDays);
        var votes = this.props.urlaUnitData.schedule.votes;
        var thumbnail = (this.props.urlaUnitData.setting)?this.props.urlaUnitData.setting.thumbnail:null;
        var iconUrl = (thumbnail)?thumbnail : '/images/icon/select.png';
        var timeStamp = moment(minDate(selectedDays)).fromNow();
        return (
            <div className={globalStyles.white_box}>
                <div onClick={(event) => {this.handleOnClick()}} className={styles.url_unit_board}>
                    <div className={styles.url_unit_icon}>
                        {this._renderNewMark()}
                        <img className={styles.urlb_unit_icon_img} src={iconUrl} />
                    </div>
                    <div className={styles.url_unit_content}>
                        <div className={styles.urlb_unit_title}>
                            {this.props.urlaUnitData.title}
                        </div>
                        <div className={styles.urlb_unit_description}>
                            {timeStamp}
                        </div>
                        <div className={styles.status_bar}>
                            <div className={styles.stats_board}>
                                <i style={{marginRight:'5px'}} className="fa fa-eye"/>
                                <span className={styles.status_text}>{this.props.urlaUnitData.viewCount}</span>
                                <i style={{marginRight:'5px',marginLeft:'5px'}} className="fa fa-user"/>
                                <span className={styles.status_text}>{this.props.urlaUnitData.urlbCount}</span>
                            </div>
                            <StatusTag status={status} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, {cloneElement, createClass, PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import styles from './styles/categoryDescription_style.css';

export default class CategoryDescription extends React.Component {
	_onSiteChanged(){
		if(this.props.onChange){
			this.props.onChange();
		}
	}
	render() {
		var addedStyle = (this.props.style)? (this.props.style): {}
		var titleStyle = styles.main;
		if (this.props.default) {
		switch (this.props.default) {
			case 'normal':
				titleStyle = styles.main;
				break;
			case 'remarkable':
				titleStyle = styles.remarkable;
				break;
			default:
			}
		};
        var {name,value,title} = this.props;
		return (
            <div  style={addedStyle}
				  className={titleStyle}>
                {this.props.children}
            </div>
		);
	}
}

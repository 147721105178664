import React from 'react';
import ReactDOM from 'react-dom';

import styles from './styles/tabMenu_style.css';


export default class TabMenu extends React.Component {
	_onClickItem(value) {
		if (this.props.onSelect) {
			this.props.onSelect(value);
		}
	}
	_renderMain() {
		var self = this;
		if (this.props.data) {
			var listNodes = this.props.data.map(function (item, index) {
				return (
					<IconText
						onClick={self._onClickItem.bind(self)}
						selectedValue={self.props.selectedValue}
						data={item}
						index={index}/>
				);
			});
			return (listNodes);
		}else {
			return null;
		}
	}
	render() {
		return (
			<div className={styles.tab_menu_board}>
				{this._renderMain()}
			</div>
		);
	}
}


export class IconText extends React.Component{
    _onClick(){
        if(this.props.onClick){
            this.props.onClick(this.props.data.value);
        }
    }
    render() {
        var icon= (this.props.data.icon)?this.props.data.icon:'fa fa-sort-asc ';
		var title= this.props.data.title;
		var className = '';
        if(this.props.data.value == this.props.selectedValue){
            className = styles.main_menu_item_selected;
        }
        return (
            <div className={styles.main_menu_item+' '+className} onClick={this._onClick.bind(this)}>
				<i className={icon+' '+styles.tab_menu_icon} aria-hidden="true"></i>
                <span >
                    {title}
                </span>
            </div>
        );
    }
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sHDU9SkkAdFKKS8w8Q_jE{\n\tfont-size: 24px;\n\tfont-weight: 700;\n\tcolor: #333;\n}\n._3NwZnSYax-Po8LNvJa8kB{\n\tfont-size: 14px;\n    color: #777;\n    font-weight: 300;\n    line-height: 16px;\n\ttext-align: justify;\n}\n._2PVCRU_ZqMl-EbesVtiS7f{\n\tmargin-top: 36px;\n}\n.S9kNfDHtz5JeysSmThs1-{\n\tfont-size: 13px;\n\tcolor: #999;\n\n}\n", ""]);

// exports
exports.locals = {
	"header_title": "sHDU9SkkAdFKKS8w8Q_jE",
	"intro_title": "_3NwZnSYax-Po8LNvJa8kB",
	"submit_button_board": "_2PVCRU_ZqMl-EbesVtiS7f",
	"description_title": "S9kNfDHtz5JeysSmThs1-"
};
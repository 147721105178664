exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Zl3FR8btoCe6s54SvOLA9{\n    padding: 10px;\n    border: solid 1px;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n}\n._1Zl3FR8btoCe6s54SvOLA9 p{\n    margin-bottom: 3px;\n}\n._1Zl3FR8btoCe6s54SvOLA9 span{\n    font-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_1Zl3FR8btoCe6s54SvOLA9"
};
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3WLCQ2K0w1c_JUg5rJHs0o{\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"container": "_3WLCQ2K0w1c_JUg5rJHs0o"
};
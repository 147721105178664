import {
    combineReducers
} from 'redux';

import {SET_USER_HOME_TAB_VALUE,SET_USER_HOME_IS_LOADING} from 'app/actions/user/userHome_action.js';

const userHomeDataDefault = {
    tabValue:'urlb',
    isLoading:false
};

function userHomeData (state = userHomeDataDefault,action){
    switch (action.type) {
        case SET_USER_HOME_TAB_VALUE:
            var newState = Object.assign({},state);
            newState.tabValue = action.data;
            return newState;
        case SET_USER_HOME_IS_LOADING:
            var newState = Object.assign({},state);
            newState.isLoading = action.data;
            return newState;
        default:
            return state;
    }
}

export default userHomeData;

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import generatePage from 'app/views/generate/generate.jsx';

import * as generatePageActions from 'app/actions/generate/generate_action.js';
import * as generateResultActions from 'app/actions/generate/generateResult_action.js';
import * as entryDatePickerActions from 'app/actions/entry/entryDatePicker_action.js';


import * as gFormInputActions from 'app/components/gTools/actions/gFormInput_action.js';

import * as commentListActions from 'app/actions/comment/commentList_action.js';
import * as indexActions from 'app/actions/index/index_action.js';
import * as languageActions from 'app/actions/api/language_action.js';
import * as collectionActions from 'app/actions/collection/collection_action.js';
import * as commentBoxActions from 'app/actions/comment/commentBox_action.js';
import * as userActions from 'app/actions/user/user_action.js';
function mapDispatchToProps(dispatch) {
    return {
        generatePageActions:bindActionCreators(generatePageActions,dispatch),
        entryDatePickerActions:bindActionCreators(entryDatePickerActions,dispatch),
        inputActions:bindActionCreators(gFormInputActions,dispatch),
        generateResultActions:bindActionCreators(generateResultActions,dispatch),
        commentListActions : bindActionCreators(commentListActions,dispatch),
        languageActions:bindActionCreators(languageActions,dispatch),
        collectionActions : bindActionCreators(collectionActions,dispatch),
        commentBoxActions : bindActionCreators(commentBoxActions,dispatch),
        userActions : bindActionCreators(userActions,dispatch)
    }
}

function mapStateToProps(state) {
	return ({
        generate:state.generate,
        gFormInputData:state.gFormInput,
        votePanelData:state.votePanelData,
        commentListData:state.commentListData,
        user:state.user,
        language:state.language,
        collectionBox :state.collectionBox,
        commentBox:state.commentBox,
        entryDatePicker:state.entryDatePicker
	});
}

export default connect(mapStateToProps,mapDispatchToProps)(generatePage);

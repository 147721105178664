import React from 'react';
import ReactDOM from 'react-dom';
import { Link, browserHistory } from 'react-router';
import LoginGoogleButton from 'app/components/login/loginGoogleButton.jsx';

import globalStyles from 'app/styles/global_styles.css';
import styles from './styles/generateCheckLogin_style.css';
import { FormattedMessage, injectIntl } from 'react-intl';


export default class GenerateCheckLogin extends React.Component {

    componentDidMount() {
        if (this.props.user.isLogin) {
            this.props.generatePageActions.changeMainRenderMod('setting');
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.user.isFetch != nextProps.user.isFetch) {
            if (nextProps.user.isFetch) {
                if (nextProps.user.isLogin) {
                    this.props.generatePageActions.changeMainRenderMod('setting');
                } else {

                }
            }
        }
    }
    _nextToGenerate() {
        this.props.generatePageActions.changeMainRenderMod('setting');
    }
    render() {
        if (!this.props.user.isFetch && !this.props.user.isLogin) {
            return (
                <div className={styles.loadingBoard}>
                    <i className={"fa fa-refresh fa-spin "} style={{ fontSize: '44', color: '#02499d' }} aria-hidden="true"></i>
                </div>
            )
        } else {
            return (
                <div className={globalStyles.white_box} style={{ minHeight: 500 }}>
                    <div className={styles.container}>
                        <img src='/images/art/camping_1.png' className={styles.mainImage} />
                        <p className={styles.title} style={{ marginBottom: 3, marginTop: 15 }}><FormattedMessage id="generateCheckLogin.eventManagementMessage" /></p>
                        <p className={styles.description} style={{ marginBottom: 44, padding: '0 31px' }}><b>Pickboz.com</b> <FormattedMessage id="generateCheckLogin.predictionManagementMessage" /></p>
                        <LoginGoogleButton align style={{ width: 200 }} />
                        <div style={{ height: 75 }}></div>
                        <span className={styles.skipButton} onClick={this._nextToGenerate.bind(this)}>
                            <FormattedMessage id="generateCheckLogin.skipMessage" />
                        </span>
                    </div>
                </div>
            );
        }
    }
}
